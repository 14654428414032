import React from 'react';
import { ToolBar } from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';

export const EquipmentAll = ({ config, data, labels }) => {
  // eslint-disable-next-line no-param-reassign
  config.toolbarConfig.defaultSelection = 'apps';

  return (
    <>
      <ToolBar config={config.toolbarConfig} />
      <br />
      <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
        <DataTable
          config={config.tableConfig}
          data={data}
          labels={labels}
        />
      </div>
    </>
  );
};
