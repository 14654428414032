import React from 'react';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { SimpleSearch } from '../Search/SimpleSearch';
import { Error } from '../Error/Error';

export const PatientsWrapper = ({
  search,
  table,
  tableData,
  searchTerm,
  setSearchTerm,
  renderState,
  error,
  hasError = false,
}) => {
  // eslint-disable-next-line no-param-reassign
  search.config.onSearch = (value) => setSearchTerm(value.q);
  return (
    <div className="ccl__dpx_patient-wrapper">
      <SimpleSearch
        config={search.config}
        labels={search.labels}
        data={search.data}
      />
      <div className="ccl__container">
        {hasError && (
          <Error
            config={error.config}
            labels={error.labels}
            data={error.data}
          />
        )}
        {!hasError && searchTerm && (
          <div className="ccl__data-table--dpx">
            <DataTable
              config={table.config}
              data={tableData}
              labels={table.labels}
              stateData={{
                parameters: { searchTerm },
                renderState,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
