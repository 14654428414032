import cookieService from '../services/cookieService';
import { ACCESS_TOKEN_COOKIE } from '../config';

const getHeaders = customHeaders => {
  const accessToken = cookieService.getCookie(ACCESS_TOKEN_COOKIE);
  return {
    headers: {
      'x-auth-token': accessToken,
      ...(customHeaders || {}),
    },
  };
};

export default getHeaders;
