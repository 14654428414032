import { useEffect } from 'react';

// custom hook to add data-analytics attributes to elements
// identifiers is an array of strings that represent the elements that needs to be tagged - e.g navbar
const useAddAttributes = (identifiers) => {
  const map = {
    navbar: {
      selector: '.ccl-e-custom-dropdown__container__header--nav-menu',
      prefix: 'navmenu',
    },
  };

  useEffect(() => {
    identifiers.forEach((identifier) => {
      const elements = document.querySelectorAll(
        map[identifier]?.selector,
      );

      elements.forEach((element, index) => {
        const pElement = element.querySelector('p');
        let textValue = pElement
          ? pElement.textContent
          : `${map[identifier].prefix}-${index}`;
        textValue = textValue.replace(/\s+/g, '-');
        element.setAttribute(
          'data-analytics',
          `${map[identifier].prefix}-${textValue}`,
        );
      });
    });
  }, [identifiers]);
};

export default useAddAttributes;
