import axios from 'axios';
import {
  PROVIDER_DETAILS_COOKIE,
  providerListEndpoint,
} from '../../config';
import getHeaders from '../../utils/getHeaders';
import cookieService from '../cookieService';
import { logger } from '../../logger';

const log = logger();

const getQueryString = (searchType, serialNumber) => {
  if (searchType === 'lotNumber') {
    return `&lotNumber=${serialNumber}&productType=Implant&status=Shipped&limit=1`;
  }

  if (serialNumber.length <= 7) {
    return `&serialNumber=${serialNumber}&partialSearch=true`;
  }

  return `&serialNumber=${serialNumber}`;
};

const deviceAlreadyRegistered = (deviceList) => {
  const registeredList = deviceList.filter((device) => {
    return device.status.toLowerCase() === 'current';
  });
  return registeredList.length > 0;
};

const searchForDevice = async (
  serialNumber,
  countryCode,
  searchType = 'serialNumber',
) => {
  const cookieContent = cookieService.getCookie(
    PROVIDER_DETAILS_COOKIE,
  );

  const allowedDevicesList = [
    'speech processor',
    'implant',
    'remote assistant',
    'accessory',
  ];

  const disallowedStatusList = [
    'current',
    'historical',
    'loaner',
    'explanted',
    'expired',
  ];

  const { providerId } = cookieContent;

  try {
    const response = await axios.get(
      `${providerListEndpoint}/${providerId}/devices?countryCode=${countryCode}${getQueryString(
        searchType,
        serialNumber,
      )}`,
      getHeaders(),
    );
    if (
      response.status === 200 &&
      response.data.devices &&
      response.data.devices.length > 0
    ) {
      const { devices } = response.data;
      const filteredList = devices.filter((device) => {
        const isAllowedStatusType = !disallowedStatusList.includes(
          device.status.toLowerCase(),
        );
        const isAllowedDeviceType = allowedDevicesList.includes(
          device.type.toLowerCase(),
        );
        return isAllowedStatusType && isAllowedDeviceType;
      });

      const registered =
        filteredList.length === 0 && deviceAlreadyRegistered(devices);

      return {
        apiResponse: `${response.status}`,
        deviceList: filteredList,
        registered,
      };
    }
    return {
      apiResponse: `${response.status}`,
      deviceList: [],
    };
  } catch (err) {
    log.error('Error searching for device', err);
    return {
      apiResponse: `400`,
      deviceList: [],
    };
  }
};

const fetchDeviceService = {
  searchForDevice,
};

export default fetchDeviceService;
