/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  TextInput,
  DateInput,
  Text,
  Button,
  OverlayTrigger,
  Tooltip,
  Stack,
  Pictogram,
  Title,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FindClinicModal } from '../../FindClinicModal/FindClinicModal';
import { Error } from '../../Error/Error';
import AgreementModal from '../modals/AgreementModal';

const DATE_UNITS = ['day', 'month', 'year'];

const getInputValidations = (inputNames, fieldsConfig, labels) =>
  Yup.object(
    inputNames.reduce((acc, inputName) => {
      // Config supplied for dateOfBirth applies to all three d/m/y inputs
      const configFieldName = DATE_UNITS.includes(inputName)
        ? 'dateOfBirth'
        : inputName;
      return {
        ...acc,
        [inputName]: fieldsConfig[configFieldName].validators
          .reduce((acc2, { type, value, message }) => {
            if (type === 'required')
              return acc2[type](labels[message]);
            return acc2[type](value, labels[message]);
          }, Yup.string())
          .trim(),
      };
    }, {}),
  );

/*
 * Gauge whether year, month & day combine as a valid date between 1000 ACE & the
 * current date. This is needed as our date input uses three text fields, & this
 * allows us to avoid using dynamic regex rules to validate numbers.
 */
const isValidPastDate = ({ year, month, day }) => {
  // If year is less than 1000, or either month or date are 0 or less
  if (
    Number(year) < 1000 ||
    [month, day].find(
      (value) => !Number(value) || Number(value) <= 0,
    ) !== undefined
  ) {
    return false;
  }

  const dateParsed = new Date(year, month - 1, day);

  return (
    // Date is valid for specified month
    dateParsed.getDate() === Number(day) &&
    // Month is valid
    Number(month) <= 12 &&
    // Is in past
    dateParsed <= new Date().setHours(0, 0, 0, 0)
  );
};

const baseDateFieldsConfig = {
  d: {
    maxLength: 2,
    name: 'day',
    size: 2,
  },
  m: {
    maxLength: 2,
    name: 'month',
    size: 2,
  },
  y: {
    minLength: 4,
    maxLength: 4,
    name: 'year',
    size: 4,
  },
};

const getInitialValues = (parameters) => {
  const { firstName = '', lastName = '', dateOfBirth } = parameters;
  const [year, month, day] = dateOfBirth
    ? dateOfBirth.split('-')
    : new Array(3).fill('');

  return {
    firstName,
    lastName,
    day,
    month,
    year,
  };
};
export default function Patient({ search, table }) {
  const {
    onSubmit: onSubmitProp,
    className,
    labels,
    searchConfig,
    parameters,
    selectedClinicData,
    submitDisabled,
    setSubmitDisabled,
  } = search;
  const {
    formats,
    patientIdentifier: patientIdentifierProp,
    fields: fieldsProp,
    hasError,
  } = searchConfig;

  const {
    patientResultTableConfig,
    tableData,
    renderState,
    patientCount,
  } = table;
  const { multipleResults } = patientResultTableConfig;
  const [validateAfterTouched, setValidateAfterTouched] =
    useState(false);
  const patientIdentifierDisplayDefault = {
    'device-serial': false,
    'account-username': false,
    'associated-clinic': false,
  };
  const [showPatientIdentifier, setShowPatientIdentifier] = useState(
    patientIdentifierDisplayDefault,
  );
  const [submitted, setSubmitted] = useState(false);
  const [show, setShow] = useState(false);
  const [clinicSelected, setClinicSelected] = useState(false);
  const scrollTo = useRef(0);
  const [nameTouched, setNameTouched] = useState(false);

  const scrollToBottom = () => {
    scrollTo.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [renderState]);

  const initialValues = getInitialValues(
    parameters,
    patientIdentifierProp,
  );

  const fieldsConfig = fieldsProp.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );

  const createValidationSchema = (validators) => {
    let schema = Yup.string();

    validators.forEach((validator) => {
      switch (validator.type) {
        case 'min':
          schema = schema.min(
            validator.value,
            labels[validator.message],
          );
          break;
        case 'max':
          schema = schema.max(
            validator.value,
            labels[validator.message],
          );
          break;
        case 'matches':
          schema = schema.matches(
            validator.value,
            labels[validator.message],
          );
          break;
        case 'required':
          schema = schema.required(labels[validator.message]);
          break;
        default:
          break;
      }
    });
    return schema;
  };

  const validationSchema1 = getInputValidations(
    [...DATE_UNITS],
    fieldsConfig,
    labels,
  );

  const validationSchema2 = Yup.object({
    patientIdentifier: Yup.string().required(''),
    deviceSerial: Yup.string().when('patientIdentifier', {
      is: 'device-serial',
      then: () =>
        createValidationSchema(fieldsConfig.deviceSerial.validators),
      otherwise: () => Yup.string().notRequired(),
    }),
    accountUsername: Yup.string().when('patientIdentifier', {
      is: 'account-username',
      then: () =>
        createValidationSchema(
          fieldsConfig.accountUsername.validators,
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    associatedClinic: Yup.string().when('patientIdentifier', {
      is: 'associated-clinic',
      then: () =>
        createValidationSchema(
          fieldsConfig.associatedClinic.validators,
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const validationSchema =
    validationSchema1.concat(validationSchema2);

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    onSubmit: ({ day, month, year, ...submittedValues }) => {
      setSubmitted(true);
      if (values.firstName || values.lastName) {
        setNameTouched(true);
      }
      onSubmitProp({
        searchType: 'patient',
        parameters: {
          dateOfBirth:
            day && month && year
              ? `${year}-${month.padStart(2, '0')}-${day.padStart(
                  2,
                  '0',
                )}`
              : '',
          ...submittedValues,
        },
      });
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: validateAfterTouched,
  });

  const [dobValid, dobErrorMessage] = (() => {
    const isTouched = DATE_UNITS.filter((unit) => touched[unit]);

    if (isTouched.length < 3) return [false];
    if (isValidPastDate(values)) return [true];

    const invalidDobErrorKey =
      fieldsConfig.dateOfBirth.validators.find(
        ({ type }) => type === 'required',
      ).message;

    return [false, labels[invalidDobErrorKey]];
  })();

  const displayPatientIdentifier = (value) => {
    const data = { ...patientIdentifierDisplayDefault };
    data[value] = true;
    setShowPatientIdentifier(data);
  };

  const buildPatientIdentifier = patientIdentifierProp.map(
    ({ value, label, disabled }) => ({
      id: `advanced-search__patient__radio__${value}`,
      label: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p onClick={() => displayPatientIdentifier(value)}>
          {labels[label]}
        </p>
      ),
      value,
      disabled,
      checked: values.patientIdentifier === value,
    }),
  );

  const dateFieldsConfig = (() =>
    // Extract dmy key order from a variety of different date formats
    formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          baseDateFieldsConfig[field];
        const label =
          labels[fieldsConfig.dateOfBirth.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: values[name],
        };
      }))();

  useEffect(() => {
    setSubmitDisabled(true);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(touched).length > 0 &&
      dobValid
    ) {
      setSubmitDisabled(false);
    }
  }, [errors, values, touched]);

  const onChange = ({ target: { value, name: inputName } }) => {
    if (inputName === 'patientIdentifier') {
      setFieldValue('patientIdentifier', value);
      displayPatientIdentifier(value);
      return;
    }
    setFieldValue(inputName, value);
  };

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;
    const isTouched = [
      'accountUsername',
      'firstName',
      'lastName',
    ].some((field) => field in touched);
    if (hasErrors && isTouched) {
      setValidateAfterTouched(true);
    }
  }, [errors, touched]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      // detect Enter key and submit
      if (e.keyCode === 13 && !submitDisabled) {
        e.preventDefault();
        handleSubmit();
      }
    };

    if (typeof document !== 'undefined') {
      // eslint-disable-next-line no-undef
      document.addEventListener('keydown', keyDownHandler);
    }

    return () => {
      if (typeof document !== 'undefined') {
        // eslint-disable-next-line no-undef
        document.removeEventListener('keydown', keyDownHandler);
      }
    };
  }, [submitDisabled]);

  useEffect(() => {
    if (selectedClinicData.length > 0) {
      setClinicSelected(true);
      setFieldValue(
        'associatedClinic',
        selectedClinicData[0].clinicId,
      );
      setShow(false);
    }
  }, [selectedClinicData]);

  return (
    <div
      className={`ccl-l-advanced-search-patient-tab ${
        className || ''
      }`}
    >
      <form onSubmit={handleSubmit} onChange={onChange}>
        <RadioGroup
          className="ccl-l-advanced-search-patient-tab__patientIdentifier"
          fields={buildPatientIdentifier}
          label={labels[searchConfig.patientIdentifierLabel]}
          name="patientIdentifier"
        />
        {showPatientIdentifier['device-serial'] && (
          <TextInput
            className="ccl-l-advanced-search-patient-tab__fields__deviceSerial"
            name="deviceSerial"
            value={values.deviceSerial}
            onChange={handleChange}
            onBlur={handleBlur}
            label={labels[fieldsConfig.deviceSerial.label]}
            hint={labels[fieldsConfig.deviceSerial.hint]}
            error={touched.deviceSerial && !!errors.deviceSerial}
            errorMsg={touched.deviceSerial && errors.deviceSerial}
          />
        )}
        {showPatientIdentifier['account-username'] && (
          <TextInput
            className="ccl-l-advanced-search-patient-tab__fields__accountUsername"
            name="accountUsername"
            value={values.accountUsername}
            onChange={handleChange}
            onBlur={handleBlur}
            label={labels[fieldsConfig.accountUsername.label]}
            hint={labels[fieldsConfig.accountUsername.hint]}
            error={
              touched.accountUsername && !!errors.accountUsername
            }
            errorMsg={
              touched.accountUsername && errors.accountUsername
            }
          />
        )}
        {showPatientIdentifier['associated-clinic'] && (
          <div className="ccl-l-advanced-search-patient-tab__fields__associated-clinic">
            {clinicSelected ? (
              <>
                <Text
                  content={selectedClinicData[0].name}
                  type="body-text"
                />
                <Text
                  content={selectedClinicData[0].address}
                  type="small-body-text"
                  className="ccl-l-advanced-search-patient-tab__fields__associated-clinic__address"
                />
                <Text
                  content={
                    labels[fieldsConfig.associatedClinic.changeLabel]
                  }
                  type="link-text"
                  className="ccl-l-advanced-search-patient-tab__fields__associated-clinic__change"
                  onClick={() => setShow(true)}
                />

                <input
                  type="hidden"
                  value={values.associatedClinic}
                  name="associatedClinic"
                />
              </>
            ) : (
              <Button
                text={labels[fieldsConfig.associatedClinic.label]}
                variant="secondary"
                onClick={() => setShow(true)}
                data-analytics="open_find_clinic"
              />
            )}
          </div>
        )}
        <DateInput
          className="ccl-l-advanced-search-patient-tab__fields__dob"
          name="dateOfBirth"
          isStrict
          label={labels[fieldsConfig.dateOfBirth.label]}
          hint={labels[fieldsConfig.dateOfBirth.hint]}
          inputMode="numeric"
          onChange={handleChange}
          onBlur={handleBlur}
          fields={dateFieldsConfig}
          error={!!dobErrorMessage}
          errorMsg={dobErrorMessage}
        />

        {(multipleResults || nameTouched) && (
          <>
            <TextInput
              className="ccl-l-advanced-search-patient-tab__fields__name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={labels[fieldsConfig.firstName.label]}
              hint={labels[fieldsConfig.firstName.hint]}
              error={touched.firstName && !!errors.firstName}
              errorMsg={touched.firstName && errors.firstName}
            />
            <TextInput
              className="ccl-l-advanced-search-patient-tab__fields__name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={labels[fieldsConfig.lastName.label]}
              hint={labels[fieldsConfig.lastName.hint]}
              error={touched.lastName && !!errors.lastName}
              errorMsg={touched.lastName && errors.lastName}
            />
          </>
        )}
        <OverlayTrigger
          show={!submitDisabled ? false : undefined}
          overlay={
            <Tooltip id="advanced-search-patient-tab-submit-tooltip">
              <Text
                className="ccl-l-advanced-search-patient-tab__fields__submit__tooltipText"
                type="x-small-body-text"
                isHTML
                content={labels[searchConfig.submitButtonTooltip]}
              />
            </Tooltip>
          }
        >
          <span
            className={`ccl-l-advanced-search-patient-tab__fields__submit ${
              submitDisabled
                ? 'ccl-l-advanced-search-patient-tab__fields__submit--disabled'
                : ''
            }`}
          >
            <Button
              className="ccl-l-advanced-search-patient-tab__fields__submit__button"
              text={labels[searchConfig.submitButtonLabel]}
              variant="digital-primary"
              disabled={submitDisabled}
              onClick={handleSubmit}
              data-analytics="search_locate_patient"
            />
          </span>
        </OverlayTrigger>
        <FindClinicModal
          {...searchConfig.clinicSelectorConfig}
          show={show}
          onHide={() => setShow(false)}
          labels={labels}
        />
      </form>

      <div className="ccl-locate-patient__table">
        {hasError ? (
          <Error
            config={{
              buttons: [],
              imgAlt: searchConfig.errorConfig.imgAlt,
              imgSrc: searchConfig.errorConfig.imgSrc,
              imgWidth: searchConfig.errorConfig.imgWidth,
              text: searchConfig.errorConfig.text,
              title: searchConfig.errorConfig.title,
              layout: searchConfig.errorConfig.layout,
            }}
            labels={labels}
            data={{}}
          />
        ) : (
          submitted &&
          !multipleResults && (
            <DataTable
              config={patientResultTableConfig}
              data={tableData}
              labels={labels}
              stateData={{
                parameters: { searchTerm: '' },
                renderState,
              }}
            />
          )
        )}
        {multipleResults &&
          (renderState === 'loading' ? (
            // use Data Table's spinner
            <DataTable
              config={patientResultTableConfig}
              data={tableData}
              labels={labels}
              stateData={{
                parameters: { searchTerm: '' },
                renderState,
              }}
            />
          ) : (
            <div className="ccl__dpx_center-600">
              <Stack direction="horizontal" gap={3}>
                <Pictogram name="404Error2" customWidth="100" />
                <div>
                  <Title
                    content={`${patientCount} ${labels['labels.advancedSearch.tabs.patient.multipleRecords.heading']}`}
                    size="heading-4"
                    tag="h4"
                  />
                  <Text
                    content={
                      labels[
                        'labels.advancedSearch.tabs.patient.multipleRecords.text'
                      ]
                    }
                    type="small-body-text"
                  />
                </div>
              </Stack>
            </div>
          ))}

        <div ref={scrollTo} />
      </div>

      <AgreementModal
        config={searchConfig.agreementModalConfig}
        labels={labels}
        onClose={searchConfig.agreementModalConfig.onClose}
        onSubmit={searchConfig.agreementModalConfig.onSubmit}
        visible={searchConfig.agreementModalConfig.visible}
      />
    </div>
  );
}

Patient.propTypes = {
  search: PropTypes.shape({
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    searchConfig: PropTypes.object,
    labels: PropTypes.object,
    parameters: PropTypes.object,
    selectedClinicData: PropTypes.array,
  }).isRequired,
  table: PropTypes.shape({
    patientResultTableConfig: PropTypes.object,
    tableData: PropTypes.array,
    renderState: PropTypes.string,
  }).isRequired,
};
