import React from 'react';
import {
  ListGroup,
  Text,
  Icon,
  Row,
  Col,
  FormattedComposite,
} from '@cochlear-design-system/foundation';

const getAddressFormatsMap = (addressFormats) =>
  addressFormats.reduce(
    (acc, { countries, format }) => ({
      ...acc,
      ...countries.reduce(
        (countryFormats, country) => ({
          ...countryFormats,
          [country]: format,
        }),
        {},
      ),
    }),
    {},
  );

const getAddressProps = (
  { countryCode, ...address },
  addressFormatsMap,
) => {
  // Fallback to default address format
  const format =
    addressFormatsMap[countryCode] || addressFormatsMap[''];
  const data = Object.entries(address).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        value,
        type: 'string',
        format: null,
      },
    };
  }, {});

  return {
    config: {
      type: 'composite',
      format,
    },
    data,
  };
};

export const ClinicList = ({
  config: { addressFormats, clickHandler, icon },
  data: clinicsList,
}) => {
  const addressFormatsMap = getAddressFormatsMap(addressFormats);

  return (
    <div className="ccl__dpx-list">
      <ListGroup bsPrefix="ccl__list-group">
        {clinicsList.map(({ id, name, address }) => {
          return (
            <ListGroup.Item
              bsPrefix="ccl__list-item"
              onClick={() => clickHandler(id)}
              key={id}
            >
              <Row>
                <Col xs={11}>
                  <Text content={name} type="interactive-text" />
                  <FormattedComposite
                    {...getAddressProps(address, addressFormatsMap)}
                  />
                </Col>
                <Col xs={1}>
                  <Icon type={icon} size="sm" />
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};
