import React from 'react';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import {
  Title,
  Text,
  ErrorMessage,
  Container,
  Row,
  Col,
  Pictogram,
} from '@cochlear-design-system/foundation';

export const ClinicResult = ({
  scrollable,
  dataTableError,
  tableData,
  tableConfig,
  labels,
  contactCustomerServiceView,
  errorView,
}) => {
  const renderNoResults = () => {
    return (
      <div className="ccl__no-results__isClinicPopup__content">
        <Title
          className="ccl__no-results__isClinicPopup__title"
          content={contactCustomerServiceView.title}
          size="heading-4"
          tag="h4"
        />
        <Text
          className="ccl__no-results__isClinicPopup__subtitle"
          content={contactCustomerServiceView.message}
          isHTML="true"
          type="small-body-text"
        />
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className="ccl__AssociatedClinicPopup-error horizontal">
        <Container>
          <ErrorMessage
            mode="list"
            data={{
              list: {
                image: {
                  pictogramName: 'Bug',
                  alt: errorView.title,
                  width: '116',
                },
                title: errorView.title,
                text: errorView.message,
                buttons: [],
              },
            }}
          />
        </Container>
      </div>
    );
  };

  const renderResultsLimit = () => {
    return (
      <div className="ccl__results-limit">
        <Container>
          <Row>
            <Col xs={3}>
              <Pictogram
                name={tableConfig.resultsLimit.pictogramName}
                customWidth="100"
              />
            </Col>
            <Col xs={9}>
              {labels[tableConfig.resultsLimit.content]}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div
      className={`
      ccl__AssociatedClinicPopup__element 
      ccl__AssociatedClinicPopup__element__dataTable
      ${
        scrollable && !dataTableError && tableData?.length > 0
          ? 'ccl__AssociatedClinicPopup__element__scrollable'
          : ''
      }`}
    >
      {dataTableError && renderError()}

      {!dataTableError && tableData?.length > 0 && (
        <DataTable
          config={tableConfig}
          labels={labels}
          data={tableData}
          stateData={{
            parameters: {},
            renderState: 'loaded',
          }}
        />
      )}

      {!dataTableError &&
        tableData?.length === 0 &&
        renderNoResults()}

      {!dataTableError &&
        tableData?.length === tableConfig.resultsLimit.maxResults &&
        renderResultsLimit()}
    </div>
  );
};
