import React, { useState, useRef } from 'react';
import { SelectDropdown } from '@cochlear-design-system/foundation';
import { useDetectOutside } from './useDetectOutside';

export const TopNav = ({ config }) => {
  const { topNavMenus } = config;
  const [activeMenu, setActiveMenu] = useState(topNavMenus.selected);
  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState();
  const topNav = useRef();

  // detect if any area outside of topNav has been clicked
  // if so, close all menus
  useDetectOutside(topNav, () => {
    setIsOpen(false);
    setOpenMenu(undefined);
  });

  return (
    <nav
      className="ccl__topNav"
      ref={topNav}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="ccl__container">
        <div className="ccl__topNav--container">
          {topNavMenus.menus.map((menu) => {
            const { items, onChange } = menu;
            const active = menu.id === activeMenu;

            return (
              <SelectDropdown
                items={items}
                onChange={onChange}
                cssModifier="nav-menu"
                key={menu.id}
                id={menu.id}
                active={active}
                setActiveMenu={setActiveMenu}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isHoverOpen
                isLeaveClose={false}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
                isPersistSelectEvent
              />
            );
          })}
        </div>
      </div>
    </nav>
  );
};
