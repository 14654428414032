/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React from 'react';
import { DatePicker, Text } from '@cochlear-design-system/foundation';
import { getDateInputFormat } from '../../utils/dateTimeFns';
import DetailActionBar from '../DetailActionBar';

const RegistrationReview = ({
  labels,
  registrationData,
  purchaseDate,
  updatePurchaseDate,
  editHandler,
}) => {
  const { format: dateFormat, placeholder } = getDateInputFormat(
    registrationData.country,
    true,
  );

  return (
    <div>
      <section className="ccl-c-form-section">
        <DetailActionBar
          label={
            labels['labels.accessoryRegistration.review.accessory']
          }
          editLabel={labels['labels.accessoryRegistration.edit']}
          onClickHandler={editHandler}
        />
        <div className="mt-3 ccl__flex">
          <Text
            content={
              labels[
                'labels.accessoryRegistration.review.accessory.description'
              ]
            }
            type="body-text-bold"
            style={{ minWidth: '8rem' }}
          />
          <Text content={registrationData.description} />
        </div>
        <div className="mt-3 ccl__flex">
          <Text
            content={
              labels[
                'labels.accessoryRegistration.submitted.accessory.partNumber'
              ]
            }
            type="body-text-bold"
            style={{ minWidth: '8rem' }}
          />
          <Text content={registrationData.partNumber} />
        </div>
      </section>
      <section className="ccl-c-form-section">
        <DetailActionBar
          label={
            labels[
              'labels.accessoryRegistration.review.accessoryDetails'
            ]
          }
        />
        <DatePicker
          isSpecial
          label={
            labels[
              'labels.accessoryRegistration.submitted.accessory.purchaseDate'
            ]
          }
          selected={purchaseDate}
          promptText={labels[placeholder]}
          optionalText={null}
          hint={null}
          dateFormat={dateFormat}
          minDate={
            new Date(new Date().setDate(new Date().getDate() - 365))
          }
          maxDate={new Date()}
          onChange={(date) => {
            updatePurchaseDate(date);
          }}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
        />
      </section>
    </div>
  );
};

export default RegistrationReview;
