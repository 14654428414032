import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  Offcanvas,
  ListGroup,
  Badge,
  Text,
  CloseButton,
} from '@cochlear-design-system/foundation';
import { getLabelsForFields } from '../../utils/labelHelper';
import eventEmitter from '../../eventEmmiter';
import notificationStatus from '../../actions/notificationStatusAction';
import fetchNotificationsService from '../../services/notifications/fetchNotificationService';
import updateNotificationsService from '../../services/notifications/updateNotificationService';
import providerService from '../../services/providerService';
import userService from '../../services/userService';

import {
  NOTIFICATION_STATUS_APPEND,
  NOTIFICATION_STATUS_DELETE,
  NOTIFICATION_UPDATE_RETRY,
} from '../../actions/types';

const AsyncNotificationWidget = ({ rendering }) => {
  const labels = getLabelsForFields(rendering.fields);
  const userCountry = localStorage.getItem('userCountry');
  const userLang = localStorage.getItem('userLang');

  const { providerId } = providerService.getProviderDetails();
  const { professionalId } = userService.getUserDetails();

  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notificationStatus.notifications,
  );
  const isNotificationsFetched = useSelector(
    (state) => state.notificationStatus.isNotificationsFetched,
  );
  const retryIntervals = useSelector(
    (state) => state.notificationStatus.retryIntervals,
  );

  const [showNotificationMessage, setShowNotificationMessage] =
    useState(false);
  const handleClose = () => setShowNotificationMessage(false);
  const handleShow = () => setShowNotificationMessage(true);

  const getCustomerSupportLink = (linkTitle) => {
    return `<a target="_blank" href=${labels['labels.asyncNotificaton.customerServiceLink']}>${linkTitle}</a>`;
  };

  const formatDeviceErrorMessage = (details) => {
    if (details.caseNumber && details.isBackupOrConsignment) {
      return `${
        labels['labels.asyncNotificaton.deviceRegErrorMessageBefore']
      } ${getCustomerSupportLink(
        labels['labels.asyncNotificaton.deviceRegErrorMessageMiddle'],
      )} ${
        labels['labels.asyncNotificaton.deviceRegErrorMessageAfter']
      }${details.caseNumber}.`;
    }
    if (details.caseNumber) {
      return `${
        labels[
          'labels.asyncNotificaton.equipmentRegErrorMessageBefore'
        ]
      } ${getCustomerSupportLink(
        labels[
          'labels.asyncNotificaton.equipmentRegErrorMessageMiddle'
        ],
      )} ${
        labels[
          'labels.asyncNotificaton.equipmentRegErrorMessageAfter'
        ]
      }${details.caseNumber}.`;
    }
    return `${details.deviceType} ${
      labels['labels.asyncNotificaton.registrationErrorMessage']
    }${getCustomerSupportLink(
      labels[
        'labels.asyncNotificaton.registrationErrorMessage.customerSupport'
      ],
    )}  `;
  };

  // <Name>'s equipment registration in Progress. A case is created and will be processed manually. Reach out to Customer support for assistance with <Caseid>

  const getCompletedNotificationMessageByEntityName = (details) => {
    switch (details.entity) {
      case 'Device':
        if (details.deviceType?.toLowerCase() === 'accessory') {
          return labels[
            'labels.asyncNotificaton.getErrorNotification.accessorySuccess'
          ];
        }
        return labels[
          'labels.asyncNotificaton.registrationSuccessMessage1'
        ];
      case 'Patient/Carer':
        return `${details.entity} ${labels['labels.asyncNotificaton.registrationSuccessMessage2']}`;
      case 'Carer':
        return `${details.entity} ${labels['labels.asyncNotificaton.registrationSuccessMessage3']}`;
      case 'Candidate':
        return labels[
          'labels.asyncNotificaton.registrationSuccessMessage4'
        ];
      default:
        return '';
    }
  };

  const getErrorNotificationMessageByEntityName = (details) => {
    switch (details.entity) {
      case 'Device':
        if (details.deviceType?.toLowerCase() === 'accessory') {
          const messageTemplate =
            labels[
              'labels.asyncNotificaton.getErrorNotification.accessoryFailure'
            ];
          return messageTemplate.replace(
            /\{([^}]+)\}/,
            (_, customerSupportText) =>
              getCustomerSupportLink(customerSupportText),
          );
        }
        return formatDeviceErrorMessage(details);
      case 'Patient/Carer':
      case 'Carer':
        return `${details.entity} ${
          labels[
            'labels.asyncNotificaton.getErrorNotification.Patient.Before'
          ]
        }${getCustomerSupportLink(
          labels[
            'labels.asyncNotificaton.getErrorNotification.Patient.customerSupport'
          ],
        )} ${
          labels[
            'labels.asyncNotificaton.getErrorNotification.Patient.After'
          ]
        }`;
      case 'Candidate':
        return `${
          labels[
            'labels.asyncNotificaton.getErrorNotification.Candidate.Before'
          ]
        } ${details.firstName} ${details.lastName} ${
          labels[
            'labels.asyncNotificaton.getErrorNotification.Candidate.After'
          ]
        } ${getCustomerSupportLink(
          labels[
            'labels.asyncNotificaton.getErrorNotification.Candidate.customerSupport'
          ],
        )} ${
          labels[
            'labels.asyncNotificaton.getErrorNotification.Candidate.After2'
          ]
        }`;
      default:
        return '';
    }
  };

  const getMessageByStatus = (details) => {
    switch (details.status) {
      case 'processed':
        return getCompletedNotificationMessageByEntityName(details);
      case 'error':
        return getErrorNotificationMessageByEntityName(details);
      default:
        return '';
    }
  };

  const getInterval = () => {
    const [first] = retryIntervals;
    return first;
  };
  const getNotifications = async () => {
    const response =
      await fetchNotificationsService.fetchNotifications(
        providerId,
        professionalId,
      );
    if (!response) {
      return [];
    }
    return response;
  };

  const updateNotification = async (payload) => {
    const { requestId } = payload;
    await updateNotificationsService.updateNotification(
      providerId,
      professionalId,
      { requestId, isViewed: true },
    );
  };

  const appendNotification = async () => {
    if (!isNotificationsFetched) {
      const notificationList = await getNotifications();
      const filteredNotifications = notificationList.filter(
        (notification) => {
          const index = notifications.findIndex(
            (row) =>
              row.requestId === notification.requestId &&
              row.details.status === notification.details.status,
          );
          return index === -1;
        },
      );
      if (filteredNotifications.length > 0) {
        const containsDeviceSuccess = filteredNotifications.some(
          (item) =>
            item.details.entity === 'Device' &&
            item.details.status === 'processed' &&
            new Date() - new Date(item.lastUpdated) < 60000,
        ); // only notifications within 1 minute
        if (containsDeviceSuccess) {
          eventEmitter.emit(
            'containsDeviceSuccess',
            containsDeviceSuccess,
          );
        }

        dispatch(
          notificationStatus(
            NOTIFICATION_STATUS_APPEND,
            filteredNotifications,
          ),
        );
      }
      dispatch(
        notificationStatus(NOTIFICATION_UPDATE_RETRY, getInterval()),
      );
    }
  };

  const deleteNotification = async (payload) => {
    await updateNotification(payload);
    dispatch(notificationStatus(NOTIFICATION_STATUS_DELETE, payload));
  };

  const goToPatientDetailsPage = async (notification) => {
    if (!notification.details.patientId) {
      return false;
    }
    await deleteNotification(notification);
    const path = `/${userCountry}/${userLang}/patients/details?patientId=${notification.details.patientId}`;
    window.location.replace(path);
    return true;
  };

  const timeAgo = (timeStamp) => {
    const beforeTime = new Date(timeStamp);
    const nowTime = new Date();
    const diffInMinutes = Math.round(
      (nowTime - beforeTime) / 1000 / 60,
    );
    const diffInHours = Math.round(diffInMinutes / 60);
    const diffInDays = Math.round(diffInHours / 24);
    const diffInMonths = Math.round(diffInDays / 30);

    if (beforeTime > nowTime) {
      return '';
    }
    if (diffInMinutes < 2) {
      return labels[
        'labels.asyncNotificaton.getErrorNotification.oneMinAgo'
      ];
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${labels['labels.asyncNotificaton.getErrorNotification.minsAgo']}`;
    }
    if (diffInHours < 24) {
      return `${diffInHours} ${
        diffInHours === 1
          ? ` ${labels['labels.asyncNotificaton.getErrorNotification.hourAgo']}`
          : ` ${labels['labels.asyncNotificaton.getErrorNotification.hoursAgo']}`
      } `;
    }
    if (diffInDays < 30) {
      return `${diffInDays} ${
        diffInDays === 1
          ? ` ${labels['labels.asyncNotificaton.getErrorNotification.dayAgo']}`
          : ` ${labels['labels.asyncNotificaton.getErrorNotification.daysAgo']}`
      } `;
    }
    return `${diffInMonths} ${
      diffInMonths === 1
        ? ` ${labels['labels.asyncNotificaton.getErrorNotification.monthAgo']}`
        : ` ${labels['labels.asyncNotificaton.getErrorNotification.monthsAgo']}`
    } `;
  };

  useEffect(() => {
    if (notifications.length === 0) {
      handleClose();
    }
    const timeout = setTimeout(async () => {
      await appendNotification();
    }, getInterval());
    return () => {
      clearTimeout(timeout);
    };
  }, [notifications, isNotificationsFetched, retryIntervals]);

  return (
    <div className="async-notification">
      <div className="badge-container">
        {notifications.length > 0 && (
          <div className="count-badge">
            <Badge pill>{notifications.length}</Badge>
          </div>
        )}
        <IconButton
          handleClick={() => handleShow()}
          icon="notifications"
          id="brandPrimary"
          variant="brand-primary"
          disabled={!notifications.length > 0}
          analytics="open_notifications"
        />
      </div>
      {notifications.length > 0 && (
        <>
          <Offcanvas
            show={showNotificationMessage}
            onHide={() => handleClose()}
            {...{ placement: 'end' }}
          >
            <Offcanvas.Header
              closeButton
              data-analytics="close_notifications"
            >
              <Offcanvas.Title>Notifications</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="async-notification">
              <ListGroup as="ul" className="mb-3 list-group-flush">
                {notifications.map((notification) => (
                  <ListGroup.Item
                    as="li"
                    key={notification.requestId}
                  >
                    <Text
                      content={`${notification.details.firstName} ${notification.details.lastName}'`}
                      type={`${
                        notification.details.patientId
                          ? 'link-text'
                          : 'body-text'
                      }`}
                      onClick={async () => {
                        await goToPatientDetailsPage(notification);
                      }}
                      className={`${
                        notification.details.patientId
                          ? 'link-text clickable-text'
                          : 'body-text'
                      }`}
                    />
                    <Text
                      content={`s ${getMessageByStatus(
                        notification.details,
                      )}`}
                      type=""
                      isHTML
                      className="body-text"
                    />
                    <Text
                      content={timeAgo(notification.lastUpdated)}
                      type="inactive-small-text"
                    />
                    <div className="close-button">
                      <CloseButton
                        onClick={async () => {
                          await deleteNotification(notification);
                        }}
                        data-analytics="remove_notifications"
                      />
                    </div>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item as="li" key="bottom" />
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
};

export default AsyncNotificationWidget;
