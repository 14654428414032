/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import tokenService from '../services/tokenService';
import userService from '../services/userService';
import cookieService from '../services/cookieService';
import providerService from '../services/providerService';
import authService from '../services/authService';
import userDetails from '../actions/userDetailsAction';
import providerDetails from '../actions/providerDetailsAction';
import auth from '../actions/authAction';
import Redirect from './Redirect';
import {
  ID_TOKEN_COOKIE,
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  DPX_OPTOUT_COOKIE,
  SHARED_MENU_COOKIE,
  USER_COOKIE,
  routes,
  auth0LoginUrl,
  auth0LogoutUrl,
} from '../config';
import Spinner from './Spinner';

const Authorizer = () => {
  const [loading, setLoading] = useState(true);
  const [authProviderResponse, setAuthProviderResponse] = useState();
  const [professionalToken, setProfessionalToken] = useState(null);
  useState(null);
  const [professionalIdToken, setProfessionalIdToken] =
    useState(null);
  const [authProviderResponseLoaded, setAuthProviderResponseLoaded] =
    useState(false);
  const [professionalTokenLoaded, setProfessionalTokenLoaded] =
    useState(false);
  const [nextStep, setNextStep] = useState();
  const [userCountry, setUserCountry] = useState();
  const [userLang, setUserLang] = useState();
  const [professional, setProfessional] = useState();

  const queryString = useLocation().search;
  const code = new URLSearchParams(queryString).get('code');
  // const state = new URLSearchParams(queryString).get('state');
  const action = new URLSearchParams(queryString).get('action');

  // check to see if there are any clinics stored in localStorage
  const providerListLocalStorage =
    providerService.getCachedProviders()?.providerList;

  const dispatch = useDispatch();

  useEffect(async () => {
    if (code) {
      setLoading(true);
      const result =
        await tokenService.convertAuthorisationCodeIntoTokens(code);
      setAuthProviderResponse(result);
      setAuthProviderResponseLoaded(true);
    } else if (action === 'logout') {
      setLoading(true);

      authService.logout().then(() => {
        window.location.replace(auth0LogoutUrl);
        setLoading(false);
      });
    } else {
      window.location.replace(auth0LoginUrl);
    }
  }, []);

  useEffect(async () => {
    if (authProviderResponse) {
      // save the tokens from the auth provider into cookies
      const { idToken, accessToken, refreshToken, expiresIn } =
        authProviderResponse;

      setProfessionalToken(accessToken);
      setProfessionalIdToken(idToken);
      setProfessionalTokenLoaded(true);
      const expiresInMins = expiresIn ? expiresIn / 60 : 30;
      cookieService.setCookie(
        ID_TOKEN_COOKIE,
        idToken,
        expiresInMins * 4,
      );
      // save the professional Token in a cookie
      cookieService.setCookie(
        ACCESS_TOKEN_COOKIE,
        accessToken || '',
        expiresInMins,
      );
      // save the professional Refresh Token in a cookie
      cookieService.setCookie(
        REFRESH_TOKEN_COOKIE,
        refreshToken || '',
        expiresInMins,
      );
      // retrieve the user info from token
      const user = userService.decodeIdToken(idToken);
      setProfessional(user);
    }
  }, [authProviderResponse]);

  useEffect(async () => {
    if (professional) {
      const country = professional.countryCode.toLowerCase();

      const browserLanguage = navigator?.language || 'en-GB';
      localStorage.setItem('browserLang', browserLanguage);

      const lang = professional.languageCode?.slice(0, 2);
      setUserCountry(country);
      localStorage.setItem('userCountry', country);
      setUserLang(lang);
      localStorage.setItem('userLang', lang);

      cookieService.setCookie(
        USER_COOKIE,
        JSON.stringify({ country, lang }),
        Infinity,
        true,
      );

      // store the user info in state
      dispatch(userDetails(professional));
      // set auth state
      dispatch(auth(true));
      const countryConfig = await userService.getCountryConfig(professional.countryCode);

      // check their CIM type and redirect to MCP if ContentProfessional
      if (professional.role === 'ContentProfessional') {        
        let resourcesUrl = routes.mcp.resourcesHome;

        if (countryConfig.region === 'EMEA') {
          resourcesUrl = routes.mcp.home;
        } else if (countryConfig.region === 'APAC') {
          resourcesUrl = resourcesUrl.replace('[REGION]', 'mcp-apac');
        } else {
          resourcesUrl = resourcesUrl.replace('[REGION]', 'mcp-cam');
        }

        cookieService.setCookie(DPX_OPTOUT_COOKIE, true, Infinity, true);
        setLoading(false);        
        setNextStep(resourcesUrl);
        return;
      }

      if (
        providerListLocalStorage &&
        providerListLocalStorage.professionalId ===
          professional.professionalId
      ) {
        // clinics are in localStorage
        setLoading(false);
        setNextStep(routes.dpx.selectClinics);
      } else {
        // get clinics from backend
        const { next, providerList, providerId, hasClinic } =
          await providerService.checkClinics(
            professional.professionalId,
            professional.countryCode,
          );

        if (providerList && hasClinic) {
          const providerDetailsData =
            providerService.storeProviderDetails(
              providerId,
              providerList,
            );

          // save provider details in state
          dispatch(providerDetails(providerDetailsData));
        }

        if (next.startsWith(routes.mcp.domain)) {
          // redirect back to mcp
          cookieService.setCookie(DPX_OPTOUT_COOKIE, true, Infinity, true);
          cookieService.setCookie(SHARED_MENU_COOKIE, false, 0, true);
        }
        // move on to next step
        setLoading(false);
        setNextStep(next);
      }
    }
  }, [professional]);

  return authProviderResponseLoaded && !professionalIdToken ? (
    <Redirect to={routes.dpx.unauthorized} />
  ) : professionalTokenLoaded && !professionalToken ? (
    <Redirect to={routes.dpx.unauthorized} />
  ) : loading ? (
    <Spinner loading={loading} />
  ) : nextStep ? (
    nextStep.includes(routes.dpx.initializer) ? (
      <Redirect push to={nextStep} />
    ) : (
      <Redirect
        to={nextStep}
        country={userCountry}
        lang={userLang}
        refresh
      />
    )
  ) : (
    <></>
  );
};

export default Authorizer;
