export const getDeviceType = (deviceType) => {
  const SPEECH_PROCESSOR = 'SPEECH_PROCESSOR';
  const IMPLANT = 'IMPLANT';
  const ACCESSORY = 'ACCESSORY';
  const REMOTE_ASSISTANT = 'REMOTE_ASSISTANT';
  const UNSUPPORTED_DEVICE = 'UNSUPPORTED_DEVICE';

  switch (deviceType.toUpperCase()) {
    case 'SPEECH PROCESSOR':
      return SPEECH_PROCESSOR;
    case 'IMPLANT':
      return IMPLANT;
    case 'REMOTE ASSISTANT':
      return REMOTE_ASSISTANT;
    case 'ACCESSORY':
      return ACCESSORY;
    default:
      return UNSUPPORTED_DEVICE;
  }
};

export const mapRegistrationFormData = (
  currentProviderId,
  deviceRegistrationFormData,
  selectedDevice,
  uDetails,
  userCountry,
  deviceType,
) => {
  const deviceTypes = {
    SPEECH_PROCESSOR: {
      deviceType: 'Speech Processor',
      eventType: 'fitting',
    },
    IMPLANT: {
      deviceType: 'Implant',
      eventType: 'surgery',
    },
    ACCESSORY: {
      deviceType: 'Accessory',
    },
    REMOTE_ASSISTANT: {
      deviceType: 'Remote Assistant',
    },
  };

  const upperDeviceType = deviceType.toUpperCase();
  const deviceInfo =
    deviceTypes[upperDeviceType] || deviceTypes.ACCESSORY;

  const tempDate =
    deviceRegistrationFormData?.datePicker?.value?.split('/');
  const newFormattedDate = `${tempDate[2]}-${tempDate[0]}-${tempDate[1]}`;

  const returnValue = {
    submittedBy: {
      source: 'DPX',
      userId: uDetails?.username,
    },
    patient: {
      cochlearId:
        deviceRegistrationFormData?.patientCochlearId?.value,
    },
    device: {
      productId: selectedDevice.productId,
      productName: selectedDevice.productName,
      serialNumber: deviceRegistrationFormData?.serialNumber?.value,
      lotNumber: deviceRegistrationFormData?.lotNumber?.value,
      deviceType: deviceInfo?.deviceType,
      isLotControlled: selectedDevice.isLotControlled,
      isBackupOrConsignment: selectedDevice.isBackupOrConsignment,
    },
    event: {
      date: newFormattedDate,
      ...(deviceInfo?.eventType && {
        eventType: deviceInfo.eventType,
      }),
      ...(deviceRegistrationFormData?.earSide && {
        earSide: deviceRegistrationFormData?.earSide?.value,
      }),
    },
    provider: {
      primaryCountryCode: userCountry,
    },
  };
  if (
    deviceType.toUpperCase() === 'IMPLANT' ||
    deviceType.toUpperCase() === 'SPEECH_PROCESSOR'
  ) {
    returnValue.provider = {
      ...returnValue.provider,
      cochlearId: currentProviderId,
    };
    if (deviceRegistrationFormData?.surgeon?.value) {
      returnValue.professional = {
        cochlearId: deviceRegistrationFormData.surgeon.value,
      };
    }
  }
  return returnValue;
};
