import axios from 'axios';
import { providerListEndpoint } from '../../config';
import getHeaders from '../../utils/getHeaders';
import { logger } from '../../logger';

const log = logger();

const postDeviceRegistration = async (providerId, payload) => {
  const requestBody = payload;

  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/devices/registration`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error posting device registration', err);
    return null;
  }
};

const deviceRegistrationService = {
  postDeviceRegistration,
};

export default deviceRegistrationService;
