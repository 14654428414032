/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { DataTable } from '@cochlear-design-system/features.dataTable';
import {
  Alert,
  Button,
  Col,
  Container,
  DateInput,
  FormButton,
  PageHeader,
  Row,
  SelectInput,
  Text,
  TextInput,
} from '@cochlear-design-system/foundation';
import dateFormat from 'dateformat';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import headerCancelModal from '../../actions/headerCancelModalAction';
import newPatientDetails from '../../actions/newPatientDetailsAction';
import matchingPatientDetails from '../../actions/matchingPatientDetailsAction';
import stepAction from '../../actions/stepAction';
import { defaultBrowserLanguage, routes } from '../../config';
import patientService from '../../services/patientService';
import providerService from '../../services/providerService';
import userService from '../../services/userService';
import Spinner from '../Spinner';
import AgreementModal from './components/AgreementModal';
import { DetailsView } from './components/DetailsView';
import { getConfig } from './createPatientRecipientConfig';
import { FindClinicModal } from '../../ui/FindClinicModal/FindClinicModal';
import {
  buildMatchingRecordSet,
  checkMinor,
  getAgeOfMaturity,
  getBaseDateFieldsConfig,
  getLanguageList,
  isValidPastDate,
} from '../../utils/patientCarerHelper';
import { trimStringValues } from '../../utils/stringFns';
import { getDateInputFormat } from '../../utils/dateTimeFns';
import notificationStatus from '../../actions/notificationStatusAction';
import createPatientAPIErrorModal from '../../actions/createPatientAPIErrorAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import { getLabelsForFields } from '../../utils/labelHelper';
import { getMergedAddress } from '../../utils/addressHelper';

const CreatePatientRecipient = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const userCountry = country.toUpperCase();
  const DATE_UNITS = ['day', 'month', 'year'];
  const currentProvider = providerService.getCurrentProvider();
  const providerCountry = currentProvider.address?.countryCode;
  const { format: dateInputFormat, hint: dateHint } =
    getDateInputFormat(providerCountry);
  const { professionalId } = userService.getUserDetails();

  const [step, setStep] = useState(
    useSelector((state) => state.step) || 'r1',
  );
  const [headingTitle, setHeadingTitle] = useState(
    labels['labels.common.createPatient'],
  );
  const [patientDetailsTitle, setPatientDetailsTitle] = useState(
    labels['labels.common.patientDetails'],
  );
  const [matchingRecordsTitle, setMatchingRecordsTitle] = useState();

  const [show, setShow] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [showClinicTable, setShowClinicTable] = useState(false);
  const [validateFields, setValidateFields] = useState([
    'firstName',
    'middleName',
    'lastName',
    ...DATE_UNITS,
  ]);
  const [isMinor, setIsMinor] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingRecord, setExistingRecord] = useState();
  const [agreementModalVisible, setAgreementModalVisible] =
    useState(false);
  const [matchingPatients, setMatchingPatients] = useState();
  const [matchingAlertHeading, setMatchingAlertHeading] = useState();
  const [matchingAlertText, setMatchingAlertText] = useState();
  const [matchingAlertVariant, setMatchingAlertVariant] =
    useState('warning');
  const [matchingAlertActions, setMatchingAlertActions] = useState();
  const [formValues, setFormValues] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [upliftTimeout, setUpliftTimeout] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [showProvisionalAlert, setShowProvisionalAlert] =
    useState(false);
  const provisionalAlertHeading =
    labels['labels.common.accessSelectedMatchingRecordNotPossible'];
  const [provisionalAlertText, setProvisionalAlertText] = useState();
  const [provisionalAlertActions, setProvisionalAlertActions] =
    useState();
  const [upliftRequestId, setUpliftRequestId] = useState();

  const ageOfMaturity = getAgeOfMaturity();
  const history = useHistory();
  const defaultLanguage =
    localStorage.getItem('browserLang')?.slice(0, 2) ||
    defaultBrowserLanguage;

  const initialValues = { preferredLanguage: defaultLanguage };

  const dispatch = useDispatch();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const apiErrorMessageTrigger = useSelector(
    (payload) => payload.apiErrorReducer,
  );
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    if (apiErrorMessageTrigger) {
      setApiError(apiErrorMessageTrigger.apiError);
    }
  }, [apiErrorMessageTrigger]);

  const handleSearchClinic = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const clinics = await providerService.searchClinics(
      countryCode,
      searchParam.clinic,
    );
    if (clinics.providers && clinics.providers.length > 0) {
      clinics.providers.forEach((provider) => {
        provider.address = getMergedAddress(provider);
      });
    }
    return clinics.providers;
  };

  const handleSelectClinic = (action, params) => {
    const selectedClinicData = [];
    selectedClinicData.push({
      clinicId: params.data.id,
      name: params.data.name,
      address: params.data.address,
      isRemovable: true,
    });
    setClinicData(selectedClinicData);
  };

  const handleRemoveClinic = () => {
    setClinicData([]);
  };

  const handleCancel = () => {
    switch (step) {
      case 'r1':
        dispatch(newPatientDetails(null));
        dispatch(matchingPatientDetails(null));
        history.push(
          `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
        );
        break;
      case 'r2':
        setStep('r1');
        dispatch(stepAction('r1'));
        break;
      case 'r2a':
      case 'r2b':
      case 'r3a':
      case 'r3b':
      case 'r3c':
      case 'r4':
        setStep('r2');
        dispatch(stepAction('r2'));
        break;
      case 'r2c':
        setFieldValue('email', formValues.email);
        setStep('r3b');
        dispatch(stepAction('r3b'));
        break;
      default:
        dispatch(newPatientDetails(null));
        dispatch(matchingPatientDetails(null));
        history.push(`/${country}/${lang}/${routes.dpx.home}`);
        break;
    }
  };

  const handleAgreementModalSubmit = async () => {
    const patientId = selectedPatient.cochlearId;
    const professionalProviderId = currentProvider.id;
    const organisationType = currentProvider.isDistributor
      ? 'Distributor'
      : 'Provider';

    if (selectedPatient.persona === 'Provisional-Recipient') {
      setShowSpinner(true);

      const res = await patientService.upliftPatient(
        currentProvider.id,
        patientId,
        professionalProviderId,
        organisationType,
        selectedPatient.firstName,
        selectedPatient.lastName,
      );
      if (!res) {
        history.push({
          pathname: `/${country}/${lang}/${routes.dpx.error}`,
        });
      }
      dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
      setUpliftRequestId(res.upliftStatus.requestId);
    } else {
      // associate
      const associateClinicsResult =
        await patientService.associateWithClinic(
          patientId,
          professionalProviderId,
          organisationType,
        );
      if (!associateClinicsResult) {
        history.push({
          pathname: `/${country}/${lang}/${routes.dpx.error}`,
        });
      }
      history.push({
        pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
        search: `?patientId=${patientId}`,
      });
    }
  };

  const existingRecordCheck = async (data) => {
    const date = new Date(+data.year, +data.month - 1, +data.day);
    const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');
    const personalData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      countryCode: providerCountry,
      dateOfBirth,
    };
    setSubmitDisabled(true);
    const result = await patientService.checkExistingRecord(
      personalData,
      currentProvider.id,
    );
    setSubmitDisabled(false);
    setExistingRecord(result);
    return result;
  };

  const handleNameClick = async (patient) => {
    setSelectedPatient(patient);
    setShowProvisionalAlert(false);
    if (patient.source === 'sfhc') {
      // SFHC
      if (patient.isRelatedToProvider) {
        dispatch(stepAction('r1'));
        history.push({
          pathname: `/${country}/${lang}/patients/details`,
          search: `?patientId=${patient.cochlearId}`,
        });
      } else {
        setAgreementModalVisible(true);
      }
    } else {
      // SFC
      // eslint-disable-next-line no-lonely-if
      if (patient.persona === 'Provisional-Recipient') {
        // PROVISIONAL RECIPIENT
        if (patient.isRelatedToProvider) {
          // RELATED
          // uplift
          const organisationType = currentProvider.isDistributor
            ? 'Distributor'
            : 'Provider';
          setShowSpinner(true);
          const result = await patientService.upliftPatient(
            currentProvider.id,
            patient.cochlearId,
            currentProvider.id,
            organisationType,
            patient.firstName,
            patient.lastName,
          );
          if (!result) {
            history.push({
              pathname: `/${country}/${lang}/${routes.dpx.error}`,
            });
          }
          dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
          setUpliftRequestId(result.upliftStatus.requestId);
        } else {
          // NON-RELATED
          // display privacy
          setAgreementModalVisible(true);
        }
      } else if (patient.persona === 'Provisional-Unknown') {
        if (step === 'r3b') {
          setShowProvisionalAlert(true);
          setProvisionalAlertText(
            labels[
              'labels.createPatient.provisionalUnknownAlertText2'
            ],
          );
          setProvisionalAlertActions([
            {
              text: labels['labels.common.contactCustomerService'],
              onClick: contactCustomerService,
            },
            {
              text: labels['labels.common.locatePatient'],
              onClick: locatePatient,
            },
          ]);
        }
        if (step === 'r2a') {
          setMatchingAlertHeading(
            labels[
              'labels.common.accessSelectedMatchingRecordNotPossible'
            ],
          );
          setMatchingAlertText(
            labels[
              'labels.createPatient.provisionalUnknownAlertText'
            ],
          );
          setMatchingAlertActions([
            {
              text: labels['labels.common.contactCustomerService'],
              onClick: contactCustomerService,
            },
            {
              text: labels['labels.common.locatePatient'],
              onClick: locatePatient,
            },
          ]);
        }
      } else if (patient.persona === 'Provisional-Requester') {
        if (step === 'r3b') {
          setShowProvisionalAlert(true);
          setProvisionalAlertText(
            labels[
              'labels.createPatient.provisionalRequesterAlertText2'
            ],
          );
          setProvisionalAlertActions([
            {
              text: labels['labels.common.contactCustomerService'],
              onClick: contactCustomerService,
            },
          ]);
        }
        if (step === 'r2a') {
          setMatchingAlertHeading(
            labels[
              'labels.common.accessSelectedMatchingRecordNotPossible'
            ],
          );
          setMatchingAlertText(
            labels[
              'labels.createPatient.provisionalRequesterAlertText'
            ],
          );
        }
      } else {
        // NON-PROVISIONAL
        // eslint-disable-next-line no-lonely-if
        if (patient.isRelatedToProvider) {
          dispatch(stepAction('r1'));
          history.push({
            pathname: `/${country}/${lang}/patients/details`,
            search: `?patientId=${patient.cochlearId}`,
          });
        } else {
          setAgreementModalVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const startPolling = async () => {
      const patientId = selectedPatient.cochlearId;
      const professionalProviderId = currentProvider.id;
      const status = await patientService.pollUpliftStatus(
        professionalProviderId,
        patientId,
        upliftRequestId,
        source.token,
      );
      if (status === 'processed') {
        history.push({
          pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
          search: `?patientId=${patientId}`,
        });
      } else {
        setUpliftTimeout(true);
      }
    };

    if (upliftRequestId) {
      startPolling();
    }

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [upliftRequestId]);

  const createPatient = async (values, clinic) => {
    setSubmitDisabled(true);

    const result = await patientService.createPatient(
      currentProvider.id,
      professionalId,
      providerCountry,
      values,
      clinic,
    );
    setSubmitDisabled(false);
    dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
    return result;
  };

  const contactCustomerService = (e) => {
    e.preventDefault();
    dispatch(stepAction('r1'));
    window.open(routes.customerService, '_blank');
  };

  const locatePatient = () => {
    dispatch(stepAction('r1'));
    history.push(`/${country}/${lang}/${routes.dpx.advancedSearch}`);
  };

  const config = getConfig(
    handleSearchClinic,
    handleSelectClinic,
    handleRemoveClinic,
    handleNameClick,
    setShow,
    userCountry,
    dateInputFormat,
    labels,
  );
  const { fields } = config;
  const fieldsConfig = fields.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );

  // eslint-disable-next-line no-shadow
  const getInputValidations = (inputNames, fieldsConfig, labels) =>
    Yup.object(
      inputNames.reduce((acc, inputName) => {
        // Config supplied for dateOfBirth applies to all three d/m/y inputs
        const configFieldName = DATE_UNITS.includes(inputName)
          ? 'dateOfBirth'
          : inputName;
        return {
          ...acc,
          [inputName]: fieldsConfig[configFieldName].validators
            .reduce((acc2, { type, value, message }) => {
              if (type === 'required')
                return acc2[type](labels[message]);
              return acc2[type](value, labels[message]);
            }, Yup.string())
            .trim(),
        };
      }, {}),
    );

  const [validationSchema, setValidateSchema] = useState(
    getInputValidations(validateFields, fieldsConfig, labels),
  );

  const onChange = () => {};

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    onSubmit: async (rawValues) => {
      const submittedValues = trimStringValues(rawValues);
      setFormValues(submittedValues);
      setIsSubmitting(true);
      setShowProvisionalAlert(false);

      switch (step) {
        case 'r1': // Default
          if (isMinor) {
            setStep('r1a');
            dispatch(stepAction('r1a'));
          } else {
            setStep('r2');
            dispatch(stepAction('r2'));
          }
          break;
        case 'r1a': // minor
          {
            const res = await existingRecordCheck(submittedValues);

            dispatch(newPatientDetails(submittedValues));
            if (res === null) {
              setIsSubmitting(false);
              break;
            }

            if (res.records.length === 0) {
              dispatch(stepAction('c3'));
            } else {
              dispatch(matchingPatientDetails(res.records));
              dispatch(stepAction('c2a'));
            }
            history.push(
              `/${country}/${lang}/${routes.dpx.createPatientCarer}`,
            );
          }
          break;
        case 'r2': // input email
        case 'r2a': // Username only match (show record table)
        case 'r2b': // Username only match outside clinic (do not show record)
        case 'r2c': // similar to r2b but coming from r3b
          {
            const res = await existingRecordCheck(submittedValues);
            if (res === null) {
              setIsSubmitting(false);
              break;
            }
            if (
              res.records.length === 0 &&
              !res.isPIIMatched &&
              !res.isAccountMatched
            ) {
              // NO DUPLICATE
              setStep('r4');
              dispatch(stepAction('r4'));
            } else if (
              res.records.length === 0 &&
              !res.isPIIMatched &&
              res.isAccountMatched
            ) {
              // ACCOUNT ONLY MATCH BUT NO FN/LN
              setStep('r2b');
              dispatch(stepAction('r2b'));
            } else if (res.records.length === 1) {
              // SINGLE RECORD MATCH
              if (res.isAccountAndPIIMatched) {
                // username & pii match
                setStep('r3a');
                dispatch(stepAction('r3a'));
              } else if (res.isPIIMatched && !res.isAccountMatched) {
                // PII ONLY MATCH
                setMatchingPatients(
                  buildMatchingRecordSet(res.records),
                );
                setStep('r3c');
                dispatch(stepAction('r3c'));
              } else if (!res.isPIIMatched && res.isAccountMatched) {
                // ACCOUNT ONLY MATCH
                setMatchingPatients(
                  buildMatchingRecordSet(res.records),
                );
                setStep('r2a');
                dispatch(stepAction('r2a'));
                setMatchingAlertActions([
                  {
                    text: labels[
                      'labels.common.contactCustomerService'
                    ],
                    onClick: contactCustomerService,
                  },
                ]);
              }
            } else {
              setMatchingPatients(
                buildMatchingRecordSet(res.records),
              );
              let nextStep = 'r3b';
              // MULTIPLE RECORDS MATCH
              if (res.isPIIMatched && !res.isAccountMatched) {
                // pii only match
                nextStep = 'r3c';
              }
              setStep(nextStep);
              dispatch(stepAction(nextStep));
            }
          }
          break;
        case 'r3a': // Username + PII match - single (show matched record)
          if (existingRecord.records[0].isRelatedToProvider) {
            history.push({
              pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
              search: `?patientId=${existingRecord.records[0].cochlearId}`,
            });
          } else {
            setSelectedPatient(existingRecord.records[0]);
            setAgreementModalVisible(true);
          }
          break;
        case 'r3b': // Username + PII match - multiple (show records table)
          setStep('r2c'); // new step r2c is same as r2b but cancel button will take back to r3b
          dispatch(stepAction('r2c'));
          break;
        case 'r3c': // PII only match - single or multiple (show records table)
          setStep('r4');
          dispatch(stepAction('r4'));
          break;
        case 'r4': // review
          {
            const res = await createPatient(
              submittedValues,
              clinicData,
            );
            if (res.success) {
              setStep('r5');
              dispatch(stepAction('r5'));
            } else {
              // maybe a duplicate, go to r3a for now
              setStep('r3a');
              dispatch(stepAction('r3a'));
            }
          }
          break;
        case 'r5': // success
          history.push(
            `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
          );
          break;
        default:
          break;
      }
      setIsSubmitting(false);
    },

    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const [dobValid, dobErrorMessage] = (() => {
    const isTouched = DATE_UNITS.filter((unit) => touched[unit]);
    if (isTouched.length < 3) return [false];
    if (isValidPastDate(values)) return [true];

    const invalidDobErrorKey =
      fieldsConfig.dateOfBirth.validators.find(
        ({ type }) => type === 'required',
      ).message;

    return [false, labels[invalidDobErrorKey]];
  })();

  useEffect(() => {
    setSubmitDisabled(true);

    if ((step === 'r1' || step === 'r1a') && dobValid) {
      const dob = `${values.year}-${values.month}-${values.day}`;
      setIsMinor(false);
      if (checkMinor(dob, ageOfMaturity)) {
        setIsMinor(true);
        setStep('r1a');
        dispatch(stepAction('r1a'));
      } else {
        setStep('r1');
        dispatch(stepAction('r1'));
      }
    }

    switch (step) {
      case 'r1':
      case 'r1a':
      case 'r4':
        if (
          Object.keys(errors).length === 0 &&
          Object.keys(touched).length > 0 &&
          dobValid
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'r2':
        if (Object.keys(errors).length === 0 && 'email' in values) {
          setSubmitDisabled(false);
        }
        break;
      case 'r2a':
        setMatchingAlertHeading(
          labels['labels.createPatient.matchingAccountFound'],
        );
        setMatchingAlertText(
          labels['labels.createPatient.selectOrProvide'],
        );
        setMatchingAlertVariant('error');
        if (
          Object.keys(errors).length === 0 &&
          'email' in values &&
          values.email !== formValues.email
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'r2b':
      case 'r2c':
        setMatchingAlertHeading(
          labels['labels.createPatient.accountFound'],
        );
        setMatchingAlertText(
          labels['labels.createPatient.enterDifferentEmail'],
        );
        setMatchingAlertVariant('error');
        if (
          Object.keys(errors).length === 0 &&
          'email' in values &&
          values.email !== formValues.email
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'r3a':
        setMatchingAlertHeading(
          labels['labels.createPatient.alert.patientExists'],
        );
        setMatchingAlertText(
          labels['labels.createPatient.alert.continuePatientRecord'],
        );
        setMatchingAlertVariant('warning');
        setSubmitDisabled(false);
        break;
      case 'r3b':
      case 'r3c':
        setMatchingAlertHeading(
          labels['labels.createPatient.alert.matchingRecordFound'],
        );
        setMatchingAlertText(
          labels['labels.createPatient.alert.selectExistingRecord'],
        );
        setMatchingAlertVariant('warning');
        setSubmitDisabled(false);
        break;
      case 'r5':
        setSubmitDisabled(false);
        break;

      default:
        setSubmitDisabled(true);
        break;
    }
  }, [errors, values, touched, step, dobValid]);

  const dateFieldsConfig = (() =>
    // Extract dmy key order from a variety of different date formats
    config.formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          getBaseDateFieldsConfig()[field];
        const label =
          labels[fieldsConfig.dateOfBirth.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: values[name],
        };
      }))();

  useEffect(() => {
    switch (step) {
      case 'r1':
      case 'r1a':
        setValidateFields([
          'firstName',
          'middleName',
          'lastName',
          ...DATE_UNITS,
        ]);
        setHeadingTitle(labels['labels.common.createPatient']);
        setPatientDetailsTitle(
          labels['labels.common.patientDetails'],
        );
        break;
      case 'r2':
        setValidateFields(['email']);
        setHeadingTitle(labels['labels.common.createPatient']);
        setPatientDetailsTitle(
          labels['labels.common.cochlearAccount'],
        );
        break;
      case 'r3a':
        setHeadingTitle(labels['labels.common.existingRecordFound']);
        setPatientDetailsTitle(
          labels['labels.common.patientDetails'],
        );
        break;
      case 'r3b':
      case 'r3c':
        setHeadingTitle(labels['labels.common.createPatient']);
        setPatientDetailsTitle(
          labels['labels.common.patientDetails'],
        );
        setMatchingRecordsTitle(
          labels['labels.common.matchingRecords'],
        );
        break;
      case 'r4':
        setHeadingTitle(labels['labels.common.reviewCreatePatient']);
        setPatientDetailsTitle(
          labels['labels.common.patientDetails'],
        );
        break;
      case 'r5':
        setHeadingTitle(labels['labels.common.requestSubmitted']);
        break;
      default:
        break;
    }
  }, [step]);

  useEffect(() => {
    setValidateSchema(
      getInputValidations(validateFields, fieldsConfig, labels),
    );
  }, [validateFields]);

  useEffect(() => {
    setShowClinicTable(false);
    if (clinicData.length > 0) {
      setShowClinicTable(true);
    }
  }, [clinicData]);

  useEffect(() => {
    if (modalTrigger) {
      dispatch(newPatientDetails(null));
      dispatch(matchingPatientDetails(null));
      dispatch(headerCancelModal(false));
      history.push(`/${country}/${lang}/${routes.dpx.home}`);
    }
  }, [modalTrigger]);

  useEffect(() => {
    dispatch(createPatientAPIErrorModal(false));
  }, []);

  return (
    <>
      {showSpinner && (
        <div className="spinner">
          <Spinner loading={showSpinner} isUplifting={showSpinner} />
        </div>
      )}
      {upliftTimeout && (
        <div className="ccl__dpx-error">
          <div className="container">
            <div className="ccl-c-error-message">
              <div className="ccl-c-error-message__content">
                <Text
                  content={`${labels['labels.common.oopsNotify']}`}
                />
              </div>
              <div className="ccl-c-error-message__button-control">
                <Button
                  icon="chevron-right"
                  text={`${labels['labels.common.goToDashboard']}`}
                  link={`/${country}/${lang}/home`}
                  data-analytics="view_dashboard_create_patient"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!showSpinner && !upliftTimeout && (
        <div className="ccl__create-patient-wrapper">
          <Container>
            <Row>
              <Col>
                <PageHeader
                  className="create-patient-review__title"
                  title={{ content: headingTitle }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {step === 'r5' ? (
                  <Alert variant="success">
                    {`${labels['labels.createPatient.alert.recordCreated']}\n${labels['labels.common.alert.requestSubmitted']}`}
                  </Alert>
                ) : (
                  <div className="ccl-l-userTabs ccl-l-userTabs--single">
                    <div className="ccl-l-userTabs__content">
                      <div className="ccl-l-userTabs__content__inner">
                        {apiError && (
                          <Alert
                            variant="error"
                            heading={
                              labels['labels.common.apiError.title']
                            }
                          >
                            {labels['labels.common.apiError.message']}
                          </Alert>
                        )}
                        <section className="ccl-c-form-section">
                          {['r3a', 'r3b', 'r3c'].includes(step) && (
                            <>
                              <div className="mt-4" />
                              <Alert
                                variant={matchingAlertVariant}
                                heading={matchingAlertHeading}
                                analytics="matching-account-found"
                              >
                                {matchingAlertText}
                              </Alert>
                            </>
                          )}

                          <div className="ccl-e-detail-action-bar">
                            <div className="ccl-e-detail-action-bar__title">
                              <span>{patientDetailsTitle}</span>
                            </div>

                            {step === 'r4' && (
                              <div className="ccl-e-detail-action-bar__action">
                                <div className="ccl-e-icon-clickable">
                                  <button
                                    type="button"
                                    className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                                    data-nw-action="edit"
                                    data-analytics="edit_review_create_patient"
                                    onClick={() => setStep('r1')}
                                  >
                                    <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                                      <span className=" ccl__icon ccl__icon--size--xs" />
                                      <span
                                        data-nw-icon-label=""
                                        className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                                      >
                                        {labels['labels.common.edit']}
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          {step === 'r2a' && (
                            <>
                              <div className="mt-4" />
                              <Alert
                                variant={matchingAlertVariant}
                                heading={matchingAlertHeading}
                                actions={matchingAlertActions}
                                analytics="matching-account-found"
                              >
                                {matchingAlertText}
                              </Alert>

                              <div className="matching-records-table mt-4">
                                <DataTable
                                  config={
                                    config.matchingPatientsTable
                                  }
                                  data={matchingPatients}
                                  labels={labels}
                                />
                              </div>
                            </>
                          )}
                          {['r2b', 'r2c'].includes(step) && (
                            <>
                              <div className="mt-4" />
                              <Alert
                                variant={matchingAlertVariant}
                                heading={matchingAlertHeading}
                                actions={[
                                  {
                                    text: labels[
                                      'labels.common.contactCustomerService'
                                    ],
                                    onClick: contactCustomerService,
                                  },
                                ]}
                                analytics="matching-account-found"
                              >
                                {matchingAlertText}
                              </Alert>
                            </>
                          )}
                          <form
                            onSubmit={handleSubmit}
                            onChange={onChange}
                          >
                            {(step === 'r1' || step === 'r1a') && (
                              <>
                                <TextInput
                                  className="ccl-l-create-patient__fields__name"
                                  name="firstName"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={
                                    labels['labels.common.firstName']
                                  }
                                  promptText=""
                                  error={
                                    touched.firstName &&
                                    !!errors.firstName
                                  }
                                  errorMsg={
                                    touched.firstName &&
                                    errors.firstName
                                  }
                                />

                                <TextInput
                                  className="ccl-l-create-patient__fields__name"
                                  name="middleName"
                                  value={values.middleName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={
                                    labels['labels.common.middleName']
                                  }
                                  hint={
                                    labels['labels.common.optional']
                                  }
                                  promptText=""
                                  error={
                                    touched.middleName &&
                                    !!errors.middleName
                                  }
                                  errorMsg={
                                    touched.middleName &&
                                    errors.middleName
                                  }
                                />

                                <TextInput
                                  className="ccl-l-create-patient__fields__name"
                                  name="lastName"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={
                                    labels['labels.common.lastName']
                                  }
                                  promptText=""
                                  error={
                                    touched.lastName &&
                                    !!errors.lastName
                                  }
                                  errorMsg={
                                    touched.lastName &&
                                    errors.lastName
                                  }
                                />

                                <DateInput
                                  className="ccl-l-advanced-search-patient-tab__fields__dob"
                                  name="dateOfBirth"
                                  isStrict
                                  label={
                                    labels[
                                      'labels.common.dateOfBirth'
                                    ]
                                  }
                                  hint={labels[dateHint]}
                                  inputMode="numeric"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fields={dateFieldsConfig}
                                  error={!!dobErrorMessage}
                                  errorMsg={dobErrorMessage}
                                />

                                {step === 'r1a' && (
                                  <div className="mt-6">
                                    <Alert
                                      variant="warning"
                                      heading={
                                        labels[
                                          'labels.createPatient.alert.patientIsChild'
                                        ]
                                      }
                                    >
                                      {
                                        labels[
                                          'labels.createPatient.alert.carerDetailsRequired'
                                        ]
                                      }
                                    </Alert>
                                  </div>
                                )}
                              </>
                            )}
                            {['r2', 'r2a', 'r2b', 'r2c'].includes(
                              step,
                            ) && (
                              <div className="ccl__view-edit-mode">
                                <TextInput
                                  className="ccl-l-advanced-search-patient-tab__fields__email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={
                                    labels[
                                      'labels.common.emailAddress'
                                    ]
                                  }
                                  promptText=""
                                  hint={
                                    labels[
                                      'labels.createPatient.hint.usernamePatientCochlearAccount'
                                    ]
                                  }
                                  error={
                                    touched.email && !!errors.email
                                  }
                                  errorMsg={
                                    touched.email && errors.email
                                  }
                                />
                                <div className="ccl__edit-mode__element">
                                  <SelectInput
                                    label={
                                      labels[
                                        'labels.common.preferredLanguageActivationEmail'
                                      ]
                                    }
                                    name="preferredLanguage"
                                    setFocus={false}
                                    error={false}
                                    errorMsg={
                                      errors.preferredLanguage
                                    }
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    dataList={getLanguageList()}
                                    isSearchable
                                    hint={
                                      labels[
                                        'labels.common.languageWelcomeEmail'
                                      ]
                                    }
                                    optionalText=""
                                    defaultValue={
                                      values.preferredLanguage === ''
                                        ? initialValues.preferredLanguage
                                        : values.preferredLanguage
                                    }
                                    disabled={false}
                                  />
                                </div>
                              </div>
                            )}

                            {step === 'r3a' && (
                              <>
                                <div className="mt-4" />
                                <DetailsView
                                  values={values}
                                  labels={labels}
                                />
                              </>
                            )}
                            {['r3b', 'r3c'].includes(step) && (
                              <>
                                <div className="mt-4" />
                                <DetailsView
                                  values={values}
                                  labels={labels}
                                />
                                <div className="ccl-e-detail-action-bar mt-4">
                                  <div className="ccl-e-detail-action-bar__title">
                                    <span>
                                      {matchingRecordsTitle}
                                    </span>
                                  </div>
                                </div>
                                {showProvisionalAlert && (
                                  <>
                                    <div className="mt-4" />
                                    <Alert
                                      variant="error"
                                      heading={
                                        provisionalAlertHeading
                                      }
                                      actions={
                                        provisionalAlertActions
                                      }
                                    >
                                      {provisionalAlertText}
                                    </Alert>
                                  </>
                                )}
                                <div className="matching-records-table mt-4">
                                  <DataTable
                                    config={
                                      config.matchingPatientsTable
                                    }
                                    data={matchingPatients}
                                    labels={labels}
                                  />
                                </div>
                              </>
                            )}

                            {step === 'r4' && (
                              <DetailsView
                                values={values}
                                labels={labels}
                                showLanguage
                              />
                            )}
                            <FindClinicModal
                              {...config.clinicSelectorConfig}
                              show={show}
                              onHide={() => setShow(false)}
                              labels={labels}
                            />
                          </form>
                        </section>
                        {step === 'r4' && (
                          <>
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title mt-4">
                                <span>
                                  {
                                    labels[
                                      'labels.createPatientRecipient.additionalClinicSurgery.heading'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="my-4">
                              <Alert variant="brand">
                                {
                                  labels[
                                    'labels.createPatientRecipient.additionalClinicSurgery.text'
                                  ]
                                }
                              </Alert>
                            </div>

                            {showClinicTable && (
                              <div className="ccl__clincsPopup__DataTable mb-6">
                                <DataTable
                                  config={config.selectedClinicTable}
                                  data={clinicData}
                                  labels={labels}
                                />
                              </div>
                            )}

                            <Button
                              text={
                                labels[
                                  'labels.createPatient.addClinicButton'
                                ]
                              }
                              variant="secondary"
                              onClick={() => setShow(true)}
                              style={{ cursor: 'pointer' }}
                              disabled={clinicData.length > 0}
                              data-analytics="add_clinic_review_create_patient"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <div className="d-flex flex-row pt-4">
                <div className="pe-2">
                  <FormButton
                    text={
                      // eslint-disable-next-line no-nested-ternary
                      step === 'r5'
                        ? labels['labels.common.createAnotherPatient']
                        : // eslint-disable-next-line no-nested-ternary
                        ['r3b', 'r3c'].includes(step)
                        ? labels['labels.common.createNewPatient']
                        : ['r4'].includes(step)
                        ? labels['labels.common.createPatient']
                        : labels['labels.common.continue']
                    }
                    variant="brand-primary"
                    disabled={step === '5' ? false : submitDisabled}
                    onClick={handleSubmit}
                    type="save"
                    progress={isSubmitting ? 2 : undefined}
                    data-analytics={
                      // eslint-disable-next-line no-nested-ternary
                      step === 'r5'
                        ? 'create_success_create_patient'
                        : // eslint-disable-next-line no-nested-ternary
                        ['r3b', 'r3c'].includes(step)
                        ? 'create_review_create_patient'
                        : ['r4'].includes(step)
                        ? 'create_review_create_patient'
                        : 'continue_patient_details_create_patient'
                    }
                  />
                </div>
                <div className="pe-2">
                  <Button
                    text={
                      step === 'r5'
                        ? labels['labels.common.close']
                        : labels['labels.common.cancel']
                    }
                    variant="secondary"
                    onClick={handleCancel}
                    data-analytics={
                      step === 'r5'
                        ? 'close_success_create_patient'
                        : 'cancel_create_patient'
                    }
                  />
                </div>
              </div>
            </Row>
          </Container>

          <AgreementModal
            labels={labels}
            onClose={() => {
              setAgreementModalVisible(false);
            }}
            onSubmit={handleAgreementModalSubmit}
            visible={agreementModalVisible}
          />
        </div>
      )}
    </>
  );
};

export default CreatePatientRecipient;
