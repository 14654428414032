/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import CryptoJS from 'crypto-js';
import IdleTimer from './components/IdleTimer';

import './styles/main.scss';
import './assets/app.css';

import {
  tokenRenewThresholdInSecs,
  tokenRenewCheckIntervalInSecs,
  routes,
} from './config';
import tokenRenewalService from './services/tokenRenewalService';
import Spinner from './components/Spinner';
import providerService from './services/providerService';
import userService from './services/userService';
import useAddAttributes from './hooks/addAttributes';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.
const Navigation = () => (
  <></>
  // <Navbar currentUser={props.currentUser} />
);
// let Navigation = ({ t, i18n }) => (
//   <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom">
//     <h5 className="my-0 mr-md-auto font-weight-normal">
//       <NavLink to="/" className="text-dark">
//         <img src={logo} alt="Sitecore" />
//       </NavLink>
//     </h5>
//     <nav className="my-2 my-md-0 mr-md-3">
//       <a
//         className="p-2 text-dark"
//         href="https://jss.sitecore.com"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         {t('Documentation')}
//       </a>
//       <NavLink to="/styleguide" className="p-2 text-dark">
//         {t('Styleguide')}
//       </NavLink>
//       <NavLink to="/graphql" className="p-2 text-dark">
//         {t('GraphQL')}
//       </NavLink>
//     </nav>
//   </div>
// );

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const Layout = ({ route, lang, locale, country, urlParams }) => {
  const [currentState, setCurrentState] = useState({
    headerData: {},
    createPatientFormData: {},
    loading: false,
  });
  const { providerId } = providerService.getProviderDetails();
  const { professionalId } = userService.getUserDetails();

  window.professionalId = CryptoJS.MD5(professionalId).toString();
  window.providerId = providerId;

  /**
   *  dynamically insert data-analytics attributes to elements
   */
  useAddAttributes(['navbar']);

  // token renewal
  useEffect(async () => {
    const performRenewalCheck = async () => {
      const accessTokenTimeToExpiryInSecs =
        tokenRenewalService.getTokenTimeToExpiry();
      if (
        accessTokenTimeToExpiryInSecs <= tokenRenewThresholdInSecs
      ) {
        const newAccessToken = await tokenRenewalService.renewToken(
          providerId,
        );
        if (!newAccessToken) {
          // token renewal failed, log out the user
          window.location.href = routes.dpx.logout;
        }
      }
    };

    performRenewalCheck();

    const interval = setInterval(async () => {
      performRenewalCheck();
    }, tokenRenewCheckIntervalInSecs * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            (route.fields &&
              route.fields.Title &&
              route.fields.Title.value) ||
            'Cochlear.com - Professional'}
        </title>
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />

      <Navigation />

      {/* root placeholder for the app, which we add components to using route data */}
      <div id="backdrop" />
      <IdleTimer />
      <div className="dpx__container">
        <Placeholder
          name="dpx-header"
          rendering={route}
          lang={lang}
          locale={locale}
          country={country}
          setCurrentState={setCurrentState}
          currentState={currentState}
          urlParams={urlParams}
        />
        {currentState.loading ? (
          <Spinner loading={currentState.loading} />
        ) : (
          <Placeholder
            name="dpx-main"
            rendering={route}
            lang={lang}
            locale={locale}
            country={country}
            setCurrentState={setCurrentState}
            currentState={currentState}
            route={route}
            urlParams={urlParams}
          />
        )}
        <Placeholder
          name="dpx-footer"
          rendering={route}
          lang={lang}
          locale={locale}
          country={country}
          setCurrentState={setCurrentState}
          currentState={currentState}
          urlParams={urlParams}
        />
      </div>
    </>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
