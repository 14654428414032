/* eslint-disable array-callback-return */
import React from 'react';
import {
  Text,
  Accordion as AccordionComponent,
} from '@cochlear-design-system/foundation';

const Accordion = ({ accordionItems, isHTML = true }) => {
  return (
    <div
      style={{
        margin: 'auto',
        marginTop: '3rem',
      }}
    >
      <AccordionComponent>
        {accordionItems.map((accordionItem, index) => {
          if (accordionItem.accordionItem) {
            return (
              <AccordionComponent.Item eventKey={index}>
                <AccordionComponent.Header
                  style={{ textAlign: 'left' }}
                >
                  {accordionItem.headerTitle}
                  {accordionItem.headerSubtitle ? <br /> : <></>}
                  {accordionItem.headerSubtitle}
                </AccordionComponent.Header>
                <AccordionComponent.Body>
                  <Text
                    content={accordionItem.summary}
                    isHTML={isHTML}
                  />
                </AccordionComponent.Body>
              </AccordionComponent.Item>
            );
          }
          return <></>;
        })}
      </AccordionComponent>
    </div>
  );
};

export default Accordion;
