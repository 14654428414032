/* eslint-disable class-methods-use-this */
import { RestLayoutService } from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';
import resolveSite from '../SiteResolver';

export class LayoutServiceFactory {
  create() {
    const { siteName } = resolveSite();
    return new RestLayoutService({
      apiHost: config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName,
      configurationName: 'jss',
    });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
