/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Text, Spinner } from '@cochlear-design-system/foundation';
import resourceService from '../../services/resourceService';
import getLocalStorageLabels from '../../utils/getLocalStorageLabels';
import SingleTabView from '../SingleTabView';

const ResourcePageLanding = ({ lang, locale, country, siteName }) => {
  const resourcePage = useLocation().pathname.slice(6);
  const labels = getLocalStorageLabels(locale);
  let resourceContent = null;
  const [headerTitle, setHeaderTitle] = useState('');

  const [headerSubtitle, setHeaderSubtitle] = useState();
  const [subSummary, setSubSummary] = useState('');
  const [resourceLoaded, setResourceLoaded] = useState(false);
  const replaceAppNameWithCountry = (richTextContent) => {
    // Replace appName in resolved internal links from Rich Text Editor fields with country code
    return richTextContent?.replaceAll('/dpx/', `/${country}/`);
  };

  function setResourceFields(
    setHeaderTitle,
    resourceContent,
    setHeaderSubtitle,
    setSubSummary,
  ) {
    setHeaderTitle(
      resourceContent.sitecore.route.placeholders['dpx-main'][0]
        .fields.data?.item.HeaderTitle?.value,
    );
    setHeaderSubtitle(
      resourceContent.sitecore.route.placeholders['dpx-main'][0]
        .fields.data?.item.HeaderSubtitle?.value,
    );
    setSubSummary(
      replaceAppNameWithCountry(
        resourceContent.sitecore.route.placeholders['dpx-main'][0]
          .fields.data?.item.SubSummary?.rendered,
      ),
    );
  }
  const getResource = async () => {
    setResourceLoaded(false);
    resourceContent = await resourceService.getResourceContent(
      resourcePage,
      lang,
      siteName,
    );
    setResourceFields(
      setHeaderTitle,
      resourceContent,
      setHeaderSubtitle,
      setSubSummary,
    );

    setResourceLoaded(true);
  };

  useEffect(() => {
    getResource();
  }, [resourcePage]);

  return !resourceLoaded ? (
    <div
      style={{
        margin: 'auto',
        textAlign: 'center',
        marginTop: '3rem',
      }}
    >
      <Spinner animation="border" />
      <Text content={labels['labels.resources.loading']} />
    </div>
  ) : (
    <div
      className="ccl__container dpx__main-content"
      style={{
        width: '60rem',
        margin: '1rem auto',
      }}
    >
      <div className="row">
        <header className="ccl__page-header">
          <h1 className="ccl__title ccl__title--heading-1 ccl__page-header__title">
            {headerTitle}
          </h1>
        </header>

        <SingleTabView
          title={headerSubtitle}
          summary={subSummary}
          isHTML
        />
      </div>
    </div>
  );
};

export default ResourcePageLanding;
