import React, { useState, useEffect } from 'react';
import {
  TextInput,
  SelectInput,
  Alert,
  Modal,
  Button,
  FormButton,
} from '@cochlear-design-system/foundation';
import patientService from '../../services/patientService';
import { getLabelsForFields } from '../../utils/labelHelper';
import { logger } from '../../logger';

import { getLanguageList } from '../../utils/patientCarerHelper';
import { defaultBrowserLanguage } from '../../config';

const log = logger();

const AccountInviteModal = ({
  rendering,
  providerId,
  inviteData,
  setInviteData,
  showInviteModal,
  onInviteModalClose,
  inviteSendingInProgress,
  setInviteSendingInProgress,
  loggedInCountryCode,
}) => {
  const labels = getLabelsForFields(rendering.fields);
  const supportedLanguages = getLanguageList();
  const [langTouched, setLangTouched] = useState(false);
  const [showError, setShowError] = useState(false);
  const countryCode = loggedInCountryCode?.toUpperCase();
  const defaultLanguage =
    localStorage.getItem('browserLang')?.slice(0, 2) ||
    defaultBrowserLanguage;

  const updateEmail = (e) => {
    const updatedData = { ...inviteData, email: e.target.value };
    setInviteData(updatedData);
  };

  /* eslint-disable no-unused-vars */
  const updateLanguage = (value) => {
    const updatedData = {
      ...inviteData,
      preferredLanguage: `${value}-${countryCode}`,
    };
    setInviteData(updatedData);
  };
  const inviteTitle =
    labels[
      'labels.patientRecord.views.associatedCarersView.read.elements.accountDetails'
    ];
  const inviteDataValid = () => {
    const valid =
      inviteData.email &&
      inviteData.email !== '' &&
      inviteData.preferredLanguage &&
      inviteData.preferredLanguage !== '';
    return !valid;
  };

  const onClose = async (send = false) => {
    setInviteSendingInProgress(true);
    if (send && !inviteData.resend) {
      // perform duplicate check on email, if this is a create and not a resend
      const requestData = {
        userName: inviteData.email,
        relatedProviderId: providerId,
      };
      try {
        const accountCheck = await patientService.checkAccountStatus(
          requestData,
          providerId,
        );

        // Invited and Active are considered existing CIM emails
        if (
          accountCheck.accountStatus?.toLowerCase() !== 'not set up'
        ) {
          setInviteSendingInProgress(false);
          setShowError(true);
          setLangTouched(false);
          return;
        }
      } catch (err) {
        log.error('Error checking account status', err);
        setInviteSendingInProgress(false);
        setShowError(true);
        setLangTouched(false);
        return;
      }
    }

    if (onInviteModalClose) {
      onInviteModalClose(send);
    }
    setLangTouched(false);
    setShowError(false);
  };

  const onLangTouched = () => {
    setLangTouched(true);
  };

  useEffect(() => {
    if (
      supportedLanguages.some(
        (lang) => lang.value === defaultLanguage,
      )
    ) {
      updateLanguage(defaultLanguage);
    }
  }, [showInviteModal]);

  return (
    <Modal
      title={inviteTitle}
      size="lg"
      show={showInviteModal}
      onHide={onClose}
      centered
    >
      <Modal.Header
        closeButton
        data-analytics="cancel_header_invite_modal"
      >
        <Modal.Title>{inviteTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {showError ? (
            <Alert
              show
              variant="error"
              heading={
                labels[
                  'labels.accountInvite.views.create.duplicateEmail.title'
                ]
              }
            >
              {
                labels[
                  'labels.accountInvite.views.create.duplicateEmail.message'
                ]
              }
            </Alert>
          ) : (
            <Alert show variant="brand">
              {labels['labels.accountInvite.views.resend.alertLabel']}
            </Alert>
          )}
          <TextInput
            hint={
              labels['labels.accountInvite.views.resend.email.hint']
            }
            label={
              labels[
                'labels.accountInvite.views.resend.email.inputLabel'
              ]
            }
            name="email"
            value={inviteData.email}
            readOnly={inviteData.resend}
            className="dpx__invite_modal_input"
            onChange={(e) => updateEmail(e)}
            onBlur={(e) => updateEmail(e)}
            error={!inviteData.email || inviteData.email === ''}
            errorMsg={
              labels['labels.accountInvite.views.resend.email.error']
            }
          />
          <SelectInput
            dataList={supportedLanguages}
            label={
              labels[
                'labels.accountInvite.views.resend.language.selectLabel'
              ]
            }
            name="preferredLang"
            promptText={
              labels[
                'labels.accountInvite.views.resend.language.prompt'
              ]
            }
            onChange={(_, value) => updateLanguage(value)}
            onBlur={onLangTouched}
            onFocus={onLangTouched}
            isSearchable
            defaultValue={defaultLanguage}
            error={
              langTouched &&
              (!inviteData.preferredLanguage ||
                inviteData.preferredLanguage === '')
            }
            errorMsg={
              labels[
                'labels.accountInvite.views.resend.language.error'
              ]
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          text={
            !inviteSendingInProgress
              ? labels['labels.accountInvite.views.resend.send']
              : labels['labels.accountInvite.views.resend.sending']
          }
          variant="brand-primary"
          disabled={inviteDataValid() || inviteSendingInProgress}
          onClick={() => onClose(true)}
          type="save"
          progress={inviteSendingInProgress ? 2 : undefined}
          data-analytics="send_invite_modal"
        />
        <Button
          onClick={() => onClose(false)}
          text={labels['labels.accountInvite.views.resend.cancel']}
          variant="tertiary"
          data-analytics="cancel_invite_modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AccountInviteModal;
