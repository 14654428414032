import React from 'react';
import {
  ListGroup,
  Text,
  Col,
} from '@cochlear-design-system/foundation';

export const Footer = ({ config, labels }) => (
  <div className="ccl__dpx-footer">
    <div className="ccl__container">
      <Col xs={7}>
        <ListGroup horizontal>
          {config.links.map((link) => (
            <ListGroup.Item key={link.id}>
              <a
                href={link.url}
                target={link.target}
                aria-label={link.label}
                data-analytics={link.analytics}
              >
                <Text content={link.label} type="x-small-body-text" />
              </a>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
      <Col xs={5} className="ccl__dpx-footer--end">
        <Text
          content={labels[config.copyright]}
          type="x-small-body-text"
          isHTML
        />
      </Col>
    </div>
  </div>
);
