/* eslint-disable dot-notation */

import { showReturnToLegacyPortal } from '../../config';

export const getFooterConfig = (labels) => {
  return {
    links: {
      targetItem: {
        children: [
          {
            label: 'contactUs',
            type: {
              linkType: 'external',
              url: 'https://www.cochlear.com/au/en/connect/contact-us',
            },
            analytics: 'view_contactus_footer',
          },
          {
            label: 'privacy',
            type: {
              linkType: 'external',
              url: 'https://www.cochlear.com/corporate/privacy/en',
            },
            analytics: 'view_privacy_footer',
          },
          {
            label: 'termsOfUse',
            type: {
              linkType: 'external',
              url: 'http://www.cochlear.com/connected-care/terms',
            },
            analytics: 'view_terms_footer',
          },
        ],
      },
    },
    caseTypes: [
      {
        label: labels['portalIssue'],
        value: labels['portalIssue'],
      },
      {
        label: labels['deviceAppSupport'],
        value: labels['deviceAppSupport'],
      },
      {
        label: labels['orderStatus'],
        value: labels['orderStatus'],
      },
      {
        label: labels['accountLoginSupport'],
        value: labels['accountLoginSupport'],
      },
      {
        label: labels['surgicalSupport'],
        value: labels['surgicalSupport'],
      },
      {
        label: labels['researchSupport'],
        value: labels['researchSupport'],
      },
      {
        label: labels['remoteCheck'],
        value: labels['remoteCheck'],
      },
      {
        label: labels['somethingElse'],
        value: labels['somethingElse'],
      },
      showReturnToLegacyPortal && {
        label: labels['returnOldPortal'],
        value: labels['returnOldPortal'],
      },
    ].filter(Boolean),
    channel: 'DPX',
  };
};
