import React, { useState } from 'react';
import {
  Modal,
  Text,
  Button,
  FormButton,
  Alert,
} from '@cochlear-design-system/foundation';

const AgreementModal = ({
  config: { fields, ...config },
  labels,
  onSubmit: onSubmitProp,
  onClose,
  visible,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal
      dialogClassName="agreement-modal"
      show={visible}
      data-analytics="cancel_header_privacy_locate_patient"
    >
      <Modal.Header
        closeButton
        onHide={onClose}
        data-analytics="cancel_agreement_modal"
      >
        <Modal.Title>{labels[config.title]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          variant="warning"
          heading={labels[config.agreementLabel]}
        />
        <div className="agreement-modal__content">
          <Text content={labels[config.content]} isHTML />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onClose}
          text={labels[config.cancel]}
          data-analytics="cancel_privacy_locate_patient"
        />
        <FormButton
          onClick={() => {
            setIsSubmitting(true);
            onSubmitProp();
          }}
          text={labels[config.submit]}
          progress={isSubmitting ? 2 : undefined}
          type="save"
          data-analytics="agree_privacy_locate_patient"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AgreementModal;
