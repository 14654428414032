import React from 'react';
import { Tabs } from '@cochlear-design-system/foundation';
import { getTabContent } from './TabContent';

export const SummaryPanel = ({
  config,
  data,
  labels,
  leftData,
  rightData,
  unknownData,
  rcIframeKey,
}) => {
  const tabs = [
    {
      id: 'view_devices_view_patient',
      label: labels[config.labels.equipment],
    },
  ];
  if (config.serviceRequestConfig.enabled) {
    tabs.push({
      id: 'view_service_requests_view_patient',
      label: labels[config.labels.serviceRequests],
    });
  }
  if (config.remoteCheckConfig.enabled) {
    tabs.push({
      id: 'view_remote_check_view_patient',
      label: labels[config.labels.remoteCheck],
    });
  }

  return (
    <Tabs
      data={{
        tabs,
      }}
      config={{ contentAsCard: true }}
    >
      {getTabContent(
        config,
        data,
        labels,
        leftData,
        rightData,
        unknownData,
        rcIframeKey,
      )}
    </Tabs>
  );
};
