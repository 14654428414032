/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import languageContent from '../data/languageContent.json';
import { getDefaultLanguage } from '../utils/languageHelper';

const Spinner = ({ loading, isUplifting }) => {
  const [spinnerCount, setSpinnerCount] = useState(-1);
  const [fade, setFade] = useState(false);

  const lang = getDefaultLanguage();

  let spinnerText = isUplifting
    ? languageContent[lang]?.spinnerUplifting
    : languageContent[lang]?.spinner;

  if (!spinnerText) {
    spinnerText = '';
  }
  let timer;

  const fadeInFadeOut = () => {
    if (!fade) {
      if (spinnerCount + 1 !== spinnerText.length) {
        setSpinnerCount(spinnerCount + 1);
      } else {
        setSpinnerCount(0);
      }
    }
    setFade(!fade);
  };

  useEffect(() => {
    timer = setTimeout(fadeInFadeOut, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [fade]);

  return loading ? (
    <>
      <div className="section spinner" style={{ flexGrow: 1 }}>
        <div className="spinner__content">
          <div className="spinner__loading" />
          <div
            className={`spinner__text fader ${
              fade ? '' : 'fadedOut'
            }`}
          >
            <p>
              <br />
              &shy;
              {spinnerText[spinnerCount]}
            </p>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Spinner;
