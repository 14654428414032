import axios from 'axios';
import { providerListEndpoint } from '../../config';
import getHeaders from '../../utils/getHeaders';
import { logger } from '../../logger';
import { getMergedAddress } from '../../utils/addressHelper';

const log = logger();

const searchForProvider = async (searchParam, currentCountryCode) => {
  // const countryCode = currentProvider?.address?.countryCode;
  try {
    const response = await axios.get(
      `${providerListEndpoint}/search?page=0&limit=20&countryCode=${currentCountryCode}&tradingName=${searchParam.clinic}`,
      getHeaders(),
    );
    const formattedResponse = response.data.providers.map(
      (clinic) => {
        return {
          name: clinic.name,
          address: getMergedAddress(clinic),
          providerId: clinic.id,
          id: clinic.id,
        };
      },
    );
    return formattedResponse;
  } catch (err) {
    log.error('Error fetching provider', err);
    return null;
  }
};

const fetchProviderService = {
  searchForProvider,
};

export default fetchProviderService;
