import { MODAL_TRIGGER_OPEN, MODAL_TRIGGER_CLOSE } from './types';
import { logger } from '../logger';

const log = logger();

const headerCancelModal = (toggle) => async (dispatch) => {
  try {
    dispatch({
      type: MODAL_TRIGGER_OPEN,
      payload: toggle,
    });
  } catch (err) {
    log.error('Error dispatching header cancel modal Action', err);
    dispatch({
      type: MODAL_TRIGGER_CLOSE,
      payload: toggle,
    });
  }
};

export default headerCancelModal;
