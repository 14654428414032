import { MODAL_TRIGGER_OPEN, MODAL_TRIGGER_CLOSE } from '../actions/types';

const initialState = false;

export default function modalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MODAL_TRIGGER_OPEN:
      return payload;
    case MODAL_TRIGGER_CLOSE:
      return false;

    default:
      return state;
  }
}
