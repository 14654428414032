import {
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  ID_TOKEN_COOKIE,
  PROVIDER_DETAILS_COOKIE,
} from '../config';
import cookieService from './cookieService';
import tokenService from './tokenService';

const getTokenTimeToExpiry = () => {
  const token = cookieService.getCookie(ACCESS_TOKEN_COOKIE);
  if (token) {
    const tokenExpiry = tokenService.getTokenExpiry(token);
    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    return tokenExpiry - currentTimeSeconds;
  }
  return false;
};

const renewToken = async (providerId) => {
  if (!providerId) {
    // eslint-disable-next-line no-console
    console.error('Token refresh failed - provider ID is missing');
    return null;
  }

  const {
    accessToken: newAccessToken,
    refreshToken: newRefreshToken,
    expiresIn,
  } = await tokenService.getProviderToken(providerId);

  if (newAccessToken) {
    const expiresInMins = expiresIn ? expiresIn / 60 : 30;

    if (newAccessToken) {
      cookieService.setCookie(
        ACCESS_TOKEN_COOKIE,
        newAccessToken,
        expiresInMins,
      );
    }

    if (newRefreshToken) {
      cookieService.setCookie(
        REFRESH_TOKEN_COOKIE,
        newRefreshToken,
        expiresInMins,
      );
    }

    // ID Token (reset the cookie expiry)
    const idToken = cookieService.getCookie(ID_TOKEN_COOKIE);
    cookieService.setCookie(
      ID_TOKEN_COOKIE,
      idToken,
      expiresInMins * 4,
    );
  } else {
    // token renewal unsuccessful. delete the provider list from local storage
    cookieService.removeCookie(PROVIDER_DETAILS_COOKIE);
  }

  return newAccessToken;
};

const tokenRenewalService = {
  getTokenTimeToExpiry,
  renewToken,
};
export default tokenRenewalService;
