import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../ui/Header/Header.js';
import { dataMapper } from './dataMapper.js';
import { isValidUrl } from '../../utils/stringFns';
import cookieService from '../../services/cookieService.js';
import {
  USER_COOKIE,
  SHARED_MENU_COOKIE,
  routes,
} from '../../config';
import providerDetails from '../../actions/providerDetailsAction';
import providerService from '../../services/providerService';
import { exchangeProviderToken } from '../../utils/authprovider';
import { getLabelsForFields } from '../../utils/labelHelper';
import featureFlagService from '../../services/featureFlagService';
// import userService from '../../services/userService';
import CustomerContactModal from '../CustomerContactModal';

const HeaderNew = ({
  rendering,
  lang,
  country,
  currentState,
  setCurrentState,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentProvider = providerService.getCurrentProvider();
  const providerCountryCode = currentProvider?.address?.countryCode;
  const providerRegion = providerService.getProviderRegion(
    providerCountryCode,
  );
  const [modalType, setModalType] = useState('contactUs');
  const [openModal, setOpenModal] = useState(false);

  const labels = getLabelsForFields(rendering.fields);

  /**
   * Compact menu to store in cookie
   *
   * @param {Array} mn - menu array
   * @returns {Array} - compacted array
   */
  function stripMenu(mn) {
    const compactMenu = [];
    mn.menus.forEach((menu) => {
      compactMenu.push(menu.id);
    });
    return compactMenu;
  }

  function onNavItemClick(selItem) {
    if (selItem.type === 'External') {
      window.open(selItem.url);
    } else if (selItem.type === 'SimpleText') {
      if (selItem.url === '#showContactUsModal') {
        setModalType('contactUs');
        setOpenModal(true);
      } else if (selItem.url === '#showReturnOldPortalModal') {
        setModalType('returnOldPortal');
        setOpenModal(true);
      } else if (isValidUrl(selItem.url)) {
        window.location = selItem.url;
      } else {
        history.push(`/${country}/${lang}/${selItem.url}`);
      }
    }
  }

  const onClinicSelected = async (selItem, professionalId) => {
    setCurrentState({ ...currentState, loading: true });
    const updatedFeatureFlag =
      await featureFlagService.getFeatureFlagConfig(
        selItem,
        professionalId,
      );
    if (updatedFeatureFlag) {
      localStorage.setItem(
        'featureFlags',
        JSON.stringify(updatedFeatureFlag),
      );
    }

    const providerDetailsData = providerService.updateProviderDetails(
      'providerId',
      selItem,
    );

    // update state
    dispatch(providerDetails(providerDetailsData));

    // update tokens with new providerId
    await exchangeProviderToken(selItem);

    // if we reset the state, then the screen can be already interacted with before the page reloads
    // leave the spinner until the page reloads
    // setCurrentState({...currentState, loading: false});

    // reload page to initialise all screens with new provider details
    // but redirect back to dashboard
    const dest =
      country && lang
        ? `/${country}/${lang}/${routes.dpx.home}`
        : `${routes.dpx.home}`;
    window.location.href = dest;
  };

  const countryConfig = useSelector(
    (state) => state.countryConfig.value,
  );

  const res = dataMapper(
    rendering.fields,
    onNavItemClick,
    lang,
    country,
    countryConfig,
    onClinicSelected,
    labels,
    providerCountryCode,
    providerRegion,
  );
  cookieService.setCookie(
    SHARED_MENU_COOKIE,
    JSON.stringify(stripMenu(res.config.topNavMenus)),
    262980,
    true,
  );

  cookieService.setCookie(
    USER_COOKIE,
    JSON.stringify({ country, lang }),
    262980,
    true,
  );

  const handleModalClosed = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Header config={res.config} data={res.data} labels={labels} />
      <CustomerContactModal
        openModal={openModal}
        modalType={modalType}
        labels={labels}
        res={res}
        onCloseClicked={handleModalClosed}
      />
    </>
  );
};

export default HeaderNew;
