import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import {
  Modal,
  Stack,
  Button,
} from '@cochlear-design-system/foundation';
import { routes, idleTimerTimeout, idleTimerPromptBeforeIdle } from '../config';

const IdleTimer = () => {
  const displayTimer = new URLSearchParams(useLocation().search).get(
    'displaytimer',
  );
  const timerDisplay = displayTimer === 'true' ? '' : 'none';

  const [event, setEvent] = useState('n/a');
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  // const [elapsed, setElapsed] = useState(0);
  const [open, setOpen] = useState(false);
  const [logout, setLogout] = useState(false);

  const throttle = 500;
  const timeout =idleTimerTimeout
  const promptBeforeIdle = idleTimerPromptBeforeIdle;

  const onIdle = () => {
    setState('Idle');
    setOpen(false);
    setLogout(true);
  };

  const onActive = () => {
    setState('Active');
    setOpen(false);
  };

  const onPrompt = () => {
    setState('Prompted');
    setOpen(true);
  };

  const onAction = (e) => {
    setEvent(e?.type ?? 'n/a');
    setCount(count + 1);
  };

  const {
    getRemainingTime,
    // getElapsedTime,
    activate,
    getTabId,
    isLeader,
    isLastActiveTab,
  } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      // setElapsed(Math.ceil(getElapsedTime() / 1000));
    }, throttle);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(
    remaining - promptBeforeIdle / 1000,
    0,
  );
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  const tabId =
    getTabId() === null ? 'loading' : getTabId().toString();
  const lastActiveTab =
    isLastActiveTab() === null
      ? 'loading'
      : isLastActiveTab().toString();
  const leader =
    isLeader() === null ? 'loading' : isLeader().toString();

  return logout ? (
    <Redirect to={routes.dpx.logout} />
  ) : (
    <>
      <div style={{ width: '100%', display: timerDisplay }}>
        <div style={{ margin: 'auto', width: '200px' }}>
          <Stack gap={1}>
            <Stack direction="horizontal" gap={1}>
              <div>Current State:</div>
              <div>
                <strong>{state}</strong>
              </div>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <div>Tab ID:</div>
              <div>
                <strong>{tabId}</strong>
              </div>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <div>Last Active Tab:</div>
              <div>
                <strong>{lastActiveTab}</strong>
              </div>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <div>Is Leader Tab:</div>
              <div>
                <strong>{leader}</strong>
              </div>
            </Stack>

            {timeTillPrompt > 0 && (
              <p>
                <strong>{timeTillPrompt}</strong>
                {seconds}
                until prompt
              </p>
            )}
            <p>
              <strong>{remaining}</strong>
              seconds remaining
            </p>
            {/* <p>
              <strong>{elapsed}</strong>
              seconds elapsed
            </p> */}
            <Stack direction="horizontal" gap={1}>
              <div>Last Event:</div>
              <div>
                <strong>{event}</strong>
              </div>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <div>Action Events:</div>
              <div>
                <strong>{count}</strong>
              </div>
            </Stack>
          </Stack>
        </div>
      </div>
      <div className="modal-container">
        <Modal
          show={open}
          onHide={() => setOpen(false)}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
          variant="primary"
        >
          <Modal.Header />
          <Modal.Body>
            <div>
              <Stack gap={3}>
                <h3>Are you still here?</h3>
                <p>
                  {'Logging out in '}
                  <strong>{remaining}</strong>
                  {' seconds'}
                </p>
                <p>
                  <Button
                    onClick={handleStillHere}
                    text="Continue working"
                  />
                </p>
              </Stack>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default IdleTimer;
