import React, { useState } from 'react';
import {
  Modal,
  Text,
  Button,
  FormButton,
  Alert,
} from '@cochlear-design-system/foundation';

const AgreementModal = ({ labels, onSubmit, onClose, visible }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal dialogClassName="agreement-modal" show={visible}>
      <Modal.Header
        closeButton
        onHide={onClose}
        data-analytics="cancel_header_privacy_create_patient"
      >
        <Modal.Title>
          {labels['labels.createPatient.agreementModal.title']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          variant="warning"
          heading={
            labels[
              'labels.advancedSearch.tabs.patient.agreementModal.notification'
            ]
          }
        />
        <div className="agreement-modal__content">
          <Text
            content={
              labels[
                'labels.advancedSearch.tabs.patient.agreementModal.content'
              ]
            }
            isHTML
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onClose}
          text={
            labels[
              'labels.advancedSearch.tabs.patient.agreementModal.cancel'
            ]
          }
          data-analytics="cancel_privacy_create_patient"
        />
        <FormButton
          onClick={() => {
            setIsSubmitting(true);
            onSubmit();
          }}
          text={
            labels[
              'labels.advancedSearch.tabs.patient.agreementModal.agree'
            ]
          }
          progress={isSubmitting ? 2 : undefined}
          type="save"
          data-analytics="agree_privacy_create_patient"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AgreementModal;
