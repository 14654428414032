/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Title,
  Text,
  Stack,
} from '@cochlear-design-system/foundation';
import Spinner from '../Spinner';
import { ClinicList } from '../../ui/SelectClinic/ClinicList';
import Redirect from '../Redirect';
import dataService from '../../services/dataService';
import userService from '../../services/userService';
import providerService from '../../services/providerService';
import cookieService from '../../services/cookieService';
import providerDetails from '../../actions/providerDetailsAction';
import {
  ACCESS_TOKEN_COOKIE,
  DPX_OPTOUT_COOKIE,
  SHARED_MENU_COOKIE,
  routes,
  allowedUserAccountTypes,
  userAccountType,
} from '../../config';
import { selectClinicConfig } from './selectClinicConfig';
import { openPopup } from '../../utils/popupManager';
import { setIdpParam } from '../../utils/authprovider';
import { getLabelsForFields } from '../../utils/labelHelper';

const SelectClinic = ({ fields }) => {
  const labels = getLabelsForFields(fields);
  const uDetails = userService.getUserDetails();
  const dispatch = useDispatch();
  const [nextStep, setNextStep] = useState();
  const [navigation] = performance.getEntriesByType('navigation');
  const [isReload, setIsReload] = useState(false);
  const [providerList, setProviderList] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const providerListLocalStorage =
    providerService.getCachedProviders()?.providerList;

  useEffect(() => {
    if (navigation.type === 'reload') {
      setIsReload(true);
    } else {
      setProviderList(providerListLocalStorage);
    }
  }, []);

  useEffect(() => {
    async function getProviders() {
      const {
        providerList: providers,
        providerId,
        hasClinic,
        next,
      } = await providerService.checkClinics(
        uDetails.professionalId,
        uDetails.countryCode,
      );

      if (next === 'error') {
        setNextStep(routes.dpx.error);
        return;
      }

      if (providers && hasClinic) {
        const providerDetailsData =
          providerService.storeProviderDetails(providerId, providers);
        dispatch(providerDetails(providerDetailsData));
      }

      setProviderList(providers);
      setLoading(false);
    }
    if (isReload) {
      setLoading(true);
      getProviders();
    }
  }, [isReload]);

  useEffect(() => {
    if (providerList) {
      const result = dataService.transformProviders(
        providerList.providers,
      );
      setData(result);
    }
  }, [providerList]);

  const handleClick = async (id) => {
    const accessToken = cookieService.getCookie(ACCESS_TOKEN_COOKIE);
    if (!accessToken) {
      // if token cookie is no longer present, log the user out
      window.location.href = routes.dpx.logout;
      return;
    }

    if (uDetails.role !== userAccountType.ContentProfessional) {
      // Enable MCP init popup only if the user is NOT ContentProfessional
      openPopup(
        setIdpParam(routes.mcp.warmup),
        'mcp',
        'popup,width=245,height=140',
      );
    }

    /** redirection logic */
    const { redirectFlag, partyId } = providerList?.providers.find(
      (p) => p.id === id,
    );

    let next = '';

    if (
      allowedUserAccountTypes.includes(uDetails.role) &&
      ['DPX', 'DISABLED'].includes(redirectFlag)
    ) {
      next = routes.dpx.initializer;

      const providerDetailsData =
        providerService.updateProviderDetails('providerId', id);

      cookieService.setCookie(DPX_OPTOUT_COOKIE, false, 0, true);

      // update state
      dispatch(providerDetails(providerDetailsData));
    } else {
      cookieService.setCookie(
        DPX_OPTOUT_COOKIE,
        true,
        Infinity,
        true,
      );
      cookieService.setCookie(SHARED_MENU_COOKIE, false, 0, true);
      next = `${routes.outbound}?id=${partyId}`;
    }

    setNextStep(next);
  };

  selectClinicConfig.clickHandler = handleClick;

  return nextStep ? (
    <Redirect push to={nextStep} />
  ) : loading ? (
    <Spinner loading={loading} />
  ) : (
    <div className="ccl__container">
      <div className="dpx__select-clinic">
        <Stack gap={3}>
          <div>
            <Title
              content={labels['labels.selectClinic.heading']}
              size="heading-3"
              tag="h3"
            />
          </div>
          <div>
            <Text
              content={labels['labels.selectClinic.myClinics']}
              type="body-text-bold"
            />
          </div>
          <div>
            <ClinicList config={selectClinicConfig} data={data} />
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default SelectClinic;
