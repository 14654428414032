import React from 'react';
import logo from '../../assets/images/wordmark.svg';

const Header = () => {
  return (
    <nav
      className="navbar header-nav"
      role="navigation"
      aria-label="main navigation"
      id="navbar"
    >
      <div className="container">
        <div className="navbar--items">
          <div className="navbar-item navbar-item--brand">
            <img src={logo} alt="Cochlear" />
          </div>

          <div className="navbar-item" />
        </div>
      </div>
    </nav>
  );
};

export default Header;
