import {
  PROVIDER_DETAILS_SUCCESS,
  PROVIDER_DETAILS_FAILURE,
} from './types';
import { logger } from '../logger';

const log = logger();

const providerDetails = (provider) => async (dispatch) => {
  try {
    dispatch({
      type: PROVIDER_DETAILS_SUCCESS,
      payload: provider,
    });
  } catch (err) {
    log.error('Error dispatching provider details', err);
    dispatch({
      type: PROVIDER_DETAILS_FAILURE,
      payload: err,
    });
  }
};

export default providerDetails;
