/* eslint-disable import/first */

import { ApolloClient, InMemoryCache } from '@apollo/client';
import 'cross-fetch/polyfill';
import { sha256 } from 'crypto-hash';

/* eslint-disable import/order */

/*
  https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
*/
import possibleTypes from '../temp/possibleTypes.json';

/*
  QUERY LINK SELECTION
  A link is transport which GraphQL queries are pushed across.
  You have many choices.
  See the apollo-link documentation for more details.
*/

// choose between a basic HTTP link to run queries...
// import { createHttpLink } from '@apollo/client';
// const link = createHttpLink({ uri: endpoint });

// ...or a batched link (multiple queries within 10ms all go in one HTTP request)
import { BatchHttpLink } from '@apollo/client/link/batch-http';

// ...and an automatic persisted query link, which reduces bandwidth by using query hashes to alias content
// the APQ link is _chained_ behind another link that performs the actual HTTP calls, so you can choose
// APQ + batched, or APQ + http links for example.
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import config from '../temp/config';

// eslint-disable-next-line func-names
export default function (endpoint, ssr, initialCacheState) {
  /* HTTP link selection: default to batched + APQ */
  const link = createPersistedQueryLink({ sha256 }).concat(
    new BatchHttpLink({
      uri: endpoint,
      headers: {
        connection: 'keep-alive',
        sc_apikey: config.sitecoreApiKey,
      },
    }),
  );

  const cache = new InMemoryCache({
    possibleTypes
  });

  return new ApolloClient({
    ssrMode: ssr,
    ssrForceFetchDelay: 100,
    link,
    cache: cache.restore(initialCacheState),
  });
}
