import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import cookieService from './cookieService';
import { ID_TOKEN_COOKIE, providerListEndpoint } from '../config';
import userDetails from '../actions/userDetailsAction';
import getHeaders from '../utils/getHeaders';
import { logger } from '../logger';

const log = logger();

const decodeIdToken = (idToken) => {
  let decoded = {};
  try {
    decoded = jwtDecode(idToken);
  } catch (err) {
    log.error('invalid token', err);
    return {};
  }

  const firstName = decoded.given_name;
  const lastName = decoded.family_name;
  const role = decoded['https://www.cochlear.com/user_type'];
  const professionalId =
    decoded['https://www.cochlear.com/cochlear_id'];
  const countryCode =
    decoded['https://www.cochlear.com/country_code'];
  const username = decoded.email;
  const languageCode = decoded['https://www.cochlear.com/language'];
  // TO BE CONFIRMED
  const dateOfBirth = decoded['https://www.cochlear.com/dob'] || '';
  const partyId = decoded['https://www.cochlear.com/party_id'] || '';
  const region = decoded['https://www.cochlear.com/region'] || '';
  const loginAppName =
    decoded['https://www.cochlear.com/login_app_name'] || 'dpxportal';

  return {
    firstName,
    lastName,
    role,
    professionalId,
    countryCode,
    username,
    dateOfBirth,
    partyId,
    region,
    languageCode,
    loginAppName,
  };
};

const getUserDetails = () => {
  const dispatch = useDispatch();

  // look for user details in global state
  const uDetails = useSelector((state) => state.userDetails);
  if (uDetails) return uDetails;

  // if not found, read the id cookie
  const idToken = cookieService.getCookie(ID_TOKEN_COOKIE);

  // decode cookie
  if (idToken) {
    const decodedUserDetails = decodeIdToken(idToken);
    // store the user info in state
    dispatch(userDetails(decodedUserDetails));
    return decodedUserDetails;
  }

  return {};
};

const getCountryConfig = async (country) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}/country-config/${country}`,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error getting country config', err);
    return null;
  }
};

const userService = {
  decodeIdToken,
  getUserDetails,
  getCountryConfig,
};

export default userService;
