export const getErrorConfig = () => {
  return {
    notFoundErrorConfig: {
      buttons: [],
      imgAlt: 'labels.errorMessage.imgAlt',
      imgSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
      imgWidth: 116,
      layout: 'horizontal',
      text: 'labels.error.checkUrl',
      title: 'labels.error.404',
      code: 404,
    },
  };
};

export const getPersonalDetailsConfig = (dateInputFormat) => {
  return {
    formats: {
      date: dateInputFormat,
    },
    fields: [
      {
        id: 'firstName',
        label:
          'labels.advancedSearch.tabs.patient.fields.firstName.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.firstName.hint',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.firstName.placeholder',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.editPatient.edit.firstname.validation.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.editPatient.edit.firstname.validation.invalidmaxlength',
          },
          {
            type: 'required',
            message:
              'labels.editPatient.edit.firstname.validation.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.specialChars',
          },
        ],
      },
      {
        id: 'middleName',
        label:
          'labels.advancedSearch.tabs.patient.fields.middleName.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.middleName.hint',
        optionalText:
          'labels.advancedSearch.tabs.patient.fields.middleName.optional',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.middleName.placeholder',
        validators: [
          {
            type: 'max',
            value: 40,
            message:
              'labels.editPatient.edit.middleName.validation.invalidmaxlength',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.advancedSearch.tabs.patient.fields.middleName.specialChars',
          },
        ],
      },
      {
        id: 'lastName',
        label:
          'labels.advancedSearch.tabs.patient.fields.lastName.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.lastName.hint',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.lastName.placeholder',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.editPatient.edit.lastName.validation.required',
          },
          {
            type: 'max',
            value: 80,
            message:
              'labels.editPatient.edit.lastName.validation.invalidmaxlength',
          },
          {
            type: 'required',
            message:
              'labels.editPatient.edit.lastName.validation.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.specialChars',
          },
        ],
      },
      {
        id: 'dateOfBirth',
        label:
          'labels.advancedSearch.tabs.patient.fields.dateOfBirth.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.hint',
        fieldLabels: {
          d: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.dayLabel',
          m: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.monthLabel',
          y: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.yearLabel',
        },
        validators: [
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.fields.dateOfBirth.validators.required',
          },
        ],
      },
    ],
  };
};

export const getContactDetailsConfig = () => {
  return {
    fields: [
      {
        id: 'mobile',
        label:
          'labels.advancedSearch.tabs.patient.fields.mobile.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.mobile.hint',
        hintForCode:
          'labels.advancedSearch.tabs.patient.fields.mobile.hintForCode',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.mobile.placeholder',
        validators: [
          {
            type: 'required',
            message:
              'labels.editPatient.edit.mobile.validation.required',
          },
          {
            type: 'min',
            value: 7,
            message:
              'labels.editPatient.edit.mobile.validation.minLength',
          },
          {
            type: 'max',
            value: 12,
            message:
              'labels.editPatient.edit.mobile.validation.maxLength',
          },
        ],
      },
      {
        id: 'phone',
        label:
          'labels.advancedSearch.tabs.patient.fields.phone.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.phone.hint',
        hintForCode:
          'labels.advancedSearch.tabs.patient.fields.phone.hintForCode',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.phone.placeholder',
        validators: [
          {
            type: 'required',
            message:
              'labels.editPatient.edit.phone.validation.required',
          },
          {
            type: 'min',
            value: 7,
            message:
              'labels.editPatient.edit.phone.validation.minLength',
          },
          {
            type: 'max',
            value: 12,
            message:
              'labels.editPatient.edit.phone.validation.maxLength',
          },
        ],
      },
      {
        id: 'email',
        label:
          'labels.advancedSearch.tabs.patient.fields.email.label',
        hint: 'labels.advancedSearch.tabs.patient.fields.email.hint',
        optionalText:
          'labels.advancedSearch.tabs.patient.fields.email.optionalText',
        placeholder:
          'labels.advancedSearch.tabs.patient.fields.email.placeholder',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.editPatient.edit.email.validation.required',
          },
          {
            type: 'max',
            value: 55,
            message:
              'labels.editPatient.edit.email.validation.invalidmaxlength',
          },
          {
            type: 'required',
            message:
              'labels.editPatient.edit.email.validation.required',
          },
          {
            type: 'matches',
            value: /^[^a-zA-Z._%+-]*$/,
            message:
              'labels.advancedSearch.tabs.patient.errors.email.specialChars',
          },
        ],
      },
    ],
  };
};
