import { ERROR_SUCCESS, ERROR_FAILURE } from './types';
import { logger } from '../logger';

const log = logger();

const error = (e) => async (dispatch) => {
  try {
    dispatch({
      type: ERROR_SUCCESS,
      payload: e,
    });
  } catch (err) {
    log.error('Error dispatching error', err);
    dispatch({
      type: ERROR_FAILURE,
      payload: err,
    });
  }
};

export default error;
