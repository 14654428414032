import axios from 'axios';
import getHeaders from '../../utils/getHeaders';
import { providerListEndpoint } from '../../config';
import { logger } from '../../logger';

const log = logger();

const createProfessionalCases = async (
  providerId,
  professionalId,
  payload,
) => {
  const requestBody = { ...payload, professionalId };
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/professionals/${professionalId}/cases`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error creating professional cases', err);
    return null;
  }
};

const createProfessionalCasesService = {
  createProfessionalCases,
};

export default createProfessionalCasesService;
