/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Text,
  Spinner,
  Title,
} from '@cochlear-design-system/foundation';
import resourceService from '../../services/resourceService';
import getLocalStorageLabels from '../../utils/getLocalStorageLabels';
import Accordion from '../Accordion';
import { dataMapper, replaceAppNameWithCountry } from './dataMapper';

const ResourcePage = ({ lang, locale, country, siteName }) => {
  const resourcePage = useLocation().pathname.slice(6);
  const labels = getLocalStorageLabels(locale);
  let resourceContent = null;
  const [headerTitle, setHeaderTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [headerSubtitle, setHeaderSubtitle] = useState();
  const [subSummary, setSubSummary] = useState('');
  const [accordionItems, setAccordionItems] = useState();
  const [resourceLoaded, setResourceLoaded] = useState(false);

  function setResourceFields(
    setHeaderTitle,
    resourceContent,
    setSummary,
    setHeaderSubtitle,
    setSubSummary,
  ) {
    setHeaderTitle(
      resourceContent.sitecore.route.placeholders['dpx-main'][0]
        .fields.data?.item.HeaderTitle?.value,
    );
    setSummary(
      replaceAppNameWithCountry(
        resourceContent.sitecore.route.placeholders['dpx-main'][0]
          .fields.data?.item.Summary?.rendered,
        country,
      ),
    );
    setHeaderSubtitle(
      resourceContent.sitecore.route.placeholders['dpx-main'][0]
        .fields.data?.item.HeaderSubtitle?.value,
    );
    setSubSummary(
      replaceAppNameWithCountry(
        resourceContent.sitecore.route.placeholders['dpx-main'][0]
          .fields.data?.item.SubSummary?.rendered,
        country,
      ),
    );
  }
  const getResource = async () => {
    setResourceLoaded(false);
    resourceContent = await resourceService.getResourceContent(
      resourcePage,
      lang,
      siteName,
    );
    setResourceFields(
      setHeaderTitle,
      resourceContent,
      setSummary,
      setHeaderSubtitle,
      setSubSummary,
    );
    setAccordionItems(
      dataMapper(
        resourceContent.sitecore.route.placeholders['dpx-main'][0]
          .fields.data?.item.AccordionItems,
        country,
      ),
    );
    setResourceLoaded(true);
  };

  useEffect(() => {
    getResource();
  }, [resourcePage]);

  return !resourceLoaded ? (
    <div
      style={{
        margin: 'auto',
        textAlign: 'center',
        marginTop: '3rem',
      }}
    >
      <Spinner animation="border" />
      <Text content={labels['labels.resources.loading']} />
    </div>
  ) : (
    <div
      className="ccl__container dpx__main-content"
      style={{
        width: '60rem',
        margin: '1rem auto',
      }}
    >
      <div className="row">
        <header className="ccl__page-header">
          <h1 className="ccl__title ccl__title--heading-1 ccl__page-header__title">
            {headerTitle}
          </h1>
        </header>
        <Text content={summary} type="body-text" isHTML />

        {headerSubtitle || subSummary ? (
          <div
            data-nw-state="read"
            className="ccl-e-detail-action-bar"
            style={{
              margin: '1rem auto 1rem auto',
              textAlign: 'center',
            }}
          >
            <div className="ccl-e-detail-action-bar__title">
              <Title
                content={headerSubtitle}
                size="heading-4"
                tag="h4"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <Text content={subSummary} type="body-text" isHTML />

        <Accordion accordionItems={accordionItems} />
      </div>
    </div>
  );
};

export default ResourcePage;
