import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  TextInput,
  DateInput,
  Container,
  Row,
  Col,
  PageHeader,
  Button,
  SelectInput,
  FormButton,
  Alert,
  Preloader,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { Feature } from 'flagged';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dateFormat from 'dateformat';
import { defaultBrowserLanguage, routes } from '../../config';
import { getLabelsForFields } from '../../utils/labelHelper';
import getQueryString from '../../utils/queryStrings';
import providerService from '../../services/providerService';
import patientService from '../../services/patientService';
import userService from '../../services/userService';
import DisabledFeature from '../DisabledFeature';
import headerCancelModal from '../../actions/headerCancelModalAction';
import error from '../../actions/errorAction';
import stepAction from '../../actions/stepAction';
import notificationStatus from '../../actions/notificationStatusAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import { trimStringValues } from '../../utils/stringFns';
import { getDateInputFormat } from '../../utils/dateTimeFns';
import {
  isValidPastDateCarer,
  getBaseDateFieldsConfigCarer,
  getLanguageList,
  checkMinor,
  getAgeOfMaturity,
  buildMatchingRecordSet,
} from '../../utils/patientCarerHelper';
import { DetailsView } from '../CreatePatientCarer/components/DetailsView';
import AlertComponent from '../CreatePatientCarer/components/alertView';
import AgreementModal from '../CreatePatientCarer/components/AgreementModal';
import { getErrorConfig } from '../EditPatient/editPatientConfig';
import { getConfig } from './addCarerConfig';

const AddCarer = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const userCountry = country.toUpperCase();

  const dispatch = useDispatch();
  const history = useHistory();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const currentProvider = providerService.getCurrentProvider();
  const providerCountry = currentProvider.address?.countryCode;
  const { format: dateInputFormat, hint: dateHint } =
    getDateInputFormat(providerCountry);
  const providerId = currentProvider.id;
  const { professionalId } = userService.getUserDetails();
  const patientId = getQueryString('patientId');

  const DATE_UNITS_CARER = ['dayCarer', 'monthCarer', 'yearCarer'];
  const ageOfMaturity = getAgeOfMaturity();

  const [pageLevelError, setPageLevelError] = useState(false);
  const [continueButtonText, setContinueButtonText] = useState(
    labels['labels.addCarer.button.add'],
  );

  const [patientDetails, setPatientDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addCarerFailed, setAddCarerFailed] = useState(false);
  const [provisionalSelected, setProvisionalSelected] =
    useState(false);
  const [isAgreementCarerSubmitting, setIsAgreementCarerSubmitting] =
    useState(false);
  const [agreementModalCarerVisible, setAgreementModalCarerVisible] =
    useState(false);

  const [validateFields, setValidateFields] = useState([
    'firstNameCarer',
    'middleNameCarer',
    'lastNameCarer',
    'email',
    ...DATE_UNITS_CARER,
  ]);

  const [step, setStep] = useState(
    useSelector((state) => state.step) || 'c3',
  );

  const [matchingCarers, setMatchingCarers] = useState([]);
  const [ismatchingCarerSelected, setIsmatchingCarerSelected] =
    useState(false);
  const [matchingCarerSelected, setMatchingCarerSelected] =
    useState();
  const defaultLanguage =
    localStorage.getItem('browserLang')?.slice(0, 2) ||
    defaultBrowserLanguage;

  const handleCancel = () => {
    setAddCarerFailed(false);
    setProvisionalSelected(false);
    setStep('c3');
    dispatch(stepAction('c3'));
    if (step === 'c4a' || step === 'c4b') {
      setIsmatchingCarerSelected(false);
      setMatchingCarerSelected();
    } else {
      history.push({
        pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
        search: `?patientId=${patientId}`,
      });
    }
  };

  const handleAgreementModalCarerSubmit = async () => {
    // eslint-disable-next-line
    handleSubmit();
  };

  const existingRecordCheckCarer = async (data) => {
    const personalData = {
      firstName: data.firstNameCarer,
      lastName: data.lastNameCarer,
      email: data.email,
      countryCode: userCountry,
    };

    if (data.yearCarer && data.monthCarer && data.dayCarer) {
      const date = new Date(
        +data.yearCarer,
        +data.monthCarer - 1,
        +data.dayCarer,
      );
      const dateOfBirthCarer = dateFormat(date, 'yyyy-mm-dd');
      personalData.dateOfBirth = dateOfBirthCarer;
    }
    setSubmitDisabled(true);
    const result = await patientService.checkExistingRecord(
      personalData,
      currentProvider.id,
    );
    setSubmitDisabled(false);

    if (result && result.records && result.records.length > 0) {
      setMatchingCarers(buildMatchingRecordSet(result.records));
    }
    return result;
  };

  const onMatchingRecordRowClickCarer = async (carer) => {
    // show error for provisional records, do not allow selection
    if (carer?.persona?.indexOf('Provisional') !== -1) {
      setProvisionalSelected(true);
      setSubmitDisabled(true);
      return;
    }

    let selectedCarer = carer;
    if (selectedCarer.dob) {
      const dobParts = selectedCarer.dob.split('-');
      if (dobParts.length === 3) {
        selectedCarer = {
          ...selectedCarer,
          day: dobParts[2],
          month: dobParts[1],
          year: dobParts[0],
        };
      }
    } else {
      selectedCarer = {
        ...selectedCarer,
        day: '--',
        month: '--',
        year: '--',
      };
    }
    setMatchingCarerSelected(selectedCarer);
    setIsmatchingCarerSelected(true);
    setSubmitDisabled(true);
    setProvisionalSelected(false);

    setAgreementModalCarerVisible(true);
  };

  const createPatient = async (values) => {
    setSubmitDisabled(true);
    const result = await patientService.createPatientCarer(
      currentProvider.id,
      professionalId,
      userCountry,
      values,
      null,
      matchingCarerSelected,
      { cochlearId: patientId },
      true,
      ismatchingCarerSelected,
    );
    setSubmitDisabled(false);
    dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
    return result;
  };

  const handleCreatePatientCalled = (cpResult) => {
    if (cpResult?.success) {
      setStep('c5');
      dispatch(stepAction('c5'));
    } else {
      setStep('c3');
      dispatch(stepAction('c3'));
      setIsSubmitting(false);
      setAddCarerFailed(true);
    }
  };

  const addPatientCarerRelationship = async () => {
    const result = await patientService.addCarer(
      providerId,
      patientId,
      matchingCarerSelected.cochlearId,
    );
    return result;
  };

  const handlePatientCarerRelationshipCalled = (addCarerResult) => {
    setIsAgreementCarerSubmitting(false);
    setAgreementModalCarerVisible(false);
    setAddCarerFailed(false);

    if (addCarerResult?.success) {
      setIsmatchingCarerSelected(false);
      setMatchingCarerSelected();
      setMatchingCarers();
      setStep('c3');
      dispatch(stepAction('c3'));
      history.push({
        pathname: `/${country}/${lang}/${routes.dpx.editPatient}`,
        search: `?patientId=${patientId}&carerId=${matchingCarerSelected.cochlearId}`,
        state: { addCarer: true },
      });
    } else {
      setIsSubmitting(false);
      setAddCarerFailed(true);
    }
  };

  const isRecipientCarerDetailsMatching = (values) => {
    const {
      firstName: firstNameRecipient,
      lastName: lastNameRecipient,
      dateOfBirth,
    } = patientDetails;
    const firstNameCarer = values.firstNameCarer || '';
    const lastNameCarer = values.lastNameCarer || '';
    let dateCarer;
    let dateOfBirthCarer;
    if (values.yearCarer && values.monthCarer && values.dayCarer) {
      dateCarer = new Date(
        +values.yearCarer,
        +values.monthCarer - 1,
        +values.dayCarer,
      );
      dateOfBirthCarer = dateFormat(dateCarer, 'yyyy-mm-dd');
    }

    return (
      firstNameRecipient.toLowerCase() ===
        firstNameCarer.toLowerCase() &&
      lastNameRecipient.toLowerCase() ===
        lastNameCarer.toLowerCase() &&
      dateOfBirth === dateOfBirthCarer
    );
  };

  const config = getConfig(
    onMatchingRecordRowClickCarer,
    dateInputFormat,
    labels,
  );
  const { fields } = config;

  const fieldsConfig = fields.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );

  const prefilledValues = {};
  const initialValues = {
    preferredLanguage: defaultLanguage,
    ...prefilledValues,
  };

  const getInputValidations = (inputNames) =>
    Yup.object(
      inputNames.reduce((acc, inputName) => {
        // Config supplied for dateOfBirth applies to all three d/m/y inputs
        let configFieldName;
        if (step === 'c3') {
          configFieldName = DATE_UNITS_CARER.includes(inputName)
            ? 'dateOfBirthCarer'
            : inputName;
        }

        return {
          ...acc,
          [inputName]: fieldsConfig[configFieldName]?.validators
            .reduce((acc2, { type, value, message }) => {
              if (
                type === 'required' &&
                configFieldName !== 'dateOfBirthCarer'
              ) {
                return acc2[type](labels[message]);
              }
              if (
                type === 'required' &&
                configFieldName === 'dateOfBirthCarer'
              ) {
                return acc2.test(
                  'DOB',
                  'Invalid DOB.',
                  (values, context) => {
                    return isValidPastDateCarer(context.parent);
                  },
                );
              }
              return acc2[type](value, labels[message]);
            }, Yup.string())
            .trim(),
        };
      }, {}),
    );

  const [validationSchema, setValidateSchema] = useState(
    getInputValidations(validateFields, fieldsConfig, labels),
  );

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues,
    onSubmit: async (rawValues) => {
      const submittedValues = trimStringValues(rawValues);

      switch (step) {
        case 'c3':
        case 'c3b':
        case 'c3c':
          {
            setAddCarerFailed(false);
            setIsSubmitting(true);
            if (isRecipientCarerDetailsMatching(submittedValues)) {
              setStep('c3c');
              dispatch(stepAction('c3c'));
              setIsSubmitting(false);
              window.scrollTo(0, 0);
              setIsSubmitting(false);
              break;
            }
            const res = await existingRecordCheckCarer(
              submittedValues,
            );
            if (res === null) {
              setIsSubmitting(false);
              break;
            }
            if (res.records.length === 0) {
              // No matching records found but email already used outside the clinic
              // all other combinations in the response should return a matching record
              if (
                res.isAccountMatched &&
                res.isAccountUsedOutsideClinic
              ) {
                setStep('c3b');
                dispatch(stepAction('c3b'));
                setFieldError(
                  'email',
                  labels['labels.addCarer.existingEmailError'],
                );
                window.scrollTo(0, 0);
                setIsSubmitting(false);
              } else {
                // no matches - create the carer record
                const cpResult = await createPatient(submittedValues);
                handleCreatePatientCalled(cpResult);
              }
            } else if (res.records.length > 0) {
              // one or more matching records found
              // account does not exist
              if (res.isPIIMatched && !res.isAccountMatched) {
                // allow these details to be created as new carer
                setStep('c4b');
                dispatch(stepAction('c4b'));
              } else {
                // account already exists
                // only allow to select a record, don't allow to recreate
                setStep('c4a');
                dispatch(stepAction('c4a'));
              }
            }
          }
          break;
        case 'c4a':
          {
            // existing patient record selected as carer
            setIsAgreementCarerSubmitting(true);
            setSubmitDisabled(true);
            // call create patient-patient-relationship
            const addCarerResult =
              await addPatientCarerRelationship();
            handlePatientCarerRelationshipCalled(addCarerResult);
          }
          break;
        case 'c4b':
          if (agreementModalCarerVisible) {
            // existing patient record selected as carer
            setIsAgreementCarerSubmitting(true);
            setSubmitDisabled(true);

            // call create patient-patient-relationship
            const addCarerResult =
              await addPatientCarerRelationship();
            handlePatientCarerRelationshipCalled(addCarerResult);
          } else {
            // user elected to create new - create the carer record
            setIsSubmitting(true);
            setSubmitDisabled(false);
            const cpResult = await createPatient(submittedValues);
            handleCreatePatientCalled(cpResult);
          }
          break;
        default:
          break;
      }

      setIsSubmitting(false);
      setIsAgreementCarerSubmitting(false);
    },

    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const dateFieldsConfigCarer = (() =>
    // Extract dmy key order from a variety of different date formats
    config.formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          getBaseDateFieldsConfigCarer()[field];
        const label =
          labels[fieldsConfig.dateOfBirthCarer.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: values[name],
        };
      }))();

  const [dobCarerValid, dobCarerErrorMessage] = (() => {
    const isTouched = DATE_UNITS_CARER.filter(
      (unit) => touched[unit] || unit in values,
    );
    if (isTouched.length < 3) {
      if (!(values.yearCarer && values.monthCarer && values.dayCarer))
        return [true];
      return [false];
    }

    const invalidDobErrorKey =
      fieldsConfig.dateOfBirthCarer.validators.find(
        ({ type }) => type === 'required',
      ).message;

    if (values.yearCarer && values.monthCarer && values.dayCarer) {
      const dob = `${values.yearCarer}-${values.monthCarer}-${values.dayCarer}`;

      if (!isValidPastDateCarer(values)) {
        return [false, labels[invalidDobErrorKey]];
      }

      if (checkMinor(dob, ageOfMaturity)) {
        const errorMessage =
          labels['labels.addCarer.carerDOBMinorError'];
        const updatedErrorMessage = errorMessage.replace(
          '{ageOfMaturityPlaceholder}',
          ageOfMaturity,
        );
        return [false, updatedErrorMessage];
      }
    }

    if (isValidPastDateCarer(values)) return [true];

    return [false, labels[invalidDobErrorKey]];
  })();

  const fetchPatientDetails = async () => {
    setLoading(true);
    const errorConfig = getErrorConfig();

    const rawPatient = await patientService.getPatient(
      providerId,
      patientId,
    );

    if (
      rawPatient === null ||
      rawPatient.data?.id === null ||
      rawPatient.data?.errors
    ) {
      // dispatch page level error
      dispatch(
        error(
          rawPatient?.status === 404
            ? errorConfig.notFoundErrorConfig
            : true,
        ),
      );
      setPageLevelError(true);
      return;
    }

    setPatientDetails(rawPatient?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  useEffect(() => {
    switch (step) {
      case 'c3':
        setValidateFields([
          'firstNameCarer',
          'middleNameCarer',
          'lastNameCarer',
          'email',
          ...DATE_UNITS_CARER,
        ]);
        setContinueButtonText(labels['labels.addCarer.button.add']);
        break;
      default:
        break;
    }
  }, [step]);

  useEffect(() => {
    setValidateSchema(
      getInputValidations(validateFields, fieldsConfig, labels),
    );
  }, [validateFields]);

  useEffect(() => {
    setSubmitDisabled(true);
    switch (step) {
      case 'c3':
      case 'c3b':
      case 'c3c':
        if (
          (Object.keys(errors).length > 0 &&
            Object.keys(touched).length > 0) ||
          !dobCarerValid
        ) {
          setSubmitDisabled(true);
        } else if (
          Object.keys(errors).length === 0 &&
          Object.keys(touched).length > 0
        ) {
          setSubmitDisabled(false);
        } else if (
          Object.keys(errors).length === 0 &&
          dobCarerValid &&
          'dayCarer' in values &&
          'monthCarer' in values &&
          'yearCarer' in values &&
          'firstNameCarer' in values &&
          'lastNameCarer' in values &&
          'email' in values
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'c4a':
        setSubmitDisabled(true);
        break;
      case 'c4b':
        setSubmitDisabled(false);
        break;
      default:
        break;
    }
  }, [errors, values, touched, step, dobCarerValid]);

  useEffect(() => {
    if (modalTrigger) {
      dispatch(headerCancelModal(false));
      handleCancel();
    }
  }, [modalTrigger]);

  return (
    !pageLevelError && (
      <Feature name="featureEnabler/viewPatient">
        {(isEnabled) =>
          isEnabled ? (
            <div className="ccl__add-carer-wrapper">
              <Container>
                <Row>
                  <Col>
                    <PageHeader
                      className="add-carer__title"
                      title={{
                        content: labels['labels.common.addCarer'],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {step === 'c5' && (
                      <Alert variant="success">
                        {`${labels['labels.addCarer.successMessage']}\n${labels['labels.common.alert.requestSubmitted']}`}
                      </Alert>
                    )}
                    {addCarerFailed && (
                      <Alert
                        variant="error"
                        heading={
                          labels['labels.addCarer.errorMessage.title']
                        }
                      >
                        {labels['labels.addCarer.errorMessage']}
                      </Alert>
                    )}

                    <div className="ccl-l-userTabs ccl-l-userTabs--single">
                      <div className="ccl-l-userTabs__content">
                        <div className="ccl-l-userTabs__content__inner">
                          <AlertComponent
                            step={step}
                            labels={labels}
                          />

                          {loading ? (
                            <Preloader
                              key={`preloader${Math.random()}`}
                              lines={[
                                'title',
                                'label-field',
                                'label-field',
                                'label-field',
                                'label-field',
                                'label-field',
                                'label-field',
                                'label-field',
                                'label-field',
                              ]}
                            />
                          ) : (
                            <>
                              <section className="ccl-c-form-section mt-3">
                                <div className="ccl-e-detail-action-bar">
                                  <div className="ccl-e-detail-action-bar__title">
                                    <span>
                                      {
                                        labels[
                                          'labels.addCarer.carerDetails.title'
                                        ]
                                      }
                                    </span>
                                  </div>
                                </div>
                              </section>

                              <form onSubmit={handleSubmit}>
                                {(step === 'c3' ||
                                  step === 'c3b' ||
                                  step === 'c3c') && (
                                  <>
                                    <TextInput
                                      className="ccl-l-create-patient__fields__name"
                                      name="firstNameCarer"
                                      value={values.firstNameCarer}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label={
                                        labels[
                                          'labels.common.firstName'
                                        ]
                                      }
                                      promptText=""
                                      error={
                                        touched.firstNameCarer &&
                                        !!errors.firstNameCarer
                                      }
                                      errorMsg={
                                        touched.firstNameCarer &&
                                        errors.firstNameCarer
                                      }
                                    />

                                    <TextInput
                                      className="ccl-l-create-patient__fields__name"
                                      name="middleNameCarer"
                                      value={values.middleNameCarer}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label={
                                        labels[
                                          'labels.common.middleName'
                                        ]
                                      }
                                      hint={
                                        labels[
                                          'labels.common.optional'
                                        ]
                                      }
                                      promptText=""
                                      error={
                                        touched.middleNameCarer &&
                                        !!errors.middleNameCarer
                                      }
                                      errorMsg={
                                        touched.middleNameCarer &&
                                        errors.middleNameCarer
                                      }
                                    />

                                    <TextInput
                                      className="ccl-l-create-patient__fields__name"
                                      name="lastNameCarer"
                                      value={values.lastNameCarer}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label={
                                        labels[
                                          'labels.common.lastName'
                                        ]
                                      }
                                      promptText=""
                                      error={
                                        touched.lastNameCarer &&
                                        !!errors.lastNameCarer
                                      }
                                      errorMsg={
                                        touched.lastNameCarer &&
                                        errors.lastNameCarer
                                      }
                                    />

                                    <DateInput
                                      className="ccl-l-advanced-search-patient-tab__fields__dob"
                                      name="dateOfBirthCarer"
                                      isStrict
                                      label={
                                        labels[
                                          'labels.common.dateOfBirth'
                                        ]
                                      }
                                      hint={labels[dateHint]}
                                      inputMode="numeric"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      fields={dateFieldsConfigCarer}
                                      error={!!dobCarerErrorMessage}
                                      errorMsg={dobCarerErrorMessage}
                                    />
                                    <section className="ccl-c-form-section">
                                      <div className="ccl-e-detail-action-bar">
                                        <div className="ccl-e-detail-action-bar__title">
                                          <span>
                                            {
                                              labels[
                                                'labels.common.cochlearAccount'
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </section>
                                    <div className="ccl__view-edit-mode">
                                      <TextInput
                                        className="ccl-l-advanced-search-patient-tab__fields__email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label={
                                          labels[
                                            'labels.common.emailAddress'
                                          ]
                                        }
                                        promptText=""
                                        hint={
                                          labels[
                                            'labels.addCarer.emailMessage'
                                          ]
                                        }
                                        error={
                                          touched.email &&
                                          !!errors.email
                                        }
                                        errorMsg={
                                          touched.email &&
                                          errors.email
                                        }
                                      />
                                      <div className="ccl__edit-mode__element">
                                        <SelectInput
                                          label={
                                            labels[
                                              'labels.common.preferredLanguageActivationEmail'
                                            ]
                                          }
                                          name="preferredLanguage"
                                          setFocus={false}
                                          error={false}
                                          errorMsg={
                                            errors.preferredLanguage
                                          }
                                          onChange={setFieldValue}
                                          onBlur={handleBlur}
                                          dataList={getLanguageList()}
                                          isSearchable
                                          hint={
                                            labels[
                                              'labels.addCarer.carerLanguagePreference'
                                            ]
                                          }
                                          optionalText=""
                                          defaultValue={
                                            values.preferredLanguage ===
                                            ''
                                              ? initialValues.preferredLanguage
                                              : values.preferredLanguage
                                          }
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </form>
                            </>
                          )}

                          {(step === 'c4a' || step === 'c4b') && (
                            <>
                              <>
                                <DetailsView
                                  values={values}
                                  labels={labels}
                                  type="carer"
                                />
                              </>
                              <>
                                <section className="ccl-c-form-section">
                                  {provisionalSelected && (
                                    <Alert
                                      variant="error"
                                      heading={
                                        labels[
                                          'labels.addCarer.provisionalError.title'
                                        ]
                                      }
                                    >
                                      {
                                        labels[
                                          'labels.addCarer.provisionalError'
                                        ]
                                      }
                                    </Alert>
                                  )}
                                  <div className="ccl-e-detail-action-bar">
                                    <div className="ccl-e-detail-action-bar__title mt-4">
                                      <span>
                                        {
                                          labels[
                                            'labels.addCarer.matchingRecords'
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="matching-records-table mb-6 mt-3">
                                    <DataTable
                                      config={
                                        config.matchingPatientsTableCarer
                                      }
                                      data={matchingCarers}
                                      labels={labels}
                                    />
                                  </div>
                                </section>
                              </>
                            </>
                          )}
                          {step === 'c5' && (
                            <>
                              <>
                                <DetailsView
                                  values={values}
                                  labels={labels}
                                  type="carer"
                                />
                              </>
                              <>
                                <section className="ccl-c-form-section">
                                  <div className="ccl-e-detail-action-bar">
                                    <div className="ccl-e-detail-action-bar__title mt-4">
                                      {
                                        labels[
                                          'labels.addCarer.contactDetails'
                                        ]
                                      }
                                    </div>
                                  </div>
                                  <div className="mt-2 ccl__flex">
                                    <Text
                                      content={
                                        labels[
                                          'labels.common.contactEmail'
                                        ]
                                      }
                                      type="body-text-bold"
                                      style={{ minWidth: '8rem' }}
                                    />
                                    <Text content={values.email} />
                                  </div>
                                </section>
                              </>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex flex-row pt-4">
                    <div className="pe-2">
                      {step !== 'c5' && (
                        <FormButton
                          text={continueButtonText}
                          variant="brand-primary"
                          disabled={submitDisabled}
                          onClick={handleSubmit}
                          type="save"
                          progress={isSubmitting ? 2 : undefined}
                          data-analytics="add_add_carer"
                        />
                      )}
                    </div>
                    <div className="pe-2">
                      <Button
                        text={
                          step === 'c5'
                            ? labels['labels.common.close']
                            : labels['labels.common.cancel']
                        }
                        variant="secondary"
                        onClick={handleCancel}
                        data-analytics={
                          step === 'c5'
                            ? 'close_add_carer'
                            : 'cancel_add_carer'
                        }
                      />
                    </div>
                  </div>
                </Row>
              </Container>
              <AgreementModal
                onClose={() => {
                  setAgreementModalCarerVisible(false);
                  setIsmatchingCarerSelected(false);
                  if (step === 'c4b') setSubmitDisabled(false);
                }}
                onSubmit={handleAgreementModalCarerSubmit}
                visible={agreementModalCarerVisible}
                isAgreementSubmitting={isAgreementCarerSubmitting}
                agreementConfig={config.carerAgreementmodelConfig}
                carerInformation={matchingCarerSelected}
                labels={labels}
              />
            </div>
          ) : (
            <DisabledFeature
              title={labels['llabels.common.patientRecord']}
              message={
                labels['labels.common.featureFlag.disabledText']
              }
            />
          )
        }
      </Feature>
    )
  );
};

export default AddCarer;
