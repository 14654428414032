import axios from 'axios';
import { providerListEndpoint } from '../../config';
import getHeaders from '../../utils/getHeaders';
import { logger } from '../../logger';

const log = logger();

const fetchNotifications = async (providerId, professionalId) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}/${providerId}/professionals/${professionalId}/notifications`,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error fetching notifications', err);
    return null;
  }
};

const fetchNotificationsService = {
  fetchNotifications,
};

export default fetchNotificationsService;
