export const getModalConfig = (labels) => {
  const {
    'labels.accessoryRegistration.review.modalConfig.modaltitle':
      modaltitle,
    'labels.accessoryRegistration.review.modalConfig.yes': yes,
    'labels.accessoryRegistration.review.modalConfig.no': no,
    'labels.accessoryRegistration.review.modalConfig.modalText':
      modalText,
  } = labels;

  return {
    modaltitle,
    size: 'xl',
    yes,
    no,
    yesanalytics: 'yes_exit_register_device',
    noanalytics: 'no_exit_register_device',
    modalText,
  };
};
