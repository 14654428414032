import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/userService";

export const fetchCountryConfig = createAsyncThunk(
  "countryConfig/fetchCountryConfig",
  async (countryCode) => {
    const countryConfig = await userService.getCountryConfig(countryCode);
    localStorage.setItem("countryConfig", JSON.stringify(countryConfig));
    return countryConfig;
  }
);

export const countryConfigSlice = createSlice({
  name: "countryConfig",
  initialState: () => {
    const jsonStr = localStorage.getItem("countryConfig");
    return { value: jsonStr ? JSON.parse(jsonStr) : null };
  },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountryConfig.fulfilled, (state, action) => {
      const cc = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.value = cc;
    });
  },
});

export const { update } = countryConfigSlice.actions;

export default countryConfigSlice.reducer;