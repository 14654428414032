import React, { useEffect, useState } from 'react';
import { Footer as FooterUI } from '../../ui/Footer/Footer.js';
import { dataMapper } from './dataMapper.js';
import { getLabelsForFields } from '../../utils/labelHelper';
import { routes } from '../../config.js';
import CustomerContactModal from '../CustomerContactModal';

const Footer = ({ rendering }) => {
  // Calls MCP every 5 mins to refresh cookie and keep session alive
  const disableIframes =
    localStorage.getItem('disableIframes') === 'true';

  if (!disableIframes) {
    setInterval(() => {
      document.getElementById('keepAlive').src += '';
    }, 300000);
  }
  
  const [openModal, setOpenModal] = useState(false);

  const modalType = 'contactUs';
  const labels = getLabelsForFields(rendering.fields);
  const res = dataMapper(labels);  

  useEffect(() => {
    document
      .getElementsByClassName('list-group-item')[0]
      .addEventListener('click', (event) => {
        event.preventDefault();        
        setOpenModal(true);
      });
  }, []);

  // useEffect(() => {
  //   resetForm();
  //   if (openModal) {
  //     document
  //       .getElementById('description')
  //       .addEventListener('keydown', (event) => {
  //         event.stopPropagation();
  //       });
  //   }
  // }, [openModal]);

  const handleModalClosed = () => {
    setOpenModal(false);
  };

  return (
    <>
      {!disableIframes && (
        <iframe
          id="keepAlive"
          title="keepAlive"
          src={routes.mcp.keepAlive}
          hidden
        />
      )}
      <FooterUI config={res.config} labels={labels} />
      <CustomerContactModal openModal={openModal} modalType={modalType} labels={labels} res={res} onCloseClicked={handleModalClosed} />      
    </>
  );
};

export default Footer;
