import { useLocation } from 'react-router-dom';

const tabs = ['DEVICE', 'ACCESSORY'];

export const tabIndex = () => {
  const { search } = useLocation();
  if (!search) {
    return 0;
  }
  return tabs.indexOf(search.split('=')[1].toUpperCase());
};
