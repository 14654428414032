/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Redirect from './Redirect';
import userService from '../services/userService';
import {
  routes,
  userAccountType,
  disableIframes,
  ACCESS_TOKEN_COOKIE,
} from '../config';
import providerService from '../services/providerService';
import cookieService from '../services/cookieService';
import featureFlagService from '../services/featureFlagService';
import { closePopup } from '../utils/popupManager';
import Spinner from './Spinner';
import { exchangeProviderToken } from '../utils/authprovider';
import { fetchCountryConfig } from '../features/countryConfig/countryConfigSlice';
import { fetchAccessoryItems } from '../services/deviceRegistration/fetchAccessoryService';

const Initializer = () => {
  const pDetails = providerService.getProviderDetails();
  const currentProvider = providerService.getCurrentProvider();
  const uDetails = userService.getUserDetails();
  const [loading, setLoading] = useState(false);
  const userCountry = localStorage.getItem('userCountry');
  const userLang = localStorage.getItem('userLang');
  const dispatch = useDispatch();

  const [nextStep, setNextStep] = useState();
  const [mcpPostMessageReceived, setMcpPostMessageReceived] =
    useState(false);
  const [featureFlagsReceived, setFeatureFlagsReceived] =
    useState(false);
  const [providerTokenReceived, setProviderTokenReceived] =
    useState(false);
  const [countryConfigReceived, setCountryConfigReceived] =
    useState(false);
  const [accessoryItemsReceived, setAccessoryItemsReceived] =
    useState(false);

  const queryString = useLocation().search;
  const nextPage =
    new URLSearchParams(queryString).get('nextPage') || 'home';

  const chosenProvider = pDetails.providerId;

  localStorage.setItem('disableIframes', disableIframes());

  const getPartyId = () => {
    if (chosenProvider) {
      return pDetails.providerList.providers.filter(
        (provider) => provider.id === chosenProvider,
      )[0]?.partyId;
    }
    return 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setNextStep('home?error=provider');
      setLoading(false);
    }, 60000);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getFeatureVersionSupport = async () => {
    const featureVersionSupport =
      await featureFlagService.getFeatureVersionSupport();
    if (featureVersionSupport) {
      sessionStorage.setItem(
        'featureVersionSupport',
        JSON.stringify(featureVersionSupport),
      );
    }
  };

  const fetchFeatureFlags = async () => {
    const featureFlags =
      await featureFlagService.getFeatureFlagConfig(
        pDetails?.providerId,
        uDetails?.professionalId,
      );
    if (featureFlags) {
      localStorage.setItem(
        'featureFlags',
        JSON.stringify(featureFlags),
      );
      setFeatureFlagsReceived(true);
      if (
        getPartyId() === 0 ||
        uDetails.role === userAccountType.ContentProfessional
      ) {
        setLoading(false);
      }
    }
  };

  const storeAccessoryItems = async () => {
    const { deviceList } = await fetchAccessoryItems(userCountry);
    if (deviceList) {
      localStorage.setItem(
        'accessoryItems',
        JSON.stringify(deviceList),
      );
      setAccessoryItemsReceived(true);
    }
  };

  useEffect(() => {
    async function exchangePToken() {
      const providerToken = await exchangeProviderToken(
        chosenProvider,
      );
      if (providerToken) {
        setProviderTokenReceived(true);
      } else {
        // token unsuccessful, log the user out
        window.location.href = routes.dpx.logout;
      }
    }

    async function getCountryConfig() {
      // get country config and store in local storage
      dispatch(
        fetchCountryConfig(currentProvider.address?.countryCode),
      );
      setCountryConfigReceived(true);
    }

    setLoading(true);

    const accessToken = cookieService.getCookie(ACCESS_TOKEN_COOKIE);
    if (!accessToken) {
      // if token cookie is no longer present, log the user out
      window.location.href = routes.dpx.logout;
    } else {
      getCountryConfig();
      exchangePToken();
      fetchFeatureFlags();
      storeAccessoryItems();
    }
  }, [chosenProvider]);

  useEffect(() => {
    if (
      mcpPostMessageReceived &&
      featureFlagsReceived &&
      providerTokenReceived &&
      countryConfigReceived &&
      accessoryItemsReceived
    ) {
      setLoading(false);
      setNextStep(nextPage);
    }
  }, [
    mcpPostMessageReceived,
    featureFlagsReceived,
    providerTokenReceived,
    countryConfigReceived,
    accessoryItemsReceived,
  ]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              closePopup();
              setMcpPostMessageReceived(true);
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return loading ? (
    <Spinner loading={loading} />
  ) : nextStep ? (
    <Redirect
      to={nextStep}
      country={userCountry}
      lang={userLang}
      refresh
    />
  ) : (
    <></>
  );
};

export default Initializer;
