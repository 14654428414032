import {formatDate} from '../../utils/dateTimeFns';

export const patientData = (searchApiResult) => {
  const resultArray = searchApiResult?.patients?.map((patient) => ({
    clinic: patient?.clinics
      ?.map((clinic) => [` ${clinic.name}`])
      .toString(),
    phoneNumber: !patient?.mobile ? '--' : patient?.mobile,
    dob: formatDate(patient?.dateOfBirth),
    name: {
      id: patient.id,
      item: [patient?.firstName || '', patient?.lastName || '']
        .join(' ')
        .trim(),
    },
    email: patient.email,
    isDeceased: patient?.isDeceased,
  }));
  return resultArray;
};
