import { SET_ERROR, CLEAR_ERROR } from '../actions/types';

const initialState = false;

export default function apiErrorReducer(
  state = initialState,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case SET_ERROR:
      return { ...state, apiError: payload };
    case CLEAR_ERROR:
      return { ...state, apiError: false };

    default:
      return state;
  }
}
