/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Tabs } from '@cochlear-design-system/foundation';
import Patient from './tabs/Patient';

export function AdvancedSearch({
  config,
  labels,
  data,
  submitDisabled,
  setSubmitDisabled,
}) {
  const { pageTitle, onSubmit, onChangeTab, tabs, formats } = config;
  const {
    searchType,
    parameters,
    tableData,
    renderState,
    selectedClinicData,
    patientCount,
  } = data;

  const tabsConfig = tabs.map((tab) => ({
    formats,
    ...tab,
  }));

  return (
    <div className="advanced-search">
      <PageHeader
        className="advanced-search__title"
        title={{ content: labels[pageTitle] }}
      />
      <Tabs
        data={{
          tabs: tabs.map(({ id, title, disabled }) => ({
            id,
            label: labels[title],
            disabled,
            tooltip: disabled ? 'Unavailable' : undefined,
          })),
        }}
        config={{
          onChangeTab,
          alignTabLabel: 'center',
          initialTabIndex: tabs.findIndex(
            ({ id: tabId }) => tabId === searchType,
          ),
        }}
      >
        <Patient
          search={{
            className: 'advanced-search__tab-content',
            onSubmit,
            searchConfig: tabsConfig[0],
            labels,
            parameters,
            selectedClinicData,
            submitDisabled,
            setSubmitDisabled,
          }}
          table={{
            patientResultTableConfig:
              tabsConfig[0].patientResultTableConfig,
            tableData,
            patientCount,
            renderState,
          }}
        />
        <h1>Device</h1>
        <h1>Service Request</h1>
      </Tabs>
    </div>
  );
}

AdvancedSearch.propTypes = {
  config: PropTypes.shape({
    pageTitle: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        patientIdentifier: PropTypes.arrayOf(PropTypes.object),
        patientIdentifierLabel: PropTypes.string,
        alertMessage: PropTypes.string,
        hasError: PropTypes.bool,
        fields: PropTypes.arrayOf(PropTypes.object),
        submitButton: PropTypes.string,
        submitButtonTooltip: PropTypes.string,
        patientResultTableConfig: PropTypes.object,
        clinicSelectorConfig: PropTypes.object,
      }),
    ),
    onChangeTab: PropTypes.func,
    onSubmit: PropTypes.func,
  }).isRequired,
  labels: PropTypes.object.isRequired,
  data: PropTypes.shape({
    searchType: PropTypes.string,
    parameters: PropTypes.object,
  }).isRequired,
};
