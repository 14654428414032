import axios from 'axios';
import cookieService from './cookieService';
import {
  ID_TOKEN_COOKIE,
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  routes,
} from '../config';

function logout() {
  // TODO: Revoke tokens

  // Clear Cookies
  cookieService.removeCookie(ACCESS_TOKEN_COOKIE);
  cookieService.removeCookie(REFRESH_TOKEN_COOKIE);
  cookieService.removeCookie(ID_TOKEN_COOKIE);

  // Fire and forget
  const logoutUrl = routes.mcp.logout;
  return new Promise((resolve) => {
    axios
      .get(logoutUrl, { withCredentials: true })
      .then(() => resolve(true))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('An error occurred while attempting to logout');
        // Ignore the error, and proceed with the logout
        resolve(true);
      });
  });
}

const authService = {
  logout,
};

export default authService;
