const transformProviders = (providers) =>
  providers.map(
    ({ address: { street, ...address }, ...provider }) => {
      const [street1, street2] = street
        ? street.split(',')
        : [null, null];
      return {
        ...provider,
        address: {
          ...address,
          street1: street1 || '',
          street2: street2 || '',
        },
      };
    },
  );

const dataService = {
  transformProviders,
};

export default dataService;
