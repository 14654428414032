import React from 'react';
import {
  Button,
  // SelectInput,
  Typeahead,
  CountrySelect,
} from '@cochlear-design-system/foundation';
import {
  MenuItem,
  Highlighter,
  Menu,
} from 'react-bootstrap-typeahead';
import * as Yup from 'yup';

export const ClinicForm = ({
  countryField,
  setCountry,
  clinicField,
  clinicError,
  clinicValue,
  isLoading,
  searchButton,
  onSearch,
  setClinicError,
  setClinicValue,
  labels,
}) => {
  const typeaheadOnChange = async (v) => {
    if (
      typeof v.target !== 'undefined' &&
      v.target.value.length === 0
    ) {
      setClinicError(false);
      setClinicValue(
        typeof v.target !== 'undefined' ? v.target.value : v[0],
      );
    } else {
      const schema = Yup.string().matches(clinicField.validationMask);
      setClinicValue(
        typeof v.target !== 'undefined' ? v.target.value : v[0],
      );
      const isValid = await schema.isValid(v.target.value);
      if (!isValid) {
        setClinicError(true);
      } else {
        setClinicError(false);
      }
      if (
        v.key === 'Enter' &&
        clinicValue?.trim().length >= clinicField.minLength
      ) {
        onSearch();
      }
    }
  };

  return (
    <>
      <div className="ccl__AssociatedClinicPopup__element">
        <CountrySelect
          hint=""
          isSearchable
          label={labels['labels.findClinicModal.countryField.label']}
          maxResults={200}
          name="Country"
          optionalText=""
          promptText={
            labels['labels.findClinicModal.countryField.promptText']
          }
          visibleCountryOption={countryField.countryList}
          showISO={false}
          showISDN={false}
          showResultsISO={false}
          showResultsISDN={false}
          onChange={(name, value) => {
            setCountry(value);
          }}
          defaultValue={countryField.defaultValue}
          disabled={countryField.disabled}
        />
      </div>
      <div className="ccl__AssociatedClinicPopup__element ccl__AssociatedClinicPopup__element__withSearchBtn">
        <div className="ccl__AssociatedClinicPopup__element__typeahead">
          <Typeahead
            id="typeahead"
            label={clinicField.label}
            hint={clinicField.hint}
            options={clinicField.recentSearches}
            error={clinicError}
            maxItems={clinicField.maxItems}
            errorMsg={clinicField.validations[0].message}
            renderMenu={(
              result,
              {
                newSelectionPrefix,
                paginationText,
                renderMenuItemChildren,
                ...menuProps
              },
              state,
            ) => {
              let index = 0;
              const items = result.map((i) => {
                const item = (
                  <MenuItem key={index} option={i} position={index}>
                    <Highlighter search={state.text}>{i}</Highlighter>
                  </MenuItem>
                );
                index += 1;
                return item;
              });

              return (
                <Menu {...menuProps}>
                  <p className="ccl__AssociatedClinicPopup__element__typeahead__menuheader">
                    {clinicField.recentSearchesLabel}
                  </p>
                  {items}
                </Menu>
              );
            }}
            onKeyUp={typeaheadOnChange}
            onBlur={typeaheadOnChange}
          />
        </div>
        <Button
          className={`
                ${
                  clinicError
                    ? 'ccl__AssociatedClinicPopup__element__btn_cinicError'
                    : ''
                }
                    ${
                      isLoading
                        ? 'ccl__AssociatedClinicPopup__element__btn_isLoading'
                        : ''
                    }`}
          text={
            isLoading ? (
              <>
                {' '}
                {searchButton.processingLabel}
                <div className="ccl-e-table--spinner">
                  <div className="spinner-border" />
                </div>
              </>
            ) : (
              searchButton.defaultLabel
            )
          }
          disabled={
            !clinicValue ||
            clinicValue?.trim().length < clinicField.minLength ||
            clinicError
          }
          variant={
            clinicValue && !clinicError
              ? 'digital-primary'
              : 'disabled'
          }
          onClick={onSearch}
          type="submit"
          data-analytics="search_find_clinic"
        />
      </div>
    </>
  );
};
