import { SET_ERROR, CLEAR_ERROR } from './types';
import { logger } from '../logger';

const log = logger();

const createPatientAPIErrorModal =
  (showError) => async (dispatch) => {
    try {
      dispatch({
        type: SET_ERROR,
        payload: showError,
      });
    } catch (err) {
      log.error('Error dispatching patient API error modal', err);
      dispatch({
        type: CLEAR_ERROR,
        payload: false,
      });
    }
  };

export default createPatientAPIErrorModal;
