import dateFormat from 'dateformat';

export const mapPayload = (
  selectedAccessory,
  professionalId,
  patientCochlearId,
  country,
  username,
  providerId,
  startDate,
) => {
  const { id, description, subFamily, partNumber, productType } =
    selectedAccessory;

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const endDate = getFormattedDate();

  return {
    assetInfo: {
      oldAccessory: {
        asset: {
          status: 'Return Received',
          endDate,
          metadata: {
            modifiedBy: professionalId,
            changeOrigin: 'SFHC',
            channel: 'DPX',
          },
        },
      },
      newAccessory: {
        submittedBy: {
          source: 'DPX',
          userId: username,
        },
        provider: {
          cochlearId: providerId,
          primaryCountryCode: country?.toUpperCase(),
        },
        patient: {
          cochlearId: patientCochlearId,
        },
        device: {
          productId: id,
          productName: description?.toUpperCase(),
          productFamily: subFamily?.toUpperCase(),
          partNumber,
          deviceType: productType,
          nonSerialised: true,
        },
        event: {
          date: dateFormat(startDate, 'yyyy-mm-dd'),
        },
        professional: {
          cochlearId: professionalId,
        },
      },
    },
  };
};
