import { getDefaultLanguage } from './utils/languageHelper';

const defaultLanguage = getDefaultLanguage();

const sitesMap = new Map();
sitesMap.set('default', {
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'en',
  locale: 'en-GB',
  country: 'int',
});
sitesMap.set('gb/en', {
  // siteName: 'dpx-gb-en',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'en',
  locale: defaultLanguage,
  country: 'gb',
});
sitesMap.set('us/en', {
  // siteName: 'dpx-us-en',
  // appName: 'dpx-cam',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'en',
  locale: defaultLanguage,
  country: 'us',
});
/*
sitesMap.set('br/pt', {
  // siteName: 'dpx-br-pt',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'pt',
  locale: 'pt-BR',
  country: 'br',
});
sitesMap.set('cn/zh', {
  // siteName: 'dpx-cn-zh',
  // appName: 'dpx-apac',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'zh',
  locale: 'zh-CN',
  country: 'cn',
});
sitesMap.set('tw/zh', {
  // siteName: 'dpx-tw-zh',
  // appName: 'dpx-apac',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'zh',
  locale: 'zh-TW',
  country: 'tw',
});
sitesMap.set('hk/zh', {
  // siteName: 'dpx-hk-zh',
  // appName: 'dpx-apac',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'zh',
  locale: 'zh-HK',
  country: 'tw',
});
sitesMap.set('nl/nl', {
  // siteName: 'dpx-nl-nl',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'nl',
  locale: 'nl-NL',
  country: 'nl',
});
sitesMap.set('fr/fr', {
  // siteName: 'dpx-fr-fr',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'fr',
  locale: 'fr-FR',
  country: 'fr',
});
sitesMap.set('ca/fr', {
  // siteName: 'dpx-ca-fr',
  // appName: 'dpx-cam',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'fr',
  locale: 'fr-CA',
  country: 'fr',
});
sitesMap.set('de/de', {
  // siteName: 'dpx-de-de',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'de',
  locale: 'de-DE',
  country: 'de',
});
sitesMap.set('it/it', {
  // siteName: 'dpx-it-it',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'it',
  locale: 'it-IT',
  country: 'it',
});
sitesMap.set('jp/ja', {
  // siteName: 'dpx-jp-ja',
  // appName: 'dpx-apac',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'ja',
  locale: 'ja-JP',
  country: 'jp',
});
sitesMap.set('kr/ko', {
  // siteName: 'dpx-kr-ko',
  // appName: 'dpx-apac',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'ko',
  locale: 'ko-KR',
  country: 'kr',
});
sitesMap.set('es/es', {
  // siteName: 'dpx-es-es',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'es',
  locale: 'es-ES',
  country: 'es',
});
sitesMap.set('se/sv', {
  // siteName: 'dpx-se-sv',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'sv',
  locale: 'sv-SE',
  country: 'se',
});
sitesMap.set('tr/tr', {
  // siteName: 'dpx-tr-tr',
  // appName: 'dpx-emea',
  siteName: 'dpx',
  appName: 'dpx',
  lang: 'tr',
  locale: 'tr-TR',
  country: 'tr',
}); 
*/

export default function resolveSite(reqUrl = '') {
  /**
   * Strip virtual path removes the virtual path component before a layoutService request is made, but
   * after the country, site and lang parameters are extracted from the virtual path.
   * @param {string} fPath full path from incoming request
   * @param {string} vPath virtual path component
   * @param {string} sitesObject virtual path site mappings
   * @returns {siteName: string, lang: string, country: string, url: string}
   */
  function stripVirtualPath(fPath, vPath, sitesObject) {
    if (
      typeof vPath === 'undefined' ||
      vPath === null ||
      !sitesObject.get(vPath)
    ) {
      return sitesObject.get('default');
    }
    const siteProps = {
      ...sitesObject.get(vPath),
      url: fPath.replace(`/${vPath}`, ''),
    };
    // prepend slash if not exists - necessary for paths that rewrite home items
    // E.g. /brand-hub -> brand
    if (siteProps.url.substr(0, 1) !== '/') {
      siteProps.url = `/${siteProps.url}`;
    }
    return siteProps;
  }

  if (!reqUrl) {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-param-reassign
      reqUrl = window.location.pathname;
    }
  }
  const match = reqUrl.match(
    /(^\/brand-hub)|(\/[a-z]{2}\/[a-z]{2}($|\/))|(\/[a-z]{2}($|\/))|\/(emea|global|apac|mdr)\/[a-z]{2}($|\/)/,
  );
  const virtualPath =
    typeof match === 'undefined' || match === null
      ? null
      : match[0].replace(/^\/|\/$/g, '');

  return stripVirtualPath(reqUrl, virtualPath, sitesMap);
}
