import React from 'react';
import { AdaptiveForm } from '@cochlear-design-system/features.adaptiveForms';
import {
  Row,
  Col,
  PageHeader,
  Alert,
  IconButton,
} from '@cochlear-design-system/foundation';
import { SummaryPanel } from '../SummaryPanel/SummaryPanel';
import { Error } from '../Error/Error';

export const MainPatientWrapper = ({
  panelDetails,
  headingDetails,
  summaryDetails,
}) => {
  const { config, labels, data } = panelDetails;
  const { title, alertConfig, buttonConfig, errorConfig } =
    headingDetails;

  const dismissible =
    alertConfig.dismissible === undefined
      ? true
      : alertConfig.dismissible;

  return (
    <div className="ccl__container">
      <div className="ccl__main-patient">
        <div className="ccl__main-patient--header">
          <PageHeader title={title} />
          {buttonConfig && (
            <IconButton
              variant={buttonConfig.variant}
              icon={buttonConfig.icon}
              handleClick={buttonConfig.onClick}
              size={buttonConfig.size}
              analytics={buttonConfig.analytics}
            />
          )}
        </div>
        {alertConfig.visible && (
          <Row>
            <Col xs={12}>
              <Alert
                defaultShow
                variant={alertConfig.type}
                dismissible={dismissible}
                heading={alertConfig.title}
                actions={alertConfig.actions || []}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: alertConfig.message,
                  }}
                />
              </Alert>
            </Col>
          </Row>
        )}
        {errorConfig?.isDeceased ? (
          <Row>
            <Col xs={12}>
              <Error
                config={errorConfig.errorView}
                labels={errorConfig.labels}
                data={{}}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={4}>
              <AdaptiveForm
                labels={labels}
                config={config}
                data={data}
              />
            </Col>
            <Col xs={8}>
              <SummaryPanel
                config={summaryDetails.config}
                data={summaryDetails.data}
                labels={summaryDetails.labels}
                leftData={summaryDetails.leftData}
                rightData={summaryDetails.rightData}
                unknownData={summaryDetails.unknownData}
                rcIframeKey={summaryDetails.rcIframeKey}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
