import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  PageHeader,
  Button,
  Text,
  Alert,
} from '@cochlear-design-system/foundation';
import { Feature } from 'flagged';
import { routes } from '../../config';
import { getLabelsForFields } from '../../utils/labelHelper';
import getQueryString from '../../utils/queryStrings';
import patientService from '../../services/patientService';
import providerService from '../../services/providerService';
import carerService from '../../services/carerService';
import DisabledFeature from '../DisabledFeature';
import MarkDeceasedModal from '../MarkDeceasedModal';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { PersonalDetailsView } from './components/PersonalDetailsView';
import { ContactDetailsView } from './components/ContactDetailsView';
import { AddressListView } from './components/AddressListView';
import { getErrorConfig } from './editPatientConfig';
import error from '../../actions/errorAction';

const EditPatient = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const history = useHistory();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const dispatch = useDispatch();
  const { providerId } = providerService.getProviderDetails();
  const patientId = getQueryString('patientId');
  const [carerId] = useState(getQueryString('carerId'));

  const location = useLocation();
  const state = useState(location.state)[0];

  const [headingTitle, setHeadingTitle] = useState(
    labels['labels.common.editPatient'],
  );
  const [patientDetails, setPatientDetails] = useState({});

  const [loading, setLoading] = useState(true);
  const [showMarkDeceasedModal, setShowMarkDeceasedModal] =
    useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [pageLevelError, setPageLevelError] = useState(false);
  const config = getErrorConfig();

  const fetchPatientDetails = async () => {
    const cacheControlHeader = {
      'cache-control': 'no-cache',
    };
    const rawPatient = !carerId
      ? await patientService.getPatient(
          providerId,
          patientId,
          cacheControlHeader,
        )
      : await carerService.getCarer(
          providerId,
          patientId,
          carerId,
          cacheControlHeader,
        );

    if (
      rawPatient === null ||
      rawPatient.data?.id === null ||
      rawPatient.data?.errors
    ) {
      // dispatch page level error
      dispatch(
        error(
          rawPatient?.status === 404
            ? config.notFoundErrorConfig
            : true,
        ),
      );
      setPageLevelError(true);
      return;
    }

    if (carerId) {
      if (rawPatient.data.firstName === 'UPDATENAME')
        rawPatient.data.firstName = '';
      if (rawPatient.data.lastName === 'UPDATENAME')
        rawPatient.data.lastName = '';
    }
    setPatientDetails(rawPatient?.data);
    setLoading(false);
  };

  const getFullName = () => {
    return [
      patientDetails?.firstName || '',
      patientDetails?.lastName || '',
    ]
      .join(' ')
      .trim();
  };

  const handleCancel = () => {
    history.push({
      pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
      search: `?patientId=${patientId}`,
    });
  };

  const handlePatientMarkedDeceased = (success) => {
    setShowMarkDeceasedModal(false);
    if (success) {
      history.push({
        pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
        search: `?patientId=${patientId}`,
        state: {
          isDeceased: !carerId,
        },
      });
    }
  };

  useEffect(() => {
    dispatch(error(false));
  }, []);

  // API calls
  useEffect(() => {
    fetchPatientDetails();
  }, []);

  useEffect(() => {
    if (patientDetails) {
      setHeadingTitle(getFullName());
    }
  }, [patientDetails]);

  useEffect(() => {
    if (modalTrigger) {
      dispatch(headerCancelModal(false));
      handleCancel();
    }
  }, [modalTrigger]);

  // NOTE: styles for below are hardcodedin main.scss
  return (
    !pageLevelError && (
      <Feature name="featureEnabler/viewPatient">
        {(isEnabled) =>
          isEnabled ? (
            <div className="ccl__edit-patient-wrapper">
              <Container>
                <Row>
                  <Col>
                    <PageHeader
                      className="edit-patient__title"
                      title={{ content: headingTitle }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {state?.addCarer && (
                      <Alert
                        variant="success"
                        dismissible
                        data-analytics="close_add_carer_alert"
                      >
                        {
                          labels[
                            'labels.editPatient.addCarer.success'
                          ]
                        }
                      </Alert>
                    )}
                    {saveSuccess && (
                      <Alert variant="success">
                        {carerId
                          ? labels[
                              'labels.editPatient.carer.update.success'
                            ]
                          : labels[
                              'labels.editPatient.patient.update.success'
                            ]}
                      </Alert>
                    )}
                    {saveError && (
                      <Alert variant="error">
                        {carerId
                          ? labels[
                              'labels.editPatient.carer.update.error'
                            ]
                          : labels[
                              'labels.editPatient.patient.update.error'
                            ]}
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="ccl-l-userTabs ccl-l-userTabs--single">
                      <div className="ccl-l-userTabs__content">
                        <div className="ccl-l-userTabs__content__inner">
                          <PersonalDetailsView
                            labels={labels}
                            providerId={providerId}
                            patientDetails={patientDetails}
                            setPatientDetails={setPatientDetails}
                            loading={loading}
                            setSaveSuccess={setSaveSuccess}
                            setSaveError={setSaveError}
                            carerFor={carerId ? patientId : null}
                          />

                          <ContactDetailsView
                            labels={labels}
                            providerId={providerId}
                            patientDetails={patientDetails}
                            setPatientDetails={setPatientDetails}
                            loading={loading}
                            setSaveSuccess={setSaveSuccess}
                            setSaveError={setSaveError}
                            carerFor={carerId ? patientId : null}
                          />

                          <AddressListView
                            labels={labels}
                            providerId={providerId}
                            patientDetails={patientDetails}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex flex-row justify-content-between pt-4">
                    <div className="pe-2">
                      <Button
                        text={labels['labels.common.close']}
                        variant="secondary"
                        onClick={handleCancel}
                        data-analytics={
                          carerId
                            ? 'close_edit_carer'
                            : 'close_edit_patient'
                        }
                      />
                    </div>
                    {!patientDetails?.isDeceased && (
                      <div className="pe-2">
                        <Text
                          content={
                            labels[
                              'labels.patientRecord.edit.markAsDeceased'
                            ]
                          }
                          type="link-small-text"
                          onClick={() =>
                            setShowMarkDeceasedModal(true)
                          }
                          data-analytics={
                            carerId
                              ? 'mark_deceased_edit_carer'
                              : 'mark_deceased_edit_patient'
                          }
                        />
                      </div>
                    )}
                  </div>
                </Row>
              </Container>
              <MarkDeceasedModal
                fields={rendering.fields}
                show={showMarkDeceasedModal}
                onHide={handlePatientMarkedDeceased}
                providerId={providerId}
                patientId={patientId}
                carerId={carerId}
              />
            </div>
          ) : (
            <DisabledFeature
              title={labels['llabels.common.patientRecord']}
              message={
                labels['labels.common.featureFlag.disabledText']
              }
            />
          )
        }
      </Feature>
    )
  );
};

export default EditPatient;
