import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Text,
  SelectInput,
  Alert,
  Modal,
  Button,
  FormButton,
} from '@cochlear-design-system/foundation';
import patientService from '../../services/patientService';
import { getLabelsForFields } from '../../utils/labelHelper';
import { mapPayload } from './mapRequest';
import { fetchMatchingAccessoriesById } from '../../services/deviceRegistration/fetchAccessoryService';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import notificationStatus from '../../actions/notificationStatusAction';

const UpdateAccessoryModal = ({
  rendering,
  showUpdateAccessoryModal,
  setShowUpdateAccessoryModal,
  selectedAccessory,
  providerId,
  patientId,
  country,
  uDetails,
  updateAccessory,
}) => {
  const labels = getLabelsForFields(rendering.fields);
  const [replacementAccessoryList, setReplacementAccessoryList] =
    useState([]);
  const [newAccessory, setNewAccessory] = useState();
  const selectedAccessoryId = selectedAccessory?.productId;
  const [updateAccessoryInProgress, setUpdateAccessoryInProgress] =
    useState(false);

  const inviteTitle =
    labels['labels.patientRecord.updateAccessoryModal.title'];
  const dispatch = useDispatch();

  const filterAndFormatAccessoryList = (productId, accessoryList) => {
    return accessoryList
      .filter((accessory) => accessory?.id !== productId)
      .map((accessory) => ({
        ...accessory,
        label: `${accessory.description}, ${accessory.partNumber}`,
        value: accessory.id,
      }));
  };

  useEffect(async () => {
    setReplacementAccessoryList([]);
    const { accessoryList } = await fetchMatchingAccessoriesById(
      country,
      {
        id: selectedAccessoryId,
      },
    );
    const formattedAccesoryList = filterAndFormatAccessoryList(
      selectedAccessoryId,
      accessoryList,
    );
    setReplacementAccessoryList(formattedAccesoryList);
  }, [selectedAccessory]);

  const updateRegisterAccessory = async () => {
    setUpdateAccessoryInProgress(true);
    const payload = mapPayload(
      newAccessory,
      uDetails.professionalId,
      patientId,
      country,
      uDetails.username,
      providerId,
      selectedAccessory.startDate,
    );
    const response = await patientService.updateAccessory(
      payload,
      selectedAccessory?.assetId,
      providerId,
      patientId,
    );
    return response;
  };

  const handleAccessorySubmit = async () => {
    const response = await updateRegisterAccessory();
    if (response?.success) {
      dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
      setUpdateAccessoryInProgress(false);
      setShowUpdateAccessoryModal(false);
      setNewAccessory();
      updateAccessory(true);
    } else {
      updateAccessory(false);
      setUpdateAccessoryInProgress(false);
      setShowUpdateAccessoryModal(false);
      setNewAccessory();
    }
  };

  const handleAccessoryChange = (value) => {
    const selectedDevice = replacementAccessoryList.find(
      (item) => item.value === value,
    );
    setNewAccessory(selectedDevice);
  };

  return (
    <Modal
      title={inviteTitle}
      size="lg"
      show={showUpdateAccessoryModal}
      onHide={() => {
        setShowUpdateAccessoryModal(false);
      }}
      centered
      className="update-accessory-modal"
    >
      <Modal.Header
        closeButton
        data-analytics="cancel_header_update_accesspry_modal"
      >
        <Modal.Title>{inviteTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Alert show variant="brand">
            {labels['labels.patientRecord.updateAccessoryModal.info']}
          </Alert>
          <div className="details-view">
            <div className="mt-3 ccl__flex">
              <Text
                content={
                  labels[
                    'labels.patientRecord.updateAccessoryModal.description'
                  ]
                }
                type="body-text-bold"
                style={{ minWidth: '8rem' }}
              />
              <Text content={selectedAccessory?.model.name} />
            </div>
            <div className="mt-2 ccl__flex">
              <Text
                content={
                  labels[
                    'labels.patientRecord.updateAccessoryModal.partNumber'
                  ]
                }
                type="body-text-bold"
                style={{ minWidth: '8rem' }}
              />
              <Text content={selectedAccessory?.model.partNumber} />
            </div>
          </div>
          <SelectInput
            dataList={replacementAccessoryList}
            label={
              labels[
                'labels.patientRecord.updateAccessoryModal.replacementAccessory'
              ]
            }
            name="preferredLang"
            promptText={
              labels[
                'labels.patientRecord.updateAccessoryModal.selectProduct'
              ]
            }
            onChange={(item, value) => {
              handleAccessoryChange(value);
            }}
            errorMsg={
              labels[
                'labels.accountInvite.views.resend.language.error'
              ]
            }
            isSearchable
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          text={
            !updateAccessoryInProgress
              ? labels[
                  'labels.patientRecord.updateAccessoryModal.update'
                ]
              : labels[
                  'labels.patientRecord.updateAccessoryModal.updating'
                ]
          }
          variant="brand-primary"
          disabled={
            updateAccessoryInProgress || newAccessory === undefined
          }
          onClick={() => {
            handleAccessorySubmit();
          }}
          type="save"
          progress={updateAccessoryInProgress ? 2 : undefined}
          data-analytics="update_accessory_modal"
        />
        <Button
          onClick={() => {
            setShowUpdateAccessoryModal(false);
          }}
          text={
            labels['labels.patientRecord.updateAccessoryModal.cancel']
          }
          variant="tertiary"
          data-analytics="cancel_update_accessory_modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAccessoryModal;
