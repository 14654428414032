import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormButton,
  Icon,
  PhoneInput,
  TextInput,
  Preloader,
} from '@cochlear-design-system/foundation';
import { DetailsViewContact } from './DetailsView';
import { getContactDetailsConfig } from '../editPatientConfig';
import { supportedCountries } from '../callingCodeConfig';
import patientService from '../../../services/patientService';
import carerService from '../../../services/carerService';
import { isEqual } from '../../../utils/stringFns';
import { logger } from '../../../logger';

const log = logger();

export const ContactDetailsView = ({
  labels,
  carerFor,
  providerId,
  patientDetails,
  setPatientDetails,
  loading,
  setSaveSuccess,
  setSaveError,
}) => {
  const [editContactDetails, setEditContactDetails] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] =
    useState(null);

  const config = getContactDetailsConfig();
  const { fields } = config;
  const fieldsConfig = fields.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );

  const initialValues = { ...patientDetails };
  const [contactDetails, setContactDetails] = useState(null);

  const validateFields = ['firstName'];

  const getInputValidations = (inputNames) =>
    Yup.object(
      inputNames.reduce((acc, inputName) => {
        const configFieldName = inputName;
        return {
          ...acc,
          [inputName]: fieldsConfig[configFieldName]?.validators
            .reduce((acc2, { type, value, message }) => {
              if (type === 'required')
                return acc2[type](labels[message]);
              return acc2[type](value, labels[message]);
            }, Yup.string())
            .trim(),
        };
      }, {}),
    );
  const validationSchema = getInputValidations(validateFields);

  const hasSupportedCountryCode = (phone, patientCountry) => {
    if (phone?.startsWith('+')) {
      // countries like the US and Canada all start with +1
      // in cases where there is more than one country with the same calling code,
      // check, if one of them matches the users country and use that
      // otherwise default to the first one found
      const matchingCountries = supportedCountries.filter((country) =>
        phone.startsWith(`+${country.callingCode}`),
      );
      if (matchingCountries?.length > 1) {
        const matchingCountry = matchingCountries.find(
          (country) => country.countryCode === patientCountry,
        );
        return matchingCountry || matchingCountries[0];
      }
      return matchingCountries[0];
    }
    return null;
  };

  const getPhoneParts = (phone, countryConfig) => {
    let short = phone;
    let prefix = null;
    let country = null;
    if (countryConfig) {
      const callPrefix = `+${countryConfig.callingCode}`;
      if (phone?.startsWith(callPrefix)) {
        short = phone?.replace(callPrefix, '');
      }
      prefix = short ? countryConfig.callingCode : null;
      country = countryConfig.countryCode;
    }
    return { short, prefix, country };
  };

  const updatePhoneDetails = (data) => {
    if (data) {
      const patientCountry =
        data.countryCode || data.address?.countryCode;
      const patientCountryConfig = patientCountry
        ? supportedCountries.find(
            (country) => country.countryCode === patientCountry,
          )
        : null;

      const existingMobileCountry = hasSupportedCountryCode(
        data.mobile,
        patientCountry,
      );
      const existingPhoneCountry = hasSupportedCountryCode(
        data.phone,
        patientCountry,
      );
      const {
        short: mshort,
        prefix: mprefix,
        country: mcountry,
      } = getPhoneParts(
        data.mobile || '',
        existingMobileCountry || patientCountryConfig,
      );
      const {
        short: pshort,
        prefix: pprefix,
        country: pcountry,
      } = getPhoneParts(
        data.phone || '',
        existingPhoneCountry || patientCountryConfig,
      );

      const updatedDetails = {
        ...data,
        patientCountry,
        mobileCallPrefix: mprefix,
        mobileShort: mshort,
        mobileCallCountry: mcountry,
        phoneCallPrefix: pprefix,
        phoneShort: pshort,
        phoneCallCountry: pcountry,
      };
      setContactDetails(updatedDetails);
    }
  };

  const allowEmailEdit = (() => {
    return patientDetails?.accountStatus === 'Not set up' || false;
  })();

  const updatedValues = (formValues) => {
    const vals = {};
    if (!isEqual(formValues.mobile, patientDetails.mobile))
      vals.mobile = formValues.mobile ?? '';
    if (!isEqual(formValues.phone, patientDetails.phone))
      vals.phone = formValues.phone ?? '';
    if (
      allowEmailEdit &&
      !isEqual(formValues.email, patientDetails.email)
    )
      vals.email = formValues.email;
    return Object.keys(vals).length === 0 ? null : vals;
  };

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (submittedValues) => {
      setUpdateInProgress(true);
      setDuplicateEmailError(null);
      const newValues = {
        ...submittedValues,
        mobile:
          contactDetails.mobileShort &&
          contactDetails.mobileCallPrefix
            ? `+${contactDetails.mobileCallPrefix}${contactDetails.mobileShort}`
            : null,
        phone:
          contactDetails.phoneShort && contactDetails.phoneCallPrefix
            ? `+${contactDetails.phoneCallPrefix}${contactDetails.phoneShort}`
            : null,
      };
      const requestBody = updatedValues(newValues);

      try {
        // skip api calls, if nothing has changed
        if (requestBody) {
          // email duplicate check, if email has changed
          if (requestBody.email) {
            const emailCheckRequestData = {
              userName: submittedValues.email.toLowerCase(),
              relatedProviderId: providerId,
            };
            const accountCheck =
              await patientService.checkAccountStatus(
                emailCheckRequestData,
                providerId,
              );

            // Invited and Active are considered existing CIM emails
            if (
              accountCheck.accountStatus?.toLowerCase() !==
              'not set up'
            ) {
              setDuplicateEmailError(
                labels[
                  'labels.editPatient.edit.email.validation.duplicate'
                ],
              );
              setUpdateInProgress(false);
              return;
            }
          }

          if (carerFor) {
            await carerService.updateCarer(
              requestBody,
              providerId,
              carerFor,
              patientDetails.id,
            );
          } else {
            await patientService.updatePatient(
              requestBody,
              providerId,
              patientDetails.id,
            );
          }
        }
        setContactDetails(newValues);
        setPatientDetails(newValues);
        setSaveSuccess(true);
        setSaveError(false);
      } catch (err) {
        // reset data on cancel
        updatePhoneDetails(patientDetails);
        setSaveSuccess(false);
        setSaveError(true);
        log.error('Error updating contact details', err);
      }
      setUpdateInProgress(false);
      setEditContactDetails(false);
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const onChange = () => {};

  const toggleEdit = (value) => {
    if (!value) {
      // reset data on cancel
      updatePhoneDetails(patientDetails);
    }
    setEditContactDetails(value);
    setSaveSuccess(false);
    setSaveError(false);
    resetForm();
  };

  const onMobileChange = (event, value) => {
    // setMobileTouched(true);
    const updatedDetails = {
      ...contactDetails,
      mobileCallPrefix: value.callingCode,
      mobileShort: value.number,
      mobile:
        value.number && value.callingCode
          ? `+${value.callingCode}${value.number}`
          : value.number,
    };
    setContactDetails(updatedDetails);
  };

  const onPhoneChange = (event, value) => {
    const updatedDetails = {
      ...contactDetails,
      phoneCallPrefix: value.callingCode,
      phoneShort: value.number,
      phone:
        value.number && value.callingCode
          ? `+${value.callingCode}${value.number}`
          : value.number,
    };
    setContactDetails(updatedDetails);
  };

  const validatePhoneNumber = (
    callPrefix,
    localNumber,
    validators,
  ) => {
    const invalidMobileErrorKey = validators.find(
      ({ type }) => type === 'required',
    ).message;
    const maxLengthMobileErrorKey = validators.find(
      ({ type }) => type === 'max',
    ).message;
    const minLengthMobileErrorKey = validators.find(
      ({ type }) => type === 'min',
    ).message;

    if (localNumber && !callPrefix) {
      return labels[invalidMobileErrorKey];
    }

    if (callPrefix) {
      const localNumberLength = localNumber?.length || 0;
      if (localNumberLength > 0) {
        const numberLength = callPrefix?.length + localNumberLength;
        if (numberLength < 7) {
          return labels[minLengthMobileErrorKey]?.replace(
            '~MINDIGITS~',
            '7',
          );
        }
        if (callPrefix === '1' && localNumberLength > 11) {
          return labels[maxLengthMobileErrorKey]?.replace(
            '~MAXDIGITS~',
            '12',
          );
        }
        if (callPrefix !== '1' && numberLength > 15) {
          return labels[maxLengthMobileErrorKey]?.replace(
            '~MAXDIGITS~',
            '15',
          );
        }
      }
    }
    return undefined;
  };

  const mobileErrorMessage = (() => {
    return validatePhoneNumber(
      contactDetails?.mobileCallPrefix,
      contactDetails?.mobileShort,
      fieldsConfig.mobile.validators,
    );
  })();

  const phoneErrorMessage = (() => {
    return validatePhoneNumber(
      contactDetails?.phoneCallPrefix,
      contactDetails?.phoneShort,
      fieldsConfig.phone.validators,
    );
  })();

  useEffect(() => {
    updatePhoneDetails(patientDetails);
  }, [patientDetails]);

  /* eslint-disable no-nested-ternary */
  return (
    <section className="ccl-c-form-section">
      <div className="ccl-e-detail-action-bar">
        <div className="ccl-e-detail-action-bar__title">
          <span>
            {labels['labels.editPatient.contactDetails.title']}
          </span>
        </div>
        <div className="ccl-e-detail-action-bar__action">
          {!patientDetails?.isUnderAge &&
            !patientDetails?.isDeceased && (
              <div className="ccl-e-icon-clickable">
                {!editContactDetails && (
                  <button
                    type="button"
                    className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                    data-nw-action="edit"
                    data-analytics={
                      carerFor
                        ? 'edit_contact_details_edit_carer'
                        : 'edit_contact_details_edit_patient'
                    }
                    onClick={() => toggleEdit(true)}
                  >
                    <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                      <span
                        data-nw-icon-label=""
                        className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                      >
                        {labels['labels.common.edit']}
                      </span>
                    </div>
                  </button>
                )}
                {editContactDetails && (
                  <button
                    type="button"
                    className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                    data-nw-action="edit"
                    data-analytics={
                      carerFor
                        ? 'cancel_header_contact_details_edit_carer'
                        : 'cancel_header_contact_details_edit_patient'
                    }
                    onClick={() => toggleEdit(false)}
                  >
                    <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                      <Icon type="close" size="sm" />
                      <span
                        data-nw-icon-label=""
                        className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                      >
                        {labels['labels.common.cancel']}
                      </span>
                    </div>
                  </button>
                )}
              </div>
            )}
        </div>
      </div>
      <div>
        {loading ? (
          <Preloader
            key={`preloader${Math.random()}`}
            lines={['title', 'label-field', 'label-field']}
          />
        ) : !editContactDetails && contactDetails ? (
          <div>
            <DetailsViewContact
              values={contactDetails}
              labels={labels}
            />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} onChange={onChange}>
              <PhoneInput
                country={contactDetails.mobileCallCountry}
                hint={labels[fieldsConfig.mobile.hint]}
                hintForCode={labels[fieldsConfig.mobile.hintForCode]}
                label={labels[fieldsConfig.mobile.label]}
                name="mobile"
                optionalText=""
                value={contactDetails.mobileShort}
                error={!!mobileErrorMessage}
                errorMsg={mobileErrorMessage}
                onBlur={onMobileChange}
                onChange={onMobileChange}
                stateManaged={false}
              />
              <PhoneInput
                country={contactDetails.phoneCallCountry}
                hint={labels[fieldsConfig.phone.hint]}
                hintForCode={labels[fieldsConfig.phone.hintForCode]}
                label={labels[fieldsConfig.phone.label]}
                name="phone"
                optionalText=""
                value={contactDetails.phoneShort}
                error={!!phoneErrorMessage}
                errorMsg={phoneErrorMessage}
                onBlur={onPhoneChange}
                onChange={onPhoneChange}
                stateManaged={false}
              />
              <TextInput
                className="ccl-l-edit-patient-tab__fields__name"
                name="email"
                value={values.email}
                disabled={!allowEmailEdit}
                onChange={handleChange}
                onBlur={handleBlur}
                label={labels[fieldsConfig.email.label]}
                optionalText={labels[fieldsConfig.email.optionalText]}
                promptText=""
                error={
                  touched.email &&
                  (!!errors.email || !!duplicateEmailError)
                }
                errorMsg={
                  touched.email &&
                  (errors.email || duplicateEmailError)
                }
              />
            </form>
            <div className="ccl-l-edit-patient-tab__actions">
              <div className="pe-3">
                <FormButton
                  text={
                    !updateInProgress
                      ? labels['labels.common.save']
                      : labels['labels.common.saving']
                  }
                  variant="brand-primary"
                  disabled={
                    !isValid ||
                    !!mobileErrorMessage ||
                    !!phoneErrorMessage ||
                    updateInProgress
                  }
                  onClick={handleSubmit}
                  type="save"
                  progress={updateInProgress ? 2 : undefined}
                  data-analytics={
                    carerFor
                      ? 'save_contact_details_edit_carer'
                      : 'save_contact_details_edit_patient'
                  }
                />
              </div>
              <div>
                <Button
                  text={labels['labels.common.cancel']}
                  variant="secondary"
                  onClick={() => toggleEdit(false)}
                  data-analytics={
                    carerFor
                      ? 'cancel_contact_details_edit_carer'
                      : 'cancel_contact_details_edit_patient'
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
