import Cookies from 'js-cookie';
import { cookieDomain, PROVIDER_DETAILS_COOKIE } from '../config';
import localStorageService from './localStorageService';

const getMinutes = (minutes) => {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
};

const setCookie = (
  name,
  value,
  expiresInMins = 30,
  isDomainLevel = false,
) => {
  const options = {
    domain: isDomainLevel ? cookieDomain : '',
    secure: true,
  };
  if (expiresInMins !== Infinity) {
    options.expires = getMinutes(expiresInMins);
  }

  if (name === PROVIDER_DETAILS_COOKIE) {
    localStorageService.setItem(name, value);
  } else {
    Cookies.set(name, value, options);
  }
};

const getCookie = (name) => {
  if (name === PROVIDER_DETAILS_COOKIE) {
    return localStorageService.getItem(name);
  }
  return Cookies.get(name);
};

const removeCookie = (name) => {
  if (name === PROVIDER_DETAILS_COOKIE) {
    localStorageService.removeItem(name);
  } else {
    Cookies.remove(name);
  }
};

const cookieService = {
  setCookie,
  getCookie,
  removeCookie,
};

export default cookieService;
