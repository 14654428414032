import React from "react";
import { ErrorMessage, Container } from "@cochlear-design-system/foundation";

export const Error = ({ config, labels, data }) => {
  const errorData = {
    list: {
      image: {
        src: config.imgSrc,
        alt: labels[config.imgAlt],
        width: config.imgWidth,
      },
      title: labels[config.title],
      text: labels[config.text],
      buttons: config.buttons,
    },
  };

  let className = "ccl__dpx-error";
  if (config.layout === "horizontal") className += "  horizontal";
  return (
    <div className={className}>
      <Container>
        <ErrorMessage
          codeLabel={labels[config.codeLabel]}
          errorResponse={data.errorResponse}
          mode="list"
          data={errorData}
          handleCallback={config.handleCallback}
        />
      </Container>
    </div>
  );
};
