import React from 'react';
import { Error as ErrorComponent } from '../../ui/Error/Error.js';

const DisabledFeature = ({ title, message }) => {
  return (
    <ErrorComponent
      config={{
        buttons: [],
        codeLabel: 'labels.errorMessage.codeLabel',
        handleCallback: function noRefCheck() {},
        imgAlt: 'labels.errorMessage.imgAlt',
        imgSrc:
          'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/CDS_Pictogram_Locked',
        imgWidth: 116,
        layout: 'horizontal',
        text: 'labels.errorMessage.text',
        title: 'labels.errorMessage.title',
      }}
      data={{}}
      labels={{
        'labels.errorMessage.codeLabel': 'Error code',
        'labels.errorMessage.imgAlt': 'Access denied',
        'labels.errorMessage.text': `${message}`,
        'labels.errorMessage.title': `${title}`,
      }}
    />
  );
};

export default DisabledFeature;
