import { STEP_SUCCESS, STEP_FAILURE } from '../actions/types';

const initialState = false;

export default function stepReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STEP_SUCCESS:
      return payload;
    case STEP_FAILURE:
      return false;
    default:
      return state;
  }
}
