import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  Text,
  Title,
  Spinner,
} from '@cochlear-design-system/foundation';
import { withFeature } from 'flagged';
import { routes } from '../../config';
import providerService from '../../services/providerService';
import getLocalStorageLabels from '../../utils/getLocalStorageLabels';
import { setIdpParam } from '../../utils/authprovider';

const ProgrammingFileRequests = ({ locale }) => {
  const labels = getLocalStorageLabels(locale);
  const pDetails = providerService.getProviderDetails();
  const chosenProvider = pDetails.providerId;
  const partyId = pDetails.providerList.providers.filter(
    (provider) => provider.id === chosenProvider,
  )[0]?.partyId;

  const [srUrl, setSrUrl] = useState('');
  const [showModalPFR, setShowModalPFR] = useState(false);
  const [pfrLoaded, setPfrLoaded] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showSelected, setShowSelected] = useState(false);

  const viewPrfIframe = useRef();
  const viewPrfListIframe = useRef();
  const [modalDivHeight, setModalDivHeight] = useState('1vh');

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('modalEvent' in e.data) {
          switch (e.data.modalEvent.target) {
            case 'programmingFile':
              if (e.data.modalEvent.type === 'open') {
                setSrUrl(e.data.modalEvent.payload.url);
                setShowModalPFR(true);
                setPfrLoaded(false);
                setShowSelected(false);
              }
              break;
            default:
              break;
          }
        }
        if ('pageEvent' in e.data) {
          const mcpPageHeight = e.data.pageEvent.payload?.height;          
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (
                e.data.pageEvent.target === 'selectedServiceRequest'
              ) {
                if (viewPrfIframe && viewPrfIframe.current) {
                  setShowSelected(true);
                  setPfrLoaded(true);
                  viewPrfIframe.current.style.height = mcpPageHeight === 0 ? '1800px' : `${mcpPageHeight + 100}px`;
                }
              }
              if (e.data.pageEvent.target === 'programmingFileList') {
                setShowList(true);
                // less than 200 means that the page is collapsed, but MCP may send correct height as well if user clicks "Refresh" button 
                viewPrfListIframe.current.style.height = mcpPageHeight < 200 ? '450px' : `${mcpPageHeight}px`;                
                if (mcpPageHeight < 200) {
                  setModalDivHeight('60vh');
                } else {
                  setModalDivHeight(`${mcpPageHeight}px`);
                }
              }
              break;

            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div style={{ height: modalDivHeight }}>
      <Modal
        footer=""
        show={showModalPFR}
        title=""
        onHide={() => setShowModalPFR(false)}
        size="xl"
      >
        <Modal.Header closeButton data-analytics="close_view_sr_modal">
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Title
          content={labels['labels.programmingFileRequests.modalTitle']}
          size="heading-2"
          tag="h2"
        />

        {!pfrLoaded && (
          <div
            style={{
              margin: 'auto',
              textAlign: 'center',
              marginTop: '3rem',
            }}
          >
            <>
              <Spinner animation="border" />
              <Text
                content={
                  labels['labels.programmingFileRequests.loading']
                }
              />
            </>
          </div>
        )}
        <iframe
          src={setIdpParam(`${routes.mcp.domain}${srUrl}&dpx=true`)}
          title="View PFR"
          width="100%"
          ref={viewPrfIframe}
          style={{
            marginTop: '2rem',
            visibility: showSelected ? 'visible' : 'hidden',
          }}
        />
      </Modal>
      <iframe
        src={setIdpParam(`${routes.mcp.programmingFileRequests}${partyId}`)}
        id="modaliFrame-programmingFileSR"
        title="programmingFileList"
        width="100%"
        ref={viewPrfListIframe}
        style={{
          visibility: showList ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default withFeature('featureEnabler/programFileRequests')(
  ProgrammingFileRequests,
);
