import React from "react";
import { DropdownItem } from "./DropdownItem";

export const DropdownItems = ({
  items,
  config,
  selected,
  setSelected,
  setOpen,
}) => {
  return (
    <ul className="ccl__dropdown__list">
      {items.map((item) => (
        <DropdownItem
          selected={selected}
          setSelected={setSelected}
          setOpen={setOpen}
          config={config}
          item={item}
          key={item.id}
        />
      ))}
    </ul>
  );
};

