import React, { useEffect, useState } from "react";

const useTick = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intId = setInterval(() => {
      setCount((c) => c + 1);
    }, 1000);

    return () => clearInterval(intId);
  }, []);

  return [count];
};

const useCountdown = (elapsed) => {
  const [endTime, setEndTime] = useState(0);
  const [tick] = useTick();

  let timer = null;
  if (endTime > tick) {
    timer = endTime - tick;
  } else if (endTime !== 0) {
    timer = 0;
  }

  const startTimer = () => {
    if (endTime === 0) {
      setEndTime(tick + elapsed);
    }
  };

  const endTimer = () => {
    setEndTime(0);
  };

  return [timer, startTimer, endTimer];
};

const AutoReloadIFrame = ({
  src,
  title,
  style,
  enabled = false,
  timeSeconds = 60,
}) => {
  const [frameKey, setFrameKey] = useState("auto_reload_frame_0_id");
  const [timer, startTimer, endTimer] = useCountdown(timeSeconds);

  if (enabled && timer === 0) {
    endTimer();
    const x = Math.floor(Math.random() * 10000);
    setFrameKey(`auto_reload_frame_${x}_id`);
  }

  const handleOnLoad = () => {    
    if (enabled) {
      startTimer();
    }
  };

  return (
    <iframe
      key={frameKey}
      src={src}
      title={title}
      style={style}
      onLoad={handleOnLoad}
    />
  );
};

export default AutoReloadIFrame;
