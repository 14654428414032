import React from 'react';
import { Text } from '@cochlear-design-system/foundation';
import { getLanguageList } from '../../../utils/patientCarerHelper';
import {getDobFormatted} from '../../../utils/dateTimeFns';

export const DetailsView = ({ values, labels, showLanguage }) => {

  if (
    'dateOfBirth' in values &&
    !('day' in values) &&
    !('month' in values) &&
    !('year' in values)
  ) {
    // eslint-disable-next-line no-param-reassign
    [values.year, values.month, values.day] =
      values.dateOfBirth.split('-');
  }
  let language = '';
  if (showLanguage) {
    const languageList = getLanguageList();
    language = languageList.find(
      (l) => l.value === values.preferredLanguage,
    );
  }
  const middleName = values.middleName ? values.middleName : '';

  return (
    <>
      <div className="mt-3 ccl__flex">
        <Text
          content={labels['labels.common.name']}
          type="body-text-bold"
          style={{ minWidth: '8rem' }}
        />
        <Text
          content={`${values.firstName} ${middleName} ${values.lastName}`}
        />
      </div>
      <div className="mt-2 ccl__flex">
        <Text
          content={labels['labels.common.dateOfBirth']}
          type="body-text-bold"
          style={{ minWidth: '8rem' }}
        />
        {'day' in values && 'month' in values && 'year' in values && (
          <Text
            content={getDobFormatted(values.day, values.month, values.year)}
          />
        )}
      </div>
      <div className="mt-2 ccl__flex">
        <Text
          content={labels['labels.common.email']}
          type="body-text-bold"
          style={{ minWidth: '8rem' }}
        />
        <Text content={values.email} />
      </div>
      {showLanguage && (
        <div className="mt-2 ccl__flex">
          <Text
            content={labels['labels.common.language']}
            type="body-text-bold"
            style={{ minWidth: '8rem' }}
          />
          <Text content={language.label} />
        </div>
      )}
    </>
  );
};
