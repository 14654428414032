import { i18CachePrefix } from '../config';

export default function getLocalStorageLabels(locale = 'en') {
  // accepts a locale string, defaults to en if locale is undefined
  const list = localStorage.getItem(
    `${i18CachePrefix}${locale}-translation`,
  );

  // if list returns null, try again with en
  const fallback =
    list || localStorage.getItem(`${i18CachePrefix}en-translation`);

  const ls = JSON.parse(fallback) || [];

  Object.keys(ls).forEach((key) => {
    if (ls[key] === ' ' || ls[key] === key) {
      ls[key] = '';
    }
  });

  return ls;
}
