let popupWindow = null;

export function openPopup(url, name, options) {
  if (!popupWindow || popupWindow.closed()) {
    popupWindow = window.open(url, name, options);
  }
}
export function closePopup() {
  if (popupWindow) {
    popupWindow.close();
    popupWindow = null;
  }
}
