function preloadImages(imageUrls) {
  const cachedImages = [];

  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
    cachedImages.push(img);
  });

  return cachedImages.length;
}

export default preloadImages;
