/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from './Spinner';
import Redirect from './Redirect';
import { routes } from '../config';
import { closePopup } from '../utils/popupManager';

const Outbound = () => {
  const [loading, setLoading] = useState(true);
  const [to, setTo] = useState();

  const queryString = useLocation().search;
  const clinicId = new URLSearchParams(queryString).get('id');

  useEffect(() => {
    setTo(`${routes.mcp.clinic}${clinicId}`);
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              closePopup();
              setLoading(false);
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return loading ? (
    <Spinner loading={loading} />
  ) : to ? (
    <Redirect to={to} refresh />
  ) : (
    <></>
  );
};

export default Outbound;
