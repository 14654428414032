export const getErrorConfig = () => {
  return {
    imgSrc: {
      value:
        'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
    },
    imgAlt: {
      targetItem: {
        key: {
          key: 'labels.error.imgAlt',
        },
      },
    },
    imgWidth: {
      value: '116',
    },
    title: {
      targetItem: {
        key: {
          key: 'labels.error.title',
        },
      },
    },
    text: {
      targetItem: {
        key: {
          key: 'labels.error.text',
        },
      },
    },
    codeLabel: {
      targetItem: {
        key: {
          key: 'labels.error.codeLabel',
        },
      },
    },
    buttons: [
      {
        children: [
          {
            name: {
              value: 'back',
            },
            variant: {
              value: 'brand-primary',
            },
            text: {
              value: 'Go back',
            },
            icon: {
              value: 'chevron-left',
            },
            link: {
              url: '',
            },
            action: {
              value: 'link-dpx-back',
            },
            useCallback: {
              value: '1',
            },
          },
        ],
      },
    ],
  };
};
