import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { FlagsProvider } from 'flagged';
import TagManager from 'react-gtm-module';
import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import i18ninit from './i18n';
import { gtmId } from './config';

import preloadImages from './utils/imagePreloader';

/* eslint-disable no-underscore-dangle */

let renderFunction = ReactDOM.render;

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
  renderFunction = ReactDOM.hydrate;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE
    ? __JSS_STATE__.APOLLO_STATE
    : null;

const graphQLClient = GraphQLClientFactory(
  config.graphQLEndpoint,
  false,
  initialGraphQLState,
);

/*
  GTM initialisation
*/
const tagManagerArgs = {
  gtmId,
};
TagManager.initialize(tagManagerArgs);

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage).then(() => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  // Get the feature flags API response from localStorage
  const featureFlags = {
    ...JSON.parse(localStorage.getItem('featureFlags')),
    ...JSON.parse(sessionStorage.getItem('featureVersionSupport')),
  };

  preloadImages([
    'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/d7d76035265f4eb6b4817ca927285c5d?v=3ef6f186',
    'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
    'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
    'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/CDS_Pictogram_Password?v=e6c2d773',
  ]);

  renderFunction(
    <FlagsProvider features={featureFlags}>
      <AppRoot
        path={window.location.pathname}
        Router={BrowserRouter}
        graphQLClient={graphQLClient}
        ssrState={__JSS_STATE__}
      />
    </FlagsProvider>,
    rootElement,
  );
});
