import { codeVerifier, codeChallenge } from './utils/codegen';

let env = process.env.REACT_APP_DPX_ENV;

if (env !== 'LOCAL' && env !== 'OFFLINE') {
  env = 'PRD';
}

export const appEnv = env;

const environments = {
  OFFLINE: 'dev',
  LOCAL: 'sit',
  DEV: 'dev',
  SIT: 'sit',
  UAT: 'uat',
  PRD: 'prd',
};

/**
 * Environment URLs
 */
const envUrls = {
  OFFLINE: 'http://localhost',
  LOCAL: 'http://localhost',
  DEV: 'https://dev.dpx.cx.nonp.cochlear.cloud',
  SIT: 'https://portal-sit.mycochlear.com',
  UAT: 'https://portal-uat.mycochlear.com',
  PRD: 'https://portal.mycochlear.com',
};

export const envUrl = envUrls[env];

/**
 * OnboardingURL
 */
const onboardingURLs = {
  OFFLINE: 'https://www3.cochlear.com/join/home?',
  LOCAL: 'https://www3.cochlear.com/join/home?',
  DEV: 'https://core.dev.cochlear.cloud/join/home?',
  SIT: 'https://www3.cochlear.com/join/home?',
  UAT: 'https://www4.cochlear.com/join/home?',
  PRD: 'https://www.cochlear.com/join/home?',
};

export const onboardingURL = onboardingURLs[env];

/**
 * Cochlear Learning Centre
 */
const cochlearLearningCentreURLs = {
  OFFLINE: 'https://cochlearsandbox.docebosaas.com/learn/auth0/login',
  LOCAL: 'https://cochlearsandbox.docebosaas.com/learn/auth0/login',
  DEV: 'https://cochlearsandbox.docebosaas.com/learn/auth0/login',
  SIT: 'https://cochlearsandbox.docebosaas.com/learn/auth0/login',
  UAT: 'https://cochlearsandbox.docebosaas.com/learn/auth0/login',
  PRD: 'https://cochlear.docebosaas.com/learn/auth0/login',
};

export const cochlearLearningCentreURL =
  cochlearLearningCentreURLs[env];

/**
 * Authorizer redirect URL
 */
const redirectUri = `${envUrls[env]}/authorize`;

/**
 * Cookie domains for domain level cookies
 */
const cookieDomains = {
  OFFLINE: '',
  LOCAL: '',
  DEV: '.cochlear.cloud',
  SIT: '.mycochlear.com',
  UAT: '.mycochlear.com',
  PRD: '.mycochlear.com',
};
export const cookieDomain = cookieDomains[env];
export const ID_TOKEN_COOKIE = 'ct_id';
export const ACCESS_TOKEN_COOKIE = 'ct_prf';
export const REFRESH_TOKEN_COOKIE = 'ctr_prf';
export const PROVIDER_DETAILS_COOKIE = 'cpd';
export const SHARED_MENU_COOKIE = 'cd_shmn';
export const USER_COOKIE = 'cd_user';
export const DPX_BOUND_COOKIE = 'dpx_bound';
export const DPX_OPTOUT_COOKIE = 'dpx_optout';

/**
 * MCP
 */
const mcpDomains = {
  OFFLINE: 'https://secure-sit.mycochlear.com',
  LOCAL: 'https://secure-sit.mycochlear.com',
  DEV: 'https://secure-sit.mycochlear.com',
  SIT: 'https://secure-sit.mycochlear.com',
  UAT: 'https://secure-uat.mycochlear.com',
  PRD: 'https://secure.mycochlear.com',
};

export const disableIframes = () => {
  return (
    env.toUpperCase() === 'DEV' || env.toUpperCase() === 'OFFLINE'
  );
};

/**
 * Clinician Portal
 */
const clinicianPortalDomains = {
  OFFLINE: 'https://clinician-portal-sit.mycochlear.com',
  LOCAL: 'https://clinician-portal-sit.mycochlear.com',
  DEV: 'https://clinician-portal-sit.mycochlear.com',
  SIT: 'https://clinician-portal-sit.mycochlear.com',
  UAT: 'https://clinician-portal-uat.mycochlear.com',
  PRD: 'https://clinician-portal.mycochlear.com',
};

/**
 * Experience Layer API endpoints
 */
// eslint-disable-next-line import/no-mutable-exports
let providerListEndpoint;

switch (env) {
  case 'PRD':
    providerListEndpoint =
      'https://api.cochlear.com/mcp/v1/providers';
    break;

  case 'OFFLINE':
    providerListEndpoint = 'http://localhost:3000';
    break;

  default:
    providerListEndpoint = `https://${environments[env]}.api.cochlear.com/mcp/v1/providers`;
    break;
}

export { providerListEndpoint };

export const authTokenEndpoint = `${providerListEndpoint}/oauth/token`;
export const providerTokenEndpoint = `${providerListEndpoint}/oauth/provider-token`;
export const refreshTokenEndpoint = `${providerListEndpoint}/oauth/refresh-token`;

/**
 * Auth0 Config
 */
const auth0Domains = {
  OFFLINE: 'https://sit.id.cochlear.com',
  LOCAL: 'https://sit.id.cochlear.com',
  DEV: 'https://sit.id.cochlear.com',
  SIT: 'https://sit.id.cochlear.com',
  UAT: 'https://uat.id.cochlear.com',
  PRD: 'https://id.cochlear.com',
};

const auth0ClientIds = {
  OFFLINE: 'YN6EuxNo4Hqpe4mJKOiNp8n5yJrFqZCY',
  LOCAL: 'YN6EuxNo4Hqpe4mJKOiNp8n5yJrFqZCY',
  DEV: 'YN6EuxNo4Hqpe4mJKOiNp8n5yJrFqZCY',
  SIT: 'YN6EuxNo4Hqpe4mJKOiNp8n5yJrFqZCY',
  UAT: '4Hdm5KnExs8B1BsaKiiUPgpvZN4xOY75',
  PRD: 'A4X2Cj5uK72Shqy5An3eBDrFqUu1oE8k',
};
const auth0ClientId = auth0ClientIds[env];

const auth0Audiences = {
  OFFLINE: 'https://sit.api.cochlear.com/mcp/v1',
  LOCAL: 'https://sit.api.cochlear.com/mcp/v1',
  DEV: 'https://sit.api.cochlear.com/mcp/v1',
  SIT: 'https://sit.api.cochlear.com/mcp/v1',
  UAT: 'https://uat.api.cochlear.com/mcp/v1',
  PRD: 'https://api.cochlear.com/mcp/v1',
};
const auth0Audience = auth0Audiences[env];

const virtualSoftwareIds = {
  OFFLINE: 'g4n2Xhokll4Je4I4iuCpj33qibn6k4Nl',
  LOCAL: 'g4n2Xhokll4Je4I4iuCpj33qibn6k4Nl',
  DEV: 'g4n2Xhokll4Je4I4iuCpj33qibn6k4Nl',
  SIT: 'g4n2Xhokll4Je4I4iuCpj33qibn6k4Nl',
  UAT: 'CaBFcC76gUlSjqHTrXSsu8YMjJxy9rEL',
  PRD: 'hfeX70FMjPmI98iiL4ZgZkdDVzdCGlR5',
};

const virtualSoftwareId = virtualSoftwareIds[env];

const virtualSoftwareClientIds = {
  OFFLINE: '3D068760682950',
  LOCAL: '3D068760682950',
  DEV: '3D068760682950',
  SIT: '3D068760682950',
  UAT: '3D068760682950',
  PRD: '3D368815665961',
};

const virtualSoftwareClientId = virtualSoftwareClientIds[env];

/* Auth0 Login URL */
const auth0LoginUrlParams = {
  url: `${auth0Domains[env]}/authorize?`,
  query: {
    client_id: auth0ClientId,
    response_type: 'code',
    redirect_uri: redirectUri,
    scope: 'openid profile email offline_access',
    state: encodeURIComponent(JSON.stringify({ t: Date.now() })),
    audience: auth0Audience,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  },
};

const auth0LoginUrlQueryString = Object.keys(
  auth0LoginUrlParams.query,
)
  .map((key) => `${key}=${auth0LoginUrlParams.query[key]}`)
  .join('&');

export const auth0LoginUrl =
  auth0LoginUrlParams.url + auth0LoginUrlQueryString;

export const auth0TokenApiParams = {
  url: `${authTokenEndpoint}`,
  data: {
    grant_type: 'authorization_code',
    client_id: auth0ClientId,
    code_verifier: codeVerifier,
    redirect_uri: redirectUri,
  },
};

/* Auth0 Logout URL */
const returnTo = `${redirectUri}`;
const auth0LogoutUrlParams = {
  url: `${auth0Domains[env]}/v2/logout?`,
  query: `returnTo=${encodeURIComponent(returnTo)}`,
};

export const auth0LogoutUrl =
  auth0LogoutUrlParams.url + auth0LogoutUrlParams.query;

/**
 * All CIM user types
 */
export const userAccountType = {
  OnlineSupportProfessional: 'OnlineSupportProfessional',
  OnlineSupportDistributor: 'OnlineSupportDistributor',
  ContentProfessional: 'ContentProfessional',
  RegistrationProfessional: 'RegistrationProfessional',
  RegistrationDistributor: 'RegistrationDistributor',
};

/**
 * Allowed CIM types for DPX
 */
export const allowedUserAccountTypes = [
  userAccountType.OnlineSupportProfessional,
  userAccountType.RegistrationProfessional,
];

export const deviceType = {
  Implant: 'implant',
  Actuator: 'actuator',
  SoundProcessor: 'sound processor',
  RemoteAssistant: 'remote assistant',
  Accessory: 'accessory',
  ActuatorUnit: 'actuator unit',
  Other: 'other',
};

/**
 * Routes
 */
export const routes = {
  customerService:
    'https://www.cochlear.com/global/en/customer-service',
  dpx: {
    home: 'home',
    profile: 'profile',
    selectClinics: 'select-clinic',
    authorizer: '/authorize',
    unauthorized: '/unauthorized',
    initializer: '/initialize',
    patient: 'patients',
    advancedSearch: 'advanced-search',
    locatePatient: 'locate-patient',
    error: 'error',
    patientDetails: 'patients/details',
    editPatient: 'patients/edit',
    addCarer: 'patients/add-carer',
    createPatientLanding: 'create-patient-landing',
    createPatientRecipient: 'create-patient-recipient',
    createPatientCarer: 'create-patient-carer',
    createPatient: 'create-patient?condition=recipient',
    deviceRegistration: 'device-registration',
    deviceRegistrationReview: 'device-registration-review',
    deviceRegistrationSuccess: 'device-registration-success',
    accessoryRegistrationReview: 'accessory-registration-review',
    logout: '/authorize?action=logout',
    onboardingQRCode: 'onboarding-qr-code',
  },
  mcp: {
    domain: `${mcpDomains[env]}`,
    home: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/onlineServicesDashboard`,
    warmup: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/onlineServicesDashboard?dpx=true&init=true`,
    clinic: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/context/CHANGE_ACTIVE_CLINIC?clinicId=`,
    servieRequest: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/recipientOverview/SET_SELECTED_RECIPIENT_CID?dpx=true&selectedRecipientCId=`,
    viewServiceRequestWithoutDomain: `/wps/OnlineServices/manageRecipient/viewServiceRequest/VIEW_SERVICE_REQUEST_URL?selectedServiceRequestId=##___SERVICEREQUESTID___##&selectedRecipientId=`,
    createServiceRequest: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/serviceRequest/SHOW_NEW_SERVICE_REQUEST_DPX?dpx=true&clinic_party_id=##___CLINICPARTYID___##&recipient_cochlear_id=##___RECIPIENTCOCHLEARID___##&instance_id=`,
    programmingFileRequests: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/context/CHANGE_ACTIVE_CLINIC?dpx=true&clinicId=`,
    recipientAdditionalData: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/recipientOverview/RECIPIENT_ADDITIONAL_DATA?selectedRecipientCId=`,
    account: `${mcpDomains[env]}/wps/OnlineServices/myAccount?dpx=true&expand=`,
    logout: `${mcpDomains[env]}/wps/OnlineServices/myAccount/LOGOUT?from=dpx`,
    clinicManagement: `${mcpDomains[env]}/wps/OnlineServices/manageClinic/clinicManagement/SHOW_CLINIC_MGMT?dpx=true`,
    clinicEquipment: `${mcpDomains[env]}/wps/OnlineServices/manageRecipient/loaner/VIEW_MANAGE_LOANERS?menuItemId=%23mnu-assign-loaner&dpx=true`,
    cspCamTraining: `${mcpDomains[env]}/wps/OnlineServices/resources/[REGION]/[LANG]/custom-sound/custom-sound-pro#/`,
    cspVirtualSoftware: `${auth0Domains[env]}/samlp/${virtualSoftwareId}?RelayState=https%3A%2F%2Fappstream2.ap-southeast-2.aws.amazon.com%2Fsaml%3Fstack%3Dcsp-stack%26accountId%${virtualSoftwareClientId}%26app%3DCustomSound`,
    cpnResource: `${mcpDomains[env]}/wps/OnlineServices/resources/mcp-cam/en/CPN_Professionals?dpx=true`,
    resources: `${mcpDomains[env]}/wps/OnlineServices/resources/[REGION]/en/resources?dpx=true`,
    resourcesHome: `${mcpDomains[env]}/wps/OnlineServices/resources/[REGION]/en/resources`,
    keepAlive: `${mcpDomains[env]}/wps/OnlineServices/dpx-session.jsp`,
  },
  clinicianPortal: {
    remoteCheck: `${clinicianPortalDomains[env]}/remoteCheck?theme=dpx&cId=`,
    home: `${clinicianPortalDomains[env]}/`,
  },
  expApi: {
    portalConfig: `${providerListEndpoint}/config/portal`,
  },
  remoteCheckScriptPath: `${clinicianPortalDomains[env]}/enrolmentStatusJS/latest`,
  outbound: '/outbound',
};

/**
 * i18 cache
 */
export const i18CachePrefix = 'i18next_mcp_res_';
export const i18CacheExpiryTime = 5 * 60 * 1000; // 5 minutes

/**
 * Token Renewal
 */
export const tokenRenewThresholdInSecs = 10 * 60; // 10 minutes
export const tokenRenewCheckIntervalInSecs = 3 * 60; // 3 minutes

/**
 * Idle Timer
 */
export const idleTimerTimeout = 50 * 60 * 1000; // 50 minutes
export const idleTimerPromptBeforeIdle = 5 * 60 * 1000; // 5 minutes

/**
 * Provider Details object in storage Time to Live
 */
export const cpdTtl = 2 * 60 * 60 * 1000; // 2 hours

/**
 * GTM Ids
 */
const gtmIds = {
  OFFLINE: 'GTM-T9LTVS8J',
  LOCAL: 'GTM-T9LTVS8J',
  DEV: 'GTM-T9LTVS8J',
  SIT: 'GTM-T9LTVS8J',
  UAT: 'GTM-T9LTVS8J',
  PRD: 'GTM-T9LTVS8J',
};

export const gtmId = gtmIds[env];

export const defaultBrowserLanguage = 'en';

export const supportedLanguagesLoginFlow = [
  'en-US',
  'en-GB',
  'fr-FR',
  'pt-BR',
  'zh-CN',
  'zh-HK',
  'nl-NL',
  'de-DE',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'es-ES',
  'sv-SE',
  'tr-TR',
];

export const supportedLanguages = ['en-US', 'en-GB'];

export const showReturnToLegacyPortal = false;
