import { AUTH_SUCCESS, AUTH_FAILURE } from './types';
import { logger } from '../logger';

const log = logger();

const auth = (isAuthorized) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_SUCCESS,
      payload: isAuthorized,
    });
  } catch (err) {
    log.error('Error dispatching auth', err);
    dispatch({
      type: AUTH_FAILURE,
      payload: err,
    });
  }
};

export default auth;
