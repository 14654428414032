import React from 'react';
import { DataTable } from '@cochlear-design-system/features.dataTable';

export const EquipmentSummary = ({
  config,
  labels,
  leftData,
  rightData,
  unknownData,
}) => (
  <>
    {rightData?.length > 0 && (
      <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
        <DataTable
          config={config.tableConfig.tableConfigRight}
          data={rightData}
          labels={labels}
        />
        <br />
      </div>
    )}
    {leftData?.length > 0 && (
      <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
        <DataTable
          config={config.tableConfig.tableConfigLeft}
          data={leftData}
          labels={labels}
        />
        <br />
      </div>
    )}
    {unknownData?.length > 0 && (
      <div className="ccl__data-table--dpx ccl__data-table--dpx--equipment">
        <DataTable
          config={config.tableConfig.tableConfigUnknown}
          data={unknownData}
          labels={labels}
        />
      </div>
    )}
  </>
);
