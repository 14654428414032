import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { withFeature } from 'flagged';
import { DeviceRegistrationWrapper } from '../../ui/DeviceRegistrationWrapper/DeviceRegistrationWrapper.js';
import { routes } from '../../config';
import headerCancelModal from '../../actions/headerCancelModalAction';
import providerService from '../../services/providerService';
import userService from '../../services/userService';

import {
  config,
  dataTableConfig,
  devicesData,
  errorConfig,
  dataError,
  errorConfigAPIError,
  dataAPIError,
  modalConfig as modalConfiguration,
  noExistingDevices,
  deviceAlreadyRegistered,
} from './deviceRegistrationConfig';
import { getLabelsForFields } from '../../utils/labelHelper';

const DeviceRegistration = ({ rendering, country, lang }) => {
  const scLabels = getLabelsForFields(rendering.fields);
  const modalConfig = modalConfiguration(scLabels);
  const history = useHistory();
  const location = useLocation();
  const { state } = useState(location)[0];
  const dispatch = useDispatch();
  const deviceRegistrationWrapperRef = useRef();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );
  const currentProvider = useState(
    providerService.getCurrentProvider(),
  )[0];
  const { professionalId } = userService.getUserDetails();
  const { countryCode } = currentProvider.address || 'US';
  const updatedConfig = config(countryCode);

  const patientLink = `${routes.dpx.patientDetails}?patientId=${state.cochlearId}`;

  useEffect(() => {
    if (modalTrigger) {
      deviceRegistrationWrapperRef.current.setModalState(true);
      dispatch(headerCancelModal(false));
    }
  }, [modalTrigger]);

  const redirectToPatientRecord = () => {
    window.location.href = patientLink;
  };
  modalConfig.onSuccess = redirectToPatientRecord;

  const redirectToDeviceReview = (data) => {
    if (data) {
      const tempData = data;
      tempData.patientName = state?.patientName;
      tempData.dob = state?.dob || '--';
      tempData.cochlearId = state?.cochlearId;
      tempData.hasExistingDevice = state?.hasExistingDevice;
      history.replace({
        pathname: `/${country}/${lang}/${routes.dpx.deviceRegistrationReview}`,
        state: { selectedDevice: tempData },
      });
    }
  };

  const baseConfig = {
    config: updatedConfig,
    labels: scLabels,
    data: {
      searchType: 'patient',
      parameters: {},
    },
    dataTableDetails: {
      config: dataTableConfig(),
      labels: scLabels,
      data: devicesData,
      redirectToDeviceReview,
    },
    patientName: state?.patientName,
    patientId: state?.cochlearId,
    providerId: currentProvider?.id,
    professionalId,
    patientLink,
    error: {
      config: errorConfig,
      data: dataError,
      labels: scLabels,
    },
    errorApi: {
      config: errorConfigAPIError,
      data: dataAPIError,
      labels: scLabels,
    },
    noExistingDevices: {
      config: noExistingDevices,
      data: {},
      labels: scLabels,
    },
    deviceAlreadyRegistered: {
      config: deviceAlreadyRegistered,
      data: {},
      labels: scLabels,
    },
    modalConfig,
  };

  return (
    baseConfig && (
      <div>
        <div id="main-content">
          <DeviceRegistrationWrapper
            {...baseConfig}
            ref={deviceRegistrationWrapperRef}
          />
        </div>
      </div>
    )
  );
};

export default withFeature('featureEnabler/deviceRegistration')(
  DeviceRegistration,
);
