import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withFeature } from 'flagged';
import { SimpleSearch } from '../../ui/Search/SimpleSearch.js';
import featureFlagService from '../../services/featureFlagService';
import { routes } from '../../config.js';
import { getSimpleSearchConfig } from './searchConfig.js';
import { getLabelsForFields } from '../../utils/labelHelper';

const queryStrings = () => {
  return new URLSearchParams(useLocation().search);
};

const Search = ({ rendering }) => {
  const labels = getLabelsForFields(rendering.fields);
  const featureFlags = featureFlagService.getFeatureFlags();
  const history = useHistory();

  const hasAdvancedSearch = featureFlags.advancedSearch;

  const onSearch = (values) => {
    history.push(
      `${routes.dpx.patient}?${new URLSearchParams(
        values,
      ).toString()}`,
    );
  };

  const onAdvancedSearch = () =>
    history.push(routes.dpx.advancedSearch);

  const config = getSimpleSearchConfig(
    onSearch,
    onAdvancedSearch,
    hasAdvancedSearch,
  );

  const data = {
    greetings: '',
    initialValues: {
      q: queryStrings().get('q') || '',
      searchType: queryStrings().get('searchType') || 'patient',
    },
  };

  return <SimpleSearch config={config} data={data} labels={labels} />;
};

export default withFeature('featureEnabler/simpleSearch')(Search);
