import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import cookieService from './services/cookieService';
import { ACCESS_TOKEN_COOKIE, routes } from './config';

const PrivateRoute = ({ render, path, key }) => {
  const accessToken = cookieService.getCookie(ACCESS_TOKEN_COOKIE);
  const queryString = useLocation().search;

  if (accessToken) {
    return <Route key={key} path={path} render={render} />;
  }

  // User is not authenticated. Redirect back to authorizer. preserve and query params
  return (
    <Redirect to={{
        pathname: routes.dpx.authorizer,
        search: queryString
      }}
    />
  );
};

export default PrivateRoute;
