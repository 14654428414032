import React, { useEffect, useState } from 'react';
import {
  TextInput,
  SelectInput,
  Button,
  Row,
  Col,
  Icon,
  Title,
  Text,
  OverlayTrigger,
  Tooltip,
} from '@cochlear-design-system/foundation';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const SimpleSearch = ({ config, labels, data }) => {
  const initialValues = {
    q: data.initialValues.q || '',
    ...config.selectInputs.reduce(
      (acc, selectInput) => ({
        ...acc,
        [selectInput.name]:
          data.initialValues[selectInput.name] ||
          selectInput.items[0].value,
      }),
      {},
    ),
  };

  const [searchType, setSearchType] = useState(
    initialValues.searchType,
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const requiredMessage =
    labels[
      config.textInput[searchType].validators.find(
        ({ type }) => type === 'required',
      ).message
    ];
  const formik = useFormik({
    initialValues,
    onSubmit: (value) => config.onSearch(value),
    validationSchema: Yup.object({
      q: config.textInput[searchType].validators
        .reduce((acc, { type, value, message }) => {
          if (type === 'required') return acc;
          return acc[type](value, labels[message]);
        }, Yup.string())
        .trim(requiredMessage)
        .required(requiredMessage),
    }),
  });

  const injectSelectOptionLabels = (options) =>
    options.map(({ label, ...item }) => {
      return {
        label: labels[label],
        ...item,
      };
    });

  // Force submit when initial value is detected
  useEffect(() => {
    if (data.initialValues.q !== '') {
      formik.handleSubmit();
    }
  }, []);

  useEffect(() => {
    formik.validateForm();
  }, [searchType]);

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formik.errors]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      // detect Enter key and submit
      if (e.keyCode === 13) {
        e.preventDefault();
        formik.handleSubmit();
      }
    };

    if (typeof document !== 'undefined') {
      // eslint-disable-next-line no-undef
      document.addEventListener('keydown', keyDownHandler);
    }

    return () => {
      if (typeof document !== 'undefined') {
        // eslint-disable-next-line no-undef
        document.removeEventListener('keydown', keyDownHandler);
      }
    };
  }, []);

  return (
    <div className="ccl__container">
      <div className="ccl__simple-search">
        {data.greetings && (
          <div className="ccl__simple-search--greetings">
            <Title
              content={labels.greetings}
              size="heading-3"
              tag="h3"
            />
          </div>
        )}
        <div className="ccl__simple-search--search-box">
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={3}>
                <Row>
                  {config.selectInputs.map((selectInput) => (
                    <Col key={selectInput.name}>
                      <SelectInput
                        dataList={injectSelectOptionLabels(
                          selectInput.items,
                        )}
                        name={selectInput.name}
                        defaultValue={formik.values[selectInput.name]}
                        onChange={(name, value) => {
                          if (name === 'searchType')
                            setSearchType(value);
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col md={6}>
                <div className="ccl__simple-search--search-input">
                  <div className="ccl__simple-search--search-icon">
                    <Icon type="search" size="sm" />
                  </div>
                  <TextInput
                    name="q"
                    value={formik.values.q}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.q && formik.errors.q)}
                    errorMsg={formik.errors.q}
                    promptText={
                      labels[config.textInput[searchType].promptText]
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>
              <Col md={3}>
                <OverlayTrigger
                  show={!buttonDisabled ? false : undefined}
                  overlay={
                    <Tooltip id="simple-search-submit-tooltip">
                      <Text
                        className="ccl__simple-search__fields__submit__tooltipText"
                        type="x-small-body-text"
                        isHTML
                        content={
                          labels[
                            config.submitButtonTooltip[searchType]
                          ]
                        }
                      />
                    </Tooltip>
                  }
                >
                  <div
                    className={`ccl__simple-search__fields__submit ${
                      buttonDisabled
                        ? 'ccl__simple-search__fields__submit--disabled'
                        : ''
                    }`}
                  >
                    <Button
                      className="ccl__simple-search__fields__submit__button"
                      text={
                        labels[config.submitButtonText[searchType]]
                      }
                      variant="digital-primary"
                      onClick={formik.handleSubmit}
                      disabled={buttonDisabled}
                      data-analytics="search_patient_search"
                    />
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          </form>
        </div>
        <div className="ccl__simple-search--advanced-search">
          <Text
            content={labels[config.advancedSearchText]}
            type="link-small-text"
            onClick={() => config.onAdvancedSearch()}
            data-analytics="view_locate_patient_search"
          />
        </div>
      </div>
    </div>
  );
};
