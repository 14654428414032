import React from 'react';
import { useFeature } from 'flagged';

/**
 *
 * Wrap release version controlled feature with:
 * <FeatureVersion releaseVersion="1.1">
 *
 * </FeatureVersion>
 */

const FeatureVersion = ({ releaseVersion, children }) => {
  const release = useFeature('release');
  if (release) {
    const { version, enabled } = release;
    if (parseFloat(releaseVersion) <= version && enabled) {
      return <>{children}</>;
    }
  }
  return null;
};

export default FeatureVersion;
