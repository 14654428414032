export const getDeviceType = (deviceType) => {
  const SPEECH_PROCESSOR = 'SPEECH_PROCESSOR';
  const IMPLANT = 'IMPLANT';
  const ACCESSORY = 'ACCESSORY';
  const REMOTE_ASSISTANT = 'REMOTE_ASSISTANT';
  const UNSUPPORTED_DEVICE = 'UNSUPPORTED_DEVICE';

  switch (deviceType.toUpperCase()) {
    case 'SPEECH PROCESSOR':
      return SPEECH_PROCESSOR;
    case 'IMPLANT':
      return IMPLANT;
    case 'REMOTE ASSISTANT':
      return REMOTE_ASSISTANT;
    case 'ACCESSORY':
      return ACCESSORY;
    default:
      return UNSUPPORTED_DEVICE;
  }
};
