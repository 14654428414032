import cookieService from '../services/cookieService';
import tokenService from '../services/tokenService';
import {
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  PROVIDER_DETAILS_COOKIE,
} from '../config';

export function setIdpParam(url) {
  return (
    url + (url.indexOf('?') !== -1 ? '&idp=auth0' : '?idp=auth0')
  );
}

export async function exchangeProviderToken(providerId) {
  // Get Provider Token
  const { accessToken, refreshToken, expiresIn } =
    await tokenService.getProviderToken(providerId);

  if (accessToken) {
    const expiresInMins = expiresIn ? expiresIn / 60 : 30;

    cookieService.setCookie(
      ACCESS_TOKEN_COOKIE,
      accessToken,
      expiresInMins,
    );
    cookieService.setCookie(
      REFRESH_TOKEN_COOKIE,
      refreshToken,
      expiresInMins,
    );
  } else {
    // token call unsuccessful. delete the provider list from local storage
    cookieService.removeCookie(PROVIDER_DETAILS_COOKIE);
  }

  return accessToken;
}
