import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Error as ErrorComponent } from '../../ui/Error/Error.js';
import { dataMapper } from './dataMapper.js';
import { getLabelsForFields } from '../../utils/labelHelper';
import { auth0LoginUrl } from '../../config';

const Error = ({ rendering }) => {
  const labels = getLabelsForFields(rendering.fields);
  const error = useSelector((state) => state.error);
  let config = dataMapper(rendering.fields);
  const history = useHistory();
  const { isFullPageError } = config;

  if (isFullPageError) {
    config.handleCallback = () => {
      window.location.replace(auth0LoginUrl);
    };
  } else {
    config.handleCallback = () => {
      history.goBack();
    };
  }
  if (error.code) {
    config = error;
  }
  return (
    (error || isFullPageError) && (
      <ErrorComponent config={config} labels={labels} data={{}} />
    )
  );
};

export default Error;
