import { NOTIFICATION_STATUS_FAILURE } from './types';
import { logger } from '../logger';

const log = logger();

const notificationStatus =
  (type, statusPayload) => async (dispatch) => {
    try {
      dispatch({
        type,
        payload: statusPayload,
      });
    } catch (err) {
      log.error('Error dispatching notification status', err);
      dispatch({
        type: NOTIFICATION_STATUS_FAILURE,
        payload: err,
      });
    }
  };

export default notificationStatus;
