export const errorConfig = {
  title: 'labels.error.title',
  text: 'labels.error.text',
  buttons: [
    {
      name: '',
      variant: 'tertiary',
      text: '',
      icon: '',
      link: '',
      action: '',
      useCallback: false,
    },
  ],
  imgSrc:
    'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
  imgAlt: 'labels.error.imgAlt',
  imgWidth: 116,
};
