export const languageMap = {
  'en-CA': 'en-US',
  fr: 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  pt: 'pt-BR',
  'pt-PT': 'pt-BR',
  zh: 'zh-CN',
  'zh-TW': 'zh-HK',
  nl: 'nl-NL',
  de: 'de-DE',
  'de-AT': 'de-DE',
  'de-LI': 'de-DE',
  'de-CH': 'de-DE',
  it: 'it-IT',
  'it-CH': 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  es: 'es-ES',
  'es-AR': 'es-ES',
  'es-CL': 'es-ES',
  'es-CO': 'es-ES',
  'es-CR': 'es-ES',
  'es-HN': 'es-ES',
  'es-419': 'es-ES',
  'es-MX': 'es-ES',
  'es-PE': 'es-ES',
  'es-US': 'es-ES',
  'es-UY': 'es-ES',
  'es-VE': 'es-ES',
  sv: 'sv-SE',
  tr: 'tr-TR',
};
