import {
  defaultBrowserLanguage,
  supportedLanguages,
  supportedLanguagesLoginFlow,
} from '../config';
import { languageMap } from '../data/languageMap';

const loginFlowPages = [
  'select-clinic',
  'authorize',
  'initialize',
  'error',
];

export const getDefaultLanguage = () => {
  const currentPage = window.location.pathname
    .split('/')
    .filter(Boolean)
    .pop();

  const validLanguages = loginFlowPages.includes(currentPage)
    ? supportedLanguagesLoginFlow
    : supportedLanguages;

  const chosenLanguage =
    navigator?.language ||
    localStorage.getItem('browserLang') ||
    defaultBrowserLanguage;
  let defaultLanguage = chosenLanguage;

  if (
    !validLanguages
      .map((lang) => lang.toLowerCase())
      .includes(chosenLanguage.toLowerCase())
  ) {
    defaultLanguage = 'en-GB';
  }
  if (
    loginFlowPages.includes(currentPage) &&
    languageMap[chosenLanguage]
  ) {
    defaultLanguage = languageMap[chosenLanguage];
  }

  return defaultLanguage;
};
