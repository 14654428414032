import React from 'react';
import { Redirect as ClientRedirect } from 'react-router-dom';

const ExternalRedirect = ({ to, push }) => {
  if (push) {
    window.location.assign(to);
  } else {
    window.location.replace(to);
  }
  return <></>;
};

const Redirect = ({
  to,
  push,
  country = '',
  lang = '',
  refresh = false,
}) => {
  if (to.includes('http')) {
    return <ExternalRedirect push={push} to={to} />;
  }

  const dest = country && lang ? `/${country}/${lang}/${to}` : to;

  return refresh ? (
    <ExternalRedirect push={push} to={dest} />
  ) : (
    <ClientRedirect push={push} to={dest} />
  );
};

export default Redirect;
