import {
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILURE,
} from '../actions/types';

const initialState = null;

export default function userDetailsReducer(
  state = initialState,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case USER_DETAILS_SUCCESS:
      return payload;
    case USER_DETAILS_FAILURE:
      return false;

    default:
      return state;
  }
}
