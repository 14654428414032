import React, { useState } from 'react';
import { MyProfile } from '@cochlear-design-system/features.universalHeader';
import {
  Title,
  Text,
  Icon,
} from '@cochlear-design-system/foundation';
import { Dropdown } from '../Dropdown/Dropdown';
import { TopNav } from './TopNav';

export const Header = ({ config, data, labels }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const navBarClasses = ['ccl__nav-bar', 'ccl__nav-bar__dpx'];

  return (
    <>
      <div className={navBarClasses.join(' ')}>
        <div className="ccl__container">
          <div className="ccl__nav-bar__menu ccl__nav-bar__dpx__menu">
            <div className="ccl__nav-bar__dpx__menu__start">
              {config.showLogo && (
                <img
                  src={config.logoSrc}
                  alt={config.logoAlt}
                  className="ccl__nav-bar__logo ccl__nav-bar__dpx__logo"
                />
              )}
              {config.showSeparator && (
                <div className="ccl__nav-bar__dpx__separator" />
              )}
              {config.showAppName && (
                <div className="ccl__nav-bar__dpx__appName">
                  <Title
                    content={labels[config.AppName]}
                    size="heading-5"
                    tag="h5"
                  />
                </div>
              )}
            </div>
            <div className="ccl__nav-bar__menu__end ccl__nav-bar__dpx__menu__end">
              {config.showClinicSelector && (
                <div className="ccl__nav-bar__dpx__clinic-selector">
                  {data.clinicSelector.items.length === 1 ? (
                    <div className="ccl__nav-bar__dpx__clinic-selector--single-item">
                      <Text
                        content={data.clinicSelector.items[0].content}
                        type="small-body-text"
                      />
                    </div>
                  ) : (
                    <Dropdown
                      data={data.clinicSelector}
                      config={{
                        onSelectItem:
                          config.clinicSelector.onSelectItem,
                      }}
                    />
                  )}
                </div>
              )}

              {config.showMyProfile && (
                <div
                  onMouseLeave={() => setOpen(false)}
                  className="ccl__nav-bar__dpx__my-profile--wrapper"
                >
                  <MyProfile
                    active={open}
                    myProfileButton={{
                      initials: data.button.initials,
                      onClick: toggleOpen,
                      buttonIconSize: 'xs',
                    }}
                    myProfileMenu={{
                      showHeader: config.profileMenu.showHeader,
                      useHeaderVariant: true,
                      header: {
                        content1: data.menuHeader.content1,
                        content2: data.menuHeader.content2,
                        showPictogram:
                          config.profileMenu.header.showPictogram,
                        pictogramSrc:
                          config.profileMenu.header.pictogramSrc,
                        pictogramAlt:
                          config.profileMenu.header.pictogramAlt,
                      },
                      links: config.profileMenu.links,
                      showWhatsNew: config.profileMenu.showWhatsNew,
                      whatsNew: {
                        text: config.profileMenu.whatsNew.text,
                        onClick: config.profileMenu.whatsNew.onClick,
                      },
                      menuLinkSize: 'small-body-text',
                    }}
                  />
                </div>
              )}
              {config.showCloseLink && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className="ccl__dropdown__toggle ccl__linktag"
                  onClick={config.closeLinkClick}
                  data-analytics={config.analytics}
                >
                  <Icon type="close" size="sm" />
                  <Text
                    content={config.closeLink}
                    type="small-body-text"
                    className="ccl__nav-bar__dpx__closeLink"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!config.hideTopNav && <TopNav config={config} />}
    </>
  );
};
