/* eslint-disable no-console */
const MAX_LOGS = 100;

/**
 *
 * @returns {Array} shouldLog
 */
const shouldLog = () => {
  const env = process.env.NODE_ENV.toLowerCase() || 'development';
  if (env === 'production' || env === 'prod') {
    return ['info', 'error'];
  }
  return ['debug', 'log', 'info', 'warn', 'error'];
};

/**
 *
 * @returns {Object} logStore
 */
const logStore = () => {
  const store = JSON.parse(sessionStorage.getItem('logStore')) || [];
  return {
    set: (log) => {
      if (store.length >= MAX_LOGS) {
        store.shift();
      }
      store.push(log);
      sessionStorage.setItem('logStore', JSON.stringify(store));
    },
    get: () => {
      return store;
    },
  };
};

const logViewer = logStore().get;

/**
 * Log Formater
 * @param {String} type
 * @param {String} message
 * @param {Object} data
 * @returns {Object} log
 * @returns {String} log.type
 * @returns {String} log.message
 * @returns {Object} log.data
 */
const logFormater = (type, message, data = null) => {
  const request = data?.request || null;
  const response = data?.response || null;
  const correlationId =
    data?.response?.headers['x-correlation-id'] || null;

  const log = {
    timestamp: new Date().toISOString(),
    type,
    message,
    request,
    response,
    correlationId,
    data,
  };
  return log;
};

/**
 * Logger
 * @returns {Object} logger
 * @returns {Function} logger.debug
 * @returns {Function} logger.log
 * @returns {Function} logger.info
 * @returns {Function} logger.warning
 * @returns {Function} logger.error
 */
const logger = () => {
  const store = logStore();

  return {
    debug: (message, data = null) => {
      if (shouldLog('debug')) {
        const log = logFormater('DEBUG', message, data);
        console.debug(log);
      }
    },
    log: (message, data = null) => {
      if (shouldLog('log')) {
        const log = logFormater('LOG', message, data);
        console.log(log);
      }
    },
    info: (message, data = null) => {
      if (shouldLog('info')) {
        const log = logFormater('INFO', message, data);
        console.info(log);
      }
    },
    warning: (message, data = null) => {
      if (shouldLog('warn')) {
        const log = logFormater('WARNING', message, data);
        console.warn(log);
      }
    },
    error: (message, data = null) => {
      const log = logFormater('ERROR', message, data);
      store.set(log);
      if (shouldLog('error')) {
        console.error(log);
      }
    },
  };
};

export { logger, logViewer };
