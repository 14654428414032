import React, { useState, useEffect } from 'react';
import { DropdownToggle } from './DropdownToggle';
import { DropdownItems } from './DropdownItems';

export const Dropdown = ({ data, config }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(data.selected);
  const [selectedContent, setSelectedContent] = useState();

  useEffect(() => {
    const i = data.items.filter((item) => item.id === selected);
    setSelectedContent(i[0].content);
  }, [selected]);

  return (
    <div
      className="ccl__dropdown"
      onMouseLeave={() => setOpen(false)}
    >
      <DropdownToggle
        content={selectedContent}
        open={open}
        setOpen={setOpen}
      />
      {open && (
        <DropdownItems
          items={data.items}
          config={config}
          selected={selected}
          setSelected={setSelected}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};
