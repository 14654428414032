export const getSimpleSearchConfig = (
  onSearch,
  onAdvancedSearch,
  hasAdvancedSearch,
) => ({
  onSearch,
  onAdvancedSearch,
  textInput: {
    patient: {
      promptText: 'labels.search.textInput.patient.promptText',
      validators: [
        {
          type: 'min',
          value: 1,
          message:
            'labels.search.errorMessages.patients.patient_required',
        },
        {
          type: 'max',
          value: 130,
          message:
            'labels.search.errorMessages.patients.patient_maxChars',
        },
        // {
        //   type: 'matches',
        //   value: /^[a-zA-ZÀ-ȕ\d'\- *%]+$/g,
        //   message: 'labels.search.errors.specialChars',
        // },
        // {
        //   type: 'matches',
        //   value: /^\*?%?[a-zA-ZÀ-ȕ\d'\- ]+\*?%?[a-zA-ZÀ-ȕ\d'\- ]?$/g,
        //   message: 'labels.search.errors.patient.required',
        // },
        {
          type: 'required',
          message:
            'labels.search.errorMessages.patients.patient_required',
        },
      ],
    },
    device: {
      promptText: 'labels.search.textInput.device.promptText',
      validators: [
        {
          type: 'required',
          message:
            'labels.search.errorMessages.devices.device_required',
        },
      ],
    },
  },
  selectInputs: [
    {
      name: 'searchType',
      items: [
        {
          label: 'labels.search.searchType.patient',
          value: 'patient',
        },
        /*
        {
          label: 'labels.search.searchType.device',
          value: 'device',
        },
        */
      ],
    },
  ],
  submitButtonText: {
    patient: 'labels.search.submitButton.patient.text',
    device: 'labels.search.submitButton.device.text',
  },
  submitButtonTooltip: {
    patient: 'labels.search.submitButton.patient.tooltip',
    device: 'labels.search.submitButton.device.tooltip',
  },

  advancedSearchText: hasAdvancedSearch
    ? 'labels.search.locatePatientText'
    : '',
});

