import axios from 'axios';
import { providerListEndpoint } from '../config';
import getHeaders from '../utils/getHeaders';
import { logger } from '../logger';

const log = logger();

const getResourceContent = async (
  item = '893207E5-E0CB-4B36-8526-D5B1EE75AE41',
  lang = 'en',
  site = 'dpx',
) => {
  try {
    const url = `${providerListEndpoint}/jss/layout?item=${item}&lang=${lang}&site=${site}`;
    const response = await axios.get(url, getHeaders());
    return response.data;
  } catch (err) {
    log.error('Error getting resource content', err);
    return null;
  }
};

const resourceService = {
  getResourceContent,
};

export default resourceService;
