export const regionMap = [
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    hub: 'Global',
    region: 'GLOBAL',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa, US',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'AX',
    countryName: 'Aland Islands',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    hub: 'Turkics',
    region: 'EMEA',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    hub: 'South Asia',
    region: 'APAC',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthélemy',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'BQ',
    countryName: 'Bonaire, Sint Eustatius and Saba',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    hub: 'South Asia',
    region: 'APAC',
  },
  {
    countryCode: 'BV',
    countryName: 'Bouvet Island',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'CD',
    countryName: 'Congo, the Democratic Republic of the',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CG',
    countryName: 'Congo, the Republic of the',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'CI',
    countryName: "Cote d'Ivoire",
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    hub: 'China',
    region: 'APAC',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    hub: 'Colombia',
    region: 'CLASA',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'CW',
    countryName: 'Curaçao',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    hub: 'Germany',
    region: 'EMEA',
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia, Federated States of',
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    hub: 'Turkics',
    region: 'EMEA',
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Isl',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'GU',
    countryName: 'Guam, US',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    hub: 'Hong Kong',
    region: 'APAC',
  },
  {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    hub: 'South Asia',
    region: 'APAC',
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'IR',
    countryName: 'Iran, Islamic Republic of',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    hub: 'Italy',
    region: 'EMEA',
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    hub: 'Japan',
    region: 'APAC',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'KP',
    countryName: "Korea, Democratic People's Republic of",
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'KR',
    countryName: 'Korea, Republic of',
    hub: 'Korea',
    region: 'APAC',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova, Republic of',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin (French part)',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'MK',
    countryName: 'North Macedonia',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    hub: 'China',
    region: 'APAC',
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    hub: 'Hong Kong',
    region: 'APAC',
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands, US',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    hub: 'South Asia',
    region: 'APAC',
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    hub: 'Mexico',
    region: 'CLASA',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    hub: 'South Asia',
    region: 'APAC',
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn',
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico, US',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine, State of',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    hub: 'EPICS',
    region: 'EMEA',
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'RE',
    countryName: 'Reunion',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena, Ascension and Tristan da C',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    hub: 'Nordics',
    region: 'EMEA',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    hub: 'CEE (EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    hub: 'Italy',
    region: 'EMEA',
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten (Dutch part)',
    hub: 'Benelux',
    region: 'EMEA',
  },
  {
    countryCode: 'SY',
    countryName: 'Syrian Arab Republic',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'TL',
    countryName: 'Timor-Leste',
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    hub: 'Turkics',
    region: 'EMEA',
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    hub: 'Taiwan',
    region: 'APAC',
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican City, Holy See',
    hub: 'Italy',
    region: 'EMEA',
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    hub: 'CLASA Regional',
    region: 'CLASA',
  },
  {
    countryCode: 'VG',
    countryName: 'Virgin Islands, British',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'VI',
    countryName: 'Virgin Islands, US',
    hub: 'CAM',
    region: 'CAM',
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    hub: 'AGM',
    region: 'APAC',
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    hub: 'APAC Regional',
    region: 'APAC',
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    hub: 'ANZ',
    region: 'APAC',
  },
  {
    countryCode: 'XK',
    countryName: 'Kosovo',
    hub: 'CEE (Non-EU)',
    region: 'EMEA',
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    hub: 'France',
    region: 'EMEA',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    hub: 'Atlantics',
    region: 'EMEA',
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    hub: 'MEA',
    region: 'EMEA',
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    hub: 'MEA',
    region: 'EMEA',
  },
];
