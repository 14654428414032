/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  Text,
  Spinner,
  Button,
  IconButton,
} from '@cochlear-design-system/foundation';
import { EquipmentAll } from './EquipmentAll';
import { EquipmentSummary } from './EquipmentSummary';
import { Error } from '../Error/Error';
import { TaskBar } from '../TaskBar/TaskBar';

export const EquipmentWrapper = ({
  config,
  data,
  labels,
  leftData,
  rightData,
  unknownData,
}) => {
  const [view, setView] = useState('summary');
  const [loading, setLoading] = useState(true);

  const switchView = (newView) => {
    config.toolbarConfig.resetDeviceFilters();
    setView(newView);
  };

  useEffect(() => {
    if ('isLoading' in config) {
      setLoading(config.isLoading);
    }
  }, [config.isLoading]);

  if (loading) {
    return (
      <div className="ccl__spinner">
        <Spinner animation="border" />
        <Text content={labels[config.labels.loadingEquipment]} />
      </div>
    );
  }

  if (config.hasError) {
    return (
      <Error config={config.errorConfig} labels={labels} data={{}} />
    );
  }
  if (config.isEmpty) {
    return (
      <>
        {config.refreshButton && (
          <div
            style={{
              float: 'right',
              marginRight: '32px',
              marginTop: '32px',
            }}
          >
            <IconButton
              variant={config.refreshButton.variant}
              icon={config.refreshButton.icon}
              handleClick={config.refreshButton.onClick}
              analytics={config.refreshButton.analytics}
            />
          </div>
        )}
        <div className="ccl__align-cm5">
          <Text content={labels[config.labels.emptyEquipment]} />
          <br />
          <Button
            text={labels[config.labels.addDevice]}
            variant="brand-primary"
            icon="add"
            iconPosition="leading"
            size="medium"
            onClick={config.emptyEquipment.button.onClick}
            data-analytics={config.emptyEquipment.button.analytics}
          />
        </div>
      </>
    );
  }

  config.taskBarConfig.labels.switchText =
    view === 'summary'
      ? config.taskBarConfig.labels.summary.switchText
      : config.taskBarConfig.labels.allDevices.switchText;

  config.taskBarConfig.labels.taskBarTitle =
    view === 'summary'
      ? config.taskBarConfig.labels.summary.taskBarTitle
      : config.taskBarConfig.labels.allDevices.taskBarTitle;

  config.taskBarConfig.analyticsTag =
    view === 'summary'
      ? config.taskBarConfig.analytics.summary
      : config.taskBarConfig.analytics.allDevices;

  return (
    <div className="ccl__align-cm2">
      <TaskBar
        config={config.taskBarConfig}
        labels={labels}
        view={view}
        switchView={switchView}
      />
      <br />

      {view === 'summary' ? (
        <EquipmentSummary
          config={config}
          labels={labels}
          leftData={leftData}
          rightData={rightData}
          unknownData={unknownData}
        />
      ) : (
        <EquipmentAll config={config} data={data} labels={labels} />
      )}
    </div>
  );
};
