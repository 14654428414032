import { USER_DETAILS_SUCCESS, USER_DETAILS_FAILURE } from './types';
import { logger } from '../logger';

const log = logger();

const userDetails = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: user,
    });
  } catch (err) {
    log.error('Error dispatching user details', err);
    dispatch({
      type: USER_DETAILS_FAILURE,
      payload: err,
    });
  }
};

export default userDetails;
