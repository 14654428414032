/**
 * Wrapper functions for window.localStorage, providing methods to set, get, update, and remove items.
 * Has added functionality of setting a time-to-live (TTL) for items.
 * When getting items, if TTL has expired, it will remove the localStorage item and return null.
 *
 */
/** */

/**
 * Sets an item in localStorage with an optional time-to-live (TTL).
 *
 * @param {string} key - The key under which the item is stored.
 * @param {any} value - The value to store.
 * @param {number} [ttl=2592000000] - The time-to-live in milliseconds (default is 30 days).
 * @returns {void}
 */
const setItem = (key, value, ttl = 30 * 24 * 60 * 60 * 1000) => {
  const now = new Date();

  const item = {
    expiry: now.getTime() + ttl,
    value,
  };
  return localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Updates an existing item in localStorage by merging the existing value with the provided data object.
 *
 * @param {string} key - The key of the item to update.
 * @param {Object} dataObj - The data object to merge with the existing value.
 * @returns {any|null} - The updated value or null if the item does not exist or is invalid.
 */
const updateItem = (key, updateObj) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;
  let item;
  try {
    item = JSON.parse(itemStr);
  } catch (e) {
    return null;
  }
  if (!item.expiry) {
    // no expiry, must be old format
    const newItem = { ...item, ...updateObj };
    localStorage.setItem(key, JSON.stringify(newItem));
    return newItem;
  }

  const newItem = {
    expiry: item.expiry,
    value: { ...item.value, ...updateObj },
  };
  localStorage.setItem(key, JSON.stringify(newItem));
  return newItem.value;
};

/**
 * Retrieves an item from localStorage.
 *
 * @param {string} key - The key of the item to retrieve.
 * @param {boolean} [honourExpiry=false] - Whether to honour the expiry time of the item.
 * @returns {any|null} - The retrieved value or null if the item does not exist, is invalid, or is expired.
 */
const getItem = (key, honourExpiry = false) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  let item;
  try {
    item = JSON.parse(itemStr);
  } catch (e) {
    return null;
  }
  if (!item.expiry) {
    // Note: specific for cpd provider list
    // these is no expiry, so must be old format
    if ('providerList' in item) {
      // if we are honouring the expiry, return null so that new list can be refetched from backend
      // otherwise return item so that the app still works with old format
      return honourExpiry ? null : item;
    }
    return null;
  }

  const now = new Date();
  if (honourExpiry && now.getTime() > item.expiry) {
    // Item is expired, delete the item from storage
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

/**
 * Removes an item from localStorage.
 *
 * @param {string} key - The key of the item to remove.
 * @returns {void}
 */
const removeItem = (key) => {
  return localStorage.removeItem(key);
};

const localStorageService = {
  setItem,
  getItem,
  removeItem,
  updateItem,
};
export default localStorageService;
