/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Title, Text } from '@cochlear-design-system/foundation';

// eslint-disable-next-line no-unused-vars
const SingleTabView = ({ title, summary, isHTML }) => {
  return (
    <div id="story--foundation-containers-tabs--basic-tabs">
      <div className="ccl-l-tabs">
        <div className="ccl-l-tabs__rowWrapper">
          <div className="ccl-l-tabs__tabs-row ccl-l-tabs__tabs-row--align--left">
            <div
              id="tab-with-path"
              className="ccl-l-tabs__tab ccl-l-tabs__tab--full-width ccl-l-tabs__tab--is-active "
              style={{ minWidth: '100px' }}
            />
          </div>
        </div>
        <div className="ccl-l-tabs__content ccl-l-tabs__content--card">
          <div className="ccl-l-tabs__content__inner">
            <div
              style={{
                margin: '24px',
              }}
            >
              {title ? (
                <div
                  data-nw-state="read"
                  className="ccl-e-detail-action-bar"
                  style={{
                    margin: '2rem auto 1rem auto',
                    textAlign: 'center',
                  }}
                >
                  <div className="ccl-e-detail-action-bar__title">
                    <Title
                      content={title}
                      size="heading-4"
                      tag="h4"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              <Text content={summary} type="body-text" isHTML />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTabView;
