/* eslint-disable react/prop-types */
import { ApolloProvider } from '@apollo/client';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
// import { PersistGate } from 'redux-persist/integration/react';
import PrivateRoute from './PrivateRoute';
import RouteHandler from './RouteHandler';
import Authorizer from './components/Authorizer';
import Initializer from './components/Initializer';
import Outbound from './components/Outbound';
import Unauthorized from './components/Unauthorized';
import { routes } from './config';
import store from './store';
import componentFactory from './temp/componentFactory';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /us/en/path, or /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:country([a-z]{2})/:lang([a-z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ssrRenderComplete: false,
    };

    if (props.ssrState) {
      this.sitecoreContext =
        props.ssrState.sitecore && props.ssrState.sitecore.route
          ? {
              route: props.ssrState.sitecore.route,
              itemId: props.ssrState.sitecore.route.itemId,
              ...props.ssrState.sitecore.context,
            }
          : props.ssrState.sitecore.context;
    } else {
      this.sitecoreContext = null;
    }
  }

  componentDidMount() {
    this.setSsrRenderComplete(true);
  }

  setSsrRenderComplete = (ssrRenderComplete) =>
    this.setState({
      ssrRenderComplete,
    });

  render() {
    const { path, Router, graphQLClient } = this.props;

    return (
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
        <ApolloProvider client={graphQLClient}>
          <SitecoreContext
            componentFactory={componentFactory}
            context={this.sitecoreContext}
          >
            <Router location={path} context={{}}>
              <Switch>
                <Route
                  exact
                  path={routes.dpx.unauthorized}
                  component={Unauthorized}
                />
                <Route
                  exact
                  path={routes.dpx.authorizer}
                  component={Authorizer}
                />
                <Route
                  exact
                  path={routes.dpx.initializer}
                  component={Initializer}
                />
                <Route
                  exact
                  path={routes.outbound}
                  component={Outbound}
                />
                <Route exact path="/" component={Authorizer} />
                {routePatterns.map((routePattern) => (
                  <PrivateRoute
                    key={routePattern}
                    path={routePattern}
                    render={(props) => (
                      <RouteHandler
                        route={props}
                        ssrRenderComplete={
                          // eslint-disable-next-line react/destructuring-assignment
                          this.state.ssrRenderComplete
                        }
                      />
                    )}
                  />
                ))}
              </Switch>
            </Router>
          </SitecoreContext>
        </ApolloProvider>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}

export default AppRoot;
