import React from 'react';
import {
  Title,
  Text,
  Button,
} from '@cochlear-design-system/foundation';
import Header from './components/Header/index';
import pictogram from './assets/images/404error.svg';

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = ({homePage}) => (
  <>
    <Header />
    <div className="columns is-centered m-6">
      <div className="column is-half">
        <div className="block">
          <img src={pictogram} alt="icon" />
        </div>
        <Title
          content="That's strange, we can't find that page."
          size="heading-3"
          tag="h3"
        />
        <div className="block">
          <Text content="Either the page was removed or moved, or the web address is incorrect." />
          <Text
            content="Head back to our home page"
            isHTML
          />
          {/* <Link to="/home">
            <Text content="home page" type="interactive-text" />
          </Link> */}
        </div>
        <div className="block mt-6">&nbsp;</div>
        <div className="block mt-6">
          <Button icon="chevron-right" text="Home" link={homePage} />
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
