import React from 'react';
import {
  Title,
  Row,
  Col,
  IconButton,
  Button,
} from '@cochlear-design-system/foundation';

export const TaskBar = ({ config, labels, view, switchView }) => {
  const newView = view === 'summary' ? 'all' : 'summary';
  return (
    <Row className="taskbar-row">
      <Col md={6}>
        <Title
          content={labels[config.labels.taskBarTitle]}
          size="heading-3"
          tag="h3"
        />
      </Col>
      <Col md={6} className="taskbar-col">
        <Button
          size="medium"
          style={{
            margin: '8px',
          }}
          text={labels[config.labels.switchText]}
          variant="secondary"
          onClick={() => switchView(newView)}
          data-analytics={config.analyticsTag}
        />
        <IconButton
          id="brand-primary"
          variant="brand-primary"
          size="large"
          icon="add"
          handleClick={config.button.onClick}
          analytics="add_devices_view_patient"
        />
        {config.refreshButton && (
          <IconButton
            variant={config.refreshButton.variant}
            icon={config.refreshButton.icon}
            handleClick={config.refreshButton.onClick}
            analytics="refresh_devices_view_patient"
          />
        )}
      </Col>
    </Row>
  );
};
