import axios from 'axios';
import getHeaders from '../../utils/getHeaders';
import { providerListEndpoint } from '../../config';
import { logger } from '../../logger';

const log = logger();

const updateNotification = async (
  providerId,
  professionalId,
  payload,
) => {
  const { requestId, ...requestBody } = payload;

  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/professionals/${professionalId}/notifications/${requestId}`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error updating notifications', err);
    return null;
  }
};

const updateNotificationsService = {
  updateNotification,
};

export default updateNotificationsService;
