import React, { useState, useEffect } from 'react';
import { Modal } from '@cochlear-design-system/foundation';
import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';
import { ClinicForm } from './ClinicForm';
import { ClinicResult } from './ClinicResult';

export const FindClinicModal = ({
  scrollable,
  modalConfig,
  tableConfig,
  contactCustomerServiceView,
  clinicField,
  countryField,
  errorView,
  searchButton,
  show,
  onHide,
  labels,
}) => {
  //* for clinic input and clinic error
  const clinicFieldValue = '';
  const clinicFieldError = false;
  const [clinicValue, setClinicValue] = useState(clinicFieldValue);
  const [clinicError, setClinicError] = useState(clinicFieldError);

  //* for table input and table error
  const [tableData, setTableData] = useState();
  const [dataTableError, setDataTableError] = useState(false);

  //* for isLoading
  const [isLoading, setIsLoading] = useState(false);

  //* for country input
  const currCountry = '';
  const [countryValue, setCountryValue] = useState(currCountry);

  useEffect(() => {
    const original = tableConfig.events.onRowClick;
    // eslint-disable-next-line no-param-reassign
    tableConfig.events.onRowClick = function JsFunction(...args) {
      const result = original.apply(this, args);
      onHide();
      return result; // Or something else if you prefer
    };
  }, []);

  const hideModal = () => {
    setTableData({});
    setDataTableError(false);
    setClinicError(false);
    setClinicValue('');
    setCountryValue(countryField.defaultValue);
    onHide();
  };

  const onSearch = async () => {
    setIsLoading(true);
    setDataTableError(false);

    let clinics = [];
    try {
      clinics = await searchButton.onClick({
        country: countryValue,
        clinic: clinicValue,
      });
    } catch (error) {
      setIsLoading(false);
      setTableData(undefined);
      setDataTableError(true);
    }

    setIsLoading(false);
    setTableData(clinics);
  };

  const setCountry = (v) => {
    setCountryValue(v);
  };

  return (
    <Modal
      {...modalConfig}
      onHide={hideModal}
      size={modalConfig.size}
      dialogClassName="ccl__AssociatedClinicPopup -leftAlign"
      show={show}
    >
      <ModalHeader title={modalConfig.modaltitle} />

      <Modal.Body>
        <ClinicForm
          countryField={countryField}
          setCountry={setCountry}
          clinicField={clinicField}
          clinicError={clinicError}
          clinicValue={clinicValue}
          isLoading={isLoading}
          searchButton={searchButton}
          onSearch={onSearch}
          setClinicError={setClinicError}
          setClinicValue={setClinicValue}
          labels={labels}
        />
        <ClinicResult
          scrollable={scrollable}
          dataTableError={dataTableError}
          tableData={tableData}
          tableConfig={tableConfig}
          labels={labels}
          contactCustomerServiceView={contactCustomerServiceView}
          errorView={errorView}
        />
      </Modal.Body>

      <ModalFooter hideModal={hideModal} labels={labels} />
    </Modal>
  );
};
