export const getConfig = (
  onMatchingRecordRowClickCarer,
  dateInputFormat,
  labels,
) => {
  return {
    formats: {
      date: dateInputFormat,
    },
    fields: [
      {
        id: 'firstNameCarer',
        label: '',
        hint: '',
        placeholder: '',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.addCarer.validation.firstName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.firstName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.addCarer.edit.firstname.validation.specialChars',
          },
        ],
      },
      {
        id: 'middleNameCarer',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.middleName.required',
          },
          {
            type: 'max',
            value: 40,
            message:
              'labels.addCarer.validation.middleName.invalidMaxLength',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.addCarer.validation.middleName.noSpecialCharacters',
          },
        ],
      },
      {
        id: 'lastNameCarer',
        label: '',
        hint: '',
        placeholder: '',
        validators: [
          {
            type: 'min',
            value: 1,
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'max',
            value: 80,
            message:
              'labels.addCarer.validation.lastName.invalidMaxLength',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.lastName.required',
          },
          {
            type: 'matches',
            value: /^[^<>()"!%{}/\\0-9]*$/,
            message:
              'labels.addCarer.edit.lastname.validation.specialChars',
          },
          {
            type: 'matches',
            value: /^[^\d]+$/,
            message:
              'labels.addCarer.edit.lastname.validation.noNumbers',
          },
        ],
      },
      {
        id: 'dateOfBirthCarer',
        label: '',
        hint: '',
        fieldLabels: {
          d: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.dayLabel',
          m: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.monthLabel',
          y: 'labels.advancedSearch.tabs.patient.fields.dateOfBirth.yearLabel',
        },
        validators: [
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.fields.dateOfBirthCarer.validators.required',
          },
        ],
      },
      {
        id: 'email',
        label: '',
        hint: '',
        placeholder: '',
        validators: [
          {
            type: 'matches',
            value:
              /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.validEmail',
          },
          {
            type: 'required',
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.required',
          },
          {
            type: 'max',
            value: 55,
            message:
              'labels.advancedSearch.tabs.patient.errors.accountUsername.invalidmaxlength',
          },
        ],
      },
    ],
    matchingPatientsTableCarer: {
      highlight: false,
      columns: [
        {
          display: 'labels.common.name',
          field: 'name',
          format: {
            collapsed: {
              type: 'composite',
              template:
                '<div><span class="ccl__text ccl__text--interactive-text">{{name}}</span></div><em></em></div>',
            },
            expanded: {
              type: 'emptycell',
            },
            onClick: () => {},
          },
          sortable: false,
        },
        {
          default: 'asc',
          display: 'labels.common.dateOfBirth',
          field: 'dobFormatted',
          format: {
            type: 'string',
          },
          sortable: false,
          syncSort: 'orderNumberOrderDate',
        },
        {
          display: 'labels.common.contactEmail',
          field: 'email',
          format: {
            type: 'string',
          },
          sortable: false,
        },
      ],
      formats: {
        currency: 'USD',
        date: 'mmm dd yyyy',
      },
      handleSortDataChange: function noRefCheck() {},
      loading: false,
      setLoading: function noRefCheck() {},
      setShow: function noRefCheck() {},
      show: false,
      clickableRow: true,
      events: {
        onLoadMore: () => {},
        onRowClick: (actions, row) => {
          onMatchingRecordRowClickCarer(row.data);
        },
        onSort: () => {},
      },
    },
    carerAgreementmodelConfig: {
      alertTitle:
        labels[
          'labels.addCarer.agreementModal.confirmCarer.title'
        ],
      alertText:
        labels[
          'labels.addCarer.agreementModal.confirmCarer.message'
        ],
      agreementBodyText: '',
      agreementAccceptText: labels['labels.common.confirm'],
      agreementCloseText:
        labels['labels.common.cancel'],
      agreementTitle: '',
      userType: 'carer',
    },
  };
};
