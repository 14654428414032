/* eslint-disable no-param-reassign */
import { toTitleCase } from '../../utils/stringFns';
import { formatDate } from '../../utils/dateTimeFns';
import { deviceType } from '../../config';

function isErrorField(fieldName, errors) {
  // Check if the field is included in the error array
  return errors?.some((x) => x.source?.pointer === fieldName);
}

function setPhoneNumberField(returnValue, data) {
  returnValue.phoneNumber = {
    value: data.mobile || data.phone || '',
  };
}

function transposeDate(inputDate) {
  return formatDate(inputDate, true);
}

function setAccountStatusField(returnValue, data, labels, errors) {
  if (!isErrorField('accountStatus', errors)) {
    // Error and Empty states are considered equal for accountStatus field
    if (data?.accountStatus) {
      returnValue.accountDetails = {
        value: data.accountStatus,
      };

      const accountLinkTextLabel = `labels.patientRecord.accountLinkText${toTitleCase(
        data.accountStatus,
      ).replace(/ /g, '')}`; // Match the dictionary label by removing whitespace from account status. E.g if account status is "Not Set Up", the label name would be "accountLinkTextNotSetUp"

      returnValue.emailVerified = {
        value: labels[accountLinkTextLabel],
      };

      if (returnValue.emailVerified.value) {
        // Add accountLinkSeparator (-) only if email link is not empty
        returnValue.accountLinkSeparator = {
          value: labels['labels.patientRecord.accountLinkSeparator'],
        };
      }
    } else {
      returnValue.accountDetails = {
        value: labels[`labels.patientRecord.notApplicable`],
      };
    }
  } else {
    // Add "Error loading" message if accountStatus field is in the errors list
    // or show "deceased", if patient/carer is deceased
    returnValue.accountDetails = data.isDeceased
      ? { value: labels[`labels.patientRecord.deceased`] }
      : { value: labels[`labels.patientRecord.error`] };
  }
}

function setAddressField(returnValue, data, labels, errors) {
  if (!isErrorField('address', errors)) {
    if (data.address) {
      // Populate address field only if address object is not NULL or EMPTY
      returnValue.street1 = {
        value:
          data.address?.street?.length > 0
            ? data.address?.street[0]
            : '',
      };
      returnValue.street2 = {
        value:
          data.address?.street?.length > 1
            ? data.address?.street[1]
            : '',
      };
      returnValue.city = {
        value: data.address?.city ?? '',
      };
      returnValue.postcode = {
        value: data.address?.postcode ?? '',
      };
      returnValue.state = {
        value: data.address?.state ?? '',
      };
      returnValue.country = {
        value: data.address?.country ?? '',
      };
    } else {
      returnValue.street1 = {
        value: labels[`labels.patientRecord.dataEmpty`],
      };
    }
  } else {
    returnValue.street1 = {
      value: labels[`labels.patientRecord.error`],
    };
  }
}

const warrantyData = {
  warrantyOptions: [
    { option: 'actions.grouping.active' },
    { option: 'actions.grouping.expires90' },
    {
      option: 'actions.grouping.expired',
      value:
        'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.expired',
    },
    {
      option: 'actions.grouping.notApplicable',
      value:
        'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.notApplicable',
    },
  ],
  abbrSingleYear:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.abbrSingleYear',
  abbrPluralYear:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.abbrPluralYear',
  singleYear:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.singleYear',
  pluralYear:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.pluralYear',
  abbrSingleMonth:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.abbrSingleMonth',
  abbrPluralMonth:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.abbrPluralMonth',
  singleMonth:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.singleMonth',
  pluralMonth:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.pluralMonth',
  singleDay:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.singleDay',
  pluralDay:
    'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.text.pluralDay',
  timestamp: Date.now() - 10000000000, // latestWarrantyDate
};

export const patientData = (data, errors, labels) => {
  const returnValue = {
    _metadata: {
      readOnlyForm: false,
    },

    firstName: {
      value: data.firstName,
      permission: 'rw',
    },
    lastName: {
      value: data.lastName,
      permission: 'rw',
    },
    language: {
      value: data.preferredLanguage ?? '',
    },
    dateOfBirth: {
      value: !Number.isNaN(Date.parse(data.dateOfBirth))
        ? data.dateOfBirth
        : '',
      permission: 'rw',
    },
    dateOfBirthFormatted: {
      value: !Number.isNaN(Date.parse(data.dateOfBirth))
        ? formatDate(data.dateOfBirth)
        : '',
      permission: 'rw',
    },
    familyConsent: {
      value: null,
      permission: 'r',
    },
    phones: {
      value: [data.phone, data.mobile],
      permission: 'rw',
    },
    relatedCarers: {
      value: '',
      permission: 'not applicable',
    },
    accountId: {
      value: data.id,
    },
  };

  if (!data.isUnderAge) {
    // Do not show some fields if patient is underAge : address, email, phone number, account status
    setAddressField(returnValue, data, labels, errors);
    setPhoneNumberField(returnValue, data, labels);
    setAccountStatusField(returnValue, data, labels, errors);
    returnValue.email = {
      value: data.email,
      permission: 'rw',
    };
  }

  return returnValue;
};

export const genericEmptyListElementsConfig = [
  {
    id: 'emptyList',
    label: '',
    value: '{{emptyText}}',
    validators: [],
  },
];

export const getGenericEmptyListData = (labels, viewId) => {
  return {
    type: 'others',
    values: [
      {
        emptyText: {
          value:
            labels[
              `labels.patientRecord.views.${viewId}.read.emptyText`
            ],
        },
      },
    ],
  };
};

export const genericErrorElementsConfig = [
  {
    id: 'errorTitle',
    label: '',
    value: '<span class="ccl__text--body-text-bold">{{title}}</span>',
    validators: [],
  },
  {
    id: 'errorBody',
    label: '',
    value: '{{line1}} {{line2}}',
    validators: [],
  },
];

export const getGenericErrorData = (labels, viewId) => {
  return {
    type: 'others',
    values: [
      {
        title: {
          value:
            labels[
              `labels.patientRecord.views.${viewId}.read.errorTitle`
            ],
        },
        line1: {
          value:
            labels[
              `labels.patientRecord.views.${viewId}.read.errorLine1`
            ],
        },
        line2: {
          value:
            labels[
              `labels.patientRecord.views.${viewId}.read.errorLine2`
            ],
        },
      },
    ],
  };
};

// DPX-3908 - intercept links rendered for account invite email
const getValue = (viewId, element) => {
  if (element.id === 'accountDetails') {
    if (viewId === 'patientDetails') {
      return '{{accountDetails}} {{accountLinkSeparator}} <a href="javascript:void(0)" class="ccl__email-verified" id="pt_invite_link_{{accountId}}" data-id="{{accountId}}" data-analytics="send_invite_patient_view_patient">{{emailVerified}}</a>';
    }
    if (viewId === 'associatedCarersView') {
      return '{{accountDetails}} {{accountLinkSeparator}} <a href="javascript:void(0)" class="ccl__email-verified" id="cr_invite_link_{{accountId}}" data-id="{{accountId}}" data-analytics="send_invite_carer_view_patient">{{emailVerified}}</a>';
    }
  }
  return element.value.value;
};

export const getCarerReadElementsConfig = (config) => {
  const viewId = 'associatedCarersView';
  const viewIndex = config.views.findIndex(
    ({ id }) => id.value === viewId,
  );

  const carerViewElements =
    config.views[viewIndex]?.read[0]?.elements;
  return carerViewElements.map((element) => ({
    id: element.id,
    label: element.label?.targetItem?.key.key,
    value: getValue(viewId, element),
    validators: [],
  }));
};

export const formatPatientCarersData = (carersList, labels) => {
  const returnValue = {
    type: 'others',
    values: carersList.map(({ data, errors }) => {
      let { firstName, lastName } = data;

      if (firstName === 'UPDATENAME' || lastName === 'UPDATENAME') {
        firstName =
          labels['labels.patientRecord.carerDetails.nameMissing'];
        lastName = '';
      }

      const carerObject = {
        accountId: {
          value: data.id,
        },
        firstName: {
          value: firstName,
        },
        lastName: {
          value: lastName,
        },
        email: {
          value: data.email,
        },
        language: {
          value: data.preferredLanguage ?? '',
        },
      };

      setAddressField(carerObject, data, labels, errors);
      setPhoneNumberField(carerObject, data);
      setAccountStatusField(carerObject, data, labels, errors);
      return carerObject;
    }),
  };

  return returnValue;
};

export const formatPatientClinicData = (providers) => {
  return {
    type: 'others',
    values: providers.map(
      ({
        name,
        phone,
        id,
        address: {
          street: [street1, street2],
          city,
          state,
          postcode,
          country,
        },
      }) => ({
        clinicId: {
          value: id,
        },
        clinicName: {
          value: name,
        },
        phoneNumber: {
          value: phone,
        },
        street1: {
          value: street1,
        },
        street2: {
          value: street2,
        },
        city: {
          value: city,
        },
        state: {
          value: state,
        },
        postcode: {
          value: postcode,
        },
        country: {
          value: country,
        },
      }),
    ),
  };
};

function setLabel(isLoaner, isCurrent, labels) {
  if (isLoaner === true) {
    return labels[`labels.patientRecord.tabs.equipments.loaner`];
  }
  if (isCurrent === true) {
    return labels[
      `labels.patientRecord.tabs.equipments.currentDevice`
    ];
  }
  return '';
}

export const formatPatientDeviceData = (deviceList, labels) => {
  const filteredDevices = deviceList?.devices?.filter(
    (item) =>
      !(
        (item?.productType?.toLowerCase() === 'accessory' &&
          (item?.status?.toLowerCase() === 'lost/stolen' ||
            item?.status?.toLowerCase() === 'return received')) ||
        ((item?.productType?.toLowerCase() === 'implant' ||
          item?.productType?.toLowerCase() === 'sound processor') &&
          item?.status?.toLowerCase() === 'inactive')
      ),
  );
  const contextMenuMap = filteredDevices?.map(
    ({
      deviceId,
      isNewServiceRequestEnabled,
      isViewServiceRequestEnabled,
      isReportAnIssueEnabled,
      productType,
      earSide,
      serialNumber,
    }) => {
      let returnValue = {};
      const contextMenu = [];
      if (isNewServiceRequestEnabled) {
        contextMenu.push('newServiceRequest');
      }
      if (isViewServiceRequestEnabled) {
        contextMenu.push('viewServiceRequest');
      }
      if (isReportAnIssueEnabled) {
        contextMenu.push('reportAnIssue');
      }
      if (
        (productType === 'Sound Processor' ||
          productType === 'Implant') &&
        (earSide === 'Left' || earSide === 'Unknown')
      ) {
        contextMenu.push('assignEarRight');
      }
      if (
        (productType === 'Sound Processor' ||
          productType === 'Implant') &&
        (earSide === 'Right' || earSide === 'Unknown')
      ) {
        contextMenu.push('assignEarLeft');
      }
      if (
        productType === 'Accessory' &&
        !isViewServiceRequestEnabled &&
        serialNumber === null
      ) {
        contextMenu.push('replaceAccessory');
      }

      if (contextMenu.length > 0) {
        returnValue = {
          id: !deviceId ? '-1' : deviceId,
          items: contextMenu,
        };
      }
      return returnValue;
    },
  );

  const getModelInfo = (productType, serialNumber, lotNumber) => {
    let info = '';
    if (serialNumber) {
      info = `${labels['labels.patientRecord.tabs.equipments.serialNumber']} ${serialNumber}`;
    } else if (lotNumber) {
      info = `${labels['labels.patientRecord.tabs.equipments.lotNumber']} ${lotNumber}`;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        productType.toLowerCase() === deviceType.Accessory ||
        productType.toLowerCase() === deviceType.RemoteAssistant
      ) {
        info = '';
      } else {
        info =
          labels[
            'labels.patientRecord.tabs.equipments.serialOrLotNumber'
          ];
      }
    }
    return info;
  };

  const data = filteredDevices?.map(
    ({
      assetId,
      deviceId,
      // productName,
      serialNumber,
      lotNumber,
      productType,
      productDescription,
      // status,
      earSide,
      startDate,
      // endDate,
      isCurrent,
      warrantyExpiration,
      serviceRequestId,
      isLoaner,
      partNumber,
      productId,
    }) => ({
      assetId: !assetId ? '-1' : assetId,
      deviceId: !deviceId ? '-1' : deviceId,
      id: !deviceId ? '-1' : deviceId,
      productId: !productId ? '-1' : productId,
      serviceRequestId: !serviceRequestId ? '-1' : serviceRequestId,
      current: isCurrent,
      startDate: startDate || '',
      icon: !(
        productType.toLowerCase() === deviceType.Accessory ||
        productType.toLowerCase() === deviceType.ActuatorUnit ||
        productType.toLowerCase() === deviceType.RemoteAssistant ||
        earSide === 'Unknown'
      ),
      earLeft: earSide === 'Left',
      isUnknownEarSIde: earSide === 'Unknown',
      loaner: isLoaner,
      device: {
        type: !productType ? '' : productType,
        status: setLabel(isLoaner, isCurrent, labels),
      },
      model: {
        name: productDescription,
        info: getModelInfo(productType, serialNumber, lotNumber),
        partNumber,
      },
      warranty: {
        ...warrantyData,
        timestamp:
          warrantyExpiration &&
          productType.toLowerCase() !== deviceType.Implant
            ? new Date(warrantyExpiration).getTime()
            : null,
      },
    }),
  );

  if (!data) {
    return [];
  }
  // eslint-disable-next-line no-new-object
  const returnValue = new Object();
  returnValue.data = data;
  if (contextMenuMap.length > 0) {
    returnValue.contextMenuMap = contextMenuMap;
  }
  return returnValue;
};

export const formatExpandedData = (device, labels) => {
  let data = {};
  /* eslint-disable prefer-const */
  let regWarranty = device?.device?.entitlements?.find(
    (warranty) =>
      warranty.warrantyName !== 'Extended Warranty' &&
      warranty.warrantyName !== 'Supplier Warranty',
  );
  let extWarranty = device?.device?.entitlements?.find(
    (warranty) => warranty.warrantyName === 'Extended Warranty',
  );
  let supWarranty = device?.device?.entitlements?.find(
    (warranty) => warranty.warrantyName === 'Supplier Warranty',
  );
  let dev = device?.device;
  switch (device?.device?.productType.toLowerCase()) {
    case deviceType.Implant:
      data = {
        expandedDataL: [
          {
            label:
              labels['labels.patientRecord.tabs.equipments.surgeon'],
            value: dev?.professional?.name,
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.surgeryCenter'
              ],
            value: dev?.organisation?.name,
          },
        ],
        expandedDataR: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.surgeryDate'
              ],
            value: transposeDate(dev?.startDate),
          },
        ],
      };

      break;

    case deviceType.SoundProcessor:
      data = {
        expandedDataL: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.audiologist'
              ],
            value: dev?.professional?.name,
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.fittingClinic'
              ],
            value: dev?.organisation?.name,
          },
        ],
        expandedDataR: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.fittingDate'
              ],
            value: transposeDate(dev?.startDate),
          },
          {
            label:
              labels['labels.patientRecord.tabs.equipments.warranty'],
            value: transposeDate(regWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.extWarranty'
              ],
            value: transposeDate(extWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.supWarranty'
              ],
            value: transposeDate(supWarranty?.warrantyExpiration),
          },
        ],
      };
      break;

    case deviceType.Accessory:
      data = {
        expandedDataL: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.partnumber'
              ],
            value: dev?.partNumber,
          },
        ],
        expandedDataR: [
          {
            label:
              labels['labels.patientRecord.tabs.equipments.warranty'],
            value: transposeDate(regWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.extWarranty'
              ],
            value: transposeDate(extWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.supWarranty'
              ],
            value: transposeDate(supWarranty?.warrantyExpiration),
          },
        ],
      };
      break;

    case deviceType.RemoteAssistant:
      data = {
        expandedDataL: [],
        expandedDataR: [
          {
            label:
              labels['labels.patientRecord.tabs.equipments.warranty'],
            value: transposeDate(regWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.extWarranty'
              ],
            value: transposeDate(extWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.supWarranty'
              ],
            value: transposeDate(supWarranty?.warrantyExpiration),
          },
        ],
      };
      break;

    case deviceType.ActuatorUnit:
      data = {
        expandedDataL: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.audiologist'
              ],
            value: dev?.professional?.name,
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.fittingClinic'
              ],
            value: dev?.organisation?.name,
          },
        ],
        expandedDataR: [
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.fittingDate'
              ],
            value: transposeDate(dev?.startDate),
          },
          {
            label:
              labels['labels.patientRecord.tabs.equipments.warranty'],
            value: transposeDate(regWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.extWarranty'
              ],
            value: transposeDate(extWarranty?.warrantyExpiration),
          },
          {
            label:
              labels[
                'labels.patientRecord.tabs.equipments.supWarranty'
              ],
            value: transposeDate(supWarranty?.warrantyExpiration),
          },
        ],
      };
      break;

    default:
      break;
  }

  if (!data) {
    return [];
  }
  return data;
};

export const errorModeConfig = {
  codeLabel: 'Error code',
  errorResponse: '',
  mode: '',
  data: {
    submit: {
      image: {
        src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
        alt: 'Bug.svg',
        width: '120',
      },
      title: "That's strange, it seems something went wrong.",
      text: 'We had a problem updating your details.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
      buttons: [
        {
          name: 'tryAgain',
          variant: 'brand-primary',
          text: 'Try again',
          icon: '',
          link: '',
          action: 'retry-submit',
          useCallback: true,
        },
        {
          name: 'backToHome',
          variant: 'secondary',
          text: 'Cochlear home',
          icon: 'chevron-right',
          link: 'https://node.sit.cms.cochlear.cloud/us/en/home',
          action: 'link-cochlear-home',
          useCallback: false,
        },
      ],
    },
    read: {
      image: {
        src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
        alt: 'Bug.svg',
        width: '120',
      },
      title: "That's strange, it seems something went wrong.",
      text: 'We’re working to fix the problem and we’ll be up and running soon.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
      buttons: [
        {
          name: 'backToHome',
          variant: 'secondary',
          text: 'Cochlear home',
          icon: 'chevron-right',
          link: '/us/en/home',
          action: 'link-cochlear-home',
          useCallback: false,
        },
      ],
    },
  },
};
export const panelConfigMapper = (
  config,
  uDetails,
  handleEditPatient,
  handleCreateCarer,
  handleAddAssociatedClinic,
) => {
  return {
    handleQueryParams: () => {},
    errorFormView: '',
    isError: false,
    errorMode: '',
    theme: 'coolgrey',
    tabsData: {
      accountId: '',
      userId: null,
      labels: {
        promptText: '',
        promptTextAbbrv: '...',
        type: '',
      },
      tabs: [
        {
          firstName: '',
          lastName: '',
          tabIndex: 0,
          type: '',
          userId: '',
        },
      ],
    },
    views: config.views.map((view) => ({
      country: uDetails.countryCode,
      language: uDetails.languageCode,
      mask: view.mask.value,
      id: view.id.value,
      title: view.title?.targetItem?.key.key,
      fallbackTitle: view.fallbackTitle?.targetItem?.key.key,
      type: view.type.value,
      mode: view.mode.value,
      showGlobalAdd: view.showGlobalAdd?.value === '1',
      data: view.data.value,
      showEditIcon: view.showEditIcon.value === '1',
      showAddIcon: view.showAddIcon.value === '1',
      isLoading: true,
      // if this is false, then the screen tries to go into edit mode and throws an error
      removeViewSwitch: true,
      onEditHandle: () => {
        handleEditPatient();
      },
      // does not seem to exist in cds
      onCreateHandle: function onCreateHandle() {},
      // only this is used, so this needs to distinguish between carers and clinics
      onGlobalCreateHandle: () => {
        if (view.id.value === 'associatedCarersView') {
          handleCreateCarer();
        } else {
          handleAddAssociatedClinic();
        }
      },
      read: {
        name: view.read[0]?.name.value,
        clkErrorLabel:
          view.read[0]?.clkErrorLabel?.targetItem?.key.key,
        errorLabel: view.read[0]?.errorLabel?.targetItem?.key.key,
        fieldLayout: 'horizontal',
        elements: view.read[0]?.elements.map((element) => ({
          id: element.id,
          label: element.label?.targetItem?.key.key,
          value: getValue(view.id.value, element),
          validators: [],
        })),
        analytics: view.read[0]?.analytics,
        fields: [],
      },
      editLabel: view.edit[0]?.edit?.targetItem?.key.key,
      createLabel: view.create[0]?.createLabel?.targetItem?.key.key,
      edit: {
        edit: view.edit[0]?.edit?.targetItem?.key?.key,
        editIcon: view.edit[0]?.editIcon?.value,
        analytics: view.edit[0]?.analytics,
      },
      create: {
        createLabel: view.create[0]?.createLabel?.targetItem?.key.key,
        createIcon: view.create[0]?.createIcon.value,
      },
    })),
    errorView: {
      codeLabel: 'Error code',
      errorResponse: '',
      mode: '',
      data: {
        submit: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: "That's strange, it seems something went wrong.",
          text: 'We had a problem updating your details.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
          buttons: [
            {
              name: 'tryAgain',
              variant: 'brand-primary',
              text: 'Try again',
              icon: '',
              link: '',
              action: 'retry-submit',
              useCallback: true,
            },
            {
              name: 'backToHome',
              variant: 'secondary',
              text: 'Cochlear home',
              icon: 'chevron-right',
              link: 'https://node.sit.cms.cochlear.cloud/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
        read: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: "That's strange, it seems something went wrong.",
          text: 'We’re working to fix the problem and we’ll be up and running soon.<br />If you need immediate help, contact our customer support team on <a href="tel:+1-877-651-7001" class="ccl__text--interactive-text" data-nw-error-link="phoneNumber" data-nw-action="call">877-651-7001.</a>',
          buttons: [
            {
              name: 'backToHome',
              variant: 'secondary',
              text: 'Cochlear home',
              icon: 'chevron-right',
              link: '/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
      },
    },
    token: '',
    popOverPrompt: {
      showPopOverPrompt: false,
      popOverPromptText: '',
      storage: {
        check: 'exists',
        method: 'session',
        name: 'carerPrompt',
        value: 1,
      },
    },
  };
};

export const getDeviceRegInProgressLabel = (deviceRegInProgress) => {
  if (
    deviceRegInProgress &&
    deviceRegInProgress.toUpperCase() === 'IMPLANT'
  ) {
    return 'labels.patientRecord.views.notification.success.implant';
  }
  if (
    deviceRegInProgress &&
    deviceRegInProgress.toUpperCase() === 'SPEECH_PROCESSOR'
  ) {
    return 'labels.patientRecord.views.notification.success.soundprocessor';
  }
  return 'labels.patientRecord.views.notification.success.accessory';
};
