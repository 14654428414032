import React from 'react';

/**
 *
 * @param {string} label
 * @param {string} [editLabel]
 * @param {function} [onClickHandler]
 * @returns {jsx}
 */
const DetailActionBar = ({
  label,
  editLabel = null,
  onClickHandler = null,
}) => {
  return (
    <div data-nw-state="read" className="ccl-e-detail-action-bar">
      <div className="ccl-e-detail-action-bar__title">
        <span>{label}</span>
      </div>
      {editLabel && (
        <div className="ccl-e-detail-action-bar__action">
          <div className="ccl-e-icon-clickable">
            <button
              type="button"
              className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
              data-nw-action="edit"
              onClick={onClickHandler}
            >
              <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                <span className="ccl__icon ccl__icon--size--xs" />
                <span
                  data-nw-icon-label=""
                  className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                >
                  {editLabel}
                </span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailActionBar;
