import React from 'react';
import { Text } from '@cochlear-design-system/foundation';
import DetailActionBar from '../DetailActionBar';

const InfoRow = ({ label, content }) => (
  <div className="mt-3 ccl__flex">
    <Text
      content={label}
      type="body-text-bold"
      style={{ minWidth: '8rem' }}
    />
    <Text content={content} />
  </div>
);

const RegistrationSuccess = ({ labels, registrationData }) => {
  const {
    patientName,
    dob,
    productType,
    description,
    partNumber,
    purchaseDate,
    serialNumber,
    isSerialNumberControlled,
  } = registrationData;

  return (
    <div>
      <section className="ccl-c-form-section">
        <DetailActionBar
          label={
            labels[
              'labels.accessoryRegistration.submitted.patient.title'
            ]
          }
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.submitted.patientName'
            ]
          }
          content={patientName}
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.submitted.dateOfBirth'
            ]
          }
          content={dob}
        />
      </section>
      <section className="ccl-c-form-section">
        <DetailActionBar
          label={
            labels['labels.accessoryRegistration.review.accessory']
          }
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.submitted.accessory.productType'
            ]
          }
          content={productType}
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.review.accessory.description'
            ]
          }
          content={description}
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.submitted.accessory.partNumber'
            ]
          }
          content={partNumber}
        />
        <InfoRow
          label={
            labels[
              'labels.accessoryRegistration.submitted.accessory.purchaseDate'
            ]
          }
          content={purchaseDate}
        />
        {isSerialNumberControlled === 'Y' && (
          <InfoRow
            label={
              labels[
                'labels.accessoryRegistration.submitted.accessory.serialNumber'
              ]
            }
            content={serialNumber}
          />
        )}
      </section>
    </div>
  );
};

export default RegistrationSuccess;
