import React from 'react';
import { Text, Icon } from '@cochlear-design-system/foundation';

export const DropdownItem = ({
  selected,
  setSelected,
  setOpen,
  config,
  item,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={`ccl__dropdown__list__item ${
        item.id === selected
          ? 'ccl__dropdown__list__item--selected'
          : ''
      }`}
      onClick={() => {
        setSelected(item.id);
        setOpen(false);
        config.onSelectItem(item.id);
      }}
      key={item.id}
      data-analytics={item.analytics}
    >
      <div className="ccl__dropdown__list__item__container">
        <div className="ccl__dropdown__list__item__container--icon">
          <Icon type="check" size="xs" />
        </div>
        <div className="ccl__dropdown__list__item__container--content">
          <Text content={item.content} type="small-body-text" />
        </div>
      </div>
    </li>
  );
};
