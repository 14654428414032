import React from 'react';
import { Title, Text } from '@cochlear-design-system/foundation';
import logo from '../assets/images/wordmark.svg';

const Unauthorized = () => (
  <>
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      id="navbar"
    >
      <div className="container">
        <div className="navbar--items">
          <div className="navbar-item navbar-item--brand">
            <a
              href="https://www.cochlear.com/"
              className="navbar-item--link"
            >
              <img src={logo} alt="Cochlear" />
            </a>
          </div>
          <div className="navbar-item" />
        </div>
      </div>
    </nav>
    <section className="section">
      <div className="container">
        <div className="block">
          <Title
            content="You don't have access to this service."
            size="heading-3"
            tag="h3"
          />
          <Text
            content="This service is for Cochlear professionals only. To login to this service, click <a href='#'>here</a>."
            type="small-body-text"
            isHTML
          />
        </div>
      </div>
    </section>
  </>
);

export default Unauthorized;
