import React from 'react';
import {
  Modal,
  Button,
  Text,
} from '@cochlear-design-system/foundation';
import { useSelector, useDispatch } from 'react-redux';
import headerCancelModal from '../../actions/headerCancelModalAction';

const RegistrationModal = ({ modalConfig, successHandler }) => {
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );
  const dispatch = useDispatch();

  const onSuccess = (e) => {
    dispatch(headerCancelModal(false));
    successHandler(e);
  };

  const onClose = () => {
    dispatch(headerCancelModal(false));
  };

  return (
    <Modal
      {...modalConfig}
      onHide={onClose}
      size={modalConfig.size}
      dialogClassName="device-registration-close"
      show={modalTrigger}
    >
      <Modal.Header
        closeButton
        data-analytics="cancel_header_exit_register_device"
      >
        <Modal.Title>{modalConfig.modaltitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p />
        <Text
          className="ccl__no-results__isClinicPopup__subtitle"
          content={modalConfig.modalText}
          isHTML="true"
          type="small-body-text"
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          text={modalConfig.yes}
          variant="brand-primary"
          onClick={onSuccess}
          data-analytics={modalConfig.yesanalytics}
        />
        <Button
          text={modalConfig.no}
          variant="secondary"
          onClick={onClose}
          data-analytics={modalConfig.noanalytics}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RegistrationModal;
