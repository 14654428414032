import React, { useState, useMemo, useEffect } from 'react';
import dateFormat from 'dateformat';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  PageHeader,
  FormButton,
  FormButtonBar,
  Alert,
} from '@cochlear-design-system/foundation';
import { routes } from '../../config';
import deviceRegistrationService from '../../services/deviceRegistration/deviceRegistrationService';
import cookieService from '../../services/cookieService';
import RegistrationReview from './RegistrationReview';
import RegistrationSuccess from './RegistrationSuccess';
import { getLabelsForFields } from '../../utils/labelHelper';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getModalConfig } from './registrationModalConfig';
import RegistrationModal from '../RegistrationModal';
import { mapPayload } from './mapRequest';
import notificationStatus from '../../actions/notificationStatusAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';

const registrationSteps = {
  STEP_1: 'review_register',
  STEP_2: 'registration_submitted',
};

const AccessoryRegistrationReview = ({
  rendering,
  country,
  lang,
}) => {
  const labels = getLabelsForFields(rendering.fields);
  const modalConfig = getModalConfig(labels);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { STEP_1, STEP_2 } = registrationSteps;

  const initialRegistrationData = useMemo(() => {
    const {
      patientDetails,
      selectedDevice,
      providerDetails,
      userDetails,
    } = location.state;
    const { id: providerId } = providerDetails ?? null;
    const { professionalId, username, loginAppName } =
      userDetails ?? null;

    return {
      ...selectedDevice,
      ...patientDetails,
      country,
      providerId,
      professionalId,
      username,
      loginAppName,
    };
  }, [location.state, country]);

  const [state, setState] = useState({
    pageTitle:
      labels['labels.accessoryRegistration.review.page.title'],
    registrationData: initialRegistrationData,
    purchaseDate: null,
    canSubmit: false,
    isSubmitting: false,
    progress: STEP_1,
  });

  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.progress === STEP_2) {
      return;
    }
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    const payload = mapPayload(state.registrationData);
    const response =
      await deviceRegistrationService.postDeviceRegistration(
        state.registrationData.providerId,
        payload,
      );
    if (response?.success) {
      setState((prevState) => ({
        ...prevState,
        progress: STEP_2,
        pageTitle:
          labels['labels.accessoryRegistration.submitted.page.title'],
        isSubmitting: false,
      }));
      dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
      cookieService.setCookie(
        'deviceRegInProgress',
        state.registrationData.productType,
      );
      cookieService.setCookie('notificationShown', 'true');
    } else {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
    }
  };

  const updatePurchaseDate = (date) => {
    const formattedDate = dateFormat(date, 'dd mmm yyyy');
    setState((prevState) => ({
      ...prevState,
      purchaseDate: date,
      registrationData: {
        ...prevState.registrationData,
        purchaseDate: formattedDate,
      },
      canSubmit: true,
    }));
  };

  const redirectToPatientRecord = () => {
    history.replace({
      pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
      search: `?patientId=${state.registrationData.cochlearId}`,
      state: {
        isActive: true,
        ...location.state,
      },
    });
  };

  const redirectToDeviceRegistration = (e) => {
    e.preventDefault();
    history.push({
      pathname: `${routes.dpx.deviceRegistration}`,
      search: '?type=accessory',
      state: {
        ...location.state,
        ...location.state.patientDetails,
      },
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(headerCancelModal(true));
  };

  useEffect(() => {
    if (modalTrigger && state.progress === STEP_2) {
      dispatch(headerCancelModal(false));
      redirectToPatientRecord();
    }
  }, [modalTrigger]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="main-content">
          <div className="ccl__register-review">
            <div className="device-registration">
              <Container>
                <Row>
                  <Col>
                    {state.progress === STEP_1 && (
                      <h6 className="device-registration__user-name">
                        {state.registrationData.patientName}
                      </h6>
                    )}
                    <PageHeader
                      className={`create-patient-review__title ${
                        state.progress === STEP_2
                          ? 'no-name-margin'
                          : ''
                      }`}
                      title={{ content: state.pageTitle }}
                    />
                  </Col>
                </Row>
                {state.progress === STEP_2 && (
                  <Row>
                    <Col>
                      <Alert
                        variant="success"
                        heading={`${labels['labels.accessoryRegistration.alert.registrationProgress']}\n${labels['labels.common.alert.requestSubmitted']}`}
                        actions={[
                          {
                            text: labels[
                              'labels.accessoryRegistration.alert.printText'
                            ],
                            onClick: (e) => {
                              e.preventDefault();
                              window.print();
                            },
                          },
                          {
                            text: labels[
                              'labels.accessoryRegistration.alert.goToPatientRecord'
                            ],
                            onClick: (e) => {
                              e.preventDefault();
                              redirectToPatientRecord();
                            },
                          },
                          {
                            text: labels[
                              'labels.accessoryRegistration.alert.registerAnother'
                            ],
                            onClick: (e) => {
                              redirectToDeviceRegistration(e);
                            },
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <div className="ccl-l-userTabs ccl-l-userTabs--single">
                      <div className="ccl-l-userTabs__content">
                        <div className="ccl-l-userTabs__content__inner">
                          {state.progress === STEP_1 && (
                            <RegistrationReview
                              labels={labels}
                              registrationData={
                                state.registrationData
                              }
                              purchaseDate={state.purchaseDate}
                              updatePurchaseDate={updatePurchaseDate}
                              editHandler={(e) =>
                                redirectToDeviceRegistration(e)
                              }
                            />
                          )}
                          {state.progress === STEP_2 && (
                            <RegistrationSuccess
                              labels={labels}
                              registrationData={
                                state.registrationData
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex flex-row pt-4">
                      {state.progress === STEP_1 && (
                        <FormButtonBar
                          cancelText={
                            labels[
                              'labels.accessoryRegistration.cancel'
                            ]
                          }
                          dirty
                          isValid={state.canSubmit}
                          isSubmitting={state.isSubmitting}
                          onCancel={handleCancel}
                          popOverPrompt={{
                            popOverPromptText: '',
                            showPopOverPrompt: false,
                            storage: {
                              check: 'exists',
                              method: 'session',
                              name: 'devicePrompt',
                              value: 1,
                            },
                          }}
                          saveButtonProgress={
                            state.isSubmitting ? 2 : 1
                          }
                          saveText={
                            labels[
                              'labels.accessoryRegistration.register'
                            ]
                          }
                        />
                      )}
                      {state.progress === STEP_2 && (
                        <FormButton
                          text={
                            labels[
                              'labels.accessoryRegistration.close'
                            ]
                          }
                          type={
                            labels[
                              'labels.accessoryRegistration.cancel'
                            ]
                          }
                          onClick={handleCancel}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </form>
      <RegistrationModal
        modalConfig={modalConfig}
        successHandler={redirectToPatientRecord}
      />
    </div>
  );
};

export default AccessoryRegistrationReview;
