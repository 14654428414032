// TODO: These address formats should come from Sitecore once clinic.countryCode
// is returned from the API

const getFormatTemplate = (keys) =>
  keys
    .map(
      (key, i) =>
        // Mostache template - not rendered if value doesn't exist for key
        // Comma appended if not last item  in list
        `{{#${key}}}
					{{.}}${i < keys.length - 1 ? ', ' : ''}
				{{/${key}}}`,
    )
    .join('');

export const selectClinicConfig = {
  addressFormats: [
    {
      countries: [
        'US',
        'AU',
        'CA',
        'GB',
        'UK',
        'AR',
        'ZA',
        // This is required to signify this is the default template
        '',
      ],
      format: getFormatTemplate([
        'street1',
        'street2',
        'city',
        'state',
        'postcode',
        'country',
      ]),
    },
    {
      countries: ['BE', 'DE', 'NL'],
      format: getFormatTemplate([
        'street1',
        'street2',
        'postcode',
        'city',
        'country',
      ]),
    },
    {
      countries: ['MY', 'SG', 'VN'],
      format: getFormatTemplate([
        'street1',
        'street2',
        'postcode',
        'city',
        'state',
        'country',
      ]),
    },
  ],
  icon: 'chevron-right',
};
