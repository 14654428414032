import {
  NOTIFICATION_STATUS_APPEND,
  NOTIFICATION_STATUS_DELETE,
  NOTIFICATION_STATUS_FAILURE,
  NOTIFICATION_IS_QUEUED,
  NOTIFICATION_UPDATE_RETRY
} from '../actions/types';

const intervals = [30000, 60000, 120000, 240000];
const initialState = {
  notifications: [],
  isNotificationsFetched: false,
  retryIntervals: intervals
};

export default function notificationStatusReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_STATUS_APPEND: {
      return {
        ...state,
        notifications: [...state.notifications, ...payload].sort(
          (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
        )
      };
    }
    case NOTIFICATION_STATUS_DELETE: {
      const notificationsClone = [...state.notifications];
      const filteredNotifications = notificationsClone.filter(
        (notification) => notification.requestId !== payload.requestId
      );
      return { ...state, notifications: filteredNotifications };
    }
    case NOTIFICATION_UPDATE_RETRY: {
      const filteredRetriedIntervals = state.retryIntervals.filter(
        (retriedInterval) => retriedInterval !== payload
      );
      return {
        ...state,
        retryIntervals: filteredRetriedIntervals,
        isNotificationsFetched: filteredRetriedIntervals.length === 0
      };
    }
    case NOTIFICATION_IS_QUEUED:
      return {
        ...state,
        isNotificationsFetched: false,
        retryIntervals: intervals
      };
    case NOTIFICATION_STATUS_FAILURE:
      return false;
    default:
      return state;
  }
}
