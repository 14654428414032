import axios from 'axios';
import { useFeature } from 'flagged';
import getHeaders from '../utils/getHeaders';
import { providerListEndpoint } from '../config';
import { logger } from '../logger';

const log = logger();

const getFeatureVersionSupport = async () => {
  try {
    const url = `${providerListEndpoint}/config/feature-flags`;
    const response = await axios.get(url, getHeaders());
    return response.data?.release ? response.data : null;
  } catch (err) {
    log.error('Error getting feature version support', err);
    return null;
  }
};

const getFeatureFlagConfig = async (providerId, professionalId) => {
  try {
    const url = `${providerListEndpoint}/config/portal?professionalId=${professionalId}${
      providerId === null ? '' : `&providerId=${providerId}`
    }`;
    const response = await axios.get(url, getHeaders());
    return response.data;
  } catch (err) {
    log.error('Error getting feature flag config', err);
    return null;
  }
};

const getFeatureFlags = () => {
  return {
    dashboardMenu: useFeature('featureEnabler/dashboardMenu'),
    patientsMenu: useFeature('featureEnabler/patientsMenu'),
    createPatient: useFeature('featureEnabler/createPatient'),
    devicesMenu: useFeature('featureEnabler/devicesMenu'),
    deviceRegistration: useFeature(
      'featureEnabler/deviceRegistration',
    ),
    unassignedDevicesSubMenu: useFeature(
      'featureEnabler/unassignedDevicesSubMenu',
    ),
    serviceRequest: useFeature('featureEnabler/serviceRequest'),
    serviceRequestsSubMenu: useFeature(
      'featureEnabler/serviceRequestsSubMenu',
    ),
    programFileRequests: useFeature(
      'featureEnabler/programFileRequests',
    ),
    findADeviceSubMenu: useFeature(
      'featureEnabler/findADeviceSubMenu',
    ),
    clinicAdmin: useFeature('featureEnabler/clinicAdmin'),
    cliniciansSubMenu: useFeature('featureEnabler/cliniciansSubMenu'),
    clinicDeviceSubMenu: useFeature(
      'featureEnabler/clinicDeviceSubMenu',
    ),
    remoteCheck: useFeature('featureEnabler/remoteCheck'),
    customSoundPro: useFeature('featureEnabler/customSoundPro'),
    launchSimulationSoftwareSubMenu: useFeature(
      'featureEnabler/launchSimulationSoftwareSubMenu',
    ),
    onlineTrainingCourseSubMenu: useFeature(
      'featureEnabler/onlineTrainingCourseSubMenu',
    ),
    resourcesMenu: useFeature('featureEnabler/resourcesMenu'),
    overviewSubMenu: useFeature('featureEnabler/overviewSubMenu'),
    fittingInstructionsAndDocsSubMenu: useFeature(
      'featureEnabler/fittingInstructionsAndDocsSubMenu',
    ),
    candidacyToolsSubMenu: useFeature(
      'featureEnabler/candidacyToolsSubMenu',
    ),
    clinicSolutionsSubMenu: useFeature(
      'featureEnabler/clinicSolutionsSubMenu',
    ),
    cpnResources: useFeature('featureEnabler/cpnResources'),
    helpCentre: useFeature('featureEnabler/helpCentre'),
    simpleSearch: useFeature('featureEnabler/simpleSearch'),
    advancedSearch: useFeature('featureEnabler/advancedSearch'),
    viewPatient: useFeature('featureEnabler/viewPatient'),
  };
};

const featureFlagService = {
  getFeatureFlagConfig,
  getFeatureFlags,
  getFeatureVersionSupport,
};

export default featureFlagService;
