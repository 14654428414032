import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dateFormat from 'dateformat';
import {
  Button,
  FormButton,
  Icon,
  TextInput,
  DateInput,
  Preloader,
} from '@cochlear-design-system/foundation';
import { DetailsViewPersonal } from './DetailsView';
import { getPersonalDetailsConfig } from '../editPatientConfig';
import {
  isValidPastDate,
  isValidPastDateCarer,
  getBaseDateFieldsConfig,
  checkMinor,
  getAgeOfMaturity,
} from '../../../utils/patientCarerHelper';
import patientService from '../../../services/patientService';
import providerService from '../../../services/providerService';
import carerService from '../../../services/carerService';
import { isEqual } from '../../../utils/stringFns';
import { getDateInputFormat } from '../../../utils/dateTimeFns';
import { logger } from '../../../logger';

const log = logger();

export const PersonalDetailsView = ({
  labels,
  carerFor,
  providerId,
  patientDetails,
  setPatientDetails,
  loading,
  setSaveSuccess,
  setSaveError,
}) => {
  const DATE_UNITS = ['day', 'month', 'year'];
  const ageOfMaturity = getAgeOfMaturity();
  const currentProvider = providerService.getCurrentProvider();
  const providerCountry = currentProvider.address?.countryCode;
  const { format: dateInputFormat, hint: dateHint } =
    getDateInputFormat(providerCountry);

  const [editPersonalDetails, setEditPersonalDetails] =
    useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const config = getPersonalDetailsConfig(dateInputFormat);
  const { fields } = config;
  const fieldsConfig = fields.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );
  const validateFields = [
    'firstName',
    'middleName',
    'lastName',
    ...DATE_UNITS,
  ];

  const getInputValidations = (inputNames) =>
    Yup.object(
      inputNames.reduce((acc, inputName) => {
        // Config supplied for dateOfBirth applies to all three d/m/y inputs
        const configFieldName = DATE_UNITS.includes(inputName)
          ? 'dateOfBirth'
          : inputName;

        if (carerFor && configFieldName === 'dateOfBirth') {
          return acc;
        }

        return {
          ...acc,
          [inputName]: fieldsConfig[configFieldName]?.validators
            .reduce((acc2, { type, value, message }) => {
              if (type === 'required')
                return acc2[type](labels[message]);
              return acc2[type](value, labels[message]);
            }, Yup.string())
            .trim(),
        };
      }, {}),
    );

  const validationSchema = getInputValidations(validateFields);

  const updateDobParts = (values) => {
    if (!values) return {};

    let updatedValues = {
      day: null,
      month: null,
      year: null,
      ...values,
    };
    updatedValues.middleName = values.middleName || '';
    if (values.dateOfBirth) {
      const dobParts = values.dateOfBirth.split('-');
      if (dobParts.length === 3) {
        updatedValues = {
          ...updatedValues,
          day: dobParts[2],
          month: dobParts[1],
          year: dobParts[0],
        };
      }
    }
    return updatedValues;
  };

  const updateDob = (values) => {
    let updatedValues = {
      ...values,
    };
    updatedValues.middleName = values.middleName || '';
    if (values.year && values.month && values.day) {
      const date = new Date(
        +values.year,
        +values.month - 1,
        +values.day,
      );
      const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');
      updatedValues = {
        ...updatedValues,
        dateOfBirth,
      };
    }
    return updatedValues;
  };

  const initialValues = updateDobParts(patientDetails);
  const [personalDetails, setPersonalDetails] = useState(null);

  const updatedValues = (formValues) => {
    const vals = {};
    if (!isEqual(formValues.firstName, patientDetails.firstName))
      vals.firstName = formValues.firstName;
    if (!isEqual(formValues.middleName, patientDetails.middleName))
      vals.middleName = formValues.middleName;
    if (!isEqual(formValues.lastName, patientDetails.lastName))
      vals.lastName = formValues.lastName;
    if (!isEqual(formValues.dateOfBirth, patientDetails.dateOfBirth))
      vals.dateOfBirth = formValues.dateOfBirth;
    return Object.keys(vals).length === 0 ? null : vals;
  };

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (submittedValues) => {
      setUpdateInProgress(true);
      const newValues = updateDob(submittedValues);
      const requestBody = updatedValues(newValues);
      try {
        // skip api call, if nothing has changed
        if (requestBody) {
          if (carerFor) {
            await carerService.updateCarer(
              requestBody,
              providerId,
              carerFor,
              patientDetails.id,
            );
          } else {
            await patientService.updatePatient(
              requestBody,
              providerId,
              patientDetails.id,
            );
          }
        }
        setPersonalDetails(newValues);
        setPatientDetails(newValues);
        setSaveSuccess(true);
        setSaveError(false);
      } catch (err) {
        log.error('Error updating patient details', err);
        setSaveSuccess(false);
        setSaveError(true);
      }
      setUpdateInProgress(false);
      setEditPersonalDetails(false);
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const onChange = () => {};

  const dobErrorMessage = (() => {
    const invalidDobErrorKey =
      fieldsConfig.dateOfBirth.validators.find(
        ({ type }) => type === 'required',
      ).message;

    const dobInPast = carerFor
      ? isValidPastDateCarer({
          yearCarer: values?.year,
          monthCarer: values?.month,
          dayCarer: values?.day,
        })
      : isValidPastDate(values);
    if (!dobInPast) {
      return labels[invalidDobErrorKey];
    }

    if (carerFor) {
      if (values.year && values.month && values.day) {
        const dob = `${values.year}-${values.month}-${values.day}`;
        if (checkMinor(dob, ageOfMaturity)) {
          const errorMessage =
            labels['labels.editPatient.carerDOBMinorError'];
          const updatedErrorMessage = errorMessage.replace(
            '{ageOfMaturityPlaceholder}',
            ageOfMaturity,
          );
          return updatedErrorMessage;
        }
      } else if (patientDetails.dateOfBirth?.length) {
        // do not allow deleting of existing dob
        return labels[
          'labels.editPatient.edit.dateOfBirth.validation.noDelete'
        ];
      }
    }

    return undefined;
  })();

  const dateFieldsConfig = (() =>
    // Extract dmy key order from a variety of different date formats
    config.formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          getBaseDateFieldsConfig()[field];
        const label =
          labels[fieldsConfig.dateOfBirth.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: initialValues[name],
        };
      }))();

  const toggleEdit = (value) => {
    setEditPersonalDetails(value);
    setSaveSuccess(false);
    setSaveError(false);
    resetForm();
  };

  useEffect(() => {
    if (patientDetails) {
      setPersonalDetails(updateDobParts(patientDetails));
    }
  }, [patientDetails]);

  /* eslint-disable no-nested-ternary */
  return (
    <section className="ccl-c-form-section mt-3">
      <div className="ccl-e-detail-action-bar">
        <div className="ccl-e-detail-action-bar__title">
          <span>
            {carerFor
              ? labels['labels.editPatient.carerDetails.title']
              : labels['labels.editPatient.personalDetails.title']}
          </span>
        </div>
        <div className="ccl-e-detail-action-bar__action">
          {!patientDetails?.isDeceased && (
            <div className="ccl-e-icon-clickable">
              {!editPersonalDetails && (
                <button
                  type="button"
                  className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                  data-nw-action="edit"
                  data-analytics={
                    carerFor
                      ? 'edit_carer_details_edit_carer'
                      : 'edit_personal_details_edit_patient'
                  }
                  onClick={() => toggleEdit(true)}
                >
                  <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                    <span
                      data-nw-icon-label=""
                      className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                    >
                      {labels['labels.common.edit']}
                    </span>
                  </div>
                </button>
              )}
              {editPersonalDetails && (
                <button
                  type="button"
                  className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                  data-nw-action="edit"
                  data-analytics={
                    carerFor
                      ? 'cancel_header_carer_details_edit_carer'
                      : 'cancel_header_personal_details_edit_patient'
                  }
                  onClick={() => toggleEdit(false)}
                >
                  <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                    <Icon type="close" size="sm" />
                    <span
                      data-nw-icon-label=""
                      className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                    >
                      {labels['labels.common.cancel']}
                    </span>
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        {loading ? (
          <Preloader
            key={`preloader${Math.random()}`}
            lines={['title', 'label-field', 'label-field']}
          />
        ) : !editPersonalDetails && personalDetails ? (
          <div>
            <DetailsViewPersonal
              values={personalDetails}
              labels={labels}
            />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} onChange={onChange}>
              <TextInput
                className="ccl-l-edit-patient-tab__fields__name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                label={labels[fieldsConfig.firstName.label]}
                promptText=""
                error={touched.firstName && !!errors.firstName}
                errorMsg={touched.firstName && errors.firstName}
              />

              <TextInput
                className="ccl-l-edit-patient-tab__fields__name"
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                label={labels[fieldsConfig.middleName.label]}
                optionalText={
                  labels[fieldsConfig.middleName.optionalText]
                }
                promptText=""
                error={touched.middleName && !!errors.middleName}
                errorMsg={touched.middleName && errors.middleName}
              />

              <TextInput
                className="ccl-l-edit-patient-tab__fields__name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                label={labels[fieldsConfig.lastName.label]}
                promptText=""
                error={touched.lastName && !!errors.lastName}
                errorMsg={touched.lastName && errors.lastName}
              />

              <DateInput
                className="ccl-l-edit-patient-tab__fields__dob"
                name="dateOfBirth"
                isStrict
                label={labels[fieldsConfig.dateOfBirth.label]}
                hint={labels[dateHint]}
                inputMode="numeric"
                onChange={handleChange}
                onBlur={handleBlur}
                fields={dateFieldsConfig}
                error={!!dobErrorMessage}
                errorMsg={dobErrorMessage}
              />
            </form>
            <div className="ccl-l-edit-patient-tab__actions">
              <div className="pe-3">
                <FormButton
                  text={
                    !updateInProgress
                      ? labels['labels.common.save']
                      : labels['labels.common.saving']
                  }
                  variant="brand-primary"
                  disabled={
                    !isValid || !!dobErrorMessage || updateInProgress
                  }
                  onClick={handleSubmit}
                  type="save"
                  progress={updateInProgress ? 2 : undefined}
                  data-analytics={
                    carerFor
                      ? 'save_carer_details_edit_carer'
                      : 'save_personal_details_edit_patient'
                  }
                />
              </div>

              <div>
                <Button
                  text={labels['labels.common.cancel']}
                  variant="secondary"
                  onClick={() => toggleEdit(false)}
                  data-analytics={
                    carerFor
                      ? 'cancel_carer_details_edit_carer'
                      : 'cancel_personal_details_edit_patient'
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
