import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFeature } from 'flagged';
import { Landing } from '../../ui/CreatePatient/Landing.js';
import { routes } from '../../config';
import { getConfig } from './createPatientLandingConfig';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getLabelsForFields } from '../../utils/labelHelper';

const CreatePatientLanding = ({ rendering }) => {
  const labels = getLabelsForFields(rendering.fields);
  const dispatch = useDispatch();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const redirectToHome = () => {
    window.location.href = `${routes.dpx.home}`;
  };

  useEffect(() => {
    if (modalTrigger) {
      dispatch(headerCancelModal(false));
      redirectToHome();
    }
  }, [modalTrigger]);

  return (
    <div id="main-content">
      <Landing config={getConfig()} labels={labels} data={{}} />
    </div>
  );
};

export default withFeature('featureEnabler/createPatient')(
  CreatePatientLanding,
);
