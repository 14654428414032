import React from 'react';
import { Text, Icon } from '@cochlear-design-system/foundation';

export const DropdownToggle = ({ content, open, setOpen }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`ccl__dropdown__toggle ${
        open ? 'ccl__dropdown__toggle--active' : ''
      }`}
      onClick={() => setOpen(!open)}
    >
      <Text content={content} type="small-body-text" />
      <div className="ccl__dropdown__toggle__icon">
        <Icon
          type="expand-more"
          rotate={open ? '180' : ''}
          size="xs"
        />
      </div>
    </div>
  );
};
