import auth from './authReducer';
import error from './errorReducer';
import headerCancelModal from './modalReducer';
import newPatientDetails from './newPatientDetailsReducer';
import matchingPatientDetails from './matchingPatientDetailsReducer';
import notificationStatus from './notificationStatusReducer';
import providerDetails from './providerDetailsReducer';
import step from './stepReducer';
import userDetails from './userDetailsReducer';
import countryConfigReducer from '../features/countryConfig/countryConfigSlice';
import apiErrorReducer from './createPatientErrorReducer';

const reducer = {
  userDetails,
  providerDetails,
  error,
  auth,
  notificationStatus,
  headerCancelModal,
  apiErrorReducer,
  step,
  newPatientDetails,
  matchingPatientDetails,
  countryConfig: countryConfigReducer,
};

export default reducer;
