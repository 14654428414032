import axios from 'axios';
import { providerListEndpoint } from '../config';
import getHeaders from '../utils/getHeaders';
import { logger } from '../logger';

const log = logger();

const getCarer = async (
  providerId,
  patientId,
  carerId,
  customHeaders,
) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/carers/${carerId}`,
      getHeaders(customHeaders),
    );
    return response.data;
  } catch (err) {
    log.error('Error getting carer', err);
    return err.response ? err.response : null;
  }
};

const markCarerAsDeceased = async (
  providerId,
  patientId,
  carerId,
) => {
  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/carers/${carerId}/deceased`,
      {},
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error marking carer as deceased', err);
    return null;
  }
};

const updateCarer = async (
  requestBody,
  providerId,
  patientId,
  carerId,
) => {
  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/carers/${carerId}`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error updating carer', err);
    throw err;
  }
};

const carerService = {
  getCarer,
  markCarerAsDeceased,
  updateCarer,
};

export default carerService;
