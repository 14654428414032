import React, { useRef, useState, useEffect } from 'react';
import { Spinner } from '@cochlear-design-system/foundation';
import { routes } from '../../config';

const CpnResource = () => {
  const resourcesUrl = routes.mcp.cpnResource;
  const resourceFrame = useRef();
  const [showResource, setShowResource] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeight = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'resources') {
                setShowSpinner(false);
                setShowResource(true);
                resourceFrame.current.style.height =
                  mcpPageHeight === 0
                    ? '1000px'
                    : `${mcpPageHeight}px`;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}

      <iframe
        src={resourcesUrl}
        id="cpn-resource-iframe"
        title="CPN Resource"
        width="100%"
        ref={resourceFrame}
        style={{
          visibility: showResource ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default CpnResource;
