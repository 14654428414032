import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFeature } from 'flagged';
import { useLocation, useHistory } from 'react-router-dom';
import { DeviceRegistrationReviewWrapper } from '../../ui/DeviceRegistrationReviewWrapper/DeviceRegistrationReviewWrapper.js';
import { routes } from '../../config';
import {
  config as adaptiveFormConfig,
  alertConfig,
} from './deviceRegistrationSuccessConfig';
import { getDeviceType } from './functions';
import providerService from '../../services/providerService';
import cookieService from '../../services/cookieService';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getLabelsForFields } from '../../utils/labelHelper';

const DeviceRegistrationSuccess = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const history = useHistory();
  const currentProvider = useState(
    providerService.getCurrentProvider(),
  )[0];
  const dispatch = useDispatch();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const getLoggedInClinicInfo = (currProvider) => {
    return {
      id: currProvider.name,
      name: currProvider.name,
      address: `${currProvider.address.street}, ${currProvider.address.city}, ${currProvider.address.postcode}, ${currProvider.address.country}`,
      isRemovable: true,
      providerId: currProvider.id,
    };
  };
  // eslint-disable-next-line
  const [clinicsPopUpData, setClinicsPopUpData] = useState([
    getLoggedInClinicInfo(currentProvider),
  ]);
  const location = useLocation();
  const state = useState(location.state)[0];
  const deviceType = getDeviceType(state.selectedDevice.type);
  const { isLotControlled } = state.selectedDevice;
  const updatePersonalData = state;
  const notificationShownStatus = 'true';
  cookieService.setCookie('deviceRegInProgress', deviceType);
  cookieService.setCookie(
    'notificationShown',
    notificationShownStatus,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleBackButton = () => {
      history.replace({
        pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
        search: `?patientId=${state.selectedDevice.cochlearId}`,
        state: { isActive: true },
      });
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [history]);

  const redirectToDeviceRegistration = () => {
    const tempState = {
      patientName: `${state.selectedDevice.patientName}`,
      dob: state.selectedDevice.dob,
      cochlearId: state.selectedDevice.cochlearId,
    };
    history.replace({
      pathname: `${routes.dpx.deviceRegistration}`,
      state: { ...state, ...tempState },
    });
  };

  const redirectToPatientRecord = () => {
    history.replace({
      pathname: `/${country}/${lang}/${routes.dpx.patientDetails}`,
      search: `?patientId=${state.selectedDevice.cochlearId}`,
      state: { isActive: true },
    });
  };

  useEffect(() => {
    if (modalTrigger) {
      dispatch(headerCancelModal(false));
      redirectToPatientRecord();
    }
  }, [modalTrigger]);

  const alertConfiguration = alertConfig(
    redirectToDeviceRegistration,
    redirectToPatientRecord,
    labels,
  );
  const successPageType =
    deviceType === 'SPEECH_PROCESSOR' || deviceType === 'IMPLANT'
      ? 'success-page'
      : 'success-page-accessory';

  // eslint-disable-next-line
  const [config, setConfig] = useState({
    labels,
    config: {
      ...adaptiveFormConfig(
        '',
        false,
        1,
        successPageType,
        redirectToPatientRecord,
        deviceType,
        isLotControlled,
      ),
      routerLocation: () => {},
    },
    data: {
      adaptiveForms: {
        personal: updatePersonalData,
        clincsPopupData: clinicsPopUpData,
      },
    },
    alertConfig: alertConfiguration,
  });

  return (
    config && (
      <div>
        <div id="main-content">
          <DeviceRegistrationReviewWrapper {...config} />
        </div>
      </div>
    )
  );
};

export default withFeature('featureEnabler/deviceRegistration')(
  DeviceRegistrationSuccess,
);
