/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

function getRandomString() {
  return [...Array(30)]
    .map(() => Math.random().toString(36)[2])
    .join('')
    .slice(-24)
    .toUpperCase();
}

export function getSelectedRecipientCId(patientId) {
  return `${getRandomString()}${parseInt(patientId, 10)
    .toString(16)
    .toUpperCase()}`;
}

function zeropad(number, size) {
  if (!number) {
    return size ? '0'.repeat(size) : '';
  }
  if (!size) {
    return number.toString();
  }
  let numberAsString = number.toString();
  while (numberAsString.length < size) {
    numberAsString = `0${numberAsString}`;
  }
  return numberAsString;
}

export function cochlearIdToUuid(cochlearID) {
  let guid = '';
  for (let i = 0; i <= 5; i++) {
    guid =
      zeropad(
        // eslint-disable-next-line no-bitwise, no-restricted-properties
        ((cochlearID / Math.pow(2, 8 * i)) & 0xff).toString(16),
        2,
      ) + guid;
  }
  return `00000000-0000-8000-8000-${guid}`;
}
