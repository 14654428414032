import { useLocation } from 'react-router-dom';
import { getErrorConfig } from './errorConfig';

export const dataMapper = () => {
  const errorConfig = getErrorConfig();
  const currentPage = useLocation();

  const isFullPageError = currentPage.pathname.match(/(error)/) !== null;
  
  
  const buttons = errorConfig.buttons.map((but) => ({
    name: but.children[0].name.value,
    variant: but.children[0].variant.value,
    text: but.children[0].text.value,
    icon: but.children[0].icon.value,
    link: but.children[0].link.url,
    action: but.children[0].action.value,
    useCallback: but.children[0].useCallback.value === '1',
  }));

  return {
    imgSrc: errorConfig.imgSrc.value,
    imgAlt: errorConfig.imgAlt.targetItem.key.key,
    imgWidth: errorConfig.imgWidth.value,
    title: errorConfig.title.targetItem.key.key,
    text: errorConfig.text.targetItem.key.key,
    codeLabel: errorConfig.codeLabel.targetItem.key.key,
    buttons,
    isFullPageError,
  };
};
