import React from 'react';
import { routes } from '../../config';
import providerService from '../../services/providerService';
import { setIdpParam } from '../../utils/authprovider';

const ClinicSync = () => {
  const disableIframes =
    localStorage.getItem('disableIframes') === 'true';
  const pDetails = providerService.getProviderDetails();
  const chosenProvider = pDetails.providerId;
  const partyId = pDetails.providerList.providers.filter(
    (provider) => provider.id === chosenProvider,
  )[0]?.partyId;

  return partyId && !disableIframes ? (
    <iframe
      src={setIdpParam(`${routes.mcp.clinic}${partyId}`)}
      title="mcpClinicSync"
      style={{
        display: 'none',
        position: 'absolute',
        visibility: 'hidden',
        zIndex: '-2147483647',
      }}
    />
  ) : (
    <></>
  );
};

export default ClinicSync;
