import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

function generateRandomString(length) {
  let result = '';
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  for (let i = 0; i < length; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

function generateCodeVerifier() {
  return generateRandomString(48);
}

function generateCodeChallenge(cv) {
  const cc = base64URL(CryptoJS.SHA256(cv));
  return cc;
}

function getCodeVerifier() {
  let cv = '';
  cv = Cookies.get('ccv');
  if (cv) {
    return cv;
  }
  cv = generateCodeVerifier();
  Cookies.set('ccv', cv, { secure: true });
  return cv;
}

export const codeVerifier = getCodeVerifier();
export const codeChallenge = generateCodeChallenge(codeVerifier);
