import { STEP_SUCCESS, STEP_FAILURE } from './types';
import { logger } from '../logger';

const log = logger();

const stepAction = (step) => async (dispatch) => {
  try {
    dispatch({
      type: STEP_SUCCESS,
      payload: step,
    });
  } catch (err) {
    log.error('Error dispatching step', err);
    dispatch({
      type: STEP_FAILURE,
      payload: step,
    });
  }
};

export default stepAction;
