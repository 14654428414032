import dateFormat from 'dateformat';

export function calculateAge(birthday) {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = '';
  // eslint-disable-next-line no-restricted-globals
  if (birthDate !== 'Invalid Date' && !isNaN(birthDate)) {
    age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      // eslint-disable-next-line no-plusplus
      age--;
    }
  }
  return age;
}

export function isValidDate(date) {
  if (!date) return false;

  let dateToCheck = date;
  if (typeof date === 'string') {
    dateToCheck = new Date(date);
  }

  /* eslint-disable no-restricted-globals */
  if (isNaN(dateToCheck)) return false;

  return true;
}

export function isValidPastDate(day, month, year) {
  if (
    Number(year) < 1900 ||
    [month, day].find(
      (value) => !Number(value) || Number(value) <= 0,
    ) !== undefined ||
    Number(month) > 12
  ) {
    return false;
  }

  const dateParsed = new Date(year, month - 1, day);

  return (
    // Date is valid for specified month
    dateParsed.getDate() === Number(day) &&
    // Is in past
    dateParsed < new Date().setHours(0, 0, 0, 0)
  );
}

export function formatDate(date, returnNull = false) {
  if (!date) return returnNull ? null : '--';

  let dateToFormat = date;
  if (typeof date === 'string') {
    dateToFormat = new Date(date);
  }
  /* eslint-disable no-restricted-globals */
  if (!isNaN(dateToFormat)) {
    const locale = navigator.language;
    return new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC', // needed to avoid timezone offset causing inconsistent dates across different timezones
    }).format(dateToFormat);
  }
  return '--';
}

export function getDobFormatted(day, month, year) {
  if (day && month && year) {
    const dateToFormat = new Date(+year, +month - 1, +day); // convert to Date object. + is used to convert string to numbers. month is 0 based so needed -1
    const paddedDate = dateFormat(dateToFormat, 'yyyy-mm-dd'); // add leading zero if absent by using dateFormat function
    return formatDate(paddedDate);
  }
  return '--';
}

export function getDateInputFormat(country, isDatePicker = false) {
  if (country === 'US') {
    return {
      format: isDatePicker ? 'MM/dd/yyyy' : 'mm dd yyyy',
      hint: isDatePicker ? '' : 'labels.common.dateHint.mmddyyyy',
      placeholder: 'labels.common.date.placeholder.mmddyyyy',
    };
  }
  return {
    format: isDatePicker ? 'dd/MM/yyyy' : 'dd mm yyyy',
    hint: isDatePicker ? '' : 'labels.common.dateHint.ddmmyyyy',
    placeholder: 'labels.common.date.placeholder.ddmmyyyy',
  };
}
