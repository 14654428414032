import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  auth0TokenApiParams,
  REFRESH_TOKEN_COOKIE,
  providerTokenEndpoint,
} from '../config';
import cookieService from './cookieService';
import { logger } from '../logger';

const log = logger();

const convertAuthorisationCodeIntoTokens = async (code) => {
  let response;

  try {
    const data = {
      ...auth0TokenApiParams.data,
      code,
      redirect_uri: btoa(auth0TokenApiParams.data.redirect_uri),
    };
    response = await axios.post(`${auth0TokenApiParams.url}`, data);
  } catch (err) {
    log.error('Error converting authorisation code into tokens', err);
  }
  return {
    ...{
      accessToken: response?.data?.access_token ?? null,
      idToken: response?.data?.id_token ?? null,
      refreshToken: response?.data?.refresh_token ?? null,
      expiresIn: response?.data?.expires_in ?? null,
    },
  };
};

const getProviderToken = async (clinicId) => {
  const refreshToken = cookieService.getCookie(REFRESH_TOKEN_COOKIE);
  const postData = {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    providerId: clinicId,
    refresh_token: refreshToken,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios.post(
      providerTokenEndpoint,
      postData,
      config,
    );
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      expiresIn: response?.data?.expires_in ?? null,
    };
  } catch (err) {
    log.error('Error getting provider token', err);
  }
  return false;
};

const getTokenExpiry = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp;
  } catch (err) {
    log.error('Error getting token expiry', err);
    return -1;
  }
};

const tokenService = {
  convertAuthorisationCodeIntoTokens,
  getProviderToken,
  getTokenExpiry,
};
export default tokenService;
