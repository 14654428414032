import React from 'react';
import {
  Preloader
} from '@cochlear-design-system/foundation';
import {DetailsViewAddress} from './DetailsView' 

export const AddressListView = ({ labels, patientDetails, loading }) => {
  return (
    <section className="ccl-c-form-section">
      <div className="ccl-e-detail-action-bar">
        <div className="ccl-e-detail-action-bar__title">
          <span>{labels['labels.editPatient.addressDetails.title']}</span>
        </div>
      </div>
      <div>
        { loading ? (
          <Preloader
            key={`preloader${Math.random()}`}
            lines={["title", "label-field", "label-field"]}
          />
        ) :  (
          <div>
            <DetailsViewAddress values={patientDetails} labels={labels} />
          </div> 
          )
        }
      </div>
    </section>
  );
}

