import {
  isValidDate,
  isValidPastDate as isValidPastDateHelper,
  formatDate,
} from './dateTimeFns';

export const isValidPastDate = ({ year, month, day }) => {
  return isValidPastDateHelper(day, month, year);
};

export const isValidPastDateCarer = ({
  yearCarer,
  monthCarer,
  dayCarer,
}) => {
  if (!yearCarer && !monthCarer && !dayCarer) {
    return true;
  }
  return isValidPastDateHelper(dayCarer, monthCarer, yearCarer);
};

export const getBaseDateFieldsConfig = () => {
  return {
    d: {
      maxLength: 2,
      name: 'day',
      size: 2,
    },
    m: {
      maxLength: 2,
      name: 'month',
      size: 2,
    },
    y: {
      minLength: 4,
      maxLength: 4,
      name: 'year',
      size: 4,
    },
  };
};

export const getBaseDateFieldsConfigCarer = () => {
  return {
    d: {
      maxLength: 2,
      name: 'dayCarer',
      size: 2,
    },
    m: {
      maxLength: 2,
      name: 'monthCarer',
      size: 2,
    },
    y: {
      minLength: 4,
      maxLength: 4,
      name: 'yearCarer',
      size: 4,
    },
  };
};

export const getLanguageList = () => [
  { label: 'Arabic', value: 'ar' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Estonian', value: 'et' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Italian', value: 'it' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Norwegian Bokmål', value: 'no' },
  { label: 'Persian (Farsi)', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Spanish (Español)', value: 'es' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Uzbek', value: 'uz' },
];

export const checkMinor = (dob, ageOfMaturity) => {
  if (!isValidDate(dob)) return false;

  const today = new Date();
  const birthDate = new Date(dob);

  if (birthDate > today) {
    return false;
  }
  birthDate.setFullYear(birthDate.getFullYear() + ageOfMaturity);
  if (birthDate <= today) {
    return false;
  }
  return true;
};

export const getAgeOfMaturity = () => {
  const countryConfigStr = localStorage.getItem('countryConfig');
  let ageOfMaturity = 18;
  if (countryConfigStr) {
    const countryConfig = JSON.parse(countryConfigStr);
    if (
      countryConfig &&
      typeof countryConfig === 'object' &&
      'ageOfMaturity' in countryConfig
    ) {
      ageOfMaturity = countryConfig.ageOfMaturity;
    }
  }
  return ageOfMaturity;
};

export const buildMatchingRecordSet = (records) => {
  return records.map((patientRecord) => {
    return {
      name2: {
        item: `${patientRecord.firstName} ${patientRecord.lastName}`,
        id: patientRecord.cochlearId,
      },
      name: `${patientRecord.firstName} ${patientRecord.lastName}`,
      dob: patientRecord.dateOfBirth,
      dobFormatted: formatDate(patientRecord.dateOfBirth),
      email: patientRecord.email,
      cochlearId: patientRecord.cochlearId,
      isRelatedToProvider: patientRecord.isRelatedToProvider,
      firstName: patientRecord.firstName,
      lastName: patientRecord.lastName,
      source: patientRecord.source,
      persona: patientRecord.persona,
    };
  });
};
