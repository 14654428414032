export const replaceAppNameWithCountry = (
  richTextContent,
  country,
) => {
  // Replace appName in resolved internal links from Rich Text Editor fields with country code
  return richTextContent?.replaceAll('/dpx/', `/${country}/`);
};

export const dataMapper = (accordionItems, country) => {
  const accordionData = accordionItems?.map((accordionItem) => ({
    headerTitle: accordionItem.HeaderTitle?.value,
    summary: replaceAppNameWithCountry(
      accordionItem.Summary?.rendered,
      country,
    ),
    headerSubtitle: accordionItem.HeaderSubtitle?.value,
    subSummary: replaceAppNameWithCountry(
      accordionItem.SubSummary?.rendered,
      country,
    ),
    accordionItem: accordionItem.AccordionItem?.value === '1',
  }));

  return accordionData;
};
