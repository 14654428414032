export function toTitleCase(sentence) {
  let result = '';
  sentence.split(' ').forEach((element) => {
    result +=
      element.charAt(0).toUpperCase() +
      element.slice(1).toLowerCase();
  });
  return result;
}

export function replaceTokens(inputString, tokenArray) {
  let outputString = inputString;
  tokenArray.forEach(({ key, value }) => {
    outputString = outputString.replace(key, value);
  });
  return outputString;
}

export function isValidUrl(urlString) {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function isEqual(a, b) {
  if (a)
    return (
      a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    );
  return !b;
}

export const trimStringValues = (rawValues) => {
  return Object.keys(rawValues).reduce((acc, key) => {
    if (typeof rawValues[key] === 'string') {
      acc[key] = rawValues[key].trim();
    } else {
      acc[key] = rawValues[key];
    }
    return acc;
  }, {});
};
