import React, { useRef, useState, useEffect } from 'react';
import { Spinner } from '@cochlear-design-system/foundation';
import { useLocation } from 'react-router-dom';
import { routes } from '../../config';
import { setIdpParam } from '../../utils/authprovider';

const MyAccount = () => {
  const myAccountFrame = useRef();
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeigth = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'myAccount') {
                setShowSpinner(false);
                setShowMyAccount(true);
                myAccountFrame.current.style.height =
                  mcpPageHeigth === 0
                    ? '1000px'
                    : `${mcpPageHeigth}px`;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}
      <iframe
        // sending "expand" parameter to MCP to expand notification settings for Remote Check
        src={setIdpParam(
          `${routes.mcp.account}${params.get('expand')}`,
        )}
        id="my-account-iframe"
        title="my-account"
        width="100%"
        ref={myAccountFrame}
        style={{
          visibility: showMyAccount ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default MyAccount;
