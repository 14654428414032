import React, { useRef, useState, useEffect } from 'react';
import { Spinner } from '@cochlear-design-system/foundation';
import { routes } from '../../config';
import { setIdpParam } from '../../utils/authprovider';

const ClinicianManagement = () => {
  const clinicianManagementFrame = useRef();
  const [showClinicianManagement, setShowClinicianManagement] =
    useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          const mcpPageHeigth = e.data.pageEvent.payload?.height;
          switch (e.data.pageEvent.type) {
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'clinicManagement') {
                setShowSpinner(false);
                setShowClinicianManagement(true);
                clinicianManagementFrame.current.style.height =
                  mcpPageHeigth === 0
                    ? '1000px'
                    : `${mcpPageHeigth}px`;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {showSpinner && (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      )}
      <iframe
        src={setIdpParam(`${routes.mcp.clinicManagement}`)}
        id="clinician-management-iframe"
        title="clinician-management"
        width="100%"
        ref={clinicianManagementFrame}
        style={{
          visibility: showClinicianManagement ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default ClinicianManagement;
