import dateFormat from 'dateformat';

export const mapPayload = (registrationData) => {
  const {
    loginAppName,
    username,
    providerId,
    country,
    cochlearId,
    id,
    description,
    productFamily,
    serialNumber,
    partNumber,
    productType,
    purchaseDate,
    professionalId,
  } = registrationData;

  return {
    submittedBy: {
      source: loginAppName,
      userId: username,
    },
    provider: {
      cochlearId: providerId,
      primaryCountryCode: country,
    },
    patient: {
      cochlearId,
    },
    device: {
      productId: id,
      productName: description,
      productFamily,
      serialNumber,
      partNumber,
      deviceType: productType,
      nonSerialised: true,
    },
    event: {
      date: dateFormat(purchaseDate, 'yyyy-mm-dd'),
    },
    professional: {
      cochlearId: professionalId,
    },
  };
};
