/* eslint-disable class-methods-use-this */
import { RestDictionaryService } from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';
import resolveSite from '../SiteResolver';

export class DictionaryServiceFactory {
  create() {
    const { appName } = resolveSite();

    return new RestDictionaryService({
      apiHost: config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName: appName,
    });
  }
}

export const dictionaryServiceFactory =
  new DictionaryServiceFactory();
