/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import {
  TextInput,
  DateInput,
  Container,
  Row,
  Col,
  PageHeader,
  Button,
  SelectInput,
  FormButton,
  Alert,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { defaultBrowserLanguage, routes } from '../../config';
import userService from '../../services/userService';
import { getConfig } from './createPatientCarerConfig';
import {
  isValidPastDate,
  isValidPastDateCarer,
  getBaseDateFieldsConfig,
  getBaseDateFieldsConfigCarer,
  getLanguageList,
  checkMinor,
  getAgeOfMaturity,
  buildMatchingRecordSet,
} from '../../utils/patientCarerHelper';
import { trimStringValues } from '../../utils/stringFns';
import patientService from '../../services/patientService';
import providerService from '../../services/providerService';
import headerCancelModal from '../../actions/headerCancelModalAction';
import stepAction from '../../actions/stepAction';
import newPatientDetails from '../../actions/newPatientDetailsAction';
import matchingPatientDetails from '../../actions/matchingPatientDetailsAction';
import { DetailsView } from './components/DetailsView';
import { getLabelsForFields } from '../../utils/labelHelper';
import { getDateInputFormat } from '../../utils/dateTimeFns';
import AgreementModal from './components/AgreementModal';
import notificationStatus from '../../actions/notificationStatusAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import AlertComponent from './components/alertView';
import store from '../../store';
import createPatientAPIErrorModal from '../../actions/createPatientAPIErrorAction';
import { FindClinicModal } from '../../ui/FindClinicModal/FindClinicModal';
import { getMergedAddress } from '../../utils/addressHelper';

const CreatePatientCarer = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const userCountry = country.toUpperCase();
  const currentProvider = providerService.getCurrentProvider();
  const providerCountry = currentProvider.address?.countryCode;
  const { format: dateInputFormat, hint: dateHint } =
    getDateInputFormat(providerCountry);
  const { professionalId } = userService.getUserDetails();
  const DATE_UNITS = ['day', 'month', 'year'];
  const DATE_UNITS_CARER = ['dayCarer', 'monthCarer', 'yearCarer'];
  const [step, setStep] = useState(
    useSelector((state) => state.step) || 'c1',
  );
  const [viewTitle, setViewTitle] = useState(
    labels['labels.createPatient.patientDetails.label'],
  );
  const [headingTitle, setHeadingTitle] = useState(
    labels['labels.createPatient.createPatientwithCarer'],
  );
  const [show, setShow] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [showClinicTable, setShowClinicTable] = useState(false);
  const [validateFields, setValidateFields] = useState([
    'firstName',
    'middleName',
    'lastName',
    ...DATE_UNITS,
  ]);
  const history = useHistory();
  const prefilledValues = useSelector(
    (state) => state.newPatientDetails,
  );
  const prefilledMatchingValues = useSelector(
    (state) => state.matchingPatientDetails,
  );
  const defaultLanguage =
    localStorage.getItem('browserLang')?.slice(0, 2) ||
    defaultBrowserLanguage;

  const initialValues = {
    preferredLanguage: defaultLanguage,
    ...prefilledValues,
  };
  const [matchingPatients, setMatchingPatients] = useState(
    prefilledMatchingValues
      ? buildMatchingRecordSet(prefilledMatchingValues)
      : [],
  );
  const [matchingCarers, setMatchingCarers] = useState([]);
  const [continueButtonText, setContinueButtonText] = useState(
    labels['labels.common.continue'],
  );
  const [cancelButtonText, setCancelButtonText] = useState(
    labels['labels.common.cancel'],
  );
  const [continueDataAnalytics, setContinueDataAnalytics] = useState(
    'continue_create_patient_carer',
  );
  const [agreementModalVisible, setAgreementModalVisible] =
    useState(false);
  const [agreementModalCarerVisible, setAgreementModalCarerVisible] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAgreementSubmitting, setIsAgreementSubmitting] =
    useState(false);
  const [isAgreementCarerSubmitting, setIsAgreementCarerSubmitting] =
    useState(false);
  const [matchingPatientSelected, setMatchingPatientSelected] =
    useState();
  const [matchingCarerSelected, setMatchingCarerSelected] =
    useState();
  const [
    ismatchingRecipientSelected,
    setIsmatchingRecipientSelected,
  ] = useState(false);
  const [ismatchingCarerSelected, setIsmatchingCarerSelected] =
    useState(false);
  const dispatch = useDispatch();
  const ageOfMaturity = getAgeOfMaturity();

  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );

  const apiErrorMessageTrigger = useSelector(
    (payload) => payload.apiErrorReducer,
  );
  const [apiError, setApiError] = useState(false);
  const [excludePersonaSelected, setExcludePersonaSelected] =
    useState(false);

  useEffect(() => {
    if (apiErrorMessageTrigger) {
      setApiError(apiErrorMessageTrigger.apiError);
    }
  }, [apiErrorMessageTrigger]);

  const handleSearchClinic = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const clinics = await providerService.searchClinics(
      countryCode,
      searchParam.clinic,
    );
    if (clinics.providers && clinics.providers.length > 0) {
      clinics.providers.forEach((provider) => {
        provider.address = getMergedAddress(provider);
      });
    }
    return clinics.providers;
  };

  const handleSelectClinic = (action, params) => {
    const selectedClinicData = [];
    selectedClinicData.push({
      clinicId: params.data.id,
      name: params.data.name,
      address: params.data.address,
      isRemovable: true,
    });
    setClinicData(selectedClinicData);
  };
  const handleRemoveClinic = () => {
    setClinicData([]);
  };
  const handleCancel = () => {
    if (step === 'c2a') {
      setStep('c1');
      dispatch(stepAction('c1'));
    } else if (step === 'c3' || step === 'c3b' || step === 'c3c') {
      setIsmatchingRecipientSelected(false);
      setMatchingPatientSelected();
      setStep('c1');
      dispatch(stepAction('c1'));
    } else if (step === 'c4a' || step === 'c4b') {
      setIsmatchingCarerSelected(false);
      setMatchingCarerSelected();
      setStep('c3');
      dispatch(stepAction('c3'));
    } else {
      dispatch(newPatientDetails(null));
      dispatch(matchingPatientDetails(null));
      history.push(
        `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
      );
    }
  };

  const handleEdit = () => {
    if (step === 'c2a') {
      setStep('c1');
      dispatch(stepAction('c1'));
    } else if (step === 'c3' || step === 'c3b' || step === 'c3c') {
      setIsmatchingRecipientSelected(false);
      setMatchingPatientSelected();
      setStep('c1');
      dispatch(stepAction('c1'));
    } else if (step === 'c4a') {
      setIsmatchingCarerSelected(false);
      setMatchingCarerSelected();
      setStep('c3');
      dispatch(stepAction('c3'));
    }
  };

  const handleAgreementModalSubmit = async () => {
    setIsAgreementSubmitting(true);
    setStep('c3');
    setIsAgreementSubmitting(false);
    setAgreementModalVisible(false);
  };

  const handleAgreementModalCarerSubmit = async () => {
    // eslint-disable-next-line
    handleSubmit();
  };

  const existingRecordCheck = async (data) => {
    const date = new Date(+data.year, +data.month - 1, +data.day);
    const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');
    let personalData;
    if (step === 'c3') {
      personalData = {
        firstName: data.firstNameCarer,
        lastName: data.lastNameCarer,
        countryCode: providerCountry,
        dateOfBirth,
      };
    } else {
      personalData = {
        firstName: data.firstName,
        lastName: data.lastName,
        countryCode: providerCountry,
        dateOfBirth,
      };
    }
    setSubmitDisabled(true);
    const result = await patientService.checkExistingRecord(
      personalData,
      currentProvider.id,
    );
    setSubmitDisabled(false);
    if (result && result.records && result.records.length > 0) {
      setMatchingPatients(buildMatchingRecordSet(result.records));
    }
    return result;
  };

  const onMatchingRecordRowClick = (patient) => {
    setMatchingPatientSelected(patient);
    setIsmatchingRecipientSelected(true);
    if (patient.isRelatedToProvider) {
      setStep('c3');
    } else {
      setAgreementModalVisible(true);
    }
  };

  const existingRecordCheckCarer = async (data) => {
    const personalData = {
      firstName: data.firstNameCarer,
      lastName: data.lastNameCarer,
      email: data.email,
      countryCode: providerCountry,
    };

    if (data.yearCarer && data.monthCarer && data.dayCarer) {
      const date = new Date(
        +data.yearCarer,
        +data.monthCarer - 1,
        +data.dayCarer,
      );
      const dateOfBirthCarer = dateFormat(date, 'yyyy-mm-dd');
      personalData.dateOfBirth = dateOfBirthCarer;
    }
    setSubmitDisabled(true);
    const result = await patientService.checkExistingRecord(
      personalData,
      currentProvider.id,
    );
    setSubmitDisabled(false);
    if (result && result.records && result.records.length > 0) {
      setMatchingCarers(buildMatchingRecordSet(result.records));
    }
    return result;
  };

  const isRecipientCarerDetailsMatching = (values) => {
    const firstNameRecipient = values.firstName || '';
    const lastNameRecipient = values.lastName || '';
    const firstNameCarer = values.firstNameCarer || '';
    const lastNameCarer = values.lastNameCarer || '';
    const date = new Date(
      +values.year,
      +values.month - 1,
      +values.day,
    );
    const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');
    let dateCarer;
    let dateOfBirthCarer;
    if (values.yearCarer && values.monthCarer && values.dayCarer) {
      dateCarer = new Date(
        +values.yearCarer,
        +values.monthCarer - 1,
        +values.dayCarer,
      );
      dateOfBirthCarer = dateFormat(dateCarer, 'yyyy-mm-dd');
    }

    return (
      firstNameRecipient.toLowerCase() ===
        firstNameCarer.toLowerCase() &&
      lastNameRecipient.toLowerCase() ===
        lastNameCarer.toLowerCase() &&
      dateOfBirth === dateOfBirthCarer
    );
  };

  const createPatient = async (values, clinic) => {
    setSubmitDisabled(true);
    const result = await patientService.createPatientCarer(
      currentProvider.id,
      professionalId,
      providerCountry,
      values,
      clinic,
      matchingCarerSelected,
      matchingPatientSelected,
      ismatchingRecipientSelected,
      ismatchingCarerSelected,
    );
    setSubmitDisabled(false);
    dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
    return result;
  };

  const onMatchingRecordRowClickCarer = async (patient) => {
    const excludePersonaTypes = [
      'provisional-requester',
      'provisional-recipient',
      'provisional-unknown',
    ];
    let selectedPatient = patient;
    if (
      selectedPatient?.persona &&
      excludePersonaTypes.includes(
        selectedPatient.persona.toLowerCase(),
      )
    ) {
      setExcludePersonaSelected(true);
    } else {
      setExcludePersonaSelected(false);
      if (selectedPatient.dob) {
        const dobParts = selectedPatient.dob.split('-');
        if (dobParts.length === 3) {
          selectedPatient = {
            ...selectedPatient,
            day: dobParts[2],
            month: dobParts[1],
            year: dobParts[0],
          };
        }
      } else {
        selectedPatient = {
          ...selectedPatient,
          day: '--',
          month: '--',
          year: '--',
        };
      }
      setMatchingCarerSelected(selectedPatient);
      setIsmatchingCarerSelected(true);
      setSubmitDisabled(true);
      setAgreementModalCarerVisible(true);
    }
  };

  const config = getConfig(
    handleSearchClinic,
    handleSelectClinic,
    handleRemoveClinic,
    onMatchingRecordRowClick,
    onMatchingRecordRowClickCarer,
    userCountry,
    dateInputFormat,
    labels,
  );
  const { fields } = config;

  const fieldsConfig = fields.reduce(
    (acc, { id, ...field }) => ({
      ...acc,
      [id]: {
        id,
        ...field,
      },
    }),
    {},
  );

  const getInputValidations = (inputNames) =>
    Yup.object(
      inputNames.reduce((acc, inputName) => {
        // Config supplied for dateOfBirth applies to all three d/m/y inputs
        let configFieldName;
        if (step === 'c1') {
          configFieldName = DATE_UNITS.includes(inputName)
            ? 'dateOfBirth'
            : inputName;
        } else if (step === 'c3') {
          configFieldName = DATE_UNITS_CARER.includes(inputName)
            ? 'dateOfBirthCarer'
            : inputName;
        }

        return {
          ...acc,
          [inputName]: fieldsConfig[configFieldName]?.validators
            .reduce((acc2, { type, value, message }) => {
              if (
                type === 'required' &&
                configFieldName !== 'dateOfBirthCarer'
              )
                return acc2[type](labels[message]);
              if (
                type === 'required' &&
                configFieldName === 'dateOfBirthCarer'
              ) {
                return acc2.test(
                  'DOB',
                  'Invalid DOB.',
                  (values, context) => {
                    return isValidPastDateCarer(context.parent);
                  },
                );
              }
              return acc2[type](value, labels[message]);
            }, Yup.string())
            .trim(),
        };
      }, {}),
    );

  const [validationSchema, setValidateSchema] = useState(
    getInputValidations(validateFields, fieldsConfig, labels),
  );

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldError,
    setTouched,
  } = useFormik({
    initialValues,
    onSubmit: async (rawValues) => {
      const submittedValues = trimStringValues(rawValues);
      switch (step) {
        case 'c1':
          {
            setIsSubmitting(true);
            const res = await existingRecordCheck(submittedValues);
            if (res === null) {
              setIsSubmitting(false);
              break;
            }
            if (res.records.length === 0) {
              setTouched({});
              setStep('c3');
              dispatch(stepAction('c3'));
            } else if (res.records.length > 0) {
              setStep('c2a');
              dispatch(stepAction('c2a'));
            }
          }
          break;
        case 'c2a':
          setTouched({});
          setStep('c3');
          break;
        case 'c3':
        case 'c3b':
        case 'c3c': {
          setIsSubmitting(true);
          if (isRecipientCarerDetailsMatching(submittedValues)) {
            setStep('c3c');
            dispatch(stepAction('c3c'));
            setIsSubmitting(false);
            window.scrollTo(0, 0);
            break;
          }
          const res = await existingRecordCheckCarer(submittedValues);
          if (res === null) {
            setIsSubmitting(false);
            break;
          }
          if (res.records.length === 0) {
            // No matching records found
            if (!res.isPIIMatched && res.isAccountMatched) {
              if (res.isAccountUsedOutsideClinic) {
                setStep('c3b');
                dispatch(stepAction('c3b'));
                setFieldError(
                  'email',
                  labels['labels.createPatient.existingEmailError'],
                );
                window.scrollTo(0, 0);
              }
            } else {
              const cpResult = await createPatient(
                submittedValues,
                clinicData,
                matchingCarerSelected,
                matchingPatientSelected,
                ismatchingRecipientSelected,
                ismatchingCarerSelected,
              );
              if (cpResult === null) {
                setIsSubmitting(false);
              }
              if (cpResult.success) {
                setStep('c5');
                dispatch(stepAction('c5'));
              }
            }
          } else if (res.records.length === 1) {
            // One matching record found
            if (res.isAccountAndPIIMatched === true) {
              // account and PII match
              setStep('c4a');
              dispatch(stepAction('c4a'));
            } else if (res.isPIIMatched && !res.isAccountMatched) {
              setStep('c4b');
              dispatch(stepAction('c4b'));
            } else if (!res.isPIIMatched && res.isAccountMatched) {
              setStep('c4a');
              dispatch(stepAction('c4a'));
            }
          } else if (res.records.length > 1) {
            // Multiple matching records found
            if (res.isAccountAndPIIMatched === true) {
              // account and PII match
              setStep('c4a');
              dispatch(stepAction('c4a'));
            } else if (res.isPIIMatched && !res.isAccountMatched) {
              setStep('c4b');
              dispatch(stepAction('c4b'));
            } else if (!res.isPIIMatched && res.isAccountMatched) {
              setStep('c4a');
              dispatch(stepAction('c4a'));
            } else if (res.isPIIMatched && res.isAccountMatched) {
              setStep('c4a');
              dispatch(stepAction('c4a'));
            }
          }
          break;
        }
        case 'c4a': {
          setIsAgreementCarerSubmitting(true);
          setSubmitDisabled(true);
          const cpResult = await createPatient(
            submittedValues,
            clinicData,
            matchingCarerSelected,
            matchingPatientSelected,
            ismatchingRecipientSelected,
            ismatchingCarerSelected,
          );
          if (cpResult === null) {
            setIsSubmitting(false);
            setIsAgreementCarerSubmitting(false);
          }
          if (cpResult.success) {
            setStep('c5');
            dispatch(stepAction('c5'));
          } else {
            setIsSubmitting(false);
          }
          setIsAgreementCarerSubmitting(false);
          setAgreementModalCarerVisible(false);
          break;
        }
        case 'c4b': {
          if (agreementModalCarerVisible) {
            setIsAgreementCarerSubmitting(true);
            setSubmitDisabled(true);
          } else {
            setIsSubmitting(true);
            setSubmitDisabled(false);
          }
          const cpResult = await createPatient(
            submittedValues,
            clinicData,
            matchingCarerSelected,
            matchingPatientSelected,
            ismatchingRecipientSelected,
            ismatchingCarerSelected,
          );
          if (cpResult === null) {
            setIsSubmitting(false);
            setIsAgreementCarerSubmitting(false);
          }
          if (cpResult.success) {
            setStep('c5');
            dispatch(stepAction('c5'));
          } else {
            setIsSubmitting(false);
          }
          setIsAgreementCarerSubmitting(false);
          setAgreementModalCarerVisible(false);
          break;
        }
        case 'c5':
          history.push(
            `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
          );
          break;
        default:
          break;
      }
      setIsSubmitting(false);
      setIsAgreementCarerSubmitting(false);
    },

    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const [dobValid, dobErrorMessage] = (() => {
    const isTouched = DATE_UNITS.filter(
      (unit) => touched[unit] || unit in values,
    );
    if (isTouched && isTouched.length < 3) return [false];
    if (isValidPastDate(values)) return [true];

    const invalidDobErrorKey =
      fieldsConfig.dateOfBirth.validators.find(
        ({ type }) => type === 'required',
      ).message;

    return [false, labels[invalidDobErrorKey]];
  })();

  const [dobCarerValid, dobCarerErrorMessage] = (() => {
    const isTouched = DATE_UNITS_CARER.filter(
      (unit) => touched[unit] || unit in values,
    );
    const invalidDobErrorKey =
      fieldsConfig.dateOfBirthCarer.validators.find(
        ({ type }) => type === 'required',
      ).message;
    if (
      isTouched.length < 3 &&
      !(values.yearCarer && values.monthCarer && values.dayCarer)
    )
      return [true];
    if (isTouched && isTouched.length < 3) return [false];
    if (values.yearCarer && values.monthCarer && values.dayCarer) {
      const dob = `${values.yearCarer}-${values.monthCarer}-${values.dayCarer}`;

      if (!isValidPastDateCarer(values)) {
        return [false, labels[invalidDobErrorKey]];
      }

      if (checkMinor(dob, ageOfMaturity)) {
        const errorMessage =
          labels['labels.createPatient.carerDOBMinorError'];
        const updatedErrorMessage = errorMessage.replace(
          '{ageOfMaturityPlaceholder}',
          ageOfMaturity,
        );
        return [false, updatedErrorMessage];
      }
    }
    if (isValidPastDateCarer(values)) return [true];

    return [false, labels[invalidDobErrorKey]];
  })();

  const excludePersonaAlert = () => {
    return (
      <Alert
        variant="error"
        heading={labels['labels.createPatient.excludePersona.title']}
      >
        {labels['labels.createPatient.excludePersonaMessageBefore']}
        <a
          target="_blank"
          rel="noreferrer"
          href={labels['labels.createPatient.customerServiceLink']}
        >
          {labels['labels.createPatient.excludePersonaMessageInside']}
        </a>
        {labels['labels.createPatient.excludePersonaMessageAfter']}
      </Alert>
    );
  };

  useEffect(() => {
    setSubmitDisabled(true);
    switch (step) {
      case 'c1':
        if (
          Object.keys(errors).length === 0 &&
          Object.keys(touched).length > 0 &&
          dobValid
        ) {
          setSubmitDisabled(false);
        } else if (
          Object.keys(errors).length === 0 &&
          dobValid &&
          'day' in values &&
          'month' in values &&
          'year' in values &&
          'firstName' in values &&
          'lastName' in values
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'c2a':
        setSubmitDisabled(false);
        break;
      case 'c3':
      case 'c3b':
      case 'c3c':
        if (
          (Object.keys(errors).length > 0 &&
            Object.keys(touched).length > 0) ||
          !dobCarerValid
        ) {
          setSubmitDisabled(true);
        } else if (
          Object.keys(errors).length === 0 &&
          Object.keys(touched).length > 0
        ) {
          setSubmitDisabled(false);
        } else if (
          Object.keys(errors).length === 0 &&
          dobCarerValid &&
          'dayCarer' in values &&
          'monthCarer' in values &&
          'yearCarer' in values &&
          'firstNameCarer' in values &&
          'lastNameCarer' in values &&
          'email' in values
        ) {
          setSubmitDisabled(false);
        }
        break;
      case 'c4a':
        setExcludePersonaSelected(false);
        setSubmitDisabled(true);
        break;
      case 'c4b':
        setExcludePersonaSelected(false);
        setSubmitDisabled(false);
        break;
      case 'c5':
        setSubmitDisabled(false);
        break;
      default:
        break;
    }
  }, [errors, values, touched, step, dobValid, dobCarerValid]);

  const dateFieldsConfig = (() =>
    // Extract dmy key order from a variety of different date formats
    config.formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          getBaseDateFieldsConfig()[field];
        const label =
          labels[fieldsConfig.dateOfBirth.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: values[name],
        };
      }))();

  const dateFieldsConfigCarer = (() =>
    // Extract dmy key order from a variety of different date formats
    config.formats.date
      .replace(/ /g, '/')
      .split('/')
      .map((fieldString) => {
        const field = fieldString[0];
        const { name, ...baseFieldConfig } =
          getBaseDateFieldsConfigCarer()[field];
        const label =
          labels[fieldsConfig.dateOfBirthCarer.fieldLabels[field]];

        return {
          ...baseFieldConfig,
          id: `advanced-search__date-input__${name}`,
          floatLabel: true,
          label,
          name,
          value: values[name],
        };
      }))();

  useEffect(() => {
    // unset errors potentially caused by add-carer, since service methods are shared
    store.dispatch(createPatientAPIErrorModal(false));
  }, []);

  useEffect(() => {
    switch (step) {
      case 'c1':
        setValidateFields([
          'firstName',
          'middleName',
          'lastName',
          ...DATE_UNITS,
        ]);
        setContinueButtonText(labels['labels.common.continue']);
        setContinueDataAnalytics('continue_create_patient_carer');
        break;
      case 'c2a':
        setValidateFields([]);
        setContinueButtonText(
          labels['labels.createPatient.createNewPatient'],
        );
        setContinueDataAnalytics(
          'create_carer_details_create_patient_carer',
        );
        break;
      case 'c3':
      case 'c3b':
      case 'c3c':
        setValidateFields([
          'firstNameCarer',
          'middleNameCarer',
          'lastNameCarer',
          'email',
          ...DATE_UNITS_CARER,
        ]);
        setViewTitle(
          labels['labels.createPatient.patientDetails.label'],
        );
        setHeadingTitle(
          labels['labels.createPatient.createPatientwithCarer'],
        );
        setContinueButtonText(labels['labels.common.createPatient']);
        setContinueDataAnalytics(
          'create_carer_details_create_patient_carer',
        );
        break;
      case 'c4a':
        setValidateFields([]);
        setViewTitle(labels['labels.createPatient.carerDetails']);
        setHeadingTitle(
          labels['labels.createPatient.createPatientwithCarer'],
        );
        setContinueButtonText(
          labels['labels.createPatient.createNewCarer'],
        );
        setContinueDataAnalytics(
          'create_carer_details_create_patient_carer',
        );
        break;
      case 'c4b':
        setValidateFields([]);
        setViewTitle(labels['labels.createPatient.carerDetails']);
        setHeadingTitle(
          labels['labels.createPatient.createPatientwithCarer'],
        );
        setContinueButtonText(
          labels['labels.createPatient.createNewCarer'],
        );
        setContinueDataAnalytics(
          'create_carer_details_create_patient_carer',
        );
        break;
      case 'c5':
        setHeadingTitle(
          labels['labels.createPatient.requestSubmitted'],
        );
        setContinueButtonText(
          labels['labels.common.createAnotherPatient'],
        );
        setContinueDataAnalytics(
          'create_new_success_create_patient_carer',
        );
        setCancelButtonText(labels['labels.common.close']);
        break;
      default:
        break;
    }
  }, [step]);

  useEffect(() => {
    setValidateSchema(
      getInputValidations(validateFields, fieldsConfig, labels),
    );
  }, [validateFields]);

  useEffect(() => {
    setShowClinicTable(false);
    if (clinicData.length > 0) {
      setShowClinicTable(true);
    }
  }, [clinicData]);

  useEffect(() => {
    if (modalTrigger) {
      dispatch(newPatientDetails(null));
      dispatch(matchingPatientDetails(null));
      dispatch(headerCancelModal(false));
      history.push(`/${country}/${lang}/${routes.dpx.home}`);
    }
  }, [modalTrigger]);

  return (
    <div className="ccl__create-patient-wrapper">
      <Container>
        <Row>
          <Col>
            <PageHeader
              className="create-patient-review__title"
              title={{ content: headingTitle }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {step === 'c5' ? (
              <Alert variant="success">
                {`${labels['labels.createPatient.successMessage']}\n${labels['labels.common.alert.requestSubmitted']}`}
              </Alert>
            ) : (
              <div className="ccl-l-userTabs ccl-l-userTabs--single">
                <div className="ccl-l-userTabs__content">
                  <div className="ccl-l-userTabs__content__inner">
                    {apiError && (
                      <Alert
                        variant="error"
                        heading={
                          labels['labels.common.apiError.title']
                        }
                      >
                        {labels['labels.common.apiError.message']}
                      </Alert>
                    )}
                    <AlertComponent step={step} labels={labels} />
                    <section className="ccl-c-form-section mt-3">
                      <div className="ccl-e-detail-action-bar">
                        <div className="ccl-e-detail-action-bar__title">
                          <span>{viewTitle}</span>
                        </div>
                        {(step === 'c3' ||
                          step === 'c3b' ||
                          step === 'c3c' ||
                          step === 'c2a' ||
                          step === 'c4a') && (
                          <div className="ccl-e-detail-action-bar__action">
                            <div className="ccl-e-icon-clickable">
                              <button
                                type="button"
                                className="ccl-e-icon-clickable__button ccl-e-icon-clickable__button--details-bar"
                                data-nw-action="edit"
                                onClick={() => handleEdit()}
                                data-analytics="edit_create_patient_carer"
                              >
                                <div className="ccl-e-icon-label ccl-e-icon-label--centered">
                                  <span className=" ccl__icon ccl__icon--size--xs" />
                                  <span
                                    data-nw-icon-label=""
                                    className="ccl-e-icon-label__text ccl-e-icon-label__text--inline"
                                  >
                                    {labels['labels.common.edit']}
                                  </span>
                                </div>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <form onSubmit={handleSubmit}>
                        {step === 'c1' && (
                          <>
                            <TextInput
                              className="ccl-l-create-patient__fields__name"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={
                                labels['labels.common.firstName']
                              }
                              promptText=""
                              error={
                                touched.firstName &&
                                !!errors.firstName
                              }
                              errorMsg={
                                touched.firstName && errors.firstName
                              }
                            />

                            <TextInput
                              className="ccl-l-create-patient__fields__name"
                              name="middleName"
                              value={values.middleName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={
                                labels['labels.common.middleName']
                              }
                              hint={labels['labels.common.optional']}
                              promptText=""
                              error={
                                touched.middleName &&
                                !!errors.middleName
                              }
                              errorMsg={
                                touched.middleName &&
                                errors.middleName
                              }
                            />

                            <TextInput
                              className="ccl-l-create-patient__fields__name"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={labels['labels.common.lastName']}
                              promptText=""
                              error={
                                touched.lastName && !!errors.lastName
                              }
                              errorMsg={
                                touched.lastName && errors.lastName
                              }
                            />

                            <DateInput
                              className="ccl-l-advanced-search-patient-tab__fields__dob"
                              name="dateOfBirth"
                              isStrict
                              label={
                                labels['labels.common.dateOfBirth']
                              }
                              hint={labels[dateHint]}
                              inputMode="numeric"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fields={dateFieldsConfig}
                              error={!!dobErrorMessage}
                              errorMsg={dobErrorMessage}
                            />
                          </>
                        )}
                        {(step === 'c3' ||
                          step === 'c3b' ||
                          step === 'c3c') && (
                          <DetailsView
                            values={values}
                            labels={labels}
                            type="recipient"
                          />
                        )}
                        <FindClinicModal
                          {...config.clinicSelectorConfig}
                          show={show}
                          onHide={() => setShow(false)}
                          labels={labels}
                        />
                      </form>
                    </section>
                    {step === 'c2a' && (
                      <>
                        <>
                          <DetailsView
                            values={values}
                            labels={labels}
                            type="recipient"
                          />
                        </>
                        <>
                          <section className="ccl-c-form-section">
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title mt-4">
                                <span>
                                  {
                                    labels[
                                      'labels.createPatient.matchingRecords'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="matching-records-table mb-6 mt-3">
                              <DataTable
                                config={config.matchingPatientsTable}
                                data={matchingPatients}
                                labels={labels}
                              />
                            </div>
                          </section>
                        </>
                      </>
                    )}
                    {(step === 'c3' ||
                      step === 'c3b' ||
                      step === 'c3c') && (
                      <>
                        <>
                          <div className="ccl-e-detail-action-bar mt-4">
                            <div className="ccl-e-detail-action-bar__title">
                              <span>
                                {
                                  labels[
                                    'labels.createPatient.findClinicTitle'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          {!showClinicTable && (
                            <div className="my-4">
                              <Alert variant="brand">
                                {
                                  labels[
                                    'labels.createPatient.findClinicMessage'
                                  ]
                                }
                              </Alert>
                            </div>
                          )}

                          {showClinicTable && (
                            <div className="ccl__clincsPopup__DataTable mb-6 mt-3">
                              <DataTable
                                config={config.selectedClinicTable}
                                data={clinicData}
                                labels={labels}
                              />
                            </div>
                          )}

                          <Button
                            text={
                              labels[
                                'labels.createPatient.addClinicButton'
                              ]
                            }
                            variant="secondary"
                            onClick={() => setShow(true)}
                            style={{ cursor: 'pointer' }}
                            disabled={clinicData.length > 0}
                            data-analytics="add_clinic_carer_details_create_patient_carer"
                          />
                        </>
                        <>
                          <section className="ccl-c-form-section">
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title">
                                <span>
                                  {
                                    labels[
                                      'labels.createPatient.carerDetails.title'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                          </section>
                          <TextInput
                            className="ccl-l-create-patient__fields__name"
                            name="firstNameCarer"
                            value={values.firstNameCarer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={labels['labels.common.firstName']}
                            promptText=""
                            error={
                              touched.firstNameCarer &&
                              !!errors.firstNameCarer
                            }
                            errorMsg={
                              touched.firstNameCarer &&
                              errors.firstNameCarer
                            }
                          />

                          <TextInput
                            className="ccl-l-create-patient__fields__name"
                            name="middleNameCarer"
                            value={values.middleNameCarer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={labels['labels.common.middleName']}
                            hint={labels['labels.common.optional']}
                            promptText=""
                            error={
                              touched.middleNameCarer &&
                              !!errors.middleNameCarer
                            }
                            errorMsg={
                              touched.middleNameCarer &&
                              errors.middleNameCarer
                            }
                          />

                          <TextInput
                            className="ccl-l-create-patient__fields__name"
                            name="lastNameCarer"
                            value={values.lastNameCarer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={labels['labels.common.lastName']}
                            promptText=""
                            error={
                              touched.lastNameCarer &&
                              !!errors.lastNameCarer
                            }
                            errorMsg={
                              touched.lastNameCarer &&
                              errors.lastNameCarer
                            }
                          />

                          <DateInput
                            className="ccl-l-advanced-search-patient-tab__fields__dob"
                            name="dateOfBirthCarer"
                            isStrict
                            label={
                              labels['labels.common.dateOfBirth']
                            }
                            hint={labels[dateHint]}
                            inputMode="numeric"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fields={dateFieldsConfigCarer}
                            error={!!dobCarerErrorMessage}
                            errorMsg={dobCarerErrorMessage}
                          />
                        </>
                        <>
                          <section className="ccl-c-form-section">
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title">
                                <span>
                                  {
                                    labels[
                                      'labels.common.cochlearAccount'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                          </section>
                          <div className="ccl__view-edit-mode">
                            <TextInput
                              className="ccl-l-advanced-search-patient-tab__fields__email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={
                                labels['labels.common.emailAddress']
                              }
                              promptText=""
                              hint={
                                labels[
                                  'labels.createPatient.emailMessage'
                                ]
                              }
                              error={touched.email && !!errors.email}
                              errorMsg={touched.email && errors.email}
                            />
                            <div className="ccl__edit-mode__element">
                              <SelectInput
                                label={
                                  labels[
                                    'labels.common.preferredLanguageActivationEmail'
                                  ]
                                }
                                name="preferredLanguage"
                                setFocus={false}
                                error={false}
                                errorMsg={errors.preferredLanguage}
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                dataList={getLanguageList()}
                                isSearchable
                                hint={
                                  labels[
                                    'labels.createPatient.carerLanguagePreference'
                                  ]
                                }
                                optionalText=""
                                defaultValue={
                                  values.preferredLanguage === ''
                                    ? initialValues.preferredLanguage
                                    : values.preferredLanguage
                                }
                                disabled={false}
                              />
                            </div>
                          </div>
                        </>
                      </>
                    )}
                    {step === 'c4a' && (
                      <>
                        <>
                          <DetailsView
                            values={values}
                            labels={labels}
                            type="carer"
                          />
                        </>
                        <>
                          <section className="ccl-c-form-section">
                            {excludePersonaSelected &&
                              excludePersonaAlert()}
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title mt-4">
                                <span>
                                  {
                                    labels[
                                      'labels.createPatient.matchingRecords'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="matching-records-table mb-6 mt-3">
                              <DataTable
                                config={
                                  config.matchingPatientsTableCarer
                                }
                                data={matchingCarers}
                                labels={labels}
                              />
                            </div>
                          </section>
                        </>
                      </>
                    )}
                    {step === 'c4b' && (
                      <>
                        <>
                          <DetailsView
                            values={values}
                            labels={labels}
                            type="carer"
                          />
                        </>
                        <>
                          <section className="ccl-c-form-section">
                            {excludePersonaSelected &&
                              excludePersonaAlert()}
                            <div className="ccl-e-detail-action-bar">
                              <div className="ccl-e-detail-action-bar__title mt-4">
                                <span>
                                  {
                                    labels[
                                      'labels.createPatient.matchingRecords'
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="matching-records-table mb-6 mt-3">
                              <DataTable
                                config={
                                  config.matchingPatientsTableCarer
                                }
                                data={matchingCarers}
                                labels={labels}
                              />
                            </div>
                          </section>
                        </>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <div className="d-flex flex-row pt-4">
            <div className="pe-2">
              <FormButton
                text={
                  step === 'c5'
                    ? labels['labels.common.createAnotherPatient']
                    : continueButtonText
                }
                variant="brand-primary"
                disabled={step === 'c5' ? false : submitDisabled}
                onClick={handleSubmit}
                type="save"
                progress={isSubmitting ? 2 : undefined}
                data-analytics={
                  step === 'c5'
                    ? 'create_new_success_create_patient_carer'
                    : continueDataAnalytics
                }
              />
            </div>
            <div className="pe-2">
              <Button
                text={
                  step === 'c5'
                    ? labels['labels.common.close']
                    : cancelButtonText
                }
                variant="secondary"
                onClick={handleCancel}
                data-analytics={
                  step === 'c5'
                    ? 'close_success_create_patient_carer'
                    : 'cancel_create_patient_carer'
                }
              />
            </div>
          </div>
        </Row>
      </Container>
      <AgreementModal
        onClose={() => {
          setAgreementModalVisible(false);
        }}
        onSubmit={handleAgreementModalSubmit}
        visible={agreementModalVisible}
        isAgreementSubmitting={isAgreementSubmitting}
        agreementConfig={config.patientAgreementmodelConfig}
        labels={labels}
      />
      <AgreementModal
        onClose={() => {
          setAgreementModalCarerVisible(false);
          setIsmatchingCarerSelected(false);
          if (step === 'c4b') setSubmitDisabled(false);
        }}
        onSubmit={handleAgreementModalCarerSubmit}
        visible={agreementModalCarerVisible}
        isAgreementSubmitting={isAgreementCarerSubmitting}
        agreementConfig={config.carerAgreementmodelConfig}
        carerInformation={matchingCarerSelected}
        labels={labels}
      />
    </div>
  );
};

export default CreatePatientCarer;
