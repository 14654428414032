export const getPatientsTableConfig = (
  handleNameClick = () => {},
  handleLocatePatient = () => {},
) => ({
  columns: [
    {
      display: 'labels.patients.columnHeaders.name',
      field: 'name',
      format: {
        type: 'link',
        onClick: handleNameClick,
      },
      hiddenColumnView: 'mobile',
      sortable: false,
    },
    {
      display: 'labels.patients.columnHeaders.dob',
      field: 'dob',
      format: {
        onClick: null,
        type: 'string',
      },
      hiddenColumnView: 'mobile',
      sortable: false,
    },
    {
      display: 'labels.patients.columnHeaders.phoneNumber',
      field: 'phoneNumber',
      format: {
        onClick: null,
        type: 'string',
      },
      hiddenColumnView: '',
      sortable: false,
    },
    {
      display: 'labels.patients.columnHeaders.email',
      field: 'email',
      format: {
        onClick: null,
        type: 'string',
      },
      hiddenColumnView: 'mobile',
      sortable: false,
    },
  ],
  country: 'US',
  formats: {
    currency: 'AUD',
    date: 'yyyy mm dd',
  },
  highlight: false,
  hover: false,
  language: 'en_US',
  lazyLoadOptions: {
    loadMoreText: 'labels.patients.lazyLoadOptions.loadMoreText',
    pageSize: '20',
  },
  loadingText: 'labels.common.loadingText',
  mode: 'lazy',
  noSearchResults: {
    additional: {
      function1: handleLocatePatient,
      function2: () => {},
      linkText1:
        'labels.patients.noSearchResults.additional.linkText1',
      linkText2:
        'labels.patients.noSearchResults.additional.linkText2',
      text1: 'labels.patients.noSearchResults.additional.text1',
      text2: 'labels.patients.noSearchResults.additional.text2',
      textOrder:
        'labels.patients.noSearchResults.additional.textOrder',
    },
    content: 'labels.patients.noSearchResults.content',
    format: 'labels.patients.noSearchResults.format',
    pictogramSrc:
      'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
  },
  resultsHeadingFormat: 'labels.patients.resultsHeadingFormat',
  resultsLimit: {
    additional: {
      function1: handleLocatePatient,
      linkText1: 'labels.patients.resultsLimit.additional.linkText1',
      text1: 'labels.patients.resultsLimit.additional.text1',
      text2: 'labels.patients.resultsLimit.additional.text2',
      textOrder: 'labels.patients.resultsLimit.additional.textOrder',
    },
    content: 'labels.patients.resultsLimit.content',
    maxResults: '40',
    pictogramSrc:
      'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
  },
});
