import React from 'react';
import {
  Text,
  Spinner,
  Button,
} from '@cochlear-design-system/foundation';
import { Error } from '../Error/Error';
import AutoReloadIFrame from '../../components/AutoReloadIFrame';

export const ServiceRequest = ({ config, labels }) => {
  // View
  let showView = '';
  if (config.serviceRequestConfig.hasError) {
    showView = 'error';
  } else if (config.isLoading !== false) {
    showView = 'spinner';
  } else if (config.isEmpty !== false) {
    showView = 'add_device';
  } else {
    showView = 'sr_frame';
  }

  const disableIframes =
    localStorage.getItem('disableIframes') === 'true';

  let serviceRequestView = null;
  switch (showView) {
    case 'error': {
      serviceRequestView = (
        <div>
          <Error
            config={config.serviceRequestConfig.errorConfig}
            labels={labels}
            data={{}}
          />
        </div>
      );
      break;
    }

    case 'add_device': {
      serviceRequestView = (
        <div className="ccl__align-cm5">
          <Text
            content={
              labels[config.labels.serviceRequestsNotApplicable]
            }
          />
          <br />
          <Button
            text={labels[config.labels.addDevice]}
            variant="brand-primary"
            icon="add"
            iconPosition="leading"
            size="medium"
            onClick={config.serviceRequestConfig.button?.onClick}
            data-analytics={
              config.serviceRequestConfig.button?.analytics
            }
          />
        </div>
      );
      break;
    }

    case 'sr_frame': {
      let iframeStyle = { display: 'none' };
      if (config.serviceRequestConfig.isLoading === false) {
        iframeStyle = { width: '100%', height: '100vh' };
      }

      serviceRequestView = (
        <>
          {config.serviceRequestConfig.isLoading && (
            <div className="ccl__spinner">
              <Spinner animation="border" />
              <Text content={labels[config.labels.loading]} />
            </div>
          )}
          <div>
            {!disableIframes && (
              <AutoReloadIFrame
                src={config.serviceRequestConfig.serviceRequestUrl}
                title="Service Request"
                style={iframeStyle}
                enabled={config.serviceRequestConfig.isLoading}
                timeSeconds={10}
              />
            )}
          </div>
        </>
      );
      break;
    }

    default: {
      serviceRequestView = (
        <div className="ccl__spinner">
          <Spinner animation="border" />
          <Text content={labels[config.labels.loading]} />
        </div>
      );
    }
  }

  return <>{serviceRequestView}</>;
};
