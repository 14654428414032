import axios from 'axios';
import { providerListEndpoint } from '../../config';
import getHeaders from '../../utils/getHeaders';
import { logger } from '../../logger';

const log = logger();

const getProfessionalList = async (
  providerId,
  deviceType,
  selectedProviderId,
) => {
  const roles =
    deviceType === 'SPEECH_PROCESSOR'
      ? 'Fitter,AUDIOLOGIST,Bimodal Fitter'
      : 'Implanter,SURGEON,IMPLANT_SURGEON,Implant Supporter';
  try {
    const { data } = await axios.get(
      `${providerListEndpoint}/${providerId}/professionals?roles=${roles}&selectedProviderId=${selectedProviderId}`,
      getHeaders(),
    );
    return data ?? [];
  } catch (err) {
    log.error('Error getting professional list', err);
    return null;
  }
};

const fetchProfessionalsService = {
  getProfessionalList,
};

export default fetchProfessionalsService;
