import React, { useState } from 'react';
import {
  Modal,
  CheckboxInput,
  Button,
  FormButton,
  Alert,
} from '@cochlear-design-system/foundation';
import {getLabelsForFields} from "../../utils/labelHelper";
import patientService from '../../services/patientService';
import carerService from '../../services/carerService';

const MarkDeceasedModal = ({ fields, show, onHide, providerId, patientId, carerId }) => {
  const labels = getLabelsForFields(fields);

  const markDeceasedTitle = labels['labels.patientRecord.views.markDeceased.title'];
  const markDeceasedMessage = carerId ? 
    labels['labels.patientRecord.views.carer.markDeceased.message']
    : labels['labels.patientRecord.views.patient.markDeceased.message']; 
  const [markDeceasedConfirmed, setMarkDeceasedConfirmed] = useState(false);
  const [markDeceasedInProgress, setMarkDeceasedInProgress] = useState(false);
  const [showError, setShowError] = useState(false);

  const onMarkDeceasedConfirm = async () => {
    setMarkDeceasedInProgress(true);
    setShowError(false);
    const success = carerId ? 
      await carerService.markCarerAsDeceased(providerId, patientId, carerId)
      : await patientService.markPatientAsDeceased(providerId, patientId);
    if (success) {
      onHide(true);
    } else {
      setShowError(true);
    }
    setMarkDeceasedInProgress(false);
  }
  
  const onCancel = () => {
    setShowError(false);
    setMarkDeceasedInProgress(false);
    setMarkDeceasedConfirmed(false);
    if (onHide) {
      onHide(false);
    }
  }

  return (
    <Modal
      title={markDeceasedTitle}
      size="lg"
      show={show}
      onHide={onCancel}
      centered
    >
      <Modal.Header closeButton data-analytics="cancel_header_mark_deceased_modal">
        <Modal.Title>{markDeceasedTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          show={showError}
          variant="error"
        >
          { carerId ? (
            labels[
              'labels.patientRecord.views.carer.markDeceased.error'
            ]
            ): (
            labels[
              'labels.patientRecord.views.patient.markDeceased.error'
            ]
            )
          }
        </Alert>
        <CheckboxInput
          label={markDeceasedMessage}
          name="confirm"
          id="confirm"
          disabled={false}
          onChange={() => setMarkDeceasedConfirmed(!markDeceasedConfirmed)}
          value={markDeceasedConfirmed}
        />
        
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onCancel}
          text={labels['labels.common.cancel']}
          variant="tertiary"
          data-analytics="cancel_mark_deceased_modal"
        />                
        <FormButton
          text={!markDeceasedInProgress ? labels['labels.common.confirm'] : labels['labels.common.saving']}
          variant="brand-primary"
          disabled={!markDeceasedConfirmed || markDeceasedInProgress}
          onClick={onMarkDeceasedConfirm}
          type="save"
          progress={markDeceasedInProgress ? 2 : undefined}
          data-analytics="confirm_mark_deceased_modal"
        />
        
      </Modal.Footer>
    </Modal>
  );
};


export default MarkDeceasedModal;
