export const dataTableConfig = (redirectToAccessoryReview) => {
  return {
    columns: [
      {
        display: 'labels.deviceRegistration.tabs.device.columnTitle1',
        field: 'description',
        format: {
          collapsed: {
            type: 'composite',
            template:
              '<div><span class="ccl__text ccl__text--interactive-text">{{type}}</span></div><div><em></em></div><div><span class="ccl__text ccl__text--body-text">{{description}}</span></div> <div><em></em></div>',
          },
          expanded: {
            type: 'emptycell',
          },
          onClick: () => {},
        },
        hiddenColumnView: 'mobile',
        sortable: false,
      },
      {
        display:
          'labels.deviceRegistration.tabs.device.columnTitle2.partNumber',
        field: 'partNumber',
        format: {
          type: 'string',
        },
        hiddenColumnView: 'mobile',
        sortable: false,
      },
    ],
    country: 'US',
    formats: {
      currency: 'USD',
      date: 'mmm dd, yyyy',
    },
    handleSortDataChange: () => {},
    language: 'en',
    highlight: false,
    clickableRow: true,
    isClinicPopup: false,
    events: {
      onLoadMore: () => {},
      // eslint-disable-next-line no-unused-vars
      onRowClick: (actions, row) => {
        redirectToAccessoryReview(row.data);
      },
      onSort: () => {},
    },
  };
};
