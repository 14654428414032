/** used by the new FindClinicModal's CountrySelect */
export const clinicCountries = [
  'DZ',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BO',
  'BA',
  'BR',
  'BG',
  'CA',
  'CL',
  'CN',
  'CO',
  'CR',
  'HR',
  'CY',
  'CZ',
  'DK',
  'DO',
  'EC',
  'EG',
  'SV',
  'EE',
  'FI',
  'FR',
  'PF',
  'GE',
  'DE',
  'GH',
  'GR',
  'GU',
  'GT',
  'HN',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KR',
  'XK',
  'ZZ',
  'KW',
  'KG',
  'LV',
  'LB',
  'LY',
  'LT',
  'LU',
  'MK',
  'MY',
  'MT',
  'MQ',
  'MR',
  'MX',
  'MD',
  'MA',
  'NA',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NG',
  'XI',
  'NO',
  'OM',
  'PK',
  'PS',
  'PA',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'SA',
  'SN',
  'RS',
  'SG',
  'SK',
  'SI',
  'ZA',
  'ES',
  'LK',
  'SD',
  'SR',
  'SE',
  'CH',
  'TW',
  'TZ',
  'TH',
  'TT',
  'TN',
  'TR',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VE',
  'VN',
  'VI',
  'YE',
];

/** Used in Device Registration which still uses adaptiveForm's associatedClinicPopup */
export const clinicCountriesFull = [
  {
    value: 'DZ',
    label: 'Algeria',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AT',
    label: 'Austria',
  },
  {
    value: 'AZ',
    label: 'Azerbaijan',
  },
  {
    value: 'BH',
    label: 'Bahrain',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Belarus',
  },
  {
    value: 'BE',
    label: 'Belgium',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnia',
  },
  {
    value: 'BR',
    label: 'Brazil',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'China',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'HR',
    label: 'Croatia',
  },
  {
    value: 'CY',
    label: 'Cyprus',
  },
  {
    value: 'CZ',
    label: 'Czech Republic',
  },
  {
    value: 'DK',
    label: 'Denmark',
  },
  {
    value: 'DO',
    label: 'Dominican Republic',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egypt',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'EE',
    label: 'Estonia',
  },
  {
    value: 'FI',
    label: 'Finland',
  },
  {
    value: 'FR',
    label: 'France',
  },
  {
    value: 'PF',
    label: 'French Polynesia',
  },
  {
    value: 'GE',
    label: 'Georgia',
  },
  {
    value: 'DE',
    label: 'Germany',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GR',
    label: 'Greece',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HU',
    label: 'Hungary',
  },
  {
    value: 'IS',
    label: 'Iceland',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonesia',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Iraq',
  },
  {
    value: 'IE',
    label: 'Ireland',
  },
  {
    value: 'IL',
    label: 'Israel',
  },
  {
    value: 'IT',
    label: 'Italy',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japan',
  },
  {
    value: 'JO',
    label: 'Jordan',
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
  },
  {
    value: 'KE',
    label: 'Kenya',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwait',
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan',
  },
  {
    value: 'LV',
    label: 'Latvia',
  },
  {
    value: 'LB',
    label: 'Lebanon',
  },
  {
    value: 'LY',
    label: 'Libya',
  },
  {
    value: 'LT',
    label: 'Lithuania',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
  },
  {
    value: 'MK',
    label: 'Macedonia',
  },
  {
    value: 'MY',
    label: 'Malaysia',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritania',
  },
  {
    value: 'MX',
    label: 'Mexico',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MA',
    label: 'Morocco',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Netherlands',
  },
  {
    value: 'NC',
    label: 'New Caledonia',
  },
  {
    value: 'NZ',
    label: 'New Zealand',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  // {
  //   value: 'XI',
  //   label: 'Northern Ireland',
  // },
  {
    value: 'NO',
    label: 'Norway',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PS',
    label: 'Palestine',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Philippines',
  },
  {
    value: 'PL',
    label: 'Poland',
  },
  {
    value: 'PT',
    label: 'Portugal',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RE',
    label: 'Reunion',
  },
  {
    value: 'RO',
    label: 'Romania',
  },
  {
    value: 'RU',
    label: 'Russia',
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'SK',
    label: 'Slovakia',
  },
  {
    value: 'SI',
    label: 'Slovenia',
  },
  {
    value: 'ZA',
    label: 'South Africa',
  },
  {
    value: 'ES',
    label: 'Spain',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Surilabel',
  },
  {
    value: 'SE',
    label: 'Sweden',
  },
  {
    value: 'CH',
    label: 'Switzerland',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TZ',
    label: 'Tanzania, United Republic of',
  },
  {
    value: 'TH',
    label: 'Thailand',
  },
  {
    value: 'TT',
    label: 'Trinidad & Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisia',
  },
  {
    value: 'TR',
    label: 'Turkey',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraine',
  },
  {
    value: 'AE',
    label: 'United Arab Emirates',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
  },
  {
    value: 'US',
    label: 'United States',
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'YE',
    label: 'Yemen',
  },
];
