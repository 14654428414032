import {
  MATCHING_PATIENT_DETAILS_SUCCESS,
  MATCHING_PATIENT_DETAILS_FAILURE,
} from './types';
import { logger } from '../logger';

const log = logger();

const matchingPatientDetails = (patient) => async (dispatch) => {
  try {
    dispatch({
      type: MATCHING_PATIENT_DETAILS_SUCCESS,
      payload: patient,
    });
  } catch (err) {
    log.error('Error dispatching matching patient details', err);
    dispatch({
      type: MATCHING_PATIENT_DETAILS_FAILURE,
      payload: err,
    });
  }
};

export default matchingPatientDetails;
