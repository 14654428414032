import React from 'react';
import { Alert } from '@cochlear-design-system/foundation';

const AlertComponent = ({ step, labels }) => {
  const alertConfig = {
    c2a: {
      variant: 'warning',
      heading:
        labels[
          'labels.createPatient.matchingRecordsRecipient.heading'
        ],
      message:
        labels[
          'labels.createPatient.matchingRecordsRecipientMessage'
        ],
    },
    c4a: {
      variant: 'warning',
      heading:
        labels['labels.createPatient.matchingRecordsCarer.heading'],
      message:
        labels['labels.createPatient.matchingRecordsCarerMessage'],
    },
    c4b: {
      variant: 'warning',
      heading:
        labels['labels.createPatient.matchingRecordsCarer.heading'],
      message:
        labels['labels.createPatient.matchingRecordsCarerMessagePII'],
    },
    c3b: {
      variant: 'error',
      heading: labels['labels.createPatient.duplicateEmailTitle'],
      messageBefore:
        labels['labels.createPatient.duplicateEmailMessageBefore'],
      messageInside:
        labels['labels.createPatient.duplicateEmailMessageInside'],
      messageAfter:
        labels['labels.createPatient.duplicateEmailMessageAfter'],
      messageLink:
        labels['labels.createPatient.duplicateEmailMessageLink'],
    },
    c3c: {
      variant: 'error',
      heading: '',
      message:
        labels[
          'labels.createPatient.matchingInformation.errorMessage'
        ],
    },
  };

  if (Object.prototype.hasOwnProperty.call(alertConfig, step)) {
    const {
      variant,
      heading,
      message,
      messageBefore,
      messageInside,
      messageAfter,
      messageLink,
    } = alertConfig[step];
    return step === 'c3b' ? (
      <Alert variant={variant} heading={heading}>
        {messageBefore}
        <a target="_blank" rel="noreferrer" href={messageLink}>
          {messageInside}
        </a>
        {messageAfter}
      </Alert>
    ) : (
      <section className="ccl-c-form-section mt-4">
        <Alert variant={variant} heading={heading}>
          {message}
        </Alert>
      </section>
    );
  }

  return null;
};

export default AlertComponent;
