export const RESET = 'RESET';
export const FETCH_ID_TOKEN_SUCCESS = 'FETCH_ID_TOKEN_SUCCESS';
export const FETCH_ID_TOKEN_FAILURE = 'FETCH_ID_TOKEN_FAILURE';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';
export const ERROR_SUCCESS = 'ERROR_SUCCESS';
export const ERROR_FAILURE = 'ERROR_FAILURE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const PROVIDER_DETAILS_SUCCESS = 'PROVIDER_DETAILS_SUCCESS';
export const PROVIDER_DETAILS_FAILURE = 'PROVIDER_DETAILS_FAILURE';
export const MODAL_TRIGGER_OPEN = 'MODAL_TRIGGER_OPEN';
export const MODAL_TRIGGER_CLOSE = 'MODAL_TRIGGER_CLOSE';
export const NOTIFICATION_STATUS_FAILURE =
  'NOTIFICATION_STATUS_FAILURE';
export const NOTIFICATION_STATUS_DELETE =
  'NOTIFICATION_STATUS_DELETE';
export const NOTIFICATION_STATUS_APPEND =
  'NOTIFICATION_STATUS_APPEND';
export const NOTIFICATION_IS_QUEUED = 'NOTIFICATION_IS_QUEUED';
export const NOTIFICATION_UPDATE_RETRY = 'NOTIFICATION_UPDATE_RETRY';
export const STEP_SUCCESS = 'STEP_SUCCESS';
export const STEP_FAILURE = 'STEP_FAILURE';
export const NEW_PATIENT_DETAILS_SUCCESS =
  'NEW_PATIENT_DETAILS_SUCCESS';
export const NEW_PATIENT_DETAILS_FAILURE =
  'NEW_PATIENT_DETAILS_FAILURE';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const MATCHING_PATIENT_DETAILS_SUCCESS =
  'MATCHING_PATIENT_DETAILS_SUCCESS';
export const MATCHING_PATIENT_DETAILS_FAILURE =
  'MATCHING_PATIENT_DETAILS_FAILURE';
