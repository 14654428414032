import React from 'react';
import { Text } from '@cochlear-design-system/foundation';
import {getDobFormatted} from '../../../utils/dateTimeFns';

export const DetailsView = ({ values, labels, type }) => {

  const name =
    type === 'recipient'
      ? `${values.firstName} ${values.middleName || ''} ${
          values.lastName
        }`
      : `${values.firstNameCarer} ${values.middleNameCarer || ''} ${
          values.lastNameCarer
        }`;
  const dob =
    type === 'recipient'
      ? getDobFormatted(values.day, values.month, values.year)
      : getDobFormatted(values.dayCarer, values.monthCarer, values.yearCarer);
  return (
    <>
      <div className="mt-3 ccl__flex">
        <Text
          content={labels['labels.common.name']}
          type="body-text-bold"
          style={{ minWidth: '8rem' }}
        />
        <Text content={name} />
      </div>
      <div className="mt-2 ccl__flex">
        <Text
          content={labels['labels.common.dateOfBirth']}
          type="body-text-bold"
          style={{ minWidth: '8rem' }}
        />
        <Text content={dob} />
      </div>
    </>
  );
};
