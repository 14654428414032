import React from 'react';
import { Modal } from '@cochlear-design-system/foundation';

export const ModalHeader = ({ title }) => {
  return (
    <Modal.Header closeButton data-analytics="close_find_clinic">
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  );
};
