import {
  NEW_PATIENT_DETAILS_SUCCESS,
  NEW_PATIENT_DETAILS_FAILURE,
} from '../actions/types';

const initialState = null;

export default function newPatientDetailsReducer(
  state = initialState,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case NEW_PATIENT_DETAILS_SUCCESS:
      return payload;
    case NEW_PATIENT_DETAILS_FAILURE:
      return false;

    default:
      return state;
  }
}
