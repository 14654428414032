import { getFooterConfig } from './footerConfig';

export const dataMapper = (labels) => {
  const footerConfig = getFooterConfig(labels);
  return {
    config: {
      links: footerConfig.links.targetItem.children.map(
        (link, index) => ({
          id: index,
          label: labels[link.label],
          url: link.type.url,
          target: link.type.linkType === 'external' ? '_blank' : '',
          analytics: link.analytics,
        }),
      ),
      copyright: 'copyright',
    },
    caseTypes: footerConfig.caseTypes,
    channel: footerConfig.channel,
  };
};
